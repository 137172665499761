<template>
	<div class="_modal bg-white">
		<div
			class="
				_modal__content
				md-down:px-4
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<img
					alt="Luca Education"
					height="250"
					src="@/assets/images/illustrations/send-parent.png"
					title="Luca Education"
					width="250"
				/>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
					text-center
				"
			>
				Gửi Kết quả can thiệp cho phụ huynh
			</div>
			<div class="text-center mt-5 text-neutral-500">
				Kết quả can thiệp này sẽ được gửi đến phụ huynh qua tin nhắn và
				lưu vào kết quả đánh giá của trẻ
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				type="button"
				@click="$emit('close')"
			>
				Quay lại
			</button>
			<button
				class="button-p text-center whitespace-nowrap"
				type="button"
				@click="$emit('submit')"
			>
				Gửi phụ huynh
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ConfirmSend',
};
</script>
