<template>
	<div class="_invite-employee _modal bg-white">
		<div
			class="
				_modal__content
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<svg
					width="60"
					height="61"
					viewBox="0 0 60 61"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M29.8034 3.88292C15.2228 3.88292 3.36674 15.8238 3.36674 30.501C3.36674 45.1762 15.2228 57.1171 29.8034 57.1171C44.3839 57.1171 56.2452 45.1762 56.2452 30.501C56.2452 15.8238 44.3839 3.88292 29.8034 3.88292ZM29.8034 60.5C13.3737 60.5 0 47.0418 0 30.501C0 13.9582 13.3737 0.5 29.8034 0.5C46.2382 0.5 59.6068 13.9582 59.6068 30.501C59.6068 47.0418 46.2382 60.5 29.8034 60.5Z"
						fill="#F4C870"
					/>
					<path
						d="M52.5551 30.5008C52.5551 43.1491 42.3697 53.4023 29.8011 53.4023C17.2376 53.4023 7.05225 43.1491 7.05225 30.5008C7.05225 17.8515 17.2376 7.59775 29.8011 7.59775C42.3697 7.59775 52.5551 17.8515 52.5551 30.5008Z"
						fill="#F0BB4F"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M42.5165 30.5002C42.5165 37.5694 36.8237 43.3002 29.8013 43.3002C22.7789 43.3002 17.0861 37.5694 17.0861 30.5002C17.0861 23.431 22.7789 17.7002 29.8013 17.7002C36.8237 17.7002 42.5165 23.431 42.5165 30.5002ZM30.596 24.9002C30.596 24.4584 30.2402 24.1002 29.8013 24.1002C29.3624 24.1002 29.0066 24.4584 29.0066 24.9002V29.7002H24.2384C23.7995 29.7002 23.4437 30.0584 23.4437 30.5002C23.4437 30.942 23.7995 31.3002 24.2384 31.3002H29.0066V36.1002C29.0066 36.542 29.3624 36.9002 29.8013 36.9002C30.2402 36.9002 30.596 36.542 30.596 36.1002V31.3002H35.3642C35.8031 31.3002 36.1589 30.942 36.1589 30.5002C36.1589 30.0584 35.8031 29.7002 35.3642 29.7002H30.596V24.9002Z"
						fill="white"
					/>
				</svg>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
				"
			>
				Thêm Lĩnh vực
			</div>
			<div class="text-neutral-400 text-center mt-6">
				Bạn hãy điền tên lĩnh vực và ấn “Xác nhận” để thêm lĩnh vực mới.
			</div>
			<div class="w-full mt-10">
				<div class="input-group flex items-center">
					<div
						class="
							input-group__label
							text-neutral-400
							font-semibold
							w-2/5
							uppercase
						"
					>
						Tên lĩnh vực
					</div>
					<input
						type="text"
						placeholder="Gõ tên lĩnh vực"
						v-model="name"
						:class="error ? 'input--error' : ''"
					/>
				</div>
				<div class="input-group flex items-center" v-if="error">
					<div class="w-2/5"></div>
					<div class="mt-4 w-full">
						<div class="error text-sienna-600 text-sm w-full">
							{{ error }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				type="button"
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				@click="$emit('close')"
			>
				Huỷ
			</button>
			<button
				type="button"
				class="button-p text-center whitespace-nowrap"
				:disabled="inProgress"
				@click="submit"
			>
				Xác nhận
			</button>
		</div>
	</div>
</template>

<script>
import { $api } from '@/services';

export default {
	name: 'AddSkill',

	components: {},

	data() {
		return {
			inProgress: false,
			name: '',
			error: '',
		};
	},

	methods: {
		async submit() {
			this.error = '';
			this.inProgress = true;
			try {
				await $api.goal.addSkill({
					name: this.name,
				});
				this.$store.dispatch('setAlertMessage', {
					message: 'Thêm lĩnh vực thành công',
				});
				this.$emit('created');
			} catch (e) {
				this.error = e?.response?.data?.errors?.join('<br/>');
				setTimeout(() => {
					this.error = '';
				}, 3000);
			} finally {
				this.inProgress = false;
			}
		},
	},
};
</script>
