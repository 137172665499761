<template>
	<ViewPhoto
		v-if="imageShow && goal_media.length"
		:key="photosIndex"
		:images="goal_media"
		:index="photosIndex"
		@close="imageShow = false"
	/>
	<div v-if="!isLoading" class="__student-detail pb-30">
		<div class="__goal-management-student-detail p-10 md-down:px-4">
			<div class="__goal-management-student-detail__header pt-5">
				<div v-if="groupClass" class="flex gap-6">
					<UserGroup
						:data="groupClass?.students"
						class="mt-2"
						placement="bottom-start"
					/>
					<div>
						<div class="text-neutral-700 font-bold text-4xl">
							{{ groupClass?.name }}
						</div>
						<div class="uppercase text-green-500 font-semibold">
							{{ groupClass?.students?.length }} học sinh
						</div>
					</div>
				</div>
				<div class="mt-8">
					<div class="border-b border-neutral-200 md-down:hidden">
						<nav
							aria-label="Tabs"
							class="-mb-px flex space-x-8 tabs overflow-auto"
						>
							<div
								v-for="item in groupClass?.students"
								:key="item?.id"
								:class="
									tab?.id === item?.id
										? 'text-yellow-700 border-yellow-500'
										: 'text-neutral-500 hover:text-yellow-700 hover:border-yellow-500'
								"
								class="
									border-transparent
									whitespace-nowrap
									py-3
									px-1
									border-b-2
									font-medium
									cursor-pointer
								"
								@click="changeStudent(item)"
							>
								{{ item?.name }}
							</div>
						</nav>
					</div>
					<div class="md:hidden">
						<div class="p-6 bg-white select-tabs">
							<Multiselect
								v-if="groupClass?.students"
								v-model="tab"
								:allow-empty="false"
								:options="groupClass?.students"
								:searchable="true"
								deselect-label="Đã chọn"
								label="name"
								placeholder=""
								selectLabel=""
								track-by="id"
								@change="(item) => changeStudent(item)"
							>
								<template #noResult>
									Không tìm thấy kết quả
								</template>
								<template v-slot:option="props">
									<div class="flex flex-nowrap items-center">
										<div>
											{{ props.option.name }}
										</div>
									</div>
								</template>
								<template v-slot:singleLabel="{ option }">
									<div
										class="
											flex flex-nowrap
											items-center
											font-medium
										"
									>
										<div>{{ option.name }}</div>
									</div>
								</template>
							</Multiselect>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="
				__goal-management-student-detail__overview
				px-10
				pb-24
				md-down:px-4
			"
		>
			<div class="flex items-center">
				<div class="relative">
					<img
						v-if="student?.profile_photo?.w200"
						:alt="student?.name"
						:src="student?.profile_photo?.w200"
						class="shadow-sm inline-block h-14 w-14 rounded-full"
						style="min-width: 56px"
					/>
					<span
						v-else
						:style="{
							background: student?.profile_photo?.default?.color,
						}"
						class="
							inline-flex
							items-center
							justify-center
							h-14
							w-14
							rounded-full
						"
						style="min-width: 56px"
					>
						<span
							class="font-medium leading-none text-white text-4xl"
							>{{
								student?.profile_photo?.default?.initial
							}}</span
						>
					</span>
					<div
						v-if="student?.profile_photo?.state !== 'active'"
						class="
							absolute
							transform
							-translate-x-1/2 -translate-y-1/2
							top-1/2
							left-1/2
							h-14
							w-14
							rounded-full
							flex
							items-center
							justify-center
						"
						style="
							min-width: 56px;
							background-color: rgba(241, 162, 103, 0.5);
						"
					>
						<svg
							fill="none"
							height="32"
							viewBox="0 0 40 40"
							width="32"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clip-rule="evenodd"
								d="M9.84364 16.0997V10.2496C9.84364 4.86466 14.3907 0.5 20 0.5C25.6093 0.5 30.1564 4.865 30.1564 10.2496V16.0997C33.5218 16.0997 36.25 18.7188 36.25 21.9497V33.6499C36.25 36.8812 33.5218 39.5 30.1564 39.5H9.84364C6.47821 39.5 3.75 36.8812 3.75 33.6499V21.9497C3.75 18.7188 6.47821 16.0997 9.84364 16.0997V16.0997ZM26.0936 16.0997V10.2496C26.0936 7.01866 23.3654 4.3995 20 4.3995C16.6346 4.3995 13.9064 7.01866 13.9064 10.2496V16.0997H26.0936ZM20 23.9C21.1218 23.9 22.0311 24.7729 22.0311 25.8499V29.7501C22.0311 30.8271 21.1218 31.7 20 31.7C18.8782 31.7 17.9689 30.8271 17.9689 29.7501V25.8499C17.9689 24.7729 18.8782 23.9 20 23.9Z"
								fill="#FAF5F1"
								fill-rule="evenodd"
							/>
						</svg>
					</div>
				</div>
				<div class="ml-4">
					<div
						class="
							font-bold
							text-neutral-700 text-4xl
							md-down:text-2xl
						"
					>
						{{ student?.name }}
					</div>
					<div
						v-if="student?.age"
						class="
							text-green-500
							font-semibold
							text-xs
							uppercase
							letter-spacing-8
						"
					>
						<span>{{ student?.age }} </span>
					</div>
				</div>
			</div>
			<div
				v-if="group_assessment?.cooperation_scale"
				class="w-full flex md-down:flex-col mt-10"
			>
				<div
					class="
						bg-white
						shadow-sm
						rounded-2xl
						w-1/2
						md-down:w-full
						pb-8
						mr-8
						md-down:mr-0 md-down:mb-8
					"
				>
					<div class="font-semibold text-neutral-500 p-6">
						Mức độ tham gia
					</div>
					<div
						class="
							p-6
							border-t border-orange-100
							text-neutral-700
							flex
							items-center
							flex-col
						"
					>
						<Rating
							:rating="group_assessment.cooperation_scale || 0"
							class="max-w-min py-6"
						/>
						<div>
							{{
								$filters.cooperation(
									group_assessment.cooperation_scale + '' ||
										'0'
								)
							}}
						</div>
					</div>
				</div>
				<div
					v-if="group_assessment?.emotion_scale"
					class="
						bg-white
						shadow-sm
						rounded-2xl
						h-full
						w-1/2
						md-down:w-full
					"
				>
					<div class="font-semibold text-neutral-500 p-6">
						Nhiệt kế cảm xúc
					</div>
					<div
						class="
							p-6
							border-t border-orange-100
							text-neutral-700
							flex
							items-center
							flex-col
						"
					>
						<div>
							<img
								v-if="group_assessment.emotion_scale"
								:src="
									require(`@/assets/images/emoji/${
										$filters.emoji(
											group_assessment.emotion_scale + ''
										).emoji
									}`)
								"
								alt=""
								height="120"
								width="120"
							/>
						</div>
						<div
							v-if="group_assessment.emotion_scale"
							class="w-full text-center mt-4"
						>
							{{
								$filters.emoji(
									group_assessment.emotion_scale + ''
								).message
							}}
						</div>
					</div>
				</div>
			</div>
			<template
				v-if="
					group_assessment?.emotion_scale ||
					group_assessment?.cooperation_scale
				"
			>
				<div class="mt-10">
					<div class="relative">
						<div
							aria-hidden="true"
							class="absolute inset-0 flex items-center"
						>
							<div class="w-full border-t border-neutral-300" />
						</div>
						<div
							class="relative flex justify-center cursor-pointer"
						>
							<div
								class="
									px-3
									bg-body
									p-2
									text-lg
									font-semibold
									text-neutral-600
									flex
									items-center
								"
							>
								<div class="ml-2 text-sm text-neutral-600">
									Các mục tiêu đã học
								</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div
						v-for="(ass, index) in goal_with_skills"
						:key="index"
						class="mt-10"
					>
						<div
							class="font-semibold text-lg text-neutral-600 mb-2"
						>
							{{ index + 1 }}. {{ ass?.skill?.name }}
						</div>
						<div
							class="
								shadow-sm
								sm:rounded-2xl
								overflow-hidden
								md-down:hidden
							"
						>
							<div
								class="
									__record__table
									overflow-x-auto
									scroll-bar
								"
							>
								<div
									class="
										overflow-hidden
										sm:rounded-t-2xl
										w-fit
										min-w-full
									"
								>
									<table
										class="
											min-w-full
											divide-y
											text-neutral-200
										"
									>
										<thead class="bg-neutral-100 h-16">
											<tr>
												<th
													class="
														px-3
														py-3
														text-center text-xs
														font-semibold
														text-neutral-600
														uppercase
														tracking-wider
														whitespace-nowrap
														w-16
													"
													scope="col"
												>
													STT
												</th>
												<th
													class="
														px-3
														py-3
														text-left text-xs
														font-semibold
														text-neutral-600
														uppercase
														tracking-wider
														whitespace-nowrap
													"
													scope="col"
												>
													Mục Tiêu
												</th>
												<th
													class="
														px-3
														py-3
														text-left text-xs
														font-semibold
														text-neutral-600
														uppercase
														tracking-wider
														whitespace-nowrap
													"
													scope="col"
												>
													Album
												</th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="(
													goal, index
												) in ass?.goals"
												:key="goal.id"
												:class="
													index % 2 === 0
														? 'bg-white'
														: 'bg-neutral-50'
												"
												class="
													text-neutral-700
													hover:bg-orange-50
													cursor-pointer
												"
												@click="
													$router.push(
														`/group-class/${id}/${
															goal.goal_id ||
															goal.id
														}`
													)
												"
											>
												<td
													class="
														px-3
														py-4
														text-center
														whitespace-nowrap
														text-neutral-700
													"
												>
													<div>
														{{ index + 1 }}
													</div>
												</td>
												<td
													class="
														px-3
														py-4
														whitespace-nowrap
														text-neutral-700
														w-full
													"
												>
													<div>
														<div
															class="
																flex
																items-center
															"
														>
															<div
																class="
																	mr-2
																	hover:text-yellow-700
																	hover:underline
																"
															>
																{{
																	goal.goal_name ||
																	goal.name
																}}
															</div>
															<TooltipGoalDetail
																:goal-id="
																	goal.goal_id ||
																	goal.id
																"
																:is-group="true"
																:student-id="
																	id.toString()
																"
															/>
														</div>
														<div
															v-if="
																goal?.assistance_levels
															"
															class="
																flex flex-wrap
																gap-2
															"
														>
															<div
																v-for="ass in goal?.assistance_levels"
																:key="ass"
															>
																<div
																	class="
																		mt-1
																		py-0.5
																		px-2.5
																		rounded-full
																		bg-pink-100
																		whitespace-nowrap
																		text-neutral-700
																		h-fit
																		w-fit
																		font-semibold
																		shadow-input
																	"
																	style="
																		font-size: 10px;
																	"
																>
																	{{ ass }}
																</div>
															</div>
														</div>
													</div>
												</td>
												<td
													class="
														px-3
														py-4
														text-center
														whitespace-nowrap
														text-neutral-700
													"
												>
													<div>
														<svg
															v-if="
																haveMedia(
																	goal?.id
																)
															"
															fill="none"
															height="20"
															viewBox="0 0 20 20"
															width="20"
															xmlns="http://www.w3.org/2000/svg"
															@click.stop="
																openMediaPopup(
																	0
																)
															"
														>
															<path
																clip-rule="evenodd"
																d="M15.002 5.00006H2.50195C1.8116 5.00006 1.25195 5.55971 1.25195 6.25006V16.2501L4.56001 13.3081C4.77068 13.0975 5.10075 13.0648 5.34864 13.23L8.67232 15.4458L13.31 10.8081C13.5002 10.6179 13.7908 10.5708 14.0315 10.6911L16.252 13.1251V6.25006C16.252 5.55971 15.6923 5.00006 15.002 5.00006ZM2.50195 3.75006C1.12124 3.75006 0.00195312 4.86935 0.00195312 6.25006V16.2501C0.00195312 17.6308 1.12124 18.7501 2.50195 18.7501H15.002C16.3827 18.7501 17.502 17.6308 17.502 16.2501V6.25006C17.502 4.86935 16.3827 3.75006 15.002 3.75006H2.50195ZM7.50195 9.37506C7.50195 10.4106 6.66249 11.2501 5.62695 11.2501C4.59142 11.2501 3.75195 10.4106 3.75195 9.37506C3.75195 8.33953 4.59142 7.50006 5.62695 7.50006C6.66249 7.50006 7.50195 8.33953 7.50195 9.37506Z"
																fill="#E5A42B"
																fill-rule="evenodd"
															/>
															<path
																clip-rule="evenodd"
																d="M4.5 2.25006H18C18.5523 2.25006 19 2.69778 19 3.25006V14.2501C19 14.8023 18.5523 15.2501 18 15.2501H17.5V16.2501H18C19.1046 16.2501 20 15.3546 20 14.2501V3.25006C20 2.14549 19.1046 1.25006 18 1.25006H4.5C3.39543 1.25006 2.5 2.14549 2.5 3.25006V3.75006H3.5V3.25006C3.5 2.69778 3.94772 2.25006 4.5 2.25006Z"
																fill="#E5A42B"
																fill-rule="evenodd"
															/>
														</svg>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div>
							<div
								v-for="assessment in ass?.assessments"
								:key="assessment.id"
								class="
									shadow-sm
									rounded-lg
									overflow-hidden
									md:hidden
									mb-4
									bg-white
								"
								@click="
									$router.push(
										`/goal/${student.id}/${assessment.goal_id}`
									)
								"
							>
								<div class="font-semibold text-neutral-600 p-4">
									<div>
										{{ assessment?.goal?.name }}
									</div>
									<div class="flex items-center">
										<div
											v-if="assessment?.goal?.raw_source"
											class="
												mt-1
												py-0.5
												px-2.5
												rounded-full
												bg-neutral-200
												text-neutral-700
												h-fit
												w-fit
												whitespace-nowrap
												font-semibold
												shadow-input
											"
											style="font-size: 10px"
										>
											{{
												assessment?.goal?.display_source
											}}
										</div>
										<div
											v-if="assessment?.goal?.state"
											class="
												mt-1
												py-0.5
												px-2.5
												rounded-full
												bg-neutral-200
												text-neutral-700
												h-fit
												w-fit
												font-semibold
												shadow-input
												whitespace-nowrap
											"
											style="font-size: 10px"
										>
											{{
												$filters.sourceMap(
													assessment?.goal?.state,
													assessment?.goal.editable
												)
											}}
										</div>
										<template
											v-if="
												assessment?.goal
													?.assistance_levels
											"
										>
											<div
												v-for="ass in assessment?.goal
													?.assistance_levels"
												:key="ass"
											>
												<div
													class="
														mt-1
														py-0.5
														px-2.5
														rounded-full
														bg-pink-100
														whitespace-nowrap
														text-neutral-700
														h-fit
														w-fit
														font-semibold
														shadow-input
													"
													style="font-size: 10px"
												>
													{{ ass }}
												</div>
											</div>
										</template>
									</div>
								</div>
								<div class="p-4 border-t border-orange-100">
									<div
										v-if="assessment?.activities_desc"
										class="
											uppercase
											font-semibold
											text-neutral-400
										"
									>
										Hoạt động
									</div>
									<div>
										<div
											v-if="assessment.activities"
											class="w-60 whitespace-normal"
										>
											<div
												v-for="a in assessment.activities"
												:key="a.id"
												class="mb-2"
											>
												{{ a.name }}
											</div>
										</div>
									</div>
									<div
										v-if="assessment?.note"
										class="
											mt-4
											uppercase
											font-semibold
											text-neutral-400
										"
									>
										Nhận xét buổi học
									</div>
									<div>
										{{ assessment?.note }}
									</div>
									<div
										class="
											mt-4
											uppercase
											font-semibold
											text-neutral-400
										"
									>
										Kết quả
									</div>
									<div
										:style="{
											background: $filters.tagResult(
												assessment?.result
											)?.background,
											color: $filters.tagResult(
												assessment?.result
											)?.color,
										}"
										class="
											my-2
											py-0.5
											px-2.5
											rounded-full
											text-neutral-700
											h-fit
											w-fit
											font-semibold
											text-xs
											shadow-input
										"
									>
										{{ assessment?.result }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<div
				v-else
				class="
					w-full
					h-full
					flex
					items-center
					justify-center
					flex-col
					pt-20
				"
			>
				<img
					alt=""
					height="250"
					src="@/assets/images/illustrations/calender.png"
					width="250"
				/>
				<div
					class="text-neutral-500 text-lg text-center"
					style="max-width: 700px"
				>
					Học sinh nghỉ học
				</div>
			</div>
		</div>
	</div>
	<div v-else class="fixed top-1/2 left-1/2">
		<Loading />
	</div>
</template>

<script>
import Multiselect from '@suadelabs/vue3-multiselect';
import { _ROUTER_NAME } from '@/enums';
import { $api } from '@/services';
import UserGroup from '@/components/elements/UserGroup.vue';
import Rating from '@/components/elements/rating/Rating.vue';
import Loading from '@/components/common/Loading.vue';
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail.vue';
import ViewPhoto from '@/components/common/ViewPhoto.vue';

export default {
	name: 'GroupClassReportDetail',

	components: {
		ViewPhoto,
		TooltipGoalDetail,
		Loading,
		Rating,
		UserGroup,
		Multiselect,
	},

	data() {
		return {
			groupClass: null,
			group_assessments: [],
			goal_with_skills: [],
			goal_media: [],
			isLoading: true,
			tab: null,
			imageShow: false,
			photosIndex: -1,
		};
	},

	computed: {
		_ROUTER_NAME() {
			return _ROUTER_NAME;
		},

		id() {
			return this.$route.params?.id;
		},

		reportId() {
			return this.$route.params?.reportId;
		},

		student() {
			return this.tab;
		},

		group_assessment() {
			return this.group_assessments.find(
				(r) => r.student_id === this.student?.id
			);
		},
	},

	created() {
		this.fetchGroupClassDetail();
		this.fetchGroupClassReportDetail();
	},

	methods: {
		async fetchGroupClassDetail() {
			try {
				const {
					data: { data },
				} = await $api.groupClasses.detail(this.id);
				this.groupClass = data;
				this.tab = this.groupClass?.students[0];
			} catch (e) {
				console.log(e);
			}
		},

		async fetchGroupClassReportDetail() {
			try {
				const {
					data: {
						group_assessments,
						goal_with_skills,
						data: { goal_media },
					},
				} = await $api.groupClasses.getGroupClassReportDetail(
					this.id,
					this.reportId
				);
				this.group_assessments = group_assessments ?? [];
				this.goal_with_skills = goal_with_skills ?? [];
				this.goal_media = goal_media ?? [];
			} catch (e) {
				console.log(e);
			} finally {
				this.isLoading = false;
			}
		},

		changeStudent(student) {
			this.tab = student;
		},

		haveMedia(goal_id) {
			const result = this.goal_media.find(
				(item) => +item.group_goal_id === +goal_id
			);
			return !!result;
		},

		openMediaPopup(index) {
			this.imageShow = true;
			this.photosIndex = index;
		},
	},
};
</script>

<style lang="scss" scoped>
.tabs {
	.router-link-exact-active {
		@apply text-yellow-700 border-yellow-500;
	}
}
</style>
