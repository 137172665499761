
import { Vue, Options } from 'vue-class-component';
import SwitchButton from '@/components/elements/switchButton/SwitchButton.vue';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { Prop, PropSync, Watch } from 'vue-property-decorator';
import { OrgOpeningHour } from '@/views/setupCenter/features/timesheet/@types/orgOpeningHour';
import { OrgDayCare } from '@/views/setupCenter/features/timesheet/@types/orgDayCare';

@Options({
	name: 'SetupTimesheet',
	components: {
		DaIcon,
		SwitchButton,
	},
})
export default class SetupTimesheet extends Vue {
	//----------------------- 🤍 Props 🤍 -----------------------//
	@Prop() org!: any;
	@Prop() mode = 0;
	@Prop() total = '';
	@PropSync('updated') updateSuccessful!: boolean;

	//----------------------- 🤍 Data 🤍 -----------------------//
	orgOpeningHour: OrgOpeningHour = {
		end_opening_hour: '',
		start_opening_hour: '',
		friday: false,
		monday: false,
		saturday: false,
		sunday: false,
		thursday: false,
		tuesday: false,
		wednesday: false,
	};

	orgDayCare: OrgDayCare = {
		day_care_start_opening_hour: '',
		day_care_end_opening_hour: '',
		day_care_friday: false,
		day_care_monday: false,
		day_care_saturday: false,
		day_care_sunday: false,
		day_care_thursday: false,
		day_care_tuesday: false,
		day_care_wednesday: false,
		has_day_care: false,
	};

	daySelected: number[] = [];
	selected = '';
	editMode = false;

	start_opening_hour: {
		hours: null | string;
		minutes: null | string;
	} | null = {
		hours: '7',
		minutes: '00',
	};

	end_opening_hour: { hours: null | string; minutes: null | string } | null =
		{
			hours: '18',
			minutes: '30',
		};

	daySelectedDayCare: number[] = [];
	selectedDayCare = '';
	editDayCareMode = false;

	start_opening_hour_DayCare: {
		hours: null | string;
		minutes: null | string;
	} | null = {
		hours: '7',
		minutes: '00',
	};

	end_opening_hour_DayCare: {
		hours: null | string;
		minutes: null | string;
	} | null = {
		hours: '18',
		minutes: '30',
	};

	//----------------------- 🤍 Getters 🤍 -----------------------//
	get daySelectedList() {
		return this.daySelected.sort();
	}

	get daySelectedListDayCare() {
		return this.daySelectedDayCare.sort();
	}

	//----------------------- 🤍 Watch 🤍-----------------------//
	@Watch('selected')
	onSelectedChange(value: string) {
		const index = this.daySelected.indexOf(+value);

		if (index == -1) {
			this.daySelected.push(+value);
		}
	}

	@Watch('editDayCareMode')
	onEditDayCareMode() {
		this.$emit('editDayCareMode');
	}

	@Watch('selectedDayCare')
	onSelectedDayCareChange(value: string) {
		const index = this.daySelectedDayCare.indexOf(+value);

		if (index == -1) {
			this.daySelectedDayCare.push(+value);
		}
	}

	@Watch('updateSuccessful')
	onUpdateSuccessful(value: boolean) {
		if (value) {
			this.editMode = false;
			this.editDayCareMode = false;
		}
	}

	@Watch('org', { deep: true, immediate: true })
	onOrgChange(org: any) {
		if (org) {
			this.start_opening_hour = this.formatTime(org?.start_opening_hour);
			this.end_opening_hour = this.formatTime(org?.end_opening_hour);
			this.orgOpeningHour = {
				end_opening_hour: org?.end_opening_hour,
				start_opening_hour: org?.start_opening_hour,
				monday: org?.monday,
				tuesday: org?.tuesday,
				thursday: org?.thursday,
				wednesday: org?.wednesday,
				friday: org?.friday,
				saturday: org?.saturday,
				sunday: org?.sunday,
			};

			this.isDayCheck(!this.orgOpeningHour.monday, 2);
			this.isDayCheck(!this.orgOpeningHour.tuesday, 3);
			this.isDayCheck(!this.orgOpeningHour.wednesday, 4);
			this.isDayCheck(!this.orgOpeningHour.thursday, 5);
			this.isDayCheck(!this.orgOpeningHour.friday, 6);
			this.isDayCheck(!this.orgOpeningHour.saturday, 7);
			this.isDayCheck(!this.orgOpeningHour.sunday, 8);

			this.start_opening_hour_DayCare = this.formatTime(
				org?.day_care_start_opening_hour
			);
			this.end_opening_hour_DayCare = this.formatTime(
				org?.day_care_end_opening_hour
			);

			this.orgDayCare = {
				day_care_start_opening_hour: org?.day_care_start_opening_hour,
				day_care_end_opening_hour: org?.day_care_end_opening_hour,
				day_care_friday: org?.day_care_friday,
				day_care_monday: org?.day_care_monday,
				day_care_saturday: org?.day_care_saturday,
				day_care_sunday: org?.day_care_sunday,
				day_care_thursday: org?.day_care_thursday,
				day_care_tuesday: org?.day_care_tuesday,
				day_care_wednesday: org?.day_care_wednesday,
				has_day_care: org?.has_day_care,
			};

			this.isDayCheckDayCare(!this.orgDayCare.day_care_monday, 2);
			this.isDayCheckDayCare(!this.orgDayCare.day_care_tuesday, 3);
			this.isDayCheckDayCare(!this.orgDayCare.day_care_wednesday, 4);
			this.isDayCheckDayCare(!this.orgDayCare.day_care_thursday, 5);
			this.isDayCheckDayCare(!this.orgDayCare.day_care_friday, 6);
			this.isDayCheckDayCare(!this.orgDayCare.day_care_saturday, 7);
			this.isDayCheckDayCare(!this.orgDayCare.day_care_sunday, 8);
		}
	}

	//----------------------- 🤍 Methods 🤍-----------------------//
	removeDay(day: number | string) {
		const index = this.daySelected.indexOf(+day);

		if (index !== -1) {
			this.daySelected.splice(index, 1);
		}
	}

	removeDayCare(day: number | string) {
		const index = this.daySelectedDayCare.indexOf(+day);
		if (index !== -1) {
			this.daySelectedDayCare.splice(index, 1);
		}
	}

	formatTime(time: string) {
		if (time && time?.length) {
			return {
				hours: time.split(':')[0],
				minutes: time.split(':')[1],
			};
		}
		return null;
	}

	isDayCheck(day: boolean, value: number) {
		if (day) {
			const index = this.daySelected.indexOf(+value);

			if (index == -1) {
				this.daySelected.push(+value);
			}
		}
	}

	isDayCheckDayCare(day: boolean, value: number) {
		if (day) {
			const index = this.daySelectedDayCare.indexOf(+value);
			if (index == -1) {
				this.daySelectedDayCare.push(+value);
			}
		}
	}

	addZero(value: string | null) {
		if (value && value.toString()?.length <= 1) {
			return '0' + value;
		}
		if (value == '0') {
			return '00';
		}
		return value;
	}

	edit() {
		this.editDayCareMode = false;
		this.editMode = true;
		this.updateSuccessful = false;
	}

	editDayCare() {
		this.editDayCareMode = true;
		this.editMode = false;
		this.updateSuccessful = false;
	}

	updateOpeningHour() {
		let end_opening_hour: null | string = null;
		let start_opening_hour: null | string = null;
		if (this.end_opening_hour) {
			end_opening_hour =
				this.addZero(this.end_opening_hour.hours) +
				':' +
				this.addZero(this.end_opening_hour.minutes);
		}
		if (this.start_opening_hour) {
			start_opening_hour =
				this.addZero(this.start_opening_hour.hours) +
				':' +
				this.addZero(this.start_opening_hour.minutes);
		}
		this.$emit('updateOrg', {
			end_opening_hour: end_opening_hour,
			start_opening_hour: start_opening_hour,
			monday: !this.daySelected.includes(2),
			tuesday: !this.daySelected.includes(3),
			thursday: !this.daySelected.includes(4),
			wednesday: !this.daySelected.includes(5),
			friday: !this.daySelected.includes(6),
			saturday: !this.daySelected.includes(7),
			sunday: !this.daySelected.includes(8),
		});
	}

	updateDayCareOpeningHour() {
		let start_opening_hour: null | string = null;
		let end_opening_hour: null | string = null;
		if (this.start_opening_hour_DayCare) {
			start_opening_hour =
				this.addZero(this.start_opening_hour_DayCare.hours) +
				':' +
				this.addZero(this.start_opening_hour_DayCare.minutes);
		}
		if (this.end_opening_hour_DayCare) {
			end_opening_hour =
				this.addZero(this.end_opening_hour_DayCare.hours) +
				':' +
				this.addZero(this.end_opening_hour_DayCare.minutes);
		}
		this.$emit('updateOrg', {
			day_care_end_opening_hour: end_opening_hour,
			day_care_start_opening_hour: start_opening_hour,
			day_care_monday: !this.daySelectedDayCare.includes(2),
			day_care_tuesday: !this.daySelectedDayCare.includes(3),
			day_care_thursday: !this.daySelectedDayCare.includes(4),
			day_care_wednesday: !this.daySelectedDayCare.includes(5),
			day_care_friday: !this.daySelectedDayCare.includes(6),
			day_care_saturday: !this.daySelectedDayCare.includes(7),
			day_care_sunday: !this.daySelectedDayCare.includes(8),
			has_day_care: this.orgDayCare.has_day_care,
		});
	}
}
