<script setup>
import { defineEmits, defineProps, onMounted, ref } from 'vue';
import { $api } from '@/services';
import { useStore } from 'vuex';
import Multiselect from '@suadelabs/vue3-multiselect';

const emit = defineEmits(['success', 'close']);
const store = useStore();
const props = defineProps(['data']);

const folder = ref(null);
const listDocument = ref([]);
const inProgress = ref(false);
const getListDocument = async () => {
	try {
		const res = await $api.org.listOnlyFolder();
		if (res) listDocument.value = res.data.data;
	} catch (e) {
		console.log(e);
	}
};
const submit = async () => {
	inProgress.value = true;
	try {
		const payload = {
			reference_document_folder_id: folder.value.id,
		};
		const res = await $api.org.updateDocuments(props.data.id, payload);
		if (res) {
			store.dispatch('setAlertMessage', {
				message: 'Di chuyển tài liệu thành công',
			});
			emit('success');
		}
	} catch (e) {
		store.dispatch('setAlertMessage', {
			message:
				e?.response?.data?.errors?.join('<br/>') ||
				'Đã có lỗi xảy ra. Vui lòng thử lại',
			type: 'danger',
		});
	} finally {
		inProgress.value = false;
	}
};
onMounted(() => {
	getListDocument();
});
</script>
<template>
	<div class="fixed inset-y-0 right-0 flex side-over z-50 w-screen md:w-2/5">
		<div class="h-full w-full flex flex-col bg-white shadow-xl">
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center gap-2">
						<svg
							fill="none"
							height="24"
							viewBox="0 0 24 24"
							width="24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M17.3006 10.5H23.1994C23.5173 10.5 23.691 10.8708 23.4874 11.1151L20.5381 14.6543C20.3882 14.8342 20.1118 14.8342 19.9619 14.6543L17.0126 11.1151C16.809 10.8708 16.9827 10.5 17.3006 10.5Z"
								fill="#94A3B8"
							/>
							<path
								d="M0.800644 13.5H6.69936C7.0173 13.5 7.19099 13.1292 6.98745 12.8849L4.03809 9.3457C3.88816 9.16579 3.61184 9.16579 3.46192 9.3457L0.51256 12.8849C0.309021 13.1292 0.482705 13.5 0.800644 13.5Z"
								fill="#94A3B8"
							/>
							<path
								clip-rule="evenodd"
								d="M12 4.5C9.67127 4.5 7.59085 5.56045 6.21403 7.22758C5.95027 7.54696 5.47754 7.59205 5.15816 7.32829C4.83879 7.06452 4.7937 6.59179 5.05746 6.27242C6.70702 4.27504 9.20493 3 12 3C16.4126 3 20.082 6.17476 20.8516 10.3645C20.8599 10.4096 20.8678 10.4547 20.8754 10.5H19.3501C18.6556 7.07667 15.6279 4.5 12 4.5ZM4.64988 13.5C5.3444 16.9233 8.37206 19.5 12 19.5C14.3287 19.5 16.4092 18.4396 17.786 16.7724C18.0497 16.453 18.5225 16.408 18.8418 16.6717C19.1612 16.9355 19.2063 17.4082 18.9425 17.7276C17.293 19.725 14.7951 21 12 21C7.58745 21 3.91797 17.8252 3.14838 13.6355C3.1401 13.5904 3.13216 13.5453 3.12456 13.5H4.64988Z"
								fill="#94A3B8"
								fill-rule="evenodd"
							/>
						</svg>
						<div class="text-2xl font-bold text-neutral-700">
							Chuyển tài liệu
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center cursor-pointer">
						<button
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							type="button"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								aria-hidden="true"
								class="h-6 w-6"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M6 18L18 6M6 6l12 12"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div
				class="
					sideover-content
					pt-6
					relative
					flex-1
					px-4
					sm:px-6
					overflow-y-auto
				"
			>
				<div>
					<div class="input-group">
						<div
							class="
								input-group__label
								text-neutral-700
								font-semibold
								mb-4
							"
						>
							Chon thư mục chuyển đến
						</div>
						<!-- <select
              class="
                block
                pr-10
                py-2
                text-base
                border-gray-300
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                sm:text-sm
                rounded-md
                input
                w-full
              "
              v-model="folder"
            >
              <option
                v-for="i in listDocument"
                :key="i.id"
                :value="i.id"
              >
                {{ i.name }}
              </option>
            </select> -->
						<Multiselect
							v-model="folder"
							:allow-empty="false"
							:options="
								listDocument?.map((s) => {
									return {
										nameSearch: s.name,
										...s,
									};
								})
							"
							:searchable="true"
							deselect-label="Đã chọn"
							label="nameSearch"
							placeholder="Chọn thư mục"
							selectLabel=""
							track-by="id"
						>
							<template #noResult>
								Không tìm thấy kết quả
							</template>
							<template v-slot:option="props">
								<div class="flex flex-nowrap items-center">
									{{ props.option.name }}
								</div>
							</template>
							<template v-slot:singleLabel="{ option }">
								<div
									class="
										flex flex-nowrap
										items-center
										font-medium
									"
								>
									{{ option.name }}
								</div>
							</template>
						</Multiselect>
					</div>
				</div>
			</div>
			<div class="px-6 py-4 border-t border-yellow-50">
				<div class="flex justify-end">
					<div>
						<button
							:disabled="!folder || inProgress"
							class="button-p text-center w-32"
							type="button"
							@click="submit(false)"
						>
							Xác nhận
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
