<template>
  <div>
    <div class="mt-12 bg-white shadow-sm rounded-2xl overflow-hidden">
      <div class="text-neutral-500 p-6">
        <div>{{ goal?.skill?.name }}</div>
        <div class="font-semibold">
          {{ goal?.name }}
        </div>
      </div>
      <div>
        <div class="flex flex-wrap p-6 border-t border-orange-50">
          <div
            v-for="(item, index) in groupClass?.students"
            :key="item?.id"
            class="
							ring-2 ring-white
							rounded-full
							cursor-pointer
							w-20
							flex flex-col
							items-center
						"
            @click="onStudentSelected(item)"
          >
            <div
              :class="
								assessmentSelected?.student_id === item.id
									? 'border-green-500'
									: 'border-white'
							"
              class="p-0.5 w-fit border-2 rounded-full relative"
            >
              <img
                v-if="item?.profile_photo?.w200"
                :class="`h-12 w-12`"
                :src="item?.profile_photo?.w200"
                alt=""
                class="shadow-sm inline-block rounded-full"
              />
              <span
                v-else
                :class="`h-12 w-12`"
                :style="{
									background:
										item?.profile_photo?.default?.color,
								}"
                class="
									inline-flex
									items-center
									justify-center
									rounded-full
								"
              >
								<span
                  class="
										font-medium
										leading-none
										text-white text-xs
									"
                >{{
                    item?.profile_photo?.default?.initial
                  }}</span
                >
							</span>
              <div
                v-if="
									assessments[index]?.complete_percent ||
									assessments[index]?.raw_result
								"
                :style="{
									background: TAG_COLOR(
										assessments[index]?.tab !== 1
											? assessments[index]
													?.complete_percent
											: 0
									),
								}"
                class="
									absolute
									transform
									-translate-x-1/2 -translate-y-1/2
									left-1/2
									p-0.5
									px-3
									text-xs
									font-semibold
									text-white
									rounded-full
								"
              >
                {{
                  assessments[index]?.tab !== 1
                    ? assessments[index]?.complete_percent
                    : 0
                }}%
              </div>
            </div>
            <div
              v-if="assessmentSelected?.student_id === item.id"
              class="
								mt-3
								font-semibold
								text-orange-500
								whitespace-nowrap
							"
            >
              {{
                item?.name?.length >= 15
                  ? item?.name?.slice(0, 12) + '...'
                  : item?.name
              }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="
					assessmentSelected &&
					list_student_ids_learn_group_goal?.includes(
						assessmentSelected?.student_id
					)
				"
        class="p-6 border-t border-orange-50"
      >
        <ul v-if="completedPercentError" class="mb-6 pl-4 list-none">
          <li v-if="completedPercentError" class="text-sienna-600">
            Vui lòng báo cáo kết quả trước khi chọn học sinh khác
          </li>
        </ul>
        <div class="flex gap-4 flex-col lg:flex-row">
          <div class="w-full">
            <div
              class="
								bg-white
								shadow-sm
								rounded-2xl
								overflow-hidden
							"
            >
              <div
                class="flex items-center w-full bg-neutral-100"
              >
                <div
                  :class="
										assessmentSelected?.tab === 0
											? 'bg-yellow-400 text-neutral-700'
											: 'text-neutral-400'
									"
                  class="
										px-6
										py-3
										font-medium
										w-full
										cursor-pointer
										truncate
										text-center
									"
                  @click="assessmentSelected.tab = 0"
                >
                  Có thực hiện
                </div>
                <div
                  :class="
										assessmentSelected?.tab === 1
											? 'bg-yellow-400 text-neutral-700'
											: 'text-neutral-400'
									"
                  class="
										px-6
										py-3
										font-medium
										w-full
										cursor-pointer
										truncate
										text-center
									"
                  @click="assessmentSelected.tab = 1"
                >
                  Không thực hiện
                </div>
              </div>
              <div class="px-10 pt-14 pb-6">
                <div
                  v-if="assessmentSelected.tab === 0"
                  :class="`range-color-${TAG_COLOR(
										assessmentSelected.complete_percent
									).substr(1)}`"
                  class="relative mb-6 w-full"
                >
                  <div
                    :style="{
											left: `${assessmentSelected.complete_percent}%`,
											top: '-35px',
											background: TAG_COLOR(
												assessmentSelected.complete_percent
											),
										}"
                    class="
											pt-1
											pb-0.5
											px-2
											font-semibold
											rounded-lg
											text-sm text-white
											h-fit
											w-fit
											absolute
											transform
											-translate-x-1/2
										"
                  >
                    {{
                      assessmentSelected.complete_percent
                    }}%
                  </div>
                  <input
                    v-model="
											assessmentSelected.complete_percent
										"
                    class="w-full"
                    max="100"
                    min="0"
                    step="5"
                    type="range"
                  />
                  <div
                    class="
											w-full
											flex
											justify-between
											text-xs
										"
                  >
                    <div
                      v-for="i in 11"
                      :key="i"
                      class="
												flex flex-col
												items-center
												text-neutral-400 text-xs
											"
                    >
                      <div>|</div>
                      <div class="font-medium">
                        {{ (i - 1) * 10 }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="space-y-4">
                  <div
                    v-for="option in options"
                    :key="option"
                    class="flex"
                    @click="
											assessmentSelected.raw_result =
												option?.id
										"
                  >
                    <input
                      :checked="
												assessmentSelected.raw_result ===
												option?.id
											"
                      :value="option"
                      class="
												h-4
												w-4
												text-yellow-600
												border-neutral-400 border-2
												focus:ring-yellow-600
												mt-1
												cursor-pointer
											"
                      type="radio"
                    />
                    <div class="pl-3 block">
                      <div class="font-semibold">
                        {{ option.title }}
                      </div>
                      <div class="text-sm">
                        {{ option.label }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="assessmentSelected?.tab === 0"
              :style="{
								background: TAG_COLOR(
									assessmentSelected?.tab !== 1
										? assessmentSelected?.complete_percent
										: 0
								),
							}"
              class="
								mt-6
								rounded-full
								w-full
								text-center
								py-1.5
								font-medium
								text-white
							"
            >
              Đạt
              {{
                assessmentSelected?.tab !== 1
                  ? assessmentSelected?.complete_percent
                  : 0
              }}%
            </div>
            <div
              v-else-if="assessmentSelected?.raw_result"
              class="
								mt-6
								rounded-full
								w-full
								text-center
								py-1.5
								font-medium
								text-white
							"
              style="background: #9ca3af"
            >
              {{
                options?.find(
                  (o) =>
                    o.id === assessmentSelected.raw_result
                )?.title
              }}
            </div>
          </div>
          <div class="w-full">
						<textarea
              v-model="assessmentSelected.note"
              class="h-full"
              placeholder="Nhận xét của giáo viên về mục tiêu này của trẻ"
            ></textarea>
          </div>
        </div>
      </div>
      <div
        v-else
        class="
					flex
					gap-4
					flex-col
					lg:flex-row
					p-6
					border-t border-orange-50
					text-neutral-500
				"
      >
        Học sinh không học mục tiêu này trong tháng
      </div>
      <div
        class="
					flex
					gap-4
					flex-nowrap
					items-end
					justify-end
					p-6
					border-t border-orange-50
				"
      >
        <button
          :disabled="!enableNextButton"
          class="button-p text-center w-fit px-6"
          type="button"
          @click="onSubmit(false)"
        >
          Xác nhận kết quả
        </button>
      </div>
    </div>
    <template v-if="false">
      <div class="relative mt-12">
        <div aria-hidden="true" class="absolute inset-0 flex items-center">
          <div class="w-full border-t border-neutral-300"/>
        </div>
        <div class="relative flex items-center justify-center">
          <div class="px-2.5 py-1 bg-body text-neutral-600 font-semibold">
            Nội dung báo cáo
          </div>
        </div>
      </div>
      <div class="mt-10">
        <div class="p-6 bg-white shadow-sm rounded-2xl">
          <div class="font-semibold text-neutral-600 mb-6">
            Thống kê chung
            {{
              `tuần ${$filters.toWeek(
                report?.data?.week_start,
                true
              )} (${$filters.formatDate2(
                report?.data?.week_start
              )} - ${$filters.endWeek(report?.data?.week_start)})`
            }}
          </div>
          <div class="flex flex-nowrap w-full md-down:flex-col">
            <div
              class="
							p-6
							border
							rounded-l-lg
							border-neutral-200
							w-full
							md-down:rounded-t md-down:rounded-b-none
						"
            >
              <div
                class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
              >
                <img
                  alt=""
                  class="mr-2"
                  height="32"
                  src="@/assets/images/icons/schedule.png"
                  style="height: 32px"
                  width="32"
                />
                {{ Number(report?.group_sessions_count || 0) }}
              </div>
              <div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
              >Tổng số buổi học
								<span
                  v-if="assessmentSelected?.id !== 'all'"
                  style="font-style: italic"
                >(PH không xem được thông tin này)</span
                >
							</span>
              </div>
            </div>
            <div
              class="
							p-6
							border-t border-b border-neutral-200
							w-full
							md-down:border-l md-down:border-r md-down:border-b-0
						"
            >
              <div
                class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
              >
                <img
                  alt=""
                  class="mr-2"
                  height="32"
                  src="@/assets/images/icons/abc.png"
                  style="height: 32px"
                  width="32"
                />
                {{ Number(report?.stats?.learn_skill_count || 0) }}
              </div>
              <div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
              >Tổng số lĩnh vực đã học
							</span>
              </div>
            </div>
            <div
              class="
							p-6
							border-l
							border-t
							border-b
							border-r
							border-neutral-200
							w-full
							md:rounded-r-lg
						"
            >
              <div
                class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
              >
                <img
                  alt=""
                  class="mr-2"
                  height="32"
                  src="@/assets/images/icons/light.png"
                  style="height: 32px"
                  width="32"
                />
                {{ report?.stats?.learn_goal_count }}
              </div>
              <div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
              >Tổng số mục tiêu đã học
							</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-10">
        <div class="grid grid-cols-2 gap-8 xl-down:grid-cols-1">
          <div class="bg-white shadow-sm rounded-2xl overflow-hidden">
            <div class="font-semibold text-neutral-500 p-6">
              Biểu đồ cảm xúc
            </div>
            <div
              class="
							border-t border-orange-50
							flex
							justify-center
							items-center
							p-6
							md-down:flex-col
						"
            >
              <div
                class="
								relative
								w-1/2
								md-down:w-full
								flex
								items-center
								justify-center
							"
              >
                <apexchart
                  :options="chartOptions"
                  :series="emotions"
                  height="220"
                  type="donut"
                ></apexchart>
                <div
                  class="
									text-center
									absolute
									top-1/2
									left-1/2
									transform
								"
                  style="transform: translate(-50%, -50%)"
                >
                  <div class="text-neutral-500">Cảm xúc</div>
                </div>
              </div>
              <div class="w-1/3 md-down:w-full md-down:mt-4">
                <div
                  v-if="emotions[0] > 0"
                  class="
									flex flex-nowrap
									items-center
									justify-between
									w-full
								"
                >
                  <div class="flex flex-nowrap items-center">
                    <img
                      alt=""
                      height="24"
                      src="@/assets/images/emoji/happy.png"
                      width="24"
                    />
                    <div
                      class="h-2.5 w-2.5 rounded-full ml-2"
                      style="background: #9edf93"
                    ></div>
                    <div
                      class="
											ml-2
											text-xs text-neutral-500
											whitespace-nowrap
										"
                    >
                      Vui vẻ
                    </div>
                  </div>
                  <div class="text-xs text-neutral-600 font-bold">
                    {{ emotions[0] }}
                    %
                  </div>
                </div>
                <div
                  v-if="emotions[1] > 0"
                  class="
									flex flex-nowrap
									items-center
									justify-between
									w-full
									mt-2.5
								"
                >
                  <div class="flex flex-nowrap items-center">
                    <img
                      alt=""
                      height="24"
                      src="@/assets/images/emoji/5565d66a50b44df9ae37a955bdfb3757.png"
                      width="24"
                    />
                    <div
                      class="h-2.5 w-2.5 rounded-full ml-2"
                      style="background: #d0db54"
                    ></div>
                    <div class="ml-2 text-xs text-neutral-500">
                      Bình thường
                    </div>
                  </div>
                  <div
                    class="
										text-xs text-neutral-600
										font-bold
										whitespace-nowrap
									"
                  >
                    {{ emotions[1] }}
                    %
                  </div>
                </div>
                <div
                  v-if="emotions[2] > 0"
                  class="
									flex flex-nowrap
									items-center
									justify-between
									w-full
									mt-2.5
								"
                >
                  <div class="flex flex-nowrap items-center">
                    <img
                      alt=""
                      height="24"
                      src="@/assets/images/emoji/cf1f643bc1244f5e8e37767bc946d607.png"
                      width="24"
                    />
                    <div
                      class="h-2.5 w-2.5 rounded-full ml-2"
                      style="background: #ffe68c"
                    ></div>
                    <div class="ml-2 text-xs text-neutral-500">
                      Khó chịu
                    </div>
                  </div>
                  <div
                    class="
										text-xs text-neutral-600
										font-bold
										whitespace-nowrap
									"
                  >
                    {{ emotions[2] }}
                    %
                  </div>
                </div>
                <div
                  v-if="emotions[3] > 0"
                  class="
									flex flex-nowrap
									items-center
									justify-between
									w-full
									mt-2.5
								"
                >
                  <div class="flex flex-nowrap items-center">
                    <img
                      alt=""
                      height="24"
                      src="@/assets/images/emoji/5ede936757cd4fbdb6247bad22bf454d.png"
                      width="24"
                    />
                    <div
                      class="h-2.5 w-2.5 rounded-full ml-2"
                      style="background: #ff9d6d"
                    ></div>
                    <div class="ml-2 text-xs text-neutral-500">
                      Tức giận
                    </div>
                  </div>
                  <div
                    class="
										text-xs text-neutral-600
										font-bold
										whitespace-nowrap
									"
                  >
                    {{ emotions[3] }}
                    %
                  </div>
                </div>
                <div
                  v-if="emotions[4] > 0"
                  class="
									flex flex-nowrap
									items-center
									justify-between
									w-full
									mt-2.5
								"
                >
                  <div class="flex flex-nowrap items-center">
                    <img
                      alt=""
                      height="24"
                      src="@/assets/images/emoji/0b356bb507d64b9489e2fbced131f795.png"
                      width="24"
                    />
                    <div
                      class="h-2.5 w-2.5 rounded-full ml-2"
                      style="background: #ff6967"
                    ></div>
                    <div class="ml-2 text-xs text-neutral-500">
                      Bùng nổ
                    </div>
                  </div>
                  <div
                    class="
										text-xs text-neutral-600
										font-bold
										whitespace-nowrap
									"
                  >
                    {{ emotions[4] }}
                    %
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-white shadow-sm rounded-2xl overflow-hidden">
            <div class="font-semibold text-neutral-500 p-6">
              Chuyên cần
            </div>
            <div
              class="
							border-t border-orange-50
							p-6
							flex
							items-center
							justify-center
							flex-col
						"
            >
              <div
                class="text-neutral-600 font-semibold mb-5"
                style="font-size: 72px"
              >
                {{ report?.group_sessions_count }}
              </div>
              <div class="text-neutral-500 mb-5 text-center">
                Số buổi học tuần này <br/>
                <i>(PH không xem được thông tin này)</i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import {TAG_COLOR} from '@/helper/tag';
import {$api} from '@/services';

export default {
  name: 'ReportDetailStep1',

  props: {
    report: {
      type: Object,
      required: true,
    },

    goal: {
      type: Object,
      required: true,
    },

    groupClass: {
      type: Object,
      required: true,
    },

    list_student_ids_learn_group_goal: {
      type: Array,
      required: true,
    },

    submittedFlag: {
      type: Number,
      required: true,
    },
  },

  watch: {
    goal: {
      handler(value) {
        if (value) {
          this.assessments =
            value?.student_assessment_group_weekly_reports?.map(
              (item) => ({
                ...item,
                complete_percent: item.raw_result
                  ? 0
                  : item.complete_percent,
                tab: item.raw_result ? 1 : 0,
              })
            );
          this.assessmentSelected = this.assessments[0];
        }
      },
      immediate: true,
      deep: true,
    },

    submittedFlag: {
      handler(value) {
        if (value) {
          this.onSubmit(true);
        }
      },
    },

    assessmentSelected: {
      handler(value) {
        this.$emit('fetchReportFilterByStudent', {
          studentId: value?.student_id,
        });
      },
    },
  },

  computed: {
    enableNextButton() {
      return this.assessments.every(
        (a) =>
          a.complete_percent ||
          a.raw_result ||
          !this.list_student_ids_learn_group_goal.includes(
            a.student_id
          )
      );
    },

    emotionTotal() {
      return this.report?.emotion_scale_stats?.reduce(
        (a, b) => +a + +b.count,
        0
      );
    },

    emotions() {
      return this.report?.emotion_scale_stats?.map((item) => {
        const r = (item.count * 100) / this.emotionTotal;
        return Math.round(r * 10) / 10 || 0;
      });
    },
  },

  data() {
    return {
      assessments: [],
      assessmentSelected: null,
      completedPercentError: false,
      options: [
        {
          id: 'reject',
          title: 'Thể hiện sự từ chối',
          label: 'Trẻ lắc đầu, xua tay, đẩy ra, nói "không", "không thích" kèm lý do',
        },
        {
          id: 'n/a',
          title: 'Không hợp tác',
          label: 'Trẻ ném đồ, ăn vạ, im lặng, không thực hiện khi được yêu cầu',
        },
        {
          id: 'not_cooperate',
          title: 'Không có cơ hội thực hiện',
          label: 'NTL không tạo ra cơ hội thực hiện mục tiêu, thời tiết, sức khỏe của trẻ ...',
        },
      ],
      chartOptions: {
        colors: ['#9EDF93', '#D0DB54', '#FFE68C', '#FF9D6D', '#FF6967'],
        chart: {
          width: 380,
          type: 'polarArea',
        },
        labels: [
          'Vui vẻ',
          'Bình thường',
          'Khó chịu',
          'Tức giận',
          'Bùng nổ',
        ],
        fill: {
          opacity: 1,
          colors: [
            '#9EDF93',
            '#D0DB54',
            '#FFE68C',
            '#FF9D6D',
            '#FF6967',
          ],
        },
        stroke: {
          lineCap: 'round',
        },
        yaxis: {
          show: false,
        },
        tooltip: {
          fillSeriesColor: true,
          y: {
            formatter: function (value) {
              if (!value || value == 'null') {
                return 'Không thực hiện';
              }
              return value + '%';
            },
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          polarArea: {
            rings: {
              strokeWidth: 0,
            },
            spokes: {
              strokeWidth: 0,
            },
          },
        },
      },
    };
  },

  methods: {
    TAG_COLOR,

    async onStudentSelected(student) {
      const {isSuccess} = await this.updateAssessmentsInReport();
      if (isSuccess) {
        this.assessmentSelected = this.assessments.find(
          (a) => a.student_id === student.id
        );
      }
    },

    async updateAssessmentsInReport() {
      if (
        (Number(this.assessmentSelected?.complete_percent || 0) <= 0 &&
          !this.assessmentSelected?.raw_result) ||
        (this.assessmentSelected &&
          !this.list_student_ids_learn_group_goal?.includes(
            this.assessmentSelected?.student_id
          ))
      ) {
        return {isSuccess: true};
      }

      try {
        const {data} = this.assessmentSelected?.id
          ? await $api.weeklyReport.updateAssessmentsInReport(
            this.groupClass?.id,
            this.report?.data?.id,
            this.assessmentSelected?.id,
            {
              ...this.assessmentSelected,
              result:
                this.assessmentSelected?.tab === 1 &&
                this.assessmentSelected?.raw_result
                  ? this.assessmentSelected?.raw_result
                  : null,
              complete_percent:
                this.assessmentSelected?.tab !== 1 &&
                this.assessmentSelected?.complete_percent
                  ? this.assessmentSelected
                    ?.complete_percent
                  : null,
            }
          )
          : await $api.weeklyReport.createAssessmentsInReport(
            this.groupClass?.id,
            this.report?.data?.id,
            {
              ...this.assessmentSelected,
              result:
                this.assessmentSelected?.tab === 1 &&
                this.assessmentSelected?.raw_result
                  ? this.assessmentSelected?.raw_result
                  : null,
              complete_percent:
                this.assessmentSelected?.tab !== 1 &&
                this.assessmentSelected?.complete_percent
                  ? this.assessmentSelected
                    ?.complete_percent
                  : null,
            }
          );
        return {isSuccess: true, data};
      } catch (err) {
        const error =
          err?.response?.data?.errors?.join(<br/>) ||
          err?.message ||
          'Đã có lỗi xảy ra. Vui lòng thử lại';
        await this.$store.dispatch('setAlertMessage', {
          message: error,
          type: 'danger',
        });
        return {isSuccess: false};
      }
    },

    onSubmit(onToGoal = false) {
      this.updateAssessmentsInReport().then((res) => {
        if (res.isSuccess) {
          this.$emit(onToGoal ? 'onToGoal' : 'onNextGoal');
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@function makelongshadow($color, $size) {
  $val: 6px 0 0 $size $color;

  @for $i from 6 through 1000 {
    $val: #{$val}, #{$i}px 0 0 $size #{$color};
  }

  @return $val;
}

input[type='range'] {
  align-items: center;
  appearance: none;
  background: none;
  cursor: pointer;
  display: flex;
  height: 100%;
  min-height: 50px;
  overflow: hidden;
  width: 100%;

  &::-webkit-slider-runnable-track {
    content: '';
    height: 8px;
    pointer-events: none;
  }

  &::-webkit-slider-thumb {
    height: 24px;
    width: 24px;
    appearance: none;
    background: white;
    border-radius: 999px;
    box-shadow: makelongshadow(#c7c7c7, -8px);
    margin-top: -8px;
    -webkit-appearance: none;
    cursor: pointer;
  }
}

.range-color-2AA797 {
  input[type='range'] {
    &::-webkit-slider-thumb {
      border: 2px solid #2aa797;
    }

    &::-webkit-slider-runnable-track {
      background: #2aa797;
    }
  }
}

.range-color-65A30D {
  input[type='range'] {
    &::-webkit-slider-thumb {
      border: 2px solid #65a30d;
    }

    &::-webkit-slider-runnable-track {
      background: #65a30d;
    }
  }
}

.range-color-F59E0B {
  input[type='range'] {
    &::-webkit-slider-thumb {
      border: 2px solid #f59e0b;
    }

    &::-webkit-slider-runnable-track {
      background: #f59e0b;
    }
  }
}

.range-color-F87171 {
  input[type='range'] {
    &::-webkit-slider-thumb {
      border: 2px solid #f87171;
    }

    &::-webkit-slider-runnable-track {
      background: #f87171;
    }
  }
}

.range-color-9CA3AF {
  input[type='range'] {
    &::-webkit-slider-thumb {
      border: 2px solid #9ca3af;
    }

    &::-webkit-slider-runnable-track {
      background: #9ca3af;
    }
  }
}
</style>
