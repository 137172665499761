<template>
	<div class="h-full w-full">
		<div
			class="
				__setup-target-smart
				__section
				shadow-sm
				bg-white
				overflow-hidden
			"
		>
			<div
				class="
					__section__header
					font-semibold
					text-neutral-600
					p-6
					border-b
				"
			>
				<div class="relative text-neutral-600 flex items-center gap-2">
					<span>Nhắc đánh giá định kỳ</span>
					<div
						v-if="!isEdit"
						class="absolute right-0 cursor-pointer"
						@click="isEdit = true"
					>
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M15.4041 8.49733L17.1377 6.76375L17.1377 6.76374L17.1377 6.76372C17.6368 6.26463 17.8863 6.01508 18.0269 5.74991C18.3376 5.164 18.3376 4.46221 18.0269 3.8763C17.8863 3.61112 17.6368 3.36156 17.1377 2.86246C16.6386 2.36335 16.389 2.11379 16.1238 1.97321C15.5379 1.66258 14.8361 1.66258 14.2502 1.97321C13.985 2.11379 13.7355 2.36335 13.2364 2.86246L11.4807 4.61818C12.4198 6.22874 13.772 7.57058 15.4041 8.49733ZM10.0262 6.07265L3.36419 12.7346C2.93913 13.1597 2.7266 13.3722 2.58687 13.6333C2.44713 13.8944 2.38819 14.1891 2.27029 14.7786L1.67957 17.7322C1.61305 18.0648 1.57979 18.2311 1.6744 18.3257C1.76901 18.4203 1.93531 18.3871 2.26792 18.3206L5.22152 17.7298C5.81098 17.6119 6.1057 17.553 6.3668 17.4133C6.6279 17.2735 6.84043 17.061 7.26549 16.6359L13.9459 9.95556C12.3647 8.96432 11.027 7.63576 10.0262 6.07265Z"
								fill="#94A3B8"
							/>
						</svg>
					</div>
				</div>
				<div
					class="text-neutral-500 font-normal leading-5 text-sm mt-2"
				>
					Ở chế độ bật, thông báo nhắc đánh giá định kỳ cho học sinh
					sẽ được gửi đến Quản lý sau khi học sinh hoàn thành một
					lượng giờ học can thiệp cá nhân cố định. Nếu không sử dụng
					tính năng lịch học, Luca sẽ tính 1 báo cáo can thiệp cá nhân
					bằng 1 giờ can thiệp
				</div>
			</div>
			<div class="__section__content">
				<div class="form-group">
					<div
						class="
							input-group
							flex-nowrap flex
							items-center
							justify-between
							p-6
						"
						:class="{ 'bg-neutral-50': !isEdit }"
					>
						<div
							class="
								input-group__label
								text-neutral-500
								font-normal
								uppercase
								text-xs
								w-full
							"
						>
							Số giờ can thiệp cá nhân cần hoàn thành
						</div>
						<input
							v-if="isEdit"
							v-model="no_hour_to_noti_periodic_assessment"
							inputmode="numeric"
							type="number"
							class="bg-white input-with-icon w-1/2"
						/>
						<div v-else class="w-1/2">
							{{ no_hour_to_noti_periodic_assessment }} giờ
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="isEdit"
				class="__section__content border-t border-orange-50"
			>
				<div class="py-4 px-6 flex items-center justify-end">
					<button
						type="button"
						class="button-p text-center whitespace-nowrap w-fit"
						@click="submit"
					>
						Lưu chỉnh sửa
					</button>
				</div>
			</div>
		</div>
		<div class="mt-12" v-if="students?.length">
			<div class="flex items-center justify-between">
				<div class="font-semibold text-neutral-700">
					Danh sách học sinh được nhắc đánh giá định kỳ
				</div>
				<div>
					<button
						type="button"
						class="
							button-p
							btn--ghost
							text-center
							mr-3
							px-6
							whitespace-nowrap
							w-fit
						"
						@click="addStudents"
					>
						Thêm học sinh
					</button>
				</div>
			</div>
			<div class="shadow-sm sm:rounded-2xl mt-3">
				<div class="__record__table overflow-x-auto scroll-bar">
					<div
						class="
							overflow-hidden
							sm:rounded-t-2xl
							w-fit
							min-w-full
						"
					>
						<table class="min-w-full divide-y text-neutral-200">
							<thead class="bg-neutral-100 h-16">
								<tr>
									<th
										scope="col"
										class="
											px-6
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											w-16
											whitespace-nowrap
										"
									>
										STT
									</th>
									<th
										scope="col"
										class="
											px-6
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
											w-32
										"
									>
										Tên học sinh
									</th>
									<th
										scope="col"
										class="
											px-6
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Tuổi
									</th>
									<th
										scope="col"
										class="
											px-6
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Mô hình
									</th>
									<th
										scope="col"
										class="
											px-6
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										bắt đầu
									</th>
									<th
										scope="col"
										class="
											px-6
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										chu kì
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									@click="
										$router.push(
											isAdmin &&
												!['pending'].includes(
													person.state
												)
												? `/record/student/${person.id}`
												: `/record/student/${person.id}/info`
										)
									"
									v-for="(person, personIdx) in students"
									:key="person.id"
									:class="
										personIdx % 2 === 0
											? 'bg-white'
											: 'bg-neutral-50'
									"
									class="
										text-neutral-700
										hover:bg-orange-50
										cursor-pointer
									"
								>
									<td>
										<div class="px-6 py-4 text-neutral-700">
											{{ personIdx + 1 }}
										</div>
									</td>
									<td
										class="px-6 py-4 text-neutral-700"
										style="width: 250px"
									>
										<div class="flex items-center">
											<div class="relative">
												<img
													class="
														shadow-sm
														inline-block
														h-6
														w-6
														rounded-full
													"
													:src="
														person?.profile_photo
															?.w200
													"
													alt=""
													v-if="
														person?.profile_photo
															?.w200
													"
												/>
												<span
													v-else
													class="
														inline-flex
														items-center
														justify-center
														h-6
														w-6
														rounded-full
													"
													:style="{
														background:
															person
																?.profile_photo
																?.default
																?.color,
													}"
												>
													<span
														class="
															font-medium
															leading-none
															text-white text-xs
														"
														>{{
															person
																?.profile_photo
																?.default
																?.initial
														}}</span
													>
												</span>
												<div
													v-if="
														person.state !==
														'active'
													"
													style="
														background-color: rgba(
															241,
															162,
															103,
															0.5
														);
													"
													class="
														absolute
														transform
														-translate-x-1/2
														-translate-y-1/2
														top-1/2
														left-1/2
														h-6
														w-6
														rounded-full
														flex
														items-center
														justify-center
													"
												>
													<svg
														width="14"
														height="14"
														viewBox="0 0 40 40"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M9.84364 16.0997V10.2496C9.84364 4.86466 14.3907 0.5 20 0.5C25.6093 0.5 30.1564 4.865 30.1564 10.2496V16.0997C33.5218 16.0997 36.25 18.7188 36.25 21.9497V33.6499C36.25 36.8812 33.5218 39.5 30.1564 39.5H9.84364C6.47821 39.5 3.75 36.8812 3.75 33.6499V21.9497C3.75 18.7188 6.47821 16.0997 9.84364 16.0997V16.0997ZM26.0936 16.0997V10.2496C26.0936 7.01866 23.3654 4.3995 20 4.3995C16.6346 4.3995 13.9064 7.01866 13.9064 10.2496V16.0997H26.0936ZM20 23.9C21.1218 23.9 22.0311 24.7729 22.0311 25.8499V29.7501C22.0311 30.8271 21.1218 31.7 20 31.7C18.8782 31.7 17.9689 30.8271 17.9689 29.7501V25.8499C17.9689 24.7729 18.8782 23.9 20 23.9Z"
															fill="#FAF5F1"
														/>
													</svg>
												</div>
											</div>
											<div
												class="
													ml-4
													text-orange-600
													font-semibold
												"
												style="max-width: 150px"
											>
												{{ person.name }}
											</div>
										</div>
									</td>
									<td>
										<div
											class="
												px-6
												py-4
												whitespace-nowrap
												text-neutral-700
											"
										>
											{{ person.age }}
										</div>
									</td>
									<td
										class="
											px-6
											py-4
											whitespace-nowrap
											text-neutral-700
										"
										style="min-width: 156px"
									>
										<div class="flex items-center">
											<div
												class="p-2"
												v-if="
													person?.has_personal_class
												"
											>
												<img
													class="w-5 h-5"
													src="@/assets/images/icons/class-teacher.png"
													alt=""
												/>
											</div>
											<div
												class="p-2"
												v-if="
													person?.has_day_care_class
												"
											>
												<img
													class="w-5 h-5"
													src="@/assets/images/icons/spoon.png"
													alt=""
												/>
											</div>
											<div
												class="p-2"
												v-if="person?.has_group_class"
											>
												<img
													class="w-5 h-5"
													src="@/assets/images/icons/group.png"
													alt=""
												/>
											</div>
										</div>
									</td>
									<td>
										<div
											v-if="person.last_renew_at"
											class="px-6 py-4 text-neutral-700"
										>
											{{
												$filters.formatDateTime(
													person.last_renew_at
												)
											}}
										</div>
									</td>
									<td>
										<div class="px-6 py-4 text-neutral-700">
											{{ person.number_of_cycle ?? 0 }}
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else
			class="
				w-full
				h-full
				flex
				items-center
				justify-center
				flex-col
				mt-20
			"
		>
			<img
				src="@/assets/images/illustrations/not_found.png"
				alt=""
				height="250"
				width="250"
			/>
			<div class="text-neutral-500 text-lg max-w-lg text-center">
				Chưa có học sinh nào trong danh sách học sinh được nhắc đánh giá
				định kỳ
			</div>
			<button
				type="button"
				class="
					mt-6
					h-12
					button-p
					btn--ghost
					text-center
					mr-3
					px-6
					whitespace-nowrap
					w-fit
				"
				@click="addStudents"
			>
				Thêm học sinh
			</button>
		</div>
	</div>
</template>

<script>
import { _ROUTER_NAME } from '@/enums';
import { $api } from '@/services';

export default {
	name: 'PeriodicAssessment',

	props: {
		org: {
			type: Object,
			required: true,
		},
	},

	computed: {
		isAdmin() {
			return this.$store.getters.isAdmin;
		},
	},

	data() {
		return {
			isEdit: false,
			onChange: false,
			onWatchOrg: true,
			no_hour_to_noti_periodic_assessment: 0,
			students: [],
		};
	},

	watch: {
		org: {
			immediate: true,
			deep: true,
			handler(value) {
				if (value && this.onWatchOrg) {
					this.no_hour_to_noti_periodic_assessment =
						value?.no_hour_to_noti_periodic_assessment;
					setTimeout(() => {
						this.onChange = true;
					}, 1000);
					this.onWatchOrg = false;
				}
			},
		},
	},

	created() {
		this.fetchStudentInPeriodicAssessment();
	},

	methods: {
		async submit() {
			try {
				this.$emit('updateOrg', {
					no_hour_to_noti_periodic_assessment:
						+this.no_hour_to_noti_periodic_assessment,
				});
			} catch (e) {
				await this.setup.store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},

		addStudents() {
			this.$router.push({
				name: _ROUTER_NAME.SETUP_PERIODIC_ASSESSMENT_ADD_STUDENT,
			});
		},

		async fetchStudentInPeriodicAssessment() {
			try {
				const {
					data: { data },
				} = await $api.org.getPeriodicAssessment();
				this.students = data ?? [];
			} catch (e) {
				console.log(e);
			}
		},
	},
};
</script>
