<template>
	<div class="__report-lesson px-12 md-down:px-4 pb-30">
		<div
			class="
				__report-lesson__header
				pt-10
				pb-10
				flex flex-nowrap
				justify-between
				items-center
			"
		>
			<div
				class="
					__record__title
					text-neutral-500
					font-semibold
					whitespace-nowrap
					flex flex-nowrap
					items-center
					text-4xl
				"
			>
				<svg
					width="28"
					height="28"
					viewBox="0 0 28 28"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M7 0.875C7 0.391751 6.60825 0 6.125 0C5.64175 0 5.25 0.391751 5.25 0.875V1.75H3.5C1.567 1.75 0 3.317 0 5.25V7H28V5.25C28 3.317 26.433 1.75 24.5 1.75H22.75V0.875C22.75 0.391751 22.3582 0 21.875 0C21.3918 0 21 0.391751 21 0.875V1.75H7V0.875ZM28 24.5V8.75H0V24.5C0 26.433 1.567 28 3.5 28H24.5C26.433 28 28 26.433 28 24.5ZM18.9937 15.4937C19.3354 15.152 19.3354 14.598 18.9937 14.2563C18.652 13.9146 18.098 13.9146 17.7563 14.2563L13.125 18.8876L11.1187 16.8813C10.777 16.5396 10.223 16.5396 9.88128 16.8813C9.53957 17.223 9.53957 17.777 9.88128 18.1187L12.5063 20.7437C12.6704 20.9078 12.8929 21 13.125 21C13.3571 21 13.5796 20.9078 13.7437 20.7437L18.9937 15.4937Z"
						fill="#94A3B8"
					/>
				</svg>
				<span class="ml-4">Thống kê lịch học</span>
			</div>
		</div>
		<div v-if="!isLoading" class="__report-lesson__container">
			<div v-for="data in statsData" :key="data" class="mb-10">
				<div
					class="mb-3 text-neutral-600 font-bold text-lg"
					v-if="data.date"
				>
					Tháng {{ toMMYY(data.date) }}
				</div>
				<div
					class="
						grid
						gap-8
						mb-10
						md-down:gap-4
						grid-cols-2
						md-down:grid-cols-1
					"
				>
					<div
						class="
							bg-white
							p-4
							rounded-xl
							border-sm
							shadow-sm
							flex
							items-center
							justify-between
						"
					>
						<div class="flex items-center flex-nowrap">
							<svg
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clip-path="url(#clip0_11104_7921)">
									<path
										d="M7 9.75C6.31 9.75 5.75 10.31 5.75 11C5.75 11.69 6.31 12.25 7 12.25C7.69 12.25 8.25 11.69 8.25 11C8.25 10.31 7.69 9.75 7 9.75ZM13 9.75C12.31 9.75 11.75 10.31 11.75 11C11.75 11.69 12.31 12.25 13 12.25C13.69 12.25 14.25 11.69 14.25 11C14.25 10.31 13.69 9.75 13 9.75ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 9.71 2.02 9.42 2.05 9.14C4.41 8.09 6.28 6.16 7.26 3.77C9.07 6.33 12.05 8 15.42 8C16.2 8 16.95 7.91 17.67 7.74C17.88 8.45 18 9.21 18 10C18 14.41 14.41 18 10 18Z"
										fill="#94A3B8"
									/>
									<path
										d="M8 13.9895C8 13.9895 9.86667 18.4895 12 13.9895H8Z"
										fill="#94A3B8"
									/>
								</g>
								<defs>
									<clipPath id="clip0_11104_7921">
										<rect
											width="20"
											height="20"
											fill="white"
										/>
									</clipPath>
								</defs>
							</svg>

							<div class="text-neutral-500 text-sm ml-2">
								Học sinh
							</div>
						</div>
						<div
							class="
								flex
								items-center
								flex-nowrap
								justify-between
							"
						>
							<div
								class="
									text-neutral-600
									font-bold
									text-4xl text-right
								"
							>
								{{ data.stats.student_count }} /
								{{ data.stats.total_student }}
							</div>
						</div>
					</div>
					<div
						class="
							bg-white
							p-4
							rounded-xl
							border-sm
							shadow-sm
							flex
							items-center
							justify-between
						"
					>
						<div class="flex items-center flex-nowrap">
							<svg
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM10 4.375C10 4.02982 9.72018 3.75 9.375 3.75C9.02982 3.75 8.75 4.02982 8.75 4.375V11.25C8.75 11.4743 8.87018 11.6814 9.06491 11.7927L13.4399 14.2927C13.7396 14.4639 14.1214 14.3598 14.2927 14.0601C14.4639 13.7604 14.3598 13.3786 14.0601 13.2073L10 10.8873V4.375Z"
									fill="#94A3B8"
								/>
							</svg>

							<div class="text-neutral-500 text-sm ml-2">
								Giờ can thiệp/ dự kiến
							</div>
						</div>
						<div
							class="
								flex
								items-center
								flex-nowrap
								justify-between
							"
						>
							<div
								class="
									text-neutral-600
									font-bold
									text-4xl text-right
								"
							>
								{{ data.stats.total_non_cancelled_hours }} /
								{{ data.stats.total_hours }}
							</div>
						</div>
					</div>
				</div>
				<div class="shadow-sm sm:rounded-2xl overflow-hidden">
					<div class="__record__table overflow-x-auto scroll-bar">
						<div
							class="
								overflow-hidden
								sm:rounded-t-2xl
								w-fit
								min-w-full
							"
						>
							<table class="min-w-full divide-y text-neutral-200">
								<thead class="bg-neutral-100 h-16">
									<tr>
										<th
											scope="col"
											class="
												px-3
												py-3
												text-center text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												w-16
												whitespace-nowrap
											"
										>
											STT
										</th>
										<th
											scope="col"
											class="
												px-3
												py-3
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
											"
										>
											HỌC SINH
										</th>
										<th
											scope="col"
											class="
												px-3
												py-3
												text-center text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
											"
										>
											Giờ học can thiệp (thực tế / dự
											kiến)
										</th>
										<th
											scope="col"
											class="
												px-3
												py-3
												text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
												text-center
											"
										>
											Ngày học bán trú (thực tế / dự kiến)
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										@click="
											$router.push(
												`/schedule/stats/student?student_id=${
													item.student_id
												}&date=${dateSubmit(data.date)}`
											)
										"
										v-for="(
											item, i
										) in data.students_with_stats"
										:key="i"
										:class="
											i % 2 === 0
												? 'bg-white'
												: 'bg-neutral-50'
										"
										class="
											text-neutral-700
											cursor-pointer
											hover:bg-orange-50
										"
									>
										<td>
											<div
												class="
													px-3
													py-4
													text-center text-neutral-700
												"
											>
												{{ i + 1 }}
											</div>
										</td>
										<td
											class="
												px-3
												py-4
												whitespace-nowrap
												text-neutral-700
											"
										>
											<div
												class="
													flex
													items-center
													cursor-pointer
												"
											>
												<img
													class="
														shadow-sm
														inline-block
														h-6
														w-6
														rounded-full
													"
													:src="
														data.students_map[
															item?.student_id
														][0]?.profile_photo
															?.w200
													"
													v-if="
														data.students_map[
															item?.student_id
														][0]?.profile_photo
															?.w200
													"
													alt=""
												/>
												<span
													v-else
													class="
														inline-flex
														items-center
														justify-center
														h-6
														w-6
														rounded-full
													"
													:style="{
														background:
															data.students_map[
																item?.student_id
															][0]?.profile_photo
																?.default
																?.color,
													}"
												>
													<span
														class="
															text-xs
															font-medium
															leading-none
															text-white
														"
														>{{
															data.students_map[
																item?.student_id
															][0]?.profile_photo
																?.default
																?.initial
														}}</span
													>
												</span>
												<div
													class="
														ml-4
														font-semibold
														text-orange-600
														truncate
														hover:underline
													"
													@click.stop="
														$router.push(
															`/record/student/${data.students_map[
																item?.student_id
															][0]?.id}`
														)"
												>
													{{
														data.students_map[
															item?.student_id
														][0]?.name
													}}
												</div>
											</div>
										</td>
										<td
											class="
												px-3
												py-4
												whitespace-nowrap
												text-neutral-700 text-center
											"
										>
											{{ item.total_non_cancelled_hours }}
											/
											{{ item.total_hours }}
										</td>
										<td
											class="
												px-3
												py-4
												whitespace-nowrap
												text-neutral-700 text-center
											"
										>
											{{ item.total_day_care_learned }} /
											{{ item.total_day_care }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<DaInfinityLoading
				@infinite-load="getScheduleStats"
				:loaded="stopLoad"
				v-if="isInfinityLoading"
			/>
		</div>
		<div v-else class="fixed top-1/2 left-1/2">
			<Loading />
		</div>
	</div>
</template>

<script>
import dayjs from 'dayjs';
import DaInfinityLoading from '@/components/common/daInfinityLoading/DaInfinityLoading';
import { $api } from '@/services';
import Loading from '@/components/common/Loading';

export default {
	name: 'ScheduleStats',

	components: { Loading, DaInfinityLoading },

	data() {
		return {
			stopLoad: false,
			isInfinityLoading: false,
			date: dayjs().toDate(),
			statsData: [],
			isLoading: true,
		};
	},

	created() {
		this.getScheduleStats();
	},

	methods: {
		dateSubmit(date) {
			return dayjs(date).format('YYYY-MM-DD');
		},
		toMMYY(date) {
			return dayjs(date).format('MM/YYYY');
		},
		prevMonth() {
			this.date = dayjs(this.date).subtract(1, 'month').toDate();
		},
		async getScheduleStats() {
			try {
				const { data, students_with_stats, students_map } =
					await $api.schedule.scheduleStatsByMonth({
						date: dayjs(this.date).format('YYYY-MM') + '-01',
					});
				this.isInfinityLoading = true;
				if (data) {
					this.statsData.push({
						...data,
						date: this.date,
					});
					this.prevMonth();
					this.students_with_stats = students_with_stats;
					this.students_map = students_map;
				} else {
					this.stopLoad = true;
				}
			} catch (e) {
				console.log(e);
			} finally {
				this.isLoading = false;
				this.isInfinityLoading = true;
			}
		},
	},
};
</script>
