<template>
	<div class="_create-boarding _modal bg-white">
		<div
			class="
				_modal__content
				p-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<img
					src="@/assets/images/illustrations/warning.png"
					alt="Luca Education"
					title="Luca Education"
					height="60"
					width="60"
				/>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
					text-center
				"
			>
				Bạn có chắc chắn muốn thoát?
			</div>
			<div class="text-center text-neutral-400 mt-2 tracking-wide">
				Chỉnh sửa của bạn chưa được lưu. Bạn có muốn lưu trước khi
				thoát?
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				type="button"
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				@click="$emit('close')"
			>
				Thoát
			</button>
			<button
				type="button"
				class="button-p text-center whitespace-nowrap"
				@click="$emit('submit')"
			>
				Lưu chỉnh sửa
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ExitModal',
};
</script>
