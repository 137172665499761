<template>
	<div class="mt-12 bg-white shadow-sm rounded-2xl overflow-hidden">
		<div class="text-neutral-500 p-6">
			<div>{{ goal?.skill?.name }}</div>
			<div class="font-semibold">
				{{ goal?.name }}
			</div>
		</div>
		<div class="border-t border-gray-100">
			<div class="flex flex-wrap p-6">
				<div class="flex flex-col items-center w-20">
					<div
						:class="
							selected?.type === 'class' ? 'border-green-500' : ''
						"
						class="
							p-0.5
							w-fit
							border-2
							rounded-full
							relative
							h-14
							w-14
						"
						@click="
							select({
								...groupClass,
								type: 'class',
							})
						"
					>
						<div
							v-if="classAssessment?.id"
							class="absolute top-0 right-0 bg-white rounded-full"
						>
							<svg
								fill="none"
								height="16"
								viewBox="0 0 16 16"
								width="16"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									clip-rule="evenodd"
									d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM12.0303 4.96967C11.7374 4.67678 11.2625 4.67678 10.9697 4.96967C10.9626 4.97674 10.9559 4.98424 10.9498 4.9921L7.47739 9.41674L5.38387 7.32322C5.09097 7.03033 4.6161 7.03033 4.32321 7.32322C4.03031 7.61612 4.03031 8.09099 4.32321 8.38388L6.96965 11.0303C7.26255 11.3232 7.73742 11.3232 8.03031 11.0303C8.03684 11.0238 8.04299 11.0169 8.04875 11.0097L12.041 6.01947C12.3232 5.72582 12.3196 5.25897 12.0303 4.96967Z"
									fill="#2AA797"
									fill-rule="evenodd"
								/>
							</svg>
						</div>
						<img
							v-if="profile?.profile_photo?.w200"
							:class="`h-12 w-12`"
							:src="profile?.profile_photo?.w200"
							alt=""
							class="shadow-sm inline-block rounded-full"
						/>
						<span
							v-else
							:class="`h-12 w-12`"
							:style="{
								background:
									profile?.profile_photo?.default?.color,
							}"
							class="
								inline-flex
								items-center
								justify-center
								rounded-full
							"
						>
							<span
								class="
									font-medium
									leading-none
									text-white text-xs
								"
								>{{
									profile?.profile_photo?.default?.initial
								}}</span
							>
						</span>
					</div>
					<Popper
						v-if="selected?.type === 'class'"
						:arrow="true"
						hover
					>
						<div
							class="mt-2 font-semibold text-orange-500 truncate"
							style="max-width: 100px"
						>
							{{ groupClass?.name }}
						</div>
						<template #content>
							{{ groupClass?.name }}
						</template>
					</Popper>
				</div>
				<div
					v-for="item in groupClass?.students"
					:key="item?.id"
					class="w-20"
				>
					<div
						class="
							ring-2 ring-white
							rounded-full
							cursor-pointer
							w-20
							flex flex-col
							items-center
						"
						@click="
							select({
								...item,
								type: 'student',
							})
						"
					>
						<div
							:class="
								selected?.type === 'student' &&
								selected?.id === item?.id
									? 'border-green-500'
									: ''
							"
							class="p-0.5 w-fit border-2 rounded-full relative"
						>
							<div
								v-if="
									assessments?.find(
										(a) => a?.student_id === item?.id
									)
								"
								class="
									absolute
									top-0
									right-0
									bg-white
									rounded-full
								"
							>
								<svg
									fill="none"
									height="16"
									viewBox="0 0 16 16"
									width="16"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										clip-rule="evenodd"
										d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM12.0303 4.96967C11.7374 4.67678 11.2625 4.67678 10.9697 4.96967C10.9626 4.97674 10.9559 4.98424 10.9498 4.9921L7.47739 9.41674L5.38387 7.32322C5.09097 7.03033 4.6161 7.03033 4.32321 7.32322C4.03031 7.61612 4.03031 8.09099 4.32321 8.38388L6.96965 11.0303C7.26255 11.3232 7.73742 11.3232 8.03031 11.0303C8.03684 11.0238 8.04299 11.0169 8.04875 11.0097L12.041 6.01947C12.3232 5.72582 12.3196 5.25897 12.0303 4.96967Z"
										fill="#2AA797"
										fill-rule="evenodd"
									/>
								</svg>
							</div>
							<img
								v-if="item?.profile_photo?.w200"
								:class="`h-12 w-12`"
								:src="item?.profile_photo?.w200"
								alt=""
								class="shadow-sm inline-block rounded-full"
							/>
							<span
								v-else
								:class="`h-12 w-12`"
								:style="{
									background:
										item?.profile_photo?.default?.color,
								}"
								class="
									inline-flex
									items-center
									justify-center
									rounded-full
								"
							>
								<span
									class="
										font-medium
										leading-none
										text-white text-xs
									"
									>{{
										item?.profile_photo?.default?.initial
									}}</span
								>
							</span>
							<div
								v-if="
									result?.data?.find(
										(a) => a?.student_id === item?.id
									)
								"
								:style="{
									background: TAG_COLOR(
										result?.data?.find(
											(a) => a?.student_id === item?.id
										)?.complete_percent
									),
								}"
								class="
									absolute
									transform
									-translate-x-1/2 -translate-y-1/2
									left-1/2
									p-0.5
									px-3
									text-xs
									font-semibold
									text-white
									rounded-full
								"
							>
								{{
									result?.data?.find(
										(a) => a?.student_id === item?.id
									)?.complete_percent || 0
								}}%
							</div>
						</div>
						<Popper
							v-if="
								selected?.type === 'student' &&
								selected?.id === item?.id
							"
							:arrow="true"
							hover
						>
							<div
								class="
									mt-2
									font-semibold
									text-orange-500
									truncate
								"
								style="max-width: 100px"
							>
								{{ item?.name }}
							</div>
							<template #content>
								{{ item?.name }}
							</template>
						</Popper>
					</div>
				</div>
			</div>
			<div
				v-if="
					selected?.type === 'class' ||
					(assessment &&
						list_student_ids_learn_group_goal?.includes(
							assessment?.student_id
						))
				"
				class="flex items-stretch flex-col 2xl:flex-row gap-5 p-6"
			>
				<div class="w-full h-full">
					<textarea
						v-if="selected?.type === 'class'"
						v-model="classAssessment.note"
						placeholder="Nhận xét / ghi chú dành cho cấp Quản lý"
						style="height: 100%; resize: none; min-height: 200px"
					></textarea>
					<textarea
						v-else
						v-model="assessment.note"
						placeholder="Nhận xét của giáo viên về mục tiêu này của trẻ"
						style="height: 100%; resize: none; min-height: 200px"
						@blur="updateAssessmentReportsGroup"
					></textarea>
				</div>
				<div
					v-if="selected?.type === 'class'"
					:style="{
						minWidth:
							selected?.type === 'class'
								? '460px'
								: 'fit-content',
						maxWidth:
							selected?.type === 'class'
								? '460px'
								: 'fit-content',
					}"
					class="flex flex-nowrap md-down:flex-col justify-center"
				>
					<div class="relative md-down:mb-8 w-60">
						<apexchart
							v-if="studentResultCount?.length"
							:options="chartOptions"
							:series="studentResultCount.map((i) => i.value)"
							height="220"
							type="donut"
						></apexchart>
						<div
							class="
								text-center
								absolute
								top-1/2
								left-1/2
								transform
							"
							style="transform: translate(-50%, -50%)"
						>
							<div class="text-4xl font-bold text-neutral-600">
								{{ result?.number_of_student }}
							</div>
							<div class="text-neutral-500">học sinh</div>
						</div>
					</div>
					<div
						class="flex flex-col justify-center md-down:mt-4 w-fit"
					>
						<div
							v-for="(count, index) in studentResultCount"
							:key="index"
							:class="{
								hidden: count?.value <= 0,
							}"
							class="flex flex-nowrap mb-4"
						>
							<span
								:style="{
									background: `${colors[index]}`,
								}"
								class="
									flex
									items-center
									justify-center
									py-0.5
									rounded-full
									text-sm
									font-medium
									text-white
									w-32
									whitespace-nowrap
									shadow-input
								"
							>
								{{ chartOptions.labels[index] }}
							</span>
							<div
								v-if="
									selected?.type === 'class' &&
									groupClass?.students?.filter((s) =>
										count?.students.includes(s.id)
									)?.length
								"
								class="px-2 pr-8"
							>
								<UserGroup
									:data="
										groupClass?.students.filter((s) =>
											count?.students.includes(s.id)
										)
									"
									placement="bottom-start"
									size="8"
								/>
							</div>
						</div>
					</div>
				</div>
				<div
					v-else-if="
						result?.data?.find(
							(a) => a?.student_id === selected?.id
						)
					"
				>
					<div
						:style="{
							background: TAG_COLOR(
								result?.data?.find(
									(a) => a?.student_id === selected?.id
								)?.complete_percent
							),
						}"
						class="
							flex
							items-center
							justify-center
							py-1
							rounded-full
							text-sm
							font-medium
							text-white
							w-32
							whitespace-nowrap
							shadow-input
						"
					>
						{{
							result?.data?.find(
								(a) => a?.student_id === selected?.id
							)?.complete_percent
								? `Đạt ${
										result?.data?.find(
											(a) =>
												a?.student_id === selected?.id
										)?.complete_percent
								  }%`
								: 'Từ chối'
						}}
					</div>
				</div>
			</div>
			<div
				v-else
				class="
					flex
					gap-4
					flex-col
					lg:flex-row
					p-6
					border-t border-orange-50
					text-neutral-500
				"
			>
				Học sinh không học mục tiêu này trong tháng
			</div>
			<div
				v-if="selected?.type === 'class'"
				class="flex gap-4 flex-nowrap flex-col p-6 pt-0"
			>
				<div class="text-neutral-500 font-semibold">Ghi chú</div>
				<div
					class="
						mt-4
						space-y-4
						sm:flex sm:items-center sm:space-y-0 sm:space-x-10
					"
				>
					<div
						v-for="re in nexPlanOptions"
						:key="re.id"
						class="flex items-center"
					>
						<input
							v-model="classAssessment.next_plan"
							:value="re.id"
							class="
								h-4
								w-4
								text-yellow-600
								border-neutral-400 border-2
								focus:ring-yellow-600
							"
							name="notification-method"
							type="radio"
						/>
						<label
							class="
								pl-3
								block
								text-sm
								font-medium
								text-neutral-700
							"
							@click="classAssessment.next_plan = re.id"
						>
							{{ re.title }}
						</label>
					</div>
				</div>
				<div
					v-if="classAssessment.next_plan === 'stopped'"
					class="mt-8"
				>
					<Multiselect
						v-model="classAssessment.stopped_reason"
						:allow-empty="false"
						:options="reasons"
						:searchable="true"
						deselect-label="Đã chọn"
						label="nameSearch"
						openDirection="top"
						placeholder="Chọn lý do tạm dừng"
						selectLabel=""
						track-by="value"
					>
						<template #noResult> Không tìm thấy kết quả</template>
						<template v-slot:option="props">
							<div class="flex flex-nowrap items-center">
								<div>
									{{ props.option.name }}
								</div>
							</div>
						</template>
						<template v-slot:singleLabel="{ option }">
							<div
								class="
									flex flex-nowrap
									items-center
									font-medium
								"
							>
								<div>{{ option.name }}</div>
							</div>
						</template>
					</Multiselect>
				</div>
				<div
					v-if="
						classAssessment.next_plan === 'stopped' &&
						classAssessment.stopped_reason?.value === 'other'
					"
					class="mt-2"
				>
					<div class="input-group">
						<input
							v-model="classAssessment.stopped_reason_other"
							placeholder="Nhập lý do khác"
							type="text"
						/>
					</div>
				</div>
				<div v-if="error" class="text-sienna-500 text-sm mt-2">
					{{ error }}
				</div>
			</div>
		</div>
		<div
			v-if="selected?.type === 'class'"
			class="
				flex
				gap-4
				flex-nowrap
				items-end
				justify-end
				p-6
				border-t border-orange-50
			"
		>
			<button
				:disabled="!canSubmit"
				class="button-p text-center w-fit px-6"
				type="button"
				@click="onSubmit"
			>
				Xác nhận kết quả
			</button>
		</div>
	</div>
</template>
<script>
import Multiselect from '@suadelabs/vue3-multiselect';
import { TAG_COLOR } from '@/helper/tag';
import UserGroup from '@/components/elements/UserGroup.vue';
import { $api } from '@/services';
import Popper from 'vue3-popper';

export default {
	name: 'ReportDetailStep2',

	components: { Popper, UserGroup, Multiselect },

	props: {
		goal: {
			type: Object,
			required: true,
		},

		groupClass: {
			type: Object,
			required: true,
		},

		student_assessment_group_monthly_reports: {
			type: Array,
			required: true,
		},

		result: {
			type: Object,
			required: true,
		},

		assessments: {
			type: Array,
			required: true,
		},

		list_student_ids_learn_group_goal: {
			type: Array,
			required: true,
		},

		assessment_group_monthly_report: {
			type: Object,
			required: true,
		},

		reportId: {
			type: String,
			required: true,
		},
	},

	watch: {
		assessments: {
			immediate: true,
			deep: true,
			handler(value) {
				if (value && !this.assessment) {
					this.assessment = value[0] || {
						complete_percent: 0,
						group_goal_id: this.goal?.id,
						note: '',
						raw_result: null,
						student_id: null,
					};
				}
			},
		},

		assessment_group_monthly_report: {
			immediate: true,
			deep: true,
			handler(value) {
				this.classAssessment = value
					? {
							...value,
							stopped_reason: this.reasons.find(
								(r) => r.value === value?.stopped_reason
							) || {
								value: '',
							},
					  }
					: {
							group_goal_id: this.goal?.id,
							note: '',
							next_plan: '',
							stopped_reason: {
								value: '',
							},
							stopped_reason_other: '',
					  };
			},
		},

		'goal.id': {
			immediate: true,
			handler(value) {
				if (value) {
					this.selected = {
						type: 'class',
						id: this.groupClass?.id,
					};
				}
			},
		},
	},

	computed: {
		studentResultCount() {
			const result = [
				{
					value: 0,
					students: [],
				},
				{
					value: 0,
					students: [],
				},
				{
					value: 0,
					students: [],
				},
				{
					value: 0,
					students: [],
				},
				{
					value: 0,
					students: [],
				},
			];
			this.student_assessment_group_monthly_reports?.forEach((item) => {
				if (+item?.complete_percent >= 75) {
					result[0].value += 1;
					result[0].students.push(item.student_id);
				} else if (+item?.complete_percent >= 50) {
					result[1].value += 1;
					result[1].students.push(item.student_id);
				} else if (+item?.complete_percent >= 25) {
					result[2].value += 1;
					result[2].students.push(item.student_id);
				} else if (+item?.complete_percent >= 5) {
					result[3].value += 1;
					result[3].students.push(item.student_id);
				} else {
					result[4].value += 1;
					result[4].students.push(item.student_id);
				}
			});
			return result;
		},

		profile() {
			return this.$store.state?.profile?.current_org;
		},

		canSubmit() {
			if (this.classAssessment?.next_plan === 'stopped') {
				return (
					this.classAssessment?.stopped_reason &&
					(this.classAssessment?.stopped_reason?.value !== 'other' ||
						this.classAssessment?.stopped_reason_other)
				);
			}

			return this.classAssessment?.next_plan;
		},
	},

	data() {
		return {
			colors: ['#2AA797', '#65A30D', '#F59E0B', '#F87171', '#9CA3AF'],
			chartOptions: {
				dataLabels: {
					enabled: false,
				},
				colors: ['#7AD2C6', '#93BF56', '#F8BB54', '#F47C7C', '#E5E7EB'],
				labels: [
					'Đạt 80 - 100%',
					'Đạt 55 - 75%',
					'Đạt 30 - 50%',
					'Đạt 5 - 25%',
					'Từ chối',
				],
				legend: {
					show: false,
				},
				tooltip: {
					fillSeriesColor: true,
					y: {
						formatter: function (value) {
							return value + ' HS';
						},
					},
				},
				stroke: {
					lineCap: 'round',
				},
			},
			nexPlanOptions: [
				{
					id: 'continued',
					title: 'Tiếp tục mục tiêu',
				},
				{
					id: 'completed',
					title: 'Mục tiêu đã hoàn thành',
				},
				{
					id: 'stopped',
					title: 'Tạm dừng mục tiêu',
				},
			],
			reasons: [
				{
					value: 'too_hard',
					name: 'Mục tiêu quá khó',
				},
				{
					value: 'no_cooperation',
					name: 'Con không hợp tác',
				},
				{
					value: 'no_opportunity',
					name: 'Con không có cơ hội',
				},
				{
					value: 'other',
					name: 'Lý do khác',
				},
			],
			assessment: {
				group_goal_id: '',
				next_plan: '',
				note: '',
				stopped_reason: '',
				stopped_reason_other: '',
			},
			selected: {
				type: 'class',
				id: this.groupClass?.id,
			},
			classAssessment: {
				id: null,
				group_goal_id: '',
				next_plan: '',
				note: '',
				stopped_reason: '',
				stopped_reason_other: '',
			},
			error: '',
		};
	},

	methods: {
		TAG_COLOR,
		async onSubmit() {
			await this.submit();
			this.$emit('submit', { completed: true, next: true });
		},

		select(item) {
			if (this.selected?.type === 'class') {
				if (!this.canSubmit) {
					this.error =
						'Vui lòng chọn ghi chú mục tiêu cho lớp nhóm này';
					setTimeout(() => {
						this.error = '';
					}, 3000);
					return;
				} else {
					this.submit().then(() => {
						this.error = '';
					});
				}
			}

			let as = this.assessments.find((a) => a.student_id === item.id);
			if (as) {
				this.assessment = as;
			} else {
				this.assessment = {
					group_goal_id: this.goal?.id,
					note: '',
					stopped_reason: null,
					stopped_reason_other: null,
					student_id: null,
				};
			}
			this.selected = item;
		},

		async submit() {
			try {
				this.classAssessment?.id
					? await $api.monthlyReport.updateAssessmentStep2ReportsGroup(
							this.groupClass?.id,
							this.reportId,
							this.classAssessment?.id,
							{
								assessment_group_monthly_report: {
									group_goal_id: this.goal?.id,
									next_plan: this.classAssessment.next_plan,
									note: this.classAssessment.note,
									stopped_reason:
										this.classAssessment.stopped_reason
											?.value || undefined,
									stopped_reason_other:
										this.classAssessment
											.stopped_reason_other || undefined,
								},
							}
					  )
					: await $api.monthlyReport.createAssessmentStep2ReportsGroup(
							this.groupClass?.id,
							this.reportId,
							{
								assessment_group_monthly_report: {
									group_goal_id: this.goal?.id,
									next_plan: this.classAssessment.next_plan,
									note: this.classAssessment.note,
									stopped_reason:
										this.classAssessment.stopped_reason
											?.value || undefined,
									stopped_reason_other:
										this.classAssessment
											.stopped_reason_other || undefined,
								},
							}
					  );
			} catch (err) {
				const error =
					err?.response?.data?.errors?.join(<br />) ||
					err?.message ||
					'Đã có lỗi xảy ra. Vui lòng thử lại';
				await this.$store.dispatch('setAlertMessage', {
					message: error,
					type: 'danger',
				});
			} finally {
				this.$emit('submit', { completed: true, next: false });
			}
		},

		async updateAssessmentReportsGroup() {
			try {
				this.assessment?.id
					? await $api.monthlyReport.updateAssessmentReportsGroup(
							this.groupClass?.id,
							this.reportId,
							this.assessment?.id,
							{
								group_goal_id: this.goal?.id,
								note: this.assessment?.note,
								student_id: this.selected?.id,
							}
					  )
					: await $api.monthlyReport.createAssessmentReportsGroup(
							this.groupClass?.id,
							this.reportId,
							{
								group_goal_id: this.goal?.id,
								note: this.assessment?.note,
								student_id: this.selected?.id,
							}
					  );
				this.$emit('submit', { completed: true, next: false });
			} catch (err) {
				let error = '';
				if (err?.response?.data?.errors_by_field?.note) {
					error = `Ghi chú ${err?.response?.data?.errors_by_field?.note}`;
				} else {
					error =
						err?.response?.data?.errors?.join(<br />) ||
						err?.message ||
						'Đã có lỗi xảy ra. Vui lòng thử lại';
				}
				await this.$store.dispatch('setAlertMessage', {
					message: error,
					type: 'danger',
				});
				throw error;
			}
		},
	},
};
</script>
<style lang="scss" scoped>
@function makelongshadow($color, $size) {
	$val: 6px 0 0 $size $color;

	@for $i from 6 through 1000 {
		$val: #{$val}, #{$i}px 0 0 $size #{$color};
	}

	@return $val;
}

input[type='range'] {
	align-items: center;
	appearance: none;
	background: none;
	cursor: pointer;
	display: flex;
	height: 100%;
	min-height: 50px;
	overflow: hidden;
	width: 100%;

	&::-webkit-slider-runnable-track {
		content: '';
		height: 8px;
		pointer-events: none;
	}

	&::-webkit-slider-thumb {
		height: 24px;
		width: 24px;
		appearance: none;
		background: white;
		border-radius: 999px;
		box-shadow: makelongshadow(#c7c7c7, -8px);
		margin-top: -8px;
		-webkit-appearance: none;
		cursor: pointer;
	}
}

.range-color-2AA797 {
	input[type='range'] {
		&::-webkit-slider-thumb {
			border: 2px solid #2aa797;
		}

		&::-webkit-slider-runnable-track {
			background: #2aa797;
		}
	}
}

.range-color-65A30D {
	input[type='range'] {
		&::-webkit-slider-thumb {
			border: 2px solid #65a30d;
		}

		&::-webkit-slider-runnable-track {
			background: #65a30d;
		}
	}
}

.range-color-F59E0B {
	input[type='range'] {
		&::-webkit-slider-thumb {
			border: 2px solid #f59e0b;
		}

		&::-webkit-slider-runnable-track {
			background: #f59e0b;
		}
	}
}

.range-color-F87171 {
	input[type='range'] {
		&::-webkit-slider-thumb {
			border: 2px solid #f87171;
		}

		&::-webkit-slider-runnable-track {
			background: #f87171;
		}
	}
}

.range-color-9CA3AF {
	input[type='range'] {
		&::-webkit-slider-thumb {
			border: 2px solid #9ca3af;
		}

		&::-webkit-slider-runnable-track {
			background: #9ca3af;
		}
	}
}
</style>
