import { tab } from '@/views/setupCenter/@types/tab';
import { _ROUTER_NAME } from '@/enums';

export const tabs: tab[] = [
	{
		id: _ROUTER_NAME.SETUP_INFORMATION,
		name: 'Thông Tin Liên Hệ',
		icon: 'das--gear',
		link: '/setup-center',
	},
	{
		id: _ROUTER_NAME.SETUP_SCHEDULE,
		name: 'Lịch học',
		icon: 'das--calender',
		link: '/setup-center/schedule',
	},
	{
		id: _ROUTER_NAME.SETUP_TARGET_SMART,
		name: 'Mục tiêu',
		icon: 'das--goal',
		link: '/setup-center/target-smart',
	},
	{
		id: _ROUTER_NAME.SETUP_TARGET_GOALS,
		name: 'Lĩnh vực',
		icon: 'das--goal',
		link: '/setup-center/goals',
	},
	{
		id: _ROUTER_NAME.SETUP_REPORT,
		name: 'Báo cáo',
		icon: 'das--report',
		link: '/setup-center/report',
	},
	{
		id: _ROUTER_NAME.SETUP_PERIODIC_ASSESSMENT,
		name: 'Đánh giá định kỳ',
		icon: 'das--report',
		link: '/setup-center/periodic-assessment',
	},
	{
		id: _ROUTER_NAME.SETUP_OTHERS,
		name: 'Nhóm chat',
		icon: 'das--report',
		link: '/setup-center/others',
	},
];
