<template>
	<div class="bg-white rounded-2xl shadow-sm overflow-hidden relative">
		<DaModalFrame v-model:open="deletePopup">
			<DeleteParentModal
				v-model:data="deleteData"
				@close="deletePopup = false"
				@submit="closeParentInvitation"
			/>
		</DaModalFrame>
		<div class="__data__header p-6 flex items-center justify-between">
			<div
				class="text-neutral-600 font-semibold"
				v-if="data && data?.relationship"
			>
				Thông tin của
				{{ data?.relationship?.role || `Phụ huynh ${index + 1}` }}
			</div>
			<div class="text-neutral-600 font-semibold" v-else>
				Thông tin của Phụ huynh {{ index + 1 }}
			</div>
			<div
				class="hover:text-blue-700 text-blue-600 cursor-pointer"
				@click="openClosePopup"
			>
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M3.125 1.25C2.43464 1.25 1.875 1.80964 1.875 2.5V3.75C1.875 4.44036 2.43464 5 3.125 5H3.75V16.25C3.75 17.6307 4.86929 18.75 6.25 18.75H13.75C15.1307 18.75 16.25 17.6307 16.25 16.25V5H16.875C17.5654 5 18.125 4.44036 18.125 3.75V2.5C18.125 1.80964 17.5654 1.25 16.875 1.25H12.5C12.5 0.559644 11.9404 0 11.25 0H8.75C8.05964 0 7.5 0.559644 7.5 1.25H3.125ZM6.875 6.25C7.22018 6.25 7.5 6.52982 7.5 6.875V15.625C7.5 15.9702 7.22018 16.25 6.875 16.25C6.52982 16.25 6.25 15.9702 6.25 15.625L6.25 6.875C6.25 6.52982 6.52982 6.25 6.875 6.25ZM10 6.25C10.3452 6.25 10.625 6.52982 10.625 6.875V15.625C10.625 15.9702 10.3452 16.25 10 16.25C9.65482 16.25 9.375 15.9702 9.375 15.625V6.875C9.375 6.52982 9.65482 6.25 10 6.25ZM13.75 6.875C13.75 6.52982 13.4702 6.25 13.125 6.25C12.7798 6.25 12.5 6.52982 12.5 6.875V15.625C12.5 15.9702 12.7798 16.25 13.125 16.25C13.4702 16.25 13.75 15.9702 13.75 15.625V6.875Z"
						fill="currentColor"
					/>
				</svg>
			</div>
		</div>
		<div class="__data__content text-neutral-800">
			<!--			<div class="grid grid-cols-2 px-6 py-4 bg-neutral-50">
				<div class="text-neutral-500">Họ và tên</div>
				<div>{{ data.name }}</div>
			</div>
			<div class="grid grid-cols-2 px-6 py-4">
				<div class="text-neutral-500">Số điện thoại</div>
				<div>{{ data.phone }}</div>
			</div>-->
			<div class="grid grid-cols-2 px-6 py-4 bg-neutral-50">
				<div class="text-neutral-500">Email</div>
				<div>{{ data.email }}</div>
			</div>
			<div v-if="!isConnected" class="grid grid-cols-2 px-6 py-4">
				<div class="text-neutral-500">Mã liên kết</div>
				<div>
					<div class="text-2xl font-semibold">
						<span
							:class="
								isExpired
									? 'text-neutral-400 line-through'
									: 'text-sienna-400'
							"
						>
							{{ data.code }}</span
						>
						<span
							v-if="isExpired"
							class="ml-2 text-neutral-500 font-normal"
							style="font-size: 16px"
							>Đã hết hạn</span
						>
					</div>
					<div
						class="text-neutral-500"
						v-if="data.valid_until && !isExpired"
					>
						Hết hạn ngày
						{{ $filters.formatDateTime(data.valid_until) }}
					</div>
					<button
						v-if="isExpired && !isConnected"
						type="button"
						class="button-p btn--text whitespace-nowrap w-fit mt-2"
						@click="reNewCode"
					>
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g clip-path="url(#clip0_5834_9251)">
								<path
									d="M4.66667 15.3333L2 12.6667L4.66667 10"
									fill="#E5A42B"
								/>
								<path
									d="M4.66667 15.3333L2 12.6667L4.66667 10"
									stroke="#E5A42B"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M14 8.66675V10.0001C14 10.7073 13.719 11.3856 13.219 11.8857C12.7189 12.3858 12.0406 12.6667 11.3333 12.6667H2"
									stroke="#E5A42B"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M11.3334 0.666748L14 3.33341L11.3334 6.00008"
									stroke="#E5A42B"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M2 7.33325V5.99992C2 5.29267 2.28095 4.6144 2.78105 4.1143C3.28115 3.6142 3.95942 3.33325 4.66667 3.33325H14"
									stroke="#E5A42B"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</g>
							<defs>
								<clipPath id="clip0_5834_9251">
									<rect width="16" height="16" fill="white" />
								</clipPath>
							</defs>
						</svg>

						<span class="ml-2">Tạo mã liên kết mới</span>
					</button>
				</div>
			</div>
		</div>
		<div
			class="
				__data__footer
				p-6
				border-t border-yellow-50
				flex
				items-center
				justify-end
			"
		>
			<div
				v-if="isConnected"
				class="text-green-500 font-semibold text-sm flex items-center"
			>
				<svg
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M8.96963 4.96967C9.26253 4.67678 9.7374 4.67678 10.0303 4.96967C10.3196 5.25897 10.3231 5.72582 10.0409 6.01947L6.04873 11.0097C6.04297 11.0169 6.03682 11.0238 6.03029 11.0303C5.7374 11.3232 5.26253 11.3232 4.96963 11.0303L2.32319 8.38388C2.03029 8.09099 2.03029 7.61612 2.32319 7.32322C2.61608 7.03033 3.09095 7.03033 3.38385 7.32322L5.47737 9.41674L8.94974 4.9921C8.95592 4.98424 8.96256 4.97674 8.96963 4.96967Z"
						fill="currentColor"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M8.04921 10.1099L8.96963 11.0303C9.26253 11.3232 9.7374 11.3232 10.0303 11.0303C10.0368 11.0238 10.043 11.0169 10.0487 11.0097L14.0409 6.01947C14.3231 5.72582 14.3196 5.25897 14.0303 4.96967C13.7374 4.67678 13.2625 4.67678 12.9696 4.96967C12.9626 4.97674 12.9559 4.98424 12.9497 4.9921L9.47737 9.41674L8.99202 8.9314L8.04921 10.1099Z"
						fill="currentColor"
					/>
				</svg>

				<span class="ml-2"> Đã liên kết App PH</span>
			</div>
			<button
				v-else
				type="button"
				class="button-p btn--text whitespace-nowrap w-56"
				:style="{ color: isExpired ? '#CBD5E1' : '' }"
				@click="generateReport"
			>
				<svg
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M8 0C6.57359 0 5.31176 0.560456 4.40576 1.34182C3.63985 2.00237 3.08479 2.8617 2.94223 3.725C1.26586 4.09512 0 5.55511 0 7.31818C0 9.36599 1.70754 11 3.78125 11H7.5V5.5C7.5 5.22386 7.72386 5 8 5C8.27614 5 8.5 5.22386 8.5 5.5V11H12.6875C14.5023 11 16 9.56949 16 7.77273C16 6.13656 14.7581 4.80411 13.1657 4.57898C12.9233 1.99944 10.6902 0 8 0Z"
						fill="currentColor"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M7.64645 15.8536C7.84171 16.0488 8.15829 16.0488 8.35355 15.8536L11.3536 12.8536C11.5488 12.6583 11.5488 12.3417 11.3536 12.1464C11.1583 11.9512 10.8417 11.9512 10.6464 12.1464L8.5 14.2929V11H7.5V14.2929L5.35355 12.1464C5.15829 11.9512 4.84171 11.9512 4.64645 12.1464C4.45118 12.3417 4.45118 12.6583 4.64645 12.8536L7.64645 15.8536Z"
						fill="currentColor"
					/>
				</svg>
				<span class="ml-2">Tải mời liên kết App PH</span>
			</button>
		</div>
	</div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { _GET_DATE_TIME } from '@/helper/formatDate';
import DaModalFrame from '../../../../../components/common/daModalFrame/DaModalFrame';
import DeleteParentModal from './DeleteParentModal';

@Options({
	components: { DeleteParentModal, DaModalFrame },
})
export default class ParentInfo extends Vue {
	@Prop() index;
	@Prop() data;

	deletePopup = false;
	deleteData = {
		parent: '',
		student: '',
		email: '',
	};

	get isConnected() {
		return this.data?.state === 'converted';
	}

	get isExpired() {
		const date = dayjs(this.data.valid_until);
		const now = dayjs();
		return date.diff(now) <= 0;
	}

	reNewCode() {
		this.$emit('reNewCode', {
			student_id: this.data.student_id,
			id: this.data.id,
		});
	}

	openClosePopup() {
		this.deletePopup = true;
		this.deleteData = {
			parent:
				this.data?.relationship?.role || `Phụ huynh ${this.index + 1}`,
			student: this.data.studentName,
			email: this.data.email,
		};
	}

	async closeParentInvitation() {
		this.$emit('closeParentInvitation', {
			student_id: this.data.student_id,
			id: this.data.id,
		});
	}

	get org() {
		return this.$store.state.profile.current_org;
	}

	generateReport() {
		let fileName = this.data.email + '_' + this.data.studentName;
		fileName = fileName.toLowerCase();
		fileName = fileName.replace(/\s/g, '_');
		this.$emit('generateReport', {
			fileName: fileName + '.pdf',
			orgName: this.org.name,
			studentName: this.data.studentName,
			email: this.data.email,
			code: this.data?.code,
			date: _GET_DATE_TIME(this.data?.valid_until),
		});
	}
}
</script>
