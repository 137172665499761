
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import Rating from '@/components/elements/rating/Rating.vue';
import { Options, Vue, setup } from 'vue-class-component';
import SideOver from '@/components/elements/sideOver/SideOver.vue';
import { useStore } from 'vuex';
import DaInfinityLoading from '@/components/common/daInfinityLoading/DaInfinityLoading.vue';
import SlideOverWeekFilter from '@/views/report/features/week/SlideOverWeekFilter.vue';
import Loading from '@/components/common/Loading.vue';
import { useListWeeklyReportOldData } from '@/features/lessonReport/listWeeklyReportOldData';
import { _GET_DATE_MM_YYYY } from '@/helper/formatDate';

@Options({
	name: 'ReportWeek',
	components: {
		Loading,
		SlideOverWeekFilter,
		SideOver,
		Rating,
		DaIcon,
		DaInfinityLoading,
	},
})
export default class ReportWeek extends Vue {
	weeklyReportList: any = [];
	date = '';
	stopLoad = false;
	isInfinityLoading = false;
	isLoading = true;
	params = {};

	//----------------------- 🤍 Setup 🤍 -----------------------//
	setup = setup(() => {
		const store = useStore();
		const { error, loading, listWeeklyReportOldData } =
			useListWeeklyReportOldData();
		return {
			store,
			error,
			loading,
			listWeeklyReportOldData,
		};
	});

	//----------------------- 🤍 Getters 🤍 -----------------------//
	get isAdmin() {
		return this.setup.store.getters.isAdmin;
	}

	get weeklyReports() {
		return this.weeklyReportList;
	}

	get id() {
		return this.$route.params.id;
	}

	get oldPayload() {
		return {
			'x-cross': this.$route.query.org_id,
		};
	}

	//----------------------- 🤍 Created 🤍 -----------------------//
	created() {
		this.getWeeklyReports();
	}

	async getWeeklyReports() {
		const res = await this.setup.listWeeklyReportOldData(
			String(this.id || ''),
			this.oldPayload,

			this.date
				? {
						date: this.date,
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  }
				: null
		);

		this.isInfinityLoading = true;
		if (res?.data && res?.data.length > 0) {
			this.weeklyReportList.push({
				title: res.latest_date
					? `Tháng ${_GET_DATE_MM_YYYY(res.latest_date)}`
					: '',
				data: [...res?.data],
			});
			this.date = res.previous_year;
		} else {
			this.stopLoad = true;
		}
		this.isLoading = false;
	}

	beforeDestroy() {
		this.isInfinityLoading = false;
	}
}
