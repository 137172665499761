export function daysOfWeekFormat(days: any[]) {
	if (days && days.length >= 6) {
		return 'Các ngày trong tuần';
	} else if (days && days.length > 0) {
		return `${days
			.sort()
			.map((day) => (day !== 7 ? `Thứ ${day + 1}` : 'Chủ nhật'))
			.join(', ')} hàng tuần`;
	} else return 'Không có ngày học';
}

export function daysOfWeekFormatWithSunday(days: any[]) {
	if (days && days.length >= 7) {
		return 'Các ngày trong tuần';
	} else if (days && days.length > 0) {
		return `${days
			.sort()
			.map((day) => (day !== 7 ? `Thứ ${day + 1}` : 'Chủ nhật'))
			.join(', ')} hàng tuần`;
	} else return 'Không có ngày học';
}
