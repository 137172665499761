
import { Options, Vue } from 'vue-class-component';
import { PropSync, Watch } from 'vue-property-decorator';
import Multiselect from '@suadelabs/vue3-multiselect';
import { toEnglish } from '@/helper/toEnglish';

@Options({
	name: 'CreateDaycareClass',
	components: { Multiselect },
})
export default class CreateDaycareClass extends Vue {
	@PropSync('data')
	classes!: any[];

	@PropSync('selected')
	_selected!: string;

	isEdit = false;

	@Watch('_selected', { immediate: true, deep: true })
	onSelectedChange(value: any) {
		if (value) {
			const item = this.classes.find((item) => item.id === value);
			if (item) {
				this.classSelected = item;
				this.isEdit = true;
			}
		}
	}

	classSelected = null;

	//----------------------- 🤍 Methods 🤍 -----------------------//
	submit() {
		this.$emit(
			this.isEdit ? `edit` : `submit`,
			// @ts-ignore
			this.classSelected?.id || null
		);
	}

	toEnglish(string = '') {
		return toEnglish(string);
	}
}
