
import { Vue, Options } from 'vue-class-component';
import { PropSync } from 'vue-property-decorator';
@Options({
	name: 'UploadAvatar',
})
export default class UploadAvatar extends Vue {
	@PropSync('data') _data!: any;
	@PropSync('error') _error!: string;

	get url() {
		return URL.createObjectURL(this._data);
	}

	upload() {
		// @ts-ignore
		const { canvas } = this.$refs.cropper.getResult();
		this.$emit('upload', canvas);
	}
}
