<template>
	<div
		class="
			fixed
			inset-y-0
			right-0
			side-over
			z-50
			w-screen
			grid
			md:grid-cols-2
		"
	>
		<div class="overflow-auto flex flex-col bg-neutral-50 shadow-xl">
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center" @click="$emit('close')">
						<svg
							width="28"
							height="28"
							viewBox="0 0 28 28"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M17.5 7L10.5 14L17.5 21"
								stroke="#94A3B8"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>

						<div
							class="
								text-2xl
								font-bold
								text-neutral-700
								ml-2
								mt-1
							"
						>
							Tạo buổi học phát sinh
						</div>
					</div>
					<div></div>
				</div>
			</div>
			<div
				class="
					sideover-content
					pt-6
					relative
					flex-1
					px-4
					sm:px-6
					overflow-y-auto
					pb-6
				"
			>
				<div>
					<div>
						<div class="font-semibold text-neutral-600 mt-10">
							Chọn ngày*
						</div>
						<div class="w-full mt-2">
							<div class="input-group flex items-center">
								<VueDatepicker
									v-model="date"
									:enableTimePicker="false"
									class="input-date w-full"
									placeholder="Chọn ngày áp dụng"
									format="dd/MM/yyyy"
									autoApply
									:clearable="false"
								>
									<template #input-icon>
										<DaIcon
											name="das--lock"
											class="h-4 w-4 text-neutral-400"
										/>
									</template>
								</VueDatepicker>
							</div>
						</div>
					</div>
					<div class="mt-10">
						<div
							class="
								form-group
								flex
								items-center
								justify-center
								gap-8
							"
						>
							<div class="input-group w-full">
								<div
									class="
										input-group__label
										w-full
										md:w-56
										font-normal
									"
								>
									Giờ bắt đầu
								</div>
								<VueDatepicker
									class="input-date w-full"
									timePicker
									placeholder="Giờ bắt đầu"
									autoApply
									v-model="startTime"
									uid="start_opening_hour"
									minutesIncrement="5"
									:clearable="false"
								>
									<template #input-icon>
										<DaIcon
											name="das--lock"
											class="h-4 w-4 text-neutral-400"
										/>
									</template>
								</VueDatepicker>
							</div>
							<div class="input-group w-full">
								<div
									class="
										input-group__label
										w-full
										md:w-56
										font-normal
									"
								>
									Giờ kết thúc
								</div>
								<VueDatepicker
									v-model="endTime"
									class="input-date w-full"
									timePicker
									placeholder="Giờ kết thúc"
									autoApply
									uid="end_opening_hour"
									minutesIncrement="5"
									:clearable="false"
								>
									<template #input-icon>
										<DaIcon
											name="das--lock"
											class="h-4 w-4 text-neutral-400"
										/>
									</template>
								</VueDatepicker>
							</div>
						</div>
					</div>
					<div>
						<div class="font-semibold text-neutral-600 mt-10">
							Chọn lớp nhóm*
						</div>
						<div class="w-full mt-2">
							<Multiselect
								v-model="groupClasseselected"
								deselect-label="Đã chọn"
								track-by="id"
								:options="
									groupClasses?.map((s) => {
										return {
											nameSearch: toEnglish(s?.name),
											...s,
										};
									})
								"
								:searchable="true"
								label="nameSearch"
								:allow-empty="false"
								selectLabel=""
								placeholder="Tìm theo tên lớp nhóm"
								:maxHeight="140"
							>
								<template #noResult>
									Không tìm thấy kết quả
								</template>
								<template v-slot:option="props">
									<div class="flex flex-nowrap items-center">
										<div>
											{{ props.option.name }}
										</div>
									</div>
								</template>
								<template v-slot:singleLabel="{ option }">
									<div
										class="
											flex flex-nowrap
											items-center
											font-medium
										"
									>
										<div>{{ option.name }}</div>
									</div>
								</template>
							</Multiselect>
						</div>
					</div>
					<div v-for="(staff, index) in staffSelected" :key="index">
						<div class="font-semibold text-neutral-600 mt-10">
							Chọn trị liệu viên*
						</div>
						<div class="w-full mt-2">
							<div class="w-full mt-2">
								<Multiselect
									v-model="staffSelected[index]"
									deselect-label="Đã chọn"
									track-by="id"
									:options="
										staffs?.map((s) => {
											return {
												nameSearch: toEnglish(s?.name),
												...s,
											};
										})
									"
									:searchable="true"
									label="nameSearch"
									:allow-empty="false"
									selectLabel=""
									placeholder="Tìm theo tên TLV"
									:maxHeight="140"
								>
									<template #noResult>
										Không tìm thấy kết quả
									</template>
									<template v-slot:option="props">
										<div
											class="
												flex flex-nowrap
												items-center
											"
										>
											<div>
												{{ props.option.name }}
											</div>
										</div>
									</template>
									<template v-slot:singleLabel="{ option }">
										<div
											class="
												flex flex-nowrap
												items-center
												font-medium
											"
										>
											<div>{{ option.name }}</div>
										</div>
									</template>
								</Multiselect>
							</div>
						</div>
						<div
							v-if="staffSelected.length > 1"
							@click="removeInputFieldStaff(index)"
							class="
								text-yellow-700
								font-semibold
								mt-2
								text-sm
								cursor-pointer
								text-right
								hover:text-yellow-800
							"
						>
							Xoá bỏ
						</div>
					</div>
					<div class="flex items-center justify-between mt-4">
						<div
							@click="isAllStaff = !isAllStaff"
							class="
								text-sm
								flex
								items-center
								gap-2
								text-neutral-500
								cursor-pointer
							"
						>
							<Checkbox :value="isAllStaff" />
							<span
								>Hiển thị tất cả TLV (ngay cả khi bị trùng giờ
								dạy)</span
							>
						</div>
						<div
							v-if="staffSelected.length <= 2"
							@click="addInputFieldStaff"
							class="
								text-yellow-700
								font-semibold
								text-sm
								cursor-pointer
								text-right
								hover:text-yellow-800
							"
						>
							Thêm trị liệu viên
						</div>
					</div>
					<div>
						<div class="font-semibold text-neutral-600 mt-10">
							Ghi chú*
						</div>
						<div class="w-full mt-2">
							<div class="input-group flex items-center">
								<textarea
									v-model="note"
									placeholder="Ghi chú về sự thay đổi này"
								>
								</textarea>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="px-6 py-4 border-t border-yellow-50">
				<div class="flex justify-end">
					<div>
						<button
							:disabled="!isSubmit || inProgress"
							type="button"
							class="button-p text-center w-32"
							@click="submit"
						>
							Xác nhận
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="bg-white px-6 py-4">
			<div class="text-xl font-semibold">Lọc buổi học</div>
			<div v-if="overlaps?.length" class="text-neutral-400">
				Các buổi học hiện có trên tiêu chí đã chọn
			</div>
			<div
				v-if="overlaps?.length"
				class="shadow-sm sm:rounded-2xl overflow-hidden mt-8"
			>
				<div class="overflow-x-auto scroll-bar">
					<div
						class="
							overflow-hidden
							sm:rounded-t-2xl
							w-fit
							min-w-full
						"
					>
						<table class="min-w-full divide-y text-neutral-200">
							<thead class="bg-neutral-100 h-16">
								<tr>
									<th
										scope="col"
										class="
											px-6
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
											w-16
										"
									>
										Giờ học
									</th>
									<th
										scope="col"
										class="
											px-6
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Giáo viên/ Lớp
									</th>
									<th
										scope="col"
										class="
											px-6
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											whitespace-nowrap
											tracking-widertext-sm
										"
									>
										tên lớp nhóm
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(overlap, index) in overlaps"
									:key="index"
									:class="
										index % 2 === 0
											? 'bg-white'
											: 'bg-neutral-50'
									"
									class="text-neutral-700"
								>
									<td
										class="
											px-6
											py-4
											whitespace-nowrap
											text-neutral-700 text-sm
										"
									>
										{{ overlap.display_start_slot }} -
										{{ overlap.display_end_slot }}
									</td>
									<td
										class="
											px-6
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div
											v-if="overlap.staffs?.length"
											class="flex flex-col gap-1"
										>
											<div
												@click.stop="
													$router.push(
														`/record/employee/${staff.id}`
													)
												"
												class="flex items-center"
												v-for="staff in overlap.staffs"
												:key="staff.id"
											>
												<div v-if="staff">
													<img
														class="
															shadow-sm
															inline-block
															h-7
															w-7
															rounded-full
														"
														:src="
															staff?.profile_photo
																?.w200
														"
														alt=""
														v-if="
															staff?.profile_photo
																?.w200
														"
													/>
													<span
														v-else
														class="
															inline-flex
															items-center
															justify-center
															h-7
															w-7
															rounded-full
														"
														:style="{
															background:
																staff
																	?.profile_photo
																	?.default
																	?.color,
														}"
													>
														<span
															class="
																font-medium
																leading-none
																text-white
																text-xs
															"
															>{{
																staff
																	?.profile_photo
																	?.default
																	?.initial
															}}</span
														>
													</span>
												</div>
												<div
													class="
														ml-4
														text-blue-600
														hover:text-blue-700
														hover:underline
														cursor-pointer
														font-semibold
														whitespace-pre-wrap
														text-sm
													"
												>
													{{ staff?.name || '' }}
												</div>
											</div>
										</div>
									</td>
									<td
										class="
											px-6
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div
											@click.stop="
												$router.push(
													`/group-classes/${overlap?.group_class?.id}`
												)
											"
											class="flex items-center gap-4"
											v-if="overlap?.group_class?.id"
										>
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<g
													clip-path="url(#clip0_21795_4077)"
												>
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M7.34111 8.43768C7.34111 9.90561 6.15158 11.1204 4.65835 11.1204C3.16511 11.1204 1.97559 9.8803 1.97559 8.41238C1.97559 6.94445 3.16511 5.72961 4.65835 5.72961C6.12627 5.72961 7.34111 6.96976 7.34111 8.43768ZM6.60714 11.3989C7.46765 11.7279 8.1763 12.3606 8.60656 13.1958C7.8979 13.7526 7.3158 14.4866 7.01209 15.3724C6.86023 15.8026 6.73369 16.2835 6.63245 16.7391H0.634203C0.279876 16.7391 -0.0238327 16.4354 0.00147638 16.0557C0.0520945 15.2458 0.229258 14.436 0.507658 13.6767C0.887294 12.6137 1.69718 11.8038 2.70955 11.3989C3.26635 11.7785 3.94969 11.981 4.65834 11.981C5.367 11.981 6.05034 11.7785 6.60714 11.3989ZM23.5136 13.6767C23.134 12.6137 22.3241 11.8038 21.3117 11.3989C20.7296 11.7785 20.0716 11.981 19.3376 11.981C18.6037 11.981 17.9456 11.7785 17.3888 11.3989C16.5283 11.7279 15.8197 12.3606 15.3894 13.1958C16.0981 13.7526 16.6802 14.4866 16.9839 15.3724C17.1357 15.828 17.2623 16.2835 17.3635 16.7391H23.3618C23.7414 16.7391 24.0451 16.4354 23.9945 16.0557C23.9692 15.2458 23.792 14.436 23.5136 13.6767ZM22.0204 8.43768C22.0204 9.90561 20.8309 11.1204 19.3377 11.1204C17.8697 11.1204 16.6549 9.8803 16.6549 8.41238C16.6549 6.94445 17.8444 5.72961 19.3377 5.72961C20.8309 5.72961 22.0204 6.96976 22.0204 8.43768ZM13.9468 13.373C13.39 13.7526 12.7066 13.9551 11.998 13.9551C11.2893 13.9551 10.606 13.7526 10.0492 13.373C9.03682 13.7779 8.20162 14.5878 7.82199 15.6508C7.54359 16.4101 7.39173 17.22 7.31581 18.0299C7.2905 18.4095 7.59421 18.7132 7.97384 18.7132H16.0221C16.4018 18.7132 16.7055 18.4095 16.6802 18.0299C16.6295 17.22 16.4524 16.4101 16.174 15.6508C15.7943 14.5878 14.9591 13.7779 13.9468 13.373ZM14.6808 10.4118C14.6808 11.8798 13.4913 13.0946 11.9981 13.0946C10.5048 13.0946 9.31531 11.9051 9.31531 10.4118C9.31531 8.9439 10.5301 7.72906 11.9981 7.72906C13.466 7.72906 14.6808 8.91859 14.6808 10.4118Z"
														fill="#94A3B8"
													/>
												</g>
												<defs>
													<clipPath
														id="clip0_21795_4077"
													>
														<rect
															width="24"
															height="24"
															fill="white"
														/>
													</clipPath>
												</defs>
											</svg>
											<div class="text-sm">
												{{ overlap?.group_class?.name }}
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div
				v-else-if="!hasOverlap"
				class="w-full h-full flex items-center flex-col pt-40"
			>
				<img
					src="@/assets/images/illustrations/not_found.png"
					alt=""
					height="160"
					width="160"
				/>
				<div
					class="text-neutral-500 text-center"
					style="max-width: 700px"
				>
					Bạn hãy điền các tiêu chí cho buổi học phát sinh, bộ lọc sẽ
					hiển thị các buổi học bị trùng lặp nếu có
				</div>
			</div>
			<div v-else class="w-full h-full flex items-center flex-col pt-40">
				<img
					src="@/assets/images/illustrations/calender.png"
					alt=""
					height="250"
					width="250"
				/>
				<div
					class="text-neutral-500 text-center"
					style="max-width: 700px"
				>
					Không có buổi học nào trùng với buổi học bạn đang chọn.<br />
					Bạn có thể tạo buổi học phát sinh dựa trên các tiêu chí đã
					chọn
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import DaIcon from '@/components/elements/daIcon/DaIcon';
import { $api } from '@/services';
import Multiselect from '@suadelabs/vue3-multiselect';
import dayjs from 'dayjs';
import { toEnglish } from '@/helper/toEnglish';
import Checkbox from '@/components/elements/checkbox/Checkbox';

export default defineComponent({
	name: 'CreateTimeSlotGroupClass',

	components: { Checkbox, DaIcon, Multiselect },

	data() {
		return {
			date: new Date(),
			groupClasses: [],
			groupClasseselected: null,
			staffs: [],
			staffSelected: [null],
			startTime: null,
			endTime: null,
			note: '',
			inProgress: false,
			isAllStaff: false,
			overlaps: [],
		};
	},

	created() {
		this.fetchGroupClasses();
		this.startTime = this.initStartTime();
	},

	computed: {
		isSubmit() {
			return (
				this.note &&
				this.groupClasseselected &&
				this.staffSelected.length &&
				this.staffSelected[0] &&
				this.startTime &&
				this.endTime &&
				this.staffSelected
			);
		},

		hasOverlap() {
			return (
				this.groupClasseselected &&
				this.staffSelected.length &&
				this.staffSelected[0] &&
				this.startTime &&
				this.endTime &&
				this.staffSelected
			);
		},
	},

	watch: {
		date() {
			this.fetchStaffs();
		},

		staffSelected: {
			immediate: true,
			deep: true,
			handler(value) {
				if (value) {
					this.fetchStaffsOverload();
				}
			},
		},

		isAllStaff(value) {
			this.fetchStaffs();

			if (value) {
				this.fetchStaffsOverload();
			}

			this.staffSelected = this.staffSelected.map(() => null);
		},

		startTime(value) {
			if (!value) {
				this.startTime = this.initStartTime();
			} else {
				this.endTime = {
					hours: value.hours + 1,
					minutes: value.minutes,
				};
			}
		},

		endTime(value) {
			if (!value) {
				this.endTime = {
					hours: this.startTime.hours + 1,
					minutes: this.startTime.minutes,
				};
			} else {
				this.fetchStaffs();

				if (this.isAllStaff) {
					this.fetchStaffsOverload();
				}
			}
		},
	},

	methods: {
		toEnglish,
		initStartTime() {
			const time = new Date();
			let minutes = time.getMinutes() / 5;
			return {
				hours: time.getHours(),
				minutes: Math.ceil(minutes) * 5,
			};
		},
		addInputFieldStaff() {
			if (this.staffSelected.length <= 2) {
				this.staffSelected.push(null);
			}
		},
		removeInputFieldStaff(index) {
			if (index < this.staffSelected.length) {
				this.staffSelected.splice(index, 1);
			}
		},
		async submit() {
			this.inProgress = true;
			try {
				const { data } = await $api.groupClasses.createSession({
					group_class_id: this.groupClasseselected
						? this.groupClasseselected.id
						: null,
					unscheduled_group_session: {
						date: this.date
							? dayjs(this.date).format('YYYY-MM-DD')
							: null,
						group_class_id: this.groupClasseselected
							? this.groupClasseselected.id
							: null,
						end_slot: this.getTimePayload(
							this.endTime.hours || null,
							this.endTime.minutes || null
						),
						staff_ids: this.staffSelected
							? this.staffSelected.map((item) => item.id)
							: null,
						start_slot: this.getTimePayload(
							this.startTime.hours || null,
							this.startTime.minutes || null
						),
						unscheduled_note: this.note,
					},
				});
				await this.$store.dispatch('setAlertMessage', {
					message: 'Tạo thành công buổi học phát sinh',
				});
				this.resetForm();
				this.$emit('success', data);
			} catch (e) {
				await this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.inProgress = false;
			}
		},

		resetForm() {
			this.date = new Date();
			this.groupClasseselected = null;
			this.staffSelected = [null];
			this.startTime = null;
			this.endTime = null;
		},

		async fetchGroupClasses() {
			try {
				const {
					data: { data },
				} = await $api.groupClasses.list();
				this.groups = data.filter((i) => i.enabled);
				this.groupClasses = data;
			} catch (err) {
				console.log(err);
			}
		},

		async fetchStaffs() {
			try {
				const {
					data: { data },
				} = await $api.staff.getStaffWhenCreateUnSchedule({
					date: this.date
						? dayjs(this.date).format('YYYY-MM-DD')
						: null,
					end_slot: this.getTimePayload(
						this.endTime?.hours || null,
						this.endTime?.minutes || null
					),
					start_slot: this.getTimePayload(
						this.startTime?.hours || null,
						this.startTime?.minutes || null
					),
					only_free_staff: !this.isAllStaff,
				});
				this.staffs = data;
			} catch (err) {
				console.log(err);
			}
		},

		async fetchStaffsOverload() {
			try {
				const {
					data: { data },
				} = await $api.groupClasses.listOverlap({
					date: this.date
						? dayjs(this.date).format('YYYY-MM-DD')
						: null,
					end_slot: this.getTimePayload(
						this.endTime?.hours || null,
						this.endTime?.minutes || null
					),
					start_slot: this.getTimePayload(
						this.startTime?.hours || null,
						this.startTime?.minutes || null
					),
					staff_ids: this.staffSelected
						? this.staffSelected.map((item) => item?.id)
						: null,
				});
				this.overlaps = data;
			} catch (err) {
				console.log(err);
			}
		},

		getTimePayload(hour = 0, minutes = 0) {
			return hour * 60 + minutes;
		},
	},
});
</script>
