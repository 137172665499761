
import { computed, defineComponent, onMounted, ref } from 'vue';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import Rating from '@/components/elements/rating/Rating.vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import CopyModal from '@/views/report/features/copyModal/CopyModal.vue';
import { dayCare } from '@/features/lessonReport/dayCare';
import Loading from '@/components/common/Loading.vue';
import { _GET_DATE_2 } from '@/helper/formatDate';
import { _MONTH_DIFF } from '@/helper/monthDiff';

export default defineComponent({
	name: 'ReportStudent',
	components: { Loading, CopyModal, DaModalFrame, Rating, DaIcon },
	setup() {
		const store = useStore();
		const route = useRoute();
		const router = useRouter();
		let count = ref({
			goal: 0,
			skill: 0,
		});
		let org = ref({});
		let student = ref({});
		let assessment_result = ref([]);
		let series = ref([] as number[]);
		let dataOfReport = ref([] as { key: string; data: any }[]);
		let chartData = ref([] as any[]);

		const id = computed(() => route.params.id);
		const student_id = computed(() => route.query.student_id);

		const path = ref('');
		const modalIsOpen = ref(false);
		const isLoading = ref(true);

		function closeShare() {
			modalIsOpen.value = false;
			path.value = '';
		}

		const oldPayload = ref({
			'x-cross': route.query.org_id,
		});

		const is_old_data = ref(route.query.is_old_data === 'true');

		async function fetchLesson() {
			const res = await dayCare(
				String(id.value),
				String(student_id.value),
				is_old_data.value ? oldPayload.value : null
			);

			if (res?.org) {
				org.value = res?.org;
			}

			if (res?.student) {
				student.value = res?.student;
			}

			res?.data.forEach((item: any) => {
				dataOfReport.value.push({
					key: item?.data?.type || item?.type,
					data: item?.data || item,
				});

				tab.value = dataOfReport.value.findIndex(
					(d) =>
						d?.data?.data?.type === 'day_care_report' ||
						d?.data?.type === 'day_care_report'
				);

				if (item?.data?.type === 'lesson_report') {
					count.value = item?.count;

					if (item?.stats?.assessment_result) {
						assessment_result.value =
							item?.stats?.assessment_result;
						let a = [];
						let count = 0;
						assessment_result.value.forEach((item: any) => {
							if (
								item.name === 'Từ chối' ||
								item.name === 'Không có cơ hội' ||
								item.name === 'Không hợp tác'
							) {
								count += item?.value;
							} else {
								a.push(item);
							}
						});
						a.push({
							name: 'X',
							value: count,
						});
						series.value = a.map((item) => {
							return item.value;
						});
					}
					chartData.value.push({
						assessment_result: assessment_result,
						series: series,
					});
				} else {
					chartData.value.push(null);
				}
			});

			isLoading.value = false;
		}

		const tab = ref(0);

		function timeLearning(session: any) {
			return session && session?.display_start_slot
				? `${session?.display_start_slot} - ${session?.display_end_slot}`
				: '';
		}

		function haveMedia(media: any[], goal_id: number) {
			const result = media.find((item) => item.goal_id == goal_id);
			return !!result;
		}

		onMounted(() => window.scrollTo(0, 0));
		fetchLesson();

		const dateData = computed(() => {
			const join_date = route.query.join_date;
			const left_date = route.query.left_date;
			if (!join_date) {
				return '';
			}
			return `${_GET_DATE_2(join_date)} - ${
				left_date ? _GET_DATE_2(left_date) : 'Hiện tại'
			} | Đã học ${_MONTH_DIFF(join_date, left_date)} tháng`;
		});

		function exit() {
			const new_id = route.query.new_id;
			const r = router.resolve({
				path: `/record/student/${new_id}/staff-list`,
			});
			window.location.assign(r.href);
		}

		return {
			exit,
			dateData,
			isLoading,
			haveMedia,
			id,
			tab,
			modalIsOpen,
			closeShare,
			timeLearning,
			path,
			count,
			series,
			store,
			org,
			student,
			assessment_result,
			dataOfReport,
			chartOptions: {
				dataLabels: {
					enabled: false,
				},
				colors: ['#7AD2C6', '#93BF56', '#F8BB54', '#F47C7C', '#E5E7EB'],
				labels: [
					'Đạt 80 - 100%',
					'Đạt 55 - 75%',
					'Đạt 30 - 50%',
					'Đạt 5 - 25%',
					'X',
				],
				tooltip: {
					fillSeriesColor: true,
					y: {
						formatter: function (value: any) {
							return value + ' mục tiêu';
						},
					},
				},
				legend: {
					show: false,
				},
				stroke: {
					lineCap: 'round',
				},
			},
			is_old_data: computed(() => route.query.is_old_data),
		};
	},
});
