<template>
	<div
		class="
			bg-white
			rounded-2xl
			shadow-sm
			overflow-hidden
			p-8
			pt-12
			h-full
			flex flex-col
			items-center
			gap-6
		"
	>
		<img src="@/assets/images/illustrations/email_parent.svg" alt="" />
		<div
			class="text-2xl font-semibold text-center"
			style="line-height: 24px"
		>
			Mời phụ huynh <br />
			sử dụng ứng dụng di động
		</div>
		<button
			type="button"
			class="button-p btn--ghost w-fit"
			@click="$emit('inviting')"
		>
			<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M13.9783 2.63269L9.98558 13.7804C9.93795 13.9121 9.81291 13.9999 9.67282 14H9.56634C9.43566 14 9.3166 13.9249 9.26023 13.807L7.68974 10.4793C7.45849 9.98874 7.548 9.40689 7.916 9.0085L9.91238 6.82555C10.0349 6.697 10.0349 6.49488 9.91238 6.36633L9.65951 6.11343C9.53097 5.99089 9.32887 5.99089 9.20034 6.11343L7.01763 8.11003C6.61929 8.47807 6.0375 8.56759 5.54696 8.33631L2.21966 6.76565C2.09135 6.71734 2.00471 6.59655 2.00006 6.45951V6.35302C1.98827 6.20328 2.07825 6.06421 2.21966 6.0136L13.3661 2.0204C13.486 1.97639 13.6204 2.00484 13.7122 2.09361L13.8852 2.26665C13.9892 2.35751 14.0262 2.50317 13.9783 2.63269Z"
					fill="#E5A42B"
				/>
			</svg>
			<span class="ml-2">Gửi lời mời liên kết App PH</span>
		</button>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
	name: 'InviteParent',
});
</script>
