<template>
	<div class="fixed inset-y-0 right-0 flex side-over z-50 w-screen md:w-2/5">
		<div
			class="
				h-full
				w-full
				flex flex-col
				bg-white
				shadow-xl
				overflow-y-auto
			"
		>
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center" @click="$emit('close')">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M6 0.75C6 0.335786 5.66421 0 5.25 0C4.83579 0 4.5 0.335786 4.5 0.75V1.5H3C1.34315 1.5 0 2.84315 0 4.5V6H24V4.5C24 2.84315 22.6569 1.5 21 1.5H19.5V0.75C19.5 0.335786 19.1642 0 18.75 0C18.3358 0 18 0.335786 18 0.75V1.5H6V0.75ZM24 21V7.5H0V21C0 22.6569 1.34315 24 3 24H21C22.6569 24 24 22.6569 24 21ZM12.75 12.75C12.75 12.3358 12.4142 12 12 12C11.5858 12 11.25 12.3358 11.25 12.75V15H9C8.58579 15 8.25 15.3358 8.25 15.75C8.25 16.1642 8.58579 16.5 9 16.5H11.25V18.75C11.25 19.1642 11.5858 19.5 12 19.5C12.4142 19.5 12.75 19.1642 12.75 18.75V16.5H15C15.4142 16.5 15.75 16.1642 15.75 15.75C15.75 15.3358 15.4142 15 15 15H12.75V12.75Z"
								fill="#94A3B8"
							/>
						</svg>
						<div
							class="
								text-2xl
								font-bold
								text-neutral-700
								ml-2
								mt-1
							"
						>
							<div>Chọn học sinh để chỉnh sửa thời khoá biểu</div>
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							type="button"
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								class="h-6 w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div
				class="sideover-content mt-6 relative flex-1 px-4 sm:px-6 pb-20"
			>
				<div>
					<div>
						<div class="font-semibold text-neutral-600 mt-10">
							Chọn ngày*
						</div>
						<div class="w-full mt-2">
							<div class="input-group flex items-center">
								<VueDatepicker
									v-model="applyDate"
									:enableTimePicker="false"
									class="input-date w-full"
									placeholder="Chọn ngày áp dụng"
									format="dd/MM/yyyy"
									autoApply
								>
									<template #input-icon>
										<DaIcon
											name="das--lock"
											class="h-4 w-4 text-neutral-400"
										/>
									</template>
								</VueDatepicker>
							</div>
						</div>
					</div>
					<div>
						<div class="font-semibold text-neutral-600 mt-10">
							Chọn học sinh*
						</div>
						<div class="w-full mt-2">
							<Multiselect
								v-model="studentSelected"
								deselect-label="Đã chọn"
								track-by="id"
								:options="
									students?.map((s) => {
										return {
											nameSearch: toEnglish(s?.name),
											...s,
										};
									})
								"
								:searchable="true"
								label="nameSearch"
								:allow-empty="false"
								selectLabel=""
								placeholder="Tìm theo tên học sinh"
								:maxHeight="300"
							>
								<template #noResult>
									Không tìm thấy kết quả
								</template>
								<template v-slot:option="props">
									<div class="flex flex-nowrap items-center">
										<div>
											{{ props.option.name }}
										</div>
									</div>
								</template>
								<template v-slot:singleLabel="{ option }">
									<div
										class="
											flex flex-nowrap
											items-center
											font-medium
										"
									>
										<div>{{ option.name }}</div>
									</div>
								</template>
							</Multiselect>
						</div>
					</div>
				</div>
				<div
					class="flex-col flex items-center mt-10"
					v-if="!isLoading && studentSelected && !staffs.length"
				>
					<svg
						width="160"
						height="160"
						viewBox="0 0 160 160"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M137.409 27.2384C128.684 16.6346 113.452 12.1476 101.576 19.6389C90.3974 26.6893 85.3966 40.4936 75.2927 48.8379C64.6999 57.5857 53.0384 58.0445 40.2129 56.4833C29.158 55.1377 16.5759 55.3492 12.2073 68.2269C7.46997 82.1912 14.46 98.625 22.9203 109.391C31.2612 120.004 42.8943 127.113 55.7142 129.897C81.2653 135.445 111.373 126.167 129.578 106.453C148.397 86.0748 156.427 50.354 137.409 27.2384Z"
							fill="#FBECE2"
						/>
						<path
							d="M148.497 125.718C148.497 131.205 144.049 135.652 138.563 135.652C133.077 135.652 128.629 131.205 128.629 125.718C128.629 120.232 133.077 115.784 138.563 115.784C144.049 115.784 148.497 120.232 148.497 125.718Z"
							fill="#FCFBF9"
						/>
						<path
							d="M20.2576 67.6651C18.8817 66.8143 17.5887 65.822 16.4149 64.7142C16.2552 64.564 16.0029 64.5715 15.8517 64.731C15.7008 64.8905 15.7081 65.1437 15.8683 65.2945C17.081 66.4382 18.417 67.464 19.8384 68.3428C19.9089 68.3862 19.99 68.4061 20.0703 68.4018C20.1179 68.3993 20.165 68.3881 20.2101 68.3682C20.2836 68.3354 20.3448 68.282 20.3868 68.2137C20.4429 68.1231 20.4602 68.0157 20.436 67.9121C20.4114 67.8091 20.3481 67.721 20.2576 67.6651Z"
							fill="#F0BB4F"
						/>
						<path
							d="M32.204 71.2679C30.5911 71.216 28.9747 71.0001 27.4009 70.6266C27.297 70.6021 27.1902 70.6196 27.0997 70.675C27.0087 70.731 26.9451 70.8186 26.9212 70.9225C26.8698 71.136 27.0029 71.3514 27.2165 71.4021C28.8422 71.7879 30.5117 72.0108 32.1784 72.0645C32.19 72.0645 32.2023 72.0645 32.214 72.0639C32.2618 72.0615 32.3085 72.0499 32.3523 72.03C32.4917 71.9688 32.5845 71.8305 32.5898 71.6787C32.5962 71.4593 32.4234 71.2749 32.204 71.2679Z"
							fill="#F0BB4F"
						/>
						<path
							d="M26.0654 24.065C26.2484 23.9837 26.3427 23.7775 26.285 23.5853C26.2544 23.4839 26.1855 23.4 26.0917 23.3493C25.9978 23.2987 25.8899 23.2882 25.7884 23.3188C24.8033 23.6159 23.825 23.9794 22.8806 24.3979C22.3131 24.6496 21.7469 24.9266 21.1972 25.2211L21.1658 25.2377C20.9725 25.3416 20.8994 25.5837 21.0035 25.7777C21.0787 25.9175 21.2273 25.9944 21.3781 25.9857C21.4342 25.983 21.4903 25.9682 21.5429 25.9402L21.5739 25.9236C22.1054 25.6387 22.6542 25.3705 23.2039 25.1267C24.1181 24.7212 25.0649 24.37 26.0186 24.0816C26.0348 24.0772 26.0504 24.0711 26.0654 24.065Z"
							fill="#F0BB4F"
						/>
						<path
							d="M15.4046 30.6389C15.5554 30.479 15.5478 30.2265 15.3881 30.0757C15.2279 29.925 14.9756 29.9325 14.8248 30.0924C13.6812 31.3062 12.6562 32.6425 11.7782 34.0646C11.6626 34.2512 11.7208 34.497 11.9077 34.6128C11.9782 34.6561 12.0589 34.6761 12.1397 34.6711C12.1868 34.6686 12.2344 34.6578 12.2789 34.6378C12.3524 34.6053 12.4137 34.552 12.4561 34.4828C13.3062 33.1066 14.2985 31.8136 15.4046 30.6389Z"
							fill="#F0BB4F"
						/>
						<path
							d="M8.85863 46.4305C8.90963 44.8165 9.12454 43.201 9.49711 41.6268C9.52173 41.5236 9.50438 41.4165 9.44872 41.3256C9.3926 41.2353 9.30467 41.1719 9.201 41.1474C8.98738 41.0969 8.77239 41.2292 8.72147 41.4433C8.33659 43.0695 8.11479 44.7385 8.06173 46.4053C8.05829 46.5115 8.09698 46.6132 8.16961 46.6904C8.24225 46.7677 8.34125 46.8128 8.44745 46.8158C8.45938 46.8166 8.47169 46.8166 8.48354 46.8158C8.5311 46.8128 8.57743 46.8021 8.62162 46.7822C8.76092 46.7203 8.85359 46.5826 8.85863 46.4305Z"
							fill="#F0BB4F"
						/>
						<path
							d="M11.7076 58.5524C11.423 58.0208 11.155 57.4727 10.9108 56.9226C10.5038 56.0049 10.1512 55.0544 9.86281 54.0969C9.79948 53.8861 9.57688 53.7666 9.36575 53.8304C9.15592 53.8943 9.03696 54.1175 9.0998 54.3263C9.39761 55.3154 9.7617 56.2975 10.1823 57.2454C10.4341 57.8134 10.7111 58.38 11.0055 58.9295L11.0165 58.9501C11.0917 59.0895 11.2408 59.1678 11.3916 59.1596C11.4379 59.1568 11.4846 59.1458 11.5293 59.1259C11.5382 59.1218 11.5475 59.117 11.5565 59.1129C11.7497 59.0091 11.8228 58.7667 11.7187 58.573L11.7076 58.5524Z"
							fill="#F0BB4F"
						/>
						<path
							d="M38.6025 23.7203C38.6497 23.7176 38.6964 23.7062 38.741 23.6859C38.8561 23.6358 38.9385 23.5363 38.9674 23.414C38.992 23.3102 38.9746 23.2028 38.9185 23.113C38.8629 23.0224 38.775 22.959 38.6713 22.9344C37.0452 22.5498 35.3753 22.3289 33.7089 22.277C33.4896 22.2699 33.3053 22.4433 33.298 22.6624C33.2913 22.8824 33.4646 23.0664 33.6842 23.0734C35.2972 23.1236 36.9135 23.3383 38.4883 23.7097C38.526 23.7185 38.5643 23.722 38.6025 23.7203Z"
							fill="#F0BB4F"
						/>
						<path
							d="M44.3376 68.4181C43.8052 68.7031 43.2566 68.9714 42.7074 69.2152C41.786 69.624 40.8316 69.9778 39.8695 70.2664C39.6604 70.3309 39.5409 70.5538 39.6036 70.7624C39.6347 70.8646 39.7028 70.9488 39.7966 70.999C39.8624 71.0343 39.9347 71.0504 40.007 71.0462C40.0387 71.0444 40.0697 71.0385 40.1002 71.0301C41.0934 70.7313 42.0794 70.3656 43.0307 69.9437C43.5984 69.6921 44.1637 69.4154 44.7111 69.122L44.7255 69.1149C44.9191 69.0109 44.9878 68.7748 44.8826 68.5782C44.7792 68.3858 44.5354 68.3141 44.3376 68.4181Z"
							fill="#F0BB4F"
						/>
						<path
							d="M57.4625 47.5488C57.2435 47.5423 57.0586 47.7149 57.0514 47.9345C56.9992 49.5477 56.7824 51.1638 56.4083 52.7378C56.3575 52.9509 56.4902 53.1663 56.7034 53.2177C56.7418 53.2264 56.7809 53.2301 56.8186 53.2279C56.8658 53.225 56.9129 53.2141 56.9571 53.1938C57.0717 53.1431 57.155 53.0437 57.184 52.9219C57.5705 51.2965 57.7938 49.6267 57.8482 47.9607C57.8554 47.741 57.6821 47.5561 57.4625 47.5488Z"
							fill="#F0BB4F"
						/>
						<path
							d="M56.2479 40.5029C56.3136 40.5382 56.3857 40.5542 56.4578 40.5502C56.4891 40.5478 56.5203 40.543 56.5508 40.5333C56.5668 40.5293 56.5828 40.5229 56.5981 40.5165C56.7816 40.4348 56.8761 40.2288 56.8184 40.0373C56.5195 39.0413 56.1525 38.0508 55.7286 37.0957C55.477 36.5283 55.2006 35.9618 54.9057 35.4121L54.8832 35.3704C54.7791 35.1765 54.5371 35.1036 54.344 35.2078C54.15 35.3111 54.0771 35.5531 54.1813 35.747L54.2037 35.7887C54.4882 36.3208 54.7566 36.8697 55.0002 37.4186C55.4105 38.3433 55.7655 39.3017 56.0548 40.2665C56.086 40.3683 56.1541 40.4524 56.2479 40.5029Z"
							fill="#F0BB4F"
						/>
						<path
							d="M49.4784 29.612C49.5595 29.6884 49.6666 29.7261 49.7736 29.7201C49.8208 29.7167 49.8679 29.7055 49.913 29.6858H49.9134C49.9618 29.6643 50.0052 29.6335 50.0417 29.5949C50.1921 29.4345 50.1844 29.1823 50.0246 29.0313C48.8106 27.8889 47.4737 26.8648 46.0511 25.9865C45.9607 25.9307 45.8536 25.9136 45.7503 25.9385C45.6467 25.9625 45.5588 26.0259 45.5027 26.1169C45.4471 26.2069 45.4297 26.3141 45.4543 26.4179C45.479 26.5217 45.5423 26.6092 45.6328 26.6649C47.0095 27.5141 48.3034 28.5056 49.4784 29.612Z"
							fill="#F0BB4F"
						/>
						<path
							d="M53.9918 59.7502C53.8043 59.6344 53.5583 59.6923 53.4431 59.8791C52.5912 61.2543 51.598 62.5469 50.4903 63.7203C50.3393 63.8798 50.3471 64.1323 50.5066 64.2833C50.5879 64.3601 50.6953 64.3978 50.8027 64.392C50.8496 64.3894 50.8971 64.3783 50.942 64.3581C50.9901 64.3367 51.0337 64.3061 51.0702 64.267C52.2144 63.0552 53.2407 61.7197 54.1206 60.2982C54.2358 60.1114 54.1786 59.8654 53.9918 59.7502Z"
							fill="#F0BB4F"
						/>
						<path
							d="M135.355 117.039C134.739 117.039 134.239 117.54 134.239 118.156C134.239 118.771 134.739 119.273 135.355 119.273C135.971 119.273 136.471 118.771 136.471 118.156C136.471 117.54 135.971 117.039 135.355 117.039ZM135.355 120.049C134.312 120.049 133.462 119.2 133.462 118.156C133.462 117.112 134.312 116.263 135.355 116.263C136.399 116.263 137.248 117.112 137.248 118.156C137.248 119.2 136.399 120.049 135.355 120.049Z"
							fill="#F0BB4F"
						/>
						<path
							d="M27.1565 126.209H25.9938V124.57C25.9938 124.387 25.846 124.24 25.6642 124.24C25.4819 124.24 25.3341 124.387 25.3341 124.57V126.209H24.1714C23.9896 126.209 23.8418 126.357 23.8418 126.539C23.8418 126.721 23.9896 126.869 24.1714 126.869H25.3341V128.508C25.3341 128.69 25.4819 128.838 25.6642 128.838C25.846 128.838 25.9938 128.69 25.9938 128.508V126.869H27.1565C27.3387 126.869 27.4861 126.721 27.4861 126.539C27.4861 126.357 27.3387 126.209 27.1565 126.209Z"
							fill="#F0BB4F"
						/>
						<path
							d="M132.848 33.7654H131.103V31.3049C131.103 31.0309 130.882 30.8095 130.609 30.8095C130.335 30.8095 130.113 31.0309 130.113 31.3049V33.7654H128.368C128.094 33.7654 127.873 33.9867 127.873 34.2597C127.873 34.5338 128.094 34.7551 128.368 34.7551H130.113V37.2155C130.113 37.4886 130.335 37.7109 130.609 37.7109C130.882 37.7109 131.103 37.4886 131.103 37.2155V34.7551H132.848C133.122 34.7551 133.343 34.5338 133.343 34.2597C133.343 33.9867 133.122 33.7654 132.848 33.7654Z"
							fill="#F0BB4F"
						/>
						<path
							d="M85.9464 62.8035C84.6444 63.5796 83.3686 64.4342 82.1563 65.3425C81.9927 65.4657 81.9591 65.698 82.0816 65.8616C82.1555 65.9595 82.272 66.014 82.3923 66.0095C82.405 66.0088 82.417 66.008 82.4297 66.0058C82.4917 65.9976 82.5507 65.9729 82.6 65.9356C83.7929 65.0422 85.0455 64.2026 86.3258 63.4399C86.4102 63.3891 86.4707 63.3085 86.4954 63.2129C86.5193 63.1165 86.5051 63.0172 86.4543 62.932C86.3497 62.7565 86.1219 62.699 85.9464 62.8035Z"
							fill="#F0BB4F"
						/>
						<path
							d="M84.5774 135.687C83.3358 134.862 82.1237 133.963 80.9765 133.015C80.8999 132.951 80.8034 132.922 80.7055 132.931C80.6068 132.941 80.5177 132.988 80.4551 133.064C80.3918 133.14 80.3623 133.236 80.3719 133.335C80.3807 133.433 80.4286 133.523 80.5044 133.585C81.6709 134.55 82.9036 135.465 84.1672 136.304C84.232 136.347 84.3093 136.368 84.3859 136.365C84.3984 136.365 84.4109 136.364 84.4227 136.362C84.528 136.347 84.6223 136.288 84.6812 136.2C84.7939 136.03 84.7475 135.799 84.5774 135.687Z"
							fill="#F0BB4F"
						/>
						<path
							d="M97.1204 58.2244C97.0342 58.1748 96.9341 58.1626 96.8397 58.1886C96.3452 58.3229 95.845 58.4685 95.3529 58.6214C94.3947 58.9183 93.4365 59.2518 92.5052 59.6121C92.3148 59.6861 92.2197 59.9008 92.2937 60.0912C92.3514 60.24 92.4954 60.3336 92.6524 60.3279C92.6646 60.3271 92.6776 60.3262 92.6898 60.3246C92.7183 60.3206 92.7459 60.3132 92.7728 60.3027C93.6887 59.9488 94.6306 59.621 95.5717 59.329C96.0556 59.1794 96.5477 59.0362 97.0342 58.9036C97.231 58.8499 97.3481 58.6458 97.2944 58.4489C97.2684 58.353 97.2066 58.2732 97.1204 58.2244Z"
							fill="#F0BB4F"
						/>
						<path
							d="M95.0491 140.748C93.633 140.287 92.2242 139.746 90.8618 139.14C90.6752 139.057 90.4557 139.141 90.3732 139.327C90.3332 139.418 90.33 139.519 90.3652 139.611C90.4004 139.703 90.4701 139.776 90.5606 139.817C91.9455 140.434 93.3791 140.984 94.8192 141.452C94.8617 141.466 94.9049 141.472 94.9482 141.47C94.9602 141.47 94.973 141.469 94.985 141.467C95.1252 141.448 95.2429 141.348 95.287 141.215C95.3174 141.12 95.3094 141.02 95.2646 140.932C95.2197 140.844 95.1436 140.778 95.0491 140.748Z"
							fill="#F0BB4F"
						/>
						<path
							d="M76.9698 70.0785C76.8703 70.0806 76.7791 70.1228 76.7107 70.1946C75.6694 71.2932 74.6738 72.4609 73.7514 73.6646C73.6912 73.7426 73.665 73.8401 73.6781 73.9382C73.6912 74.0363 73.7417 74.1233 73.8198 74.1835C73.8895 74.2367 73.9725 74.2629 74.0588 74.2595C74.0713 74.2595 74.0837 74.2581 74.0961 74.2567C74.1936 74.2429 74.2799 74.1924 74.3393 74.1151C75.2459 72.9315 76.2249 71.7838 77.2482 70.7038C77.3892 70.5553 77.383 70.3204 77.2344 70.1801C77.1626 70.1117 77.0686 70.0765 76.9698 70.0785Z"
							fill="#F0BB4F"
						/>
						<path
							d="M70.3156 79.6515C70.228 79.6048 70.1274 79.5957 70.0327 79.6249C69.9386 79.6534 69.8608 79.7177 69.8147 79.8053C69.1062 81.1458 68.4599 82.5382 67.8948 83.9442C67.8585 84.0363 67.8591 84.1369 67.898 84.2277C67.937 84.3186 68.009 84.3893 68.1005 84.4263C68.1491 84.4457 68.201 84.4548 68.2529 84.4529C68.2653 84.4522 68.277 84.4509 68.2893 84.449C68.421 84.4308 68.5332 84.3432 68.5825 84.2206C69.138 82.8386 69.7725 81.4695 70.4694 80.1517C70.5161 80.0648 70.5252 79.9642 70.496 79.8695C70.4668 79.7748 70.4025 79.6976 70.3156 79.6515Z"
							fill="#F0BB4F"
						/>
						<path
							d="M65.9064 105.944C65.7559 104.862 65.6458 103.761 65.5799 102.671C65.5675 102.467 65.3909 102.312 65.19 102.324C65.0918 102.328 65.0016 102.371 64.9351 102.445C64.8692 102.518 64.8343 102.617 64.8406 102.716C64.9077 103.824 65.0196 104.945 65.1726 106.046C65.228 106.447 65.2901 106.851 65.3567 107.25C65.3884 107.436 65.5507 107.566 65.7354 107.558C65.7472 107.558 65.7602 107.557 65.7733 107.555C65.777 107.555 65.7808 107.554 65.7832 107.553C65.9847 107.52 66.1209 107.328 66.0873 107.127C66.022 106.735 65.9605 106.337 65.9064 105.944Z"
							fill="#F0BB4F"
						/>
						<path
							d="M66.4249 90.7971C66.3712 90.7141 66.2888 90.6561 66.1921 90.6355C65.9923 90.5918 65.7945 90.7191 65.7508 90.9182C65.4288 92.4017 65.181 93.9171 65.0168 95.4231C65.0062 95.5217 65.0343 95.6184 65.0961 95.6952C65.1585 95.7726 65.2465 95.8213 65.3445 95.8319C65.3632 95.8338 65.382 95.8344 65.4001 95.8338C65.4125 95.8331 65.4244 95.8319 65.4363 95.8306C65.6041 95.8069 65.7346 95.6727 65.7533 95.5036C65.915 94.0232 66.1577 92.534 66.4748 91.0761C66.496 90.9794 66.4779 90.8807 66.4249 90.7971Z"
							fill="#F0BB4F"
						/>
						<path
							d="M106.496 142.803C105.008 142.742 103.505 142.602 102.03 142.385C101.828 142.355 101.64 142.495 101.609 142.697C101.58 142.9 101.72 143.088 101.922 143.118C103.423 143.339 104.953 143.481 106.467 143.543C106.476 143.543 106.486 143.543 106.496 143.543C106.509 143.542 106.521 143.541 106.533 143.54C106.71 143.515 106.844 143.367 106.852 143.187C106.86 142.984 106.7 142.811 106.496 142.803Z"
							fill="#F0BB4F"
						/>
						<path
							d="M73.1104 124.451C73.0537 124.37 72.9698 124.315 72.8722 124.297C72.7746 124.28 72.6757 124.301 72.5945 124.357C72.514 124.413 72.4594 124.498 72.4417 124.596C72.4239 124.693 72.4451 124.791 72.5017 124.873C73.3642 126.118 74.3038 127.332 75.2939 128.481C75.3683 128.567 75.4761 128.613 75.5887 128.609C75.601 128.608 75.6132 128.607 75.6255 128.606C75.6965 128.596 75.7627 128.566 75.8166 128.519C75.9708 128.386 75.9885 128.152 75.8555 127.997C74.8818 126.869 73.9585 125.675 73.1104 124.451Z"
							fill="#F0BB4F"
						/>
						<path
							d="M67.8584 114.078C67.7922 113.884 67.5806 113.782 67.3877 113.848C67.2945 113.88 67.2186 113.947 67.1755 114.036C67.1318 114.125 67.126 114.225 67.1581 114.319C67.654 115.755 68.2295 117.178 68.8687 118.548C68.933 118.685 69.07 118.767 69.2179 118.761C69.2301 118.761 69.243 118.76 69.2552 118.758C69.2919 118.753 69.3272 118.743 69.3613 118.727C69.4507 118.685 69.5189 118.611 69.553 118.518C69.5864 118.425 69.5819 118.324 69.5401 118.235C68.9118 116.888 68.3459 115.489 67.8584 114.078Z"
							fill="#F0BB4F"
						/>
						<path
							d="M138.228 130.518C137.17 131.566 136.043 132.568 134.88 133.497C134.721 133.624 134.694 133.858 134.822 134.017C134.895 134.109 135.01 134.161 135.126 134.156C135.138 134.156 135.151 134.155 135.162 134.153C135.228 134.144 135.291 134.117 135.343 134.075C136.526 133.13 137.672 132.111 138.75 131.044C138.895 130.901 138.896 130.666 138.753 130.521C138.609 130.376 138.374 130.374 138.228 130.518Z"
							fill="#F0BB4F"
						/>
						<path
							d="M146.958 80.4292C146.273 79.0758 145.508 77.7453 144.684 76.4739C144.574 76.3027 144.344 76.2538 144.172 76.3643C144.089 76.4182 144.032 76.5011 144.012 76.5979C143.99 76.6947 144.008 76.7937 144.063 76.8766C144.872 78.1259 145.624 79.4339 146.299 80.7639C146.363 80.8938 146.499 80.972 146.643 80.9665C146.655 80.9661 146.667 80.9652 146.679 80.9635H146.68C146.72 80.9576 146.759 80.9453 146.796 80.927C146.979 80.8344 147.051 80.611 146.958 80.4292Z"
							fill="#F0BB4F"
						/>
						<path
							d="M150.102 110.234C149.904 110.184 149.703 110.305 149.652 110.503C149.289 111.947 148.845 113.389 148.331 114.789C148.261 114.981 148.36 115.194 148.551 115.265C148.597 115.281 148.646 115.289 148.694 115.287C148.706 115.286 148.717 115.285 148.729 115.284H148.73C148.865 115.265 148.979 115.173 149.027 115.044C149.549 113.62 150.001 112.153 150.371 110.684C150.395 110.588 150.38 110.488 150.33 110.403C150.279 110.318 150.198 110.258 150.102 110.234Z"
							fill="#F0BB4F"
						/>
						<path
							d="M151.281 98.5615C151.281 98.5615 151.28 98.5615 151.279 98.5615C151.076 98.569 150.916 98.7395 150.922 98.942C150.964 100.432 150.927 101.941 150.811 103.427C150.803 103.526 150.834 103.621 150.898 103.696C150.962 103.772 151.053 103.817 151.151 103.825C151.165 103.826 151.18 103.826 151.194 103.826C151.206 103.826 151.219 103.824 151.231 103.823C151.401 103.799 151.536 103.657 151.549 103.484C151.668 101.973 151.705 100.437 151.663 98.9212C151.657 98.7173 151.486 98.556 151.281 98.5615Z"
							fill="#F0BB4F"
						/>
						<path
							d="M108.575 56.6614C107.059 56.649 105.524 56.718 104.016 56.8666C103.917 56.8745 103.828 56.9214 103.766 56.9975C103.701 57.0744 103.672 57.1717 103.682 57.2717C103.701 57.4672 103.87 57.6131 104.064 57.6052C104.072 57.6052 104.079 57.6043 104.087 57.6043C105.571 57.4583 107.079 57.3902 108.569 57.4017C108.586 57.4017 108.605 57.4008 108.623 57.3982C108.803 57.3734 108.94 57.2168 108.942 57.0346C108.944 56.8303 108.778 56.6622 108.575 56.6614Z"
							fill="#F0BB4F"
						/>
						<path
							d="M150.531 92.112V92.1115C150.538 92.1107 150.546 92.1095 150.553 92.1082C150.753 92.0682 150.883 91.8729 150.843 91.6723C150.547 90.1902 150.169 88.7026 149.719 87.2497L149.7 87.1882C149.639 86.9941 149.431 86.8845 149.236 86.9447C149.141 86.9741 149.064 87.0386 149.018 87.1262C148.972 87.2136 148.963 87.3139 148.992 87.4082L149.011 87.4698C149.454 88.8976 149.825 90.3601 150.117 91.8171C150.152 91.9973 150.314 92.1221 150.493 92.1149C150.506 92.1145 150.518 92.1137 150.531 92.112Z"
							fill="#F0BB4F"
						/>
						<path
							d="M145.814 121.156C145.637 121.055 145.41 121.117 145.309 121.294C144.57 122.589 143.755 123.858 142.886 125.068C142.828 125.148 142.806 125.246 142.821 125.344C142.837 125.442 142.89 125.527 142.97 125.585C143.038 125.633 143.118 125.657 143.2 125.654C143.213 125.654 143.225 125.652 143.237 125.651C143.337 125.637 143.428 125.582 143.487 125.5C144.371 124.27 145.201 122.978 145.953 121.661C146.001 121.576 146.014 121.476 145.988 121.38C145.961 121.285 145.9 121.205 145.814 121.156Z"
							fill="#F0BB4F"
						/>
						<path
							d="M136.955 67.5226C136.801 67.3879 136.567 67.4028 136.432 67.5556C136.297 67.709 136.312 67.944 136.465 68.0786C137.584 69.0629 138.662 70.1172 139.67 71.2123C139.745 71.2926 139.849 71.336 139.958 71.3318C139.97 71.3313 139.982 71.33 139.995 71.3283C140.07 71.3181 140.139 71.2854 140.194 71.234C140.267 71.1669 140.309 71.0755 140.313 70.977C140.318 70.878 140.283 70.7833 140.215 70.7106C139.191 69.5968 138.094 68.5242 136.955 67.5226Z"
							fill="#F0BB4F"
						/>
						<path
							d="M118.073 141.675C116.62 142.021 115.136 142.294 113.658 142.485C113.457 142.513 113.315 142.699 113.341 142.9C113.366 143.091 113.533 143.23 113.723 143.222C113.733 143.222 113.746 143.221 113.756 143.219C113.886 143.203 114.017 143.185 114.147 143.167C115.516 142.977 116.895 142.718 118.245 142.396C118.341 142.373 118.422 142.314 118.474 142.23C118.526 142.146 118.542 142.046 118.519 141.95C118.472 141.752 118.272 141.629 118.073 141.675Z"
							fill="#F0BB4F"
						/>
						<path
							d="M120.283 58.3737C118.825 57.9513 117.33 57.6014 115.839 57.3347C115.742 57.3175 115.643 57.3386 115.562 57.395C115.481 57.4523 115.426 57.5364 115.409 57.6339C115.392 57.7314 115.413 57.8299 115.47 57.9111C115.527 57.9924 115.611 58.0459 115.708 58.064C117.175 58.3259 118.644 58.6691 120.076 59.0849C120.115 59.0963 120.154 59.1011 120.194 59.0992C120.205 59.0992 120.218 59.0982 120.23 59.0963C120.376 59.0763 120.495 58.973 120.535 58.8325C120.563 58.7379 120.551 58.6375 120.505 58.5506C120.456 58.4636 120.378 58.4014 120.283 58.3737Z"
							fill="#F0BB4F"
						/>
						<path
							d="M131.094 63.1799C129.806 62.383 128.46 61.6444 127.094 60.9855C127.006 60.9426 126.905 60.9365 126.811 60.9691C126.719 61.0018 126.643 61.0693 126.6 61.1581C126.557 61.247 126.552 61.3482 126.585 61.4411C126.617 61.5341 126.684 61.6097 126.773 61.6526C128.115 62.3003 129.438 63.0266 130.705 63.8102C130.767 63.8488 130.841 63.8679 130.914 63.8649C130.926 63.8645 130.939 63.8637 130.95 63.862C131.06 63.8466 131.156 63.7838 131.215 63.6899C131.267 63.6059 131.283 63.5068 131.26 63.4097C131.237 63.3137 131.179 63.232 131.094 63.1799Z"
							fill="#F0BB4F"
						/>
						<path
							d="M128.921 137.483C127.62 138.202 126.263 138.862 124.888 139.443C124.7 139.522 124.612 139.74 124.691 139.928C124.752 140.071 124.894 140.16 125.047 140.154C125.059 140.154 125.071 140.152 125.083 140.151C125.116 140.146 125.147 140.137 125.176 140.125C126.575 139.535 127.955 138.864 129.28 138.132C129.459 138.033 129.524 137.807 129.426 137.628C129.326 137.449 129.1 137.384 128.921 137.483Z"
							fill="#F0BB4F"
						/>
						<path
							d="M28.8604 109.754H110.594L121.742 56.6604H40.0088L28.8604 109.754Z"
							fill="#FAB445"
						/>
						<path
							d="M40.0145 56.6616L39.4873 59.1599L43.3017 109.752H49.3755L40.0145 56.6616Z"
							fill="#F9A831"
						/>
						<path
							d="M49.5537 109.539L40.2676 56.8732H121.623L130.909 109.539H49.5537Z"
							fill="white"
						/>
						<path
							d="M121.802 56.6619H40.0146L49.3759 109.752H131.163L121.802 56.6619ZM121.444 57.0865L130.657 109.327H49.7323L40.5206 57.0865H121.444Z"
							fill="#F0BB4F"
						/>
						<path
							d="M50.7012 62.4863C50.6959 62.4863 50.6906 62.4863 50.6853 62.4863C50.5056 62.485 50.3279 62.46 50.1547 62.4152C49.2455 62.1808 48.4673 61.3882 48.3014 60.446C48.1026 59.3196 48.8551 58.4064 49.9815 58.4064C50.0579 58.4064 50.133 58.4104 50.208 58.4189C50.4134 58.4406 50.6156 58.492 50.8085 58.5684C51.5932 58.8785 52.2325 59.6027 52.3813 60.446C52.5801 61.5732 51.8276 62.4863 50.7012 62.4863Z"
							fill="#FAB445"
						/>
						<path
							d="M113.252 62.4845C113.225 62.4854 113.198 62.4863 113.169 62.4863C113.015 62.4863 112.862 62.4697 112.713 62.4375C111.766 62.2328 110.941 61.4196 110.769 60.446C110.57 59.3194 111.323 58.4067 112.45 58.4067C112.56 58.4067 112.67 58.415 112.778 58.4316C112.988 58.4648 113.193 58.5274 113.389 58.616C114.121 58.9488 114.708 59.643 114.849 60.446C115.043 61.545 114.333 62.4402 113.252 62.4845Z"
							fill="#FAB445"
						/>
						<path
							d="M61.1129 62.4863C61.1129 62.4863 61.1129 62.4863 61.1123 62.4863C60.9325 62.4863 60.7541 62.4633 60.581 62.4192C59.6652 62.1901 58.8791 61.3934 58.7125 60.446C58.5137 59.3196 59.2662 58.4064 60.3927 58.4064C60.4743 58.4064 60.556 58.411 60.6369 58.4202C60.843 58.4439 61.0458 58.4979 61.2387 58.5763C62.0149 58.891 62.645 59.6099 62.7924 60.446C62.9913 61.5732 62.2394 62.4863 61.1129 62.4863Z"
							fill="#FAB445"
						/>
						<path
							d="M71.5391 62.4857C71.5338 62.4863 71.5285 62.4863 71.5231 62.4863C71.3485 62.4863 71.1752 62.4643 71.0053 62.4223C70.0842 62.1971 69.2911 61.3973 69.1238 60.4456C68.9245 59.3192 69.677 58.4061 70.804 58.4061C70.8906 58.4061 70.9766 58.4114 71.0613 58.4214C71.2685 58.4474 71.4718 58.5021 71.6658 58.5821C72.4342 58.9 73.0574 59.6151 73.2033 60.4456C73.4013 61.5672 72.6568 62.477 71.5391 62.4857Z"
							fill="#FAB445"
						/>
						<path
							d="M81.9674 62.4856C81.9564 62.4863 81.9455 62.4863 81.9345 62.4863C81.7651 62.4863 81.5963 62.4651 81.432 62.4264C80.5045 62.2066 79.7033 61.4032 79.5346 60.4457C79.3366 59.3195 80.0882 58.4058 81.2151 58.4058C81.3071 58.4058 81.3991 58.4117 81.4904 58.4241C81.6986 58.4511 81.9016 58.5081 82.0959 58.5906C82.8562 58.912 83.4697 59.6219 83.615 60.4457C83.8115 61.5617 83.076 62.4681 81.9674 62.4856Z"
							fill="#FAB445"
						/>
						<path
							d="M92.3952 62.4855C92.3785 62.4863 92.3619 62.4863 92.346 62.4863C92.1816 62.4863 92.0187 62.4673 91.8591 62.4299C90.9243 62.2155 90.1158 61.4086 89.9458 60.4468C89.7473 59.3198 90.5002 58.4064 91.6264 58.4064C91.7249 58.4064 91.8226 58.4136 91.9195 58.4271C92.1283 58.4557 92.3325 58.5152 92.5263 58.5986C93.2768 58.9243 93.882 59.6295 94.0257 60.4468C94.2219 61.5563 93.4944 62.4593 92.3952 62.4855Z"
							fill="#FAB445"
						/>
						<path
							d="M102.827 62.4846C102.804 62.4863 102.781 62.4863 102.758 62.4863C102.599 62.4863 102.442 62.4683 102.287 62.434C101.346 62.2246 100.529 61.4146 100.358 60.4458C100.159 59.3191 100.912 58.4061 102.038 58.4061C102.144 58.4061 102.248 58.4147 102.353 58.4293C102.563 58.4602 102.768 58.522 102.962 58.6086C103.702 58.939 104.295 59.6375 104.438 60.4458C104.633 61.5493 103.915 62.4486 102.827 62.4846Z"
							fill="#FAB445"
						/>
						<path
							d="M50.6845 62.4844C50.5051 62.483 50.3271 62.4589 50.1538 62.4136C50.5415 61.4794 50.6591 60.4538 50.4838 59.4317C50.424 59.0818 50.3305 58.7423 50.2075 58.4171C49.96 57.7648 49.5902 57.1702 49.1152 56.6602C48.8629 56.39 48.5818 56.1439 48.2732 55.9253C46.0343 54.3423 42.9954 54.7025 41.1787 56.6602H40.4961C42.4366 54.2096 45.9904 53.6755 48.5729 55.5019C49.041 55.8332 49.452 56.2237 49.7998 56.6602C50.2494 57.2259 50.5903 57.87 50.8075 58.567C50.8873 58.8192 50.9498 59.0791 50.9952 59.3444C51.1787 60.4174 51.0688 61.4952 50.6845 62.4844Z"
							fill="#3F4042"
						/>
						<path
							d="M113.253 62.4845C113.225 62.4854 113.198 62.4863 113.169 62.4863C113.016 62.4863 112.863 62.4699 112.713 62.437C113.254 61.1416 113.259 59.7055 112.779 58.4321C112.536 57.7868 112.168 57.1834 111.683 56.6614C111.433 56.3936 111.154 56.1477 110.843 55.9274C109.662 55.0919 108.226 54.7664 106.801 55.0114C105.613 55.2153 104.552 55.793 103.747 56.6614H103.065C103.974 55.5124 105.258 54.7491 106.713 54.4995C108.275 54.2317 109.848 54.5882 111.142 55.5041C111.615 55.8378 112.024 56.2291 112.367 56.6614C112.833 57.2502 113.176 57.9147 113.389 58.6159C113.769 59.8655 113.737 61.2303 113.253 62.4845Z"
							fill="#3F4042"
						/>
						<path
							d="M61.113 62.4863C61.1124 62.4863 61.1124 62.4863 61.1117 62.4863C60.9325 62.4863 60.754 62.4624 60.5802 62.4186C60.9694 61.4836 61.087 60.4556 60.912 59.433C60.8518 59.0835 60.7594 58.7443 60.6363 58.4201C60.3894 57.7669 60.0187 57.1711 59.5433 56.6616C59.2909 56.3907 59.0098 56.1452 58.7013 55.927C57.5201 55.0918 56.0844 54.7663 54.659 55.0105C53.4716 55.2143 52.4101 55.7922 51.6064 56.6616H50.9238C51.8322 55.5125 53.116 54.7485 54.5708 54.4995C56.133 54.2314 57.7062 54.5878 59.0009 55.5029C59.4694 55.834 59.8812 56.2238 60.228 56.6616C60.6787 57.2293 61.0207 57.8756 61.2382 58.576C61.3169 58.8257 61.3784 59.0829 61.4236 59.3455C61.6076 60.4187 61.4968 61.4966 61.113 62.4863Z"
							fill="#3F4042"
						/>
						<path
							d="M71.5394 62.4856C71.5339 62.4863 71.5285 62.4863 71.523 62.4863C71.3486 62.4863 71.1748 62.4638 71.0052 62.422C71.5421 61.1279 71.5435 59.693 71.0613 58.4214C70.8185 57.7799 70.4519 57.18 69.9697 56.6616C69.72 56.3935 69.4396 56.1465 69.1291 55.927C67.9478 55.0918 66.5129 54.7663 65.0868 55.0105C63.8994 55.2143 62.8378 55.7922 62.0342 56.6616H61.3516C62.2606 55.5125 63.5437 54.7485 64.9992 54.4995C66.5614 54.2314 68.1346 54.5878 69.4287 55.5029C69.9013 55.8374 70.311 56.2286 70.653 56.6616C71.1119 57.2402 71.4512 57.8934 71.6653 58.5815C72.0565 59.8414 72.0278 61.2196 71.5394 62.4856Z"
							fill="#3F4042"
						/>
						<path
							d="M81.9678 62.4863C81.9569 62.4863 81.9461 62.4863 81.9345 62.4863C81.7655 62.4863 81.5965 62.4661 81.4319 62.4264C81.9707 61.1322 81.9728 59.6964 81.4911 58.4246C81.2477 57.7819 80.8816 57.1817 80.3977 56.6617C80.1492 56.3938 79.8683 56.1468 79.5577 55.9272C77.3189 54.3441 74.2798 54.7045 72.4635 56.6617H71.7803C72.6888 55.5127 73.9722 54.7493 75.4274 54.5001C76.9903 54.2322 78.5633 54.5883 79.8567 55.504C80.3298 55.8384 80.7393 56.2291 81.0816 56.6617C81.5417 57.2431 81.8825 57.8996 82.0963 58.5907C82.4849 59.8481 82.4553 61.2232 81.9678 62.4863Z"
							fill="#3F4042"
						/>
						<path
							d="M92.3954 62.4855C92.3789 62.4863 92.3624 62.4863 92.3467 62.4863C92.1824 62.4863 92.0188 62.4667 91.8593 62.4297C92.3986 61.1357 92.4009 59.6993 91.9198 58.4265C91.6769 57.7833 91.3097 57.1819 90.8262 56.6615C90.577 56.3934 90.2963 56.1473 89.9858 55.9272C88.8049 55.0922 87.3693 54.7667 85.9432 55.0105C84.7568 55.2149 83.6947 55.7927 82.8904 56.6615H82.208C83.1168 55.5128 84.4007 54.7495 85.8559 54.4994C87.4181 54.2314 88.9912 54.5883 90.2853 55.5034C90.7578 55.8375 91.1674 56.2291 91.5094 56.6615C91.9717 57.2456 92.3137 57.9044 92.5267 58.5986C92.9127 59.8534 92.8821 61.2253 92.3954 62.4855Z"
							fill="#3F4042"
						/>
						<path
							d="M102.826 62.4846C102.803 62.4855 102.781 62.4863 102.758 62.4863C102.599 62.4863 102.442 62.4676 102.287 62.4336C102.682 61.4952 102.801 60.4615 102.625 59.433C102.565 59.087 102.474 58.7513 102.353 58.4291C102.106 57.7729 101.734 57.1745 101.256 56.6619C101.004 56.3916 100.723 56.1451 100.415 55.9266C98.1755 54.3438 95.1367 54.7043 93.3201 56.6619H92.6367C94.5773 54.2104 98.1313 53.6766 100.714 55.5033C101.182 55.8348 101.594 56.2241 101.94 56.6619C102.399 57.2382 102.744 57.8961 102.962 58.6085C103.035 58.8482 103.093 59.0947 103.136 59.3454C103.32 60.4182 103.21 61.496 102.826 62.4846Z"
							fill="#3F4042"
						/>
						<path
							d="M57.2684 77.1211H51.4713C50.7402 77.1211 50.0431 76.5287 49.9145 75.7976L48.8916 70C48.763 69.2689 49.2512 68.6765 49.9823 68.6765H55.7794C56.5105 68.6765 57.2082 69.2689 57.3368 70L58.3591 75.7976C58.4877 76.5287 58.0001 77.1211 57.2684 77.1211Z"
							fill="#EEEFED"
						/>
						<path
							d="M59.3868 89.1328H53.5893C52.8585 89.1328 52.1605 88.5407 52.0324 87.8093L51.0099 82.0123C50.8811 81.2809 51.3691 80.6882 52.1 80.6882H57.8975C58.6284 80.6882 59.3257 81.2809 59.4545 82.0123L60.4764 87.8093C60.6057 88.5407 60.1177 89.1328 59.3868 89.1328Z"
							fill="#EEEFED"
						/>
						<path
							d="M61.492 101.074H55.6951C54.9638 101.074 54.2668 100.482 54.1376 99.7504L53.1154 93.9536C52.9862 93.2223 53.4748 92.6292 54.2061 92.6292H60.003C60.7343 92.6292 61.4313 93.2223 61.5599 93.9536L62.5826 99.7504C62.7112 100.482 62.2233 101.074 61.492 101.074Z"
							fill="#EEEFED"
						/>
						<path
							d="M69.2769 70.0001L70.2991 75.7974C70.4281 76.5286 69.94 77.1211 69.2088 77.1211H63.4116C62.6803 77.1211 61.9835 76.5286 61.8545 75.7974L60.8322 70.0001C60.7032 69.2696 61.1913 68.6764 61.9225 68.6764H67.7198C68.451 68.6764 69.1485 69.2696 69.2769 70.0001Z"
							fill="#EEEFED"
						/>
						<path
							d="M81.2171 70.0006L82.2394 75.7979C82.3686 76.5287 81.8801 77.1211 81.1493 77.1211H75.352C74.6204 77.1211 73.9235 76.5287 73.795 75.7979L72.7727 70.0006C72.6435 69.269 73.132 68.6767 73.8628 68.6767H79.6601C80.391 68.6767 81.0887 69.269 81.2171 70.0006Z"
							fill="#EEEFED"
						/>
						<path
							d="M93.159 70.0001L94.1811 75.797C94.3099 76.5282 93.8219 77.1211 93.0907 77.1211H87.2937C86.5618 77.1211 85.8648 76.5282 85.736 75.797L84.714 70.0001C84.5852 69.2689 85.0732 68.6768 85.8044 68.6768H91.6013C92.3325 68.6768 93.0303 69.2689 93.159 70.0001Z"
							fill="#EEEFED"
						/>
						<path
							d="M105.099 70.0008L106.121 75.7974C106.25 76.5282 105.763 77.1211 105.031 77.1211H99.2343C98.5034 77.1211 97.8055 76.5282 97.6771 75.7974L96.6545 70.0008C96.5252 69.2691 97.0139 68.6771 97.7448 68.6771H103.542C104.273 68.6771 104.97 69.2691 105.099 70.0008Z"
							fill="#EEEFED"
						/>
						<path
							d="M117.039 70.0003L118.061 75.7974C118.19 76.5285 117.702 77.1211 116.971 77.1211H111.174C110.443 77.1211 109.745 76.5285 109.616 75.7974L108.594 70.0003C108.465 69.269 108.953 68.6769 109.685 68.6769H115.481C116.212 68.6769 116.91 69.269 117.039 70.0003Z"
							fill="#EEEFED"
						/>
						<path
							d="M71.395 82.0126L72.4173 87.8097C72.5462 88.5403 72.0582 89.1328 71.3269 89.1328H65.5298C64.7985 89.1328 64.1016 88.5403 63.9726 87.8097L62.9503 82.0126C62.8214 81.2806 63.3094 80.6881 64.0407 80.6881H69.8378C70.5691 80.6881 71.2661 81.2806 71.395 82.0126Z"
							fill="#EEEFED"
						/>
						<path
							d="M83.3363 82.0119L84.358 87.8093C84.4873 88.5401 83.9989 89.1328 83.268 89.1328H77.4707C76.7399 89.1328 76.0421 88.5401 75.9135 87.8093L74.8919 82.0119C74.7626 81.2803 75.2503 80.6883 75.9819 80.6883H81.7785C82.51 80.6883 83.2071 81.2803 83.3363 82.0119Z"
							fill="#EEEFED"
						/>
						<path
							d="M95.2761 82.0119L96.2987 87.8092C96.4271 88.5402 95.9393 89.1328 95.2082 89.1328H89.411C88.68 89.1328 87.9821 88.5402 87.8537 87.8092L86.8311 82.0119C86.7026 81.2801 87.1905 80.688 87.9215 80.688H93.7188C94.4498 80.688 95.1469 81.2801 95.2761 82.0119Z"
							fill="#EEEFED"
						/>
						<path
							d="M107.216 82.0119L108.239 87.8091C108.367 88.5402 107.879 89.1328 107.148 89.1328H101.351C100.62 89.1328 99.9221 88.5402 99.7935 87.8091L98.7715 82.0119C98.6429 81.2804 99.131 80.6879 99.8613 80.6879H105.659C106.39 80.6879 107.087 81.2804 107.216 82.0119Z"
							fill="#EEEFED"
						/>
						<path
							d="M119.157 82.0119L120.179 87.8092C120.308 88.5402 119.819 89.1328 119.089 89.1328H113.291C112.56 89.1328 111.863 88.5402 111.734 87.8092L110.712 82.0119C110.583 81.2804 111.071 80.6878 111.803 80.6878H117.6C118.331 80.6878 119.028 81.2804 119.157 82.0119Z"
							fill="#EEEFED"
						/>
						<path
							d="M73.5009 93.9534L74.5229 99.7506C74.6517 100.482 74.1636 101.074 73.4328 101.074H67.6351C66.9043 101.074 66.2072 100.482 66.0778 99.7506L65.0558 93.9534C64.927 93.222 65.4151 92.6294 66.1466 92.6294H71.9436C72.6744 92.6294 73.3715 93.222 73.5009 93.9534Z"
							fill="#EEEFED"
						/>
						<path
							d="M85.4411 93.9534L86.4635 99.7506C86.5921 100.482 86.104 101.074 85.3731 101.074H79.5754C78.8445 101.074 78.1479 100.482 78.0186 99.7506L76.9961 93.9534C76.8676 93.2219 77.3556 92.6293 78.0866 92.6293H83.8842C84.6152 92.6293 85.3125 93.2219 85.4411 93.9534Z"
							fill="#EEEFED"
						/>
						<path
							d="M97.3821 93.9534L98.4038 99.7506C98.5327 100.482 98.0444 101.074 97.3132 101.074H91.516C90.7856 101.074 90.088 100.482 89.9592 99.7506L88.9366 93.9534C88.8078 93.2219 89.2961 92.6293 90.0273 92.6293H95.8245C96.5557 92.6293 97.2524 93.2219 97.3821 93.9534Z"
							fill="#EEEFED"
						/>
						<path
							d="M109.323 93.9533L110.345 99.7505C110.474 100.482 109.986 101.074 109.254 101.074H103.457C102.726 101.074 102.029 100.482 101.9 99.7505L100.878 93.9533C100.749 93.2218 101.238 92.6293 101.968 92.6293H107.766C108.496 92.6293 109.194 93.2218 109.323 93.9533Z"
							fill="#EEEFED"
						/>
						<path
							d="M121.264 93.9534L122.286 99.7505C122.415 100.482 121.926 101.074 121.195 101.074H115.399C114.668 101.074 113.97 100.482 113.841 99.7505L112.819 93.9534C112.691 93.2219 113.178 92.6292 113.909 92.6292H119.707C120.438 92.6292 121.135 93.2219 121.264 93.9534Z"
							fill="#EEEFED"
						/>
						<path
							d="M66.0836 75.293C65.8851 75.293 65.6732 75.2172 65.4955 75.0656L61.7458 71.8785C61.3897 71.5754 61.3032 71.0853 61.5527 70.7822C61.8021 70.4798 62.293 70.4798 62.6483 70.7822L65.7543 73.4221L72.5255 65.2247C72.7757 64.9222 73.2665 64.9229 73.6219 65.226C73.978 65.5284 74.0638 66.0199 73.8137 66.3217L66.5912 75.0663C66.4665 75.2172 66.2814 75.293 66.0836 75.293Z"
							fill="#4F7771"
						/>
						<path
							d="M80.1291 87.1777C79.9308 87.1777 79.7197 87.1021 79.5411 86.9507L75.7916 83.7627C75.4359 83.4607 75.3489 82.9696 75.5986 82.6669C75.8476 82.3642 76.3387 82.3642 76.6951 82.6669L79.7999 85.3071L86.5718 77.1096C86.8208 76.8069 87.3119 76.8077 87.6675 77.1103C88.024 77.4138 88.1095 77.9041 87.8598 78.2068L80.6369 86.9507C80.512 87.1021 80.3274 87.1777 80.1291 87.1777Z"
							fill="#4F7771"
						/>
						<path
							d="M104.279 78.6474C102.572 78.771 100.838 79.3675 99.5527 80.6915C98.2673 82.0156 97.516 84.1532 97.9531 86.0954C98.2816 87.5563 99.2367 88.7542 100.376 89.4563C101.515 90.1581 102.824 90.4058 104.11 90.4139C105.425 90.4223 106.78 90.1739 107.899 89.3625C109.017 88.5507 109.857 87.0937 109.803 85.5499C109.738 83.6604 108.356 82.1057 106.817 81.5526C105.278 80.9995 103.618 81.2505 102.041 81.6261"
							stroke="#F14A46"
							stroke-width="1.32749"
							stroke-miterlimit="10"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M137.247 62.4097L107.907 84.0098L106.051 81.489L135.391 59.8888L137.247 62.4097Z"
							fill="#A8D5CF"
						/>
						<path
							d="M138.848 61.2317L137.247 62.4102L135.391 59.8894L136.991 58.7104C137.254 58.5178 137.624 58.5734 137.817 58.8363L138.973 60.4059C139.167 60.6685 139.111 61.0385 138.848 61.2317Z"
							fill="#4F7771"
						/>
						<path
							d="M106.051 81.487L107.908 84.0095L105.149 84.9015L103.196 85.5332L104.38 83.8557L106.051 81.487Z"
							fill="#4F7771"
						/>
						<path
							d="M104.379 83.8576L105.149 84.9035L103.196 85.5352L104.379 83.8576Z"
							fill="#387F34"
						/>
						<path
							d="M120.148 71.1112L111.345 77.5918C111.031 77.1649 111.122 76.5646 111.549 76.2504L118.807 70.9073C119.234 70.5933 119.833 70.6842 120.148 71.1112Z"
							fill="#4F7771"
						/>
						<path
							d="M40.2755 46.0423C41.7842 49.8 39.9608 54.068 36.2038 55.5757C32.447 57.0841 28.1786 55.2614 26.6702 51.5044C25.1622 47.7475 26.9849 43.4788 30.7419 41.9703C34.4991 40.4626 38.7671 42.2854 40.2755 46.0423Z"
							fill="#F14A46"
						/>
						<path
							d="M36.4406 48.5989L35.8234 47.0618C35.2436 45.6165 33.6017 44.9154 32.1565 45.4957C30.7114 46.0761 30.0105 47.7177 30.5903 49.1629L31.2076 50.7C31.5432 51.536 31.4424 51.9473 31.2959 52.8359L37.8539 50.2034C37.1335 49.6624 36.7762 49.4349 36.4406 48.5989Z"
							fill="white"
						/>
						<path
							d="M35.1117 52.853C34.5661 53.0717 33.9463 52.8067 33.7275 52.2621L35.7026 51.4687C35.9213 52.014 35.657 52.6336 35.1117 52.853Z"
							fill="white"
						/>
						<path
							d="M32.35 45.9765C32.0841 46.0836 31.782 45.9542 31.6754 45.6887C31.5683 45.4225 31.6975 45.1206 31.9634 45.0143C32.2293 44.9072 32.5313 45.0359 32.6384 45.3021C32.745 45.5675 32.6159 45.8702 32.35 45.9765ZM31.8351 44.6938C31.3925 44.8715 31.1775 45.3742 31.3555 45.8166C31.5331 46.2598 32.036 46.4747 32.4787 46.297C32.9209 46.1185 33.1359 45.6166 32.9583 45.1734C32.7803 44.731 32.2778 44.5161 31.8351 44.6938Z"
							fill="white"
						/>
						<path
							d="M30.3947 41.1037C28.3463 41.9264 26.7406 43.4968 25.8736 45.527C25.0062 47.5571 24.9819 49.8039 25.8044 51.8518C26.6268 53.9005 28.1976 55.5065 30.2278 56.3734C32.2579 57.241 34.5043 57.265 36.5526 56.443C38.6014 55.6203 40.2071 54.0499 41.0741 52.0198C41.9411 49.9897 41.9658 47.7428 41.1434 45.6941C40.321 43.6462 38.7501 42.0402 36.72 41.1734C34.6899 40.3057 32.4435 40.2818 30.3947 41.1037ZM36.8134 57.0916C34.5913 57.984 32.1551 57.9577 29.9529 57.0165C27.7512 56.0769 26.0469 54.3347 25.1548 52.1127C24.2632 49.8906 24.2896 47.4549 25.2301 45.2522C26.1705 43.051 27.9122 41.3467 30.1343 40.4551C32.356 39.5627 34.7926 39.5891 36.9944 40.5294C39.1965 41.4698 40.9004 43.212 41.7924 45.4341C42.6843 47.6554 42.658 50.0918 41.7173 52.2938C40.7767 54.4957 39.0351 56.2 36.8134 57.0916Z"
							fill="#F14A46"
						/>
					</svg>
					<div class="text-neutral-400 text-center text-lg">
						Chưa có Trị Liệu Viên<br />
						TKB được xây dựng bởi Lịch can thiệp cá nhân & Lịch học
						bán trú (nếu có). <br />Bạn cần thêm Trị Liệu Viên cho
						bé để tạo TKB.
					</div>
					<div class="mt-10">
						<button
							type="button"
							class="button-p btn--ghost w-fit"
							@click="
								$router.push(
									`/record/student/${studentSelected.id}`
								)
							"
						>
							<span class="ml-2 whitespace-nowrap"
								>Thêm trị liệu viên</span
							>
						</button>
					</div>
				</div>
				<div
					v-if="!isLoading && studentSelected && staffs.length"
					class="mt-10"
				>
					<div class="text-neutral-600 font-semibold">
						Trị liệu viên
					</div>
					<div>
						<div
							v-for="(staff, index) in staffs"
							:key="index"
							@click="
								isAdminAndAccountant &&
									$router.push(
										`/record/employee/${staff?.staff.id}`
									)
							"
							:class="{
								'cursor-pointer': isAdminAndAccountant,
							}"
							class="mt-3"
						>
							<div class="flex items-center">
								<img
									class="
										shadow-sm
										inline-block
										h-6
										w-6
										rounded-full
									"
									:src="staff?.profile_photo?.w200"
									v-if="staff?.profile_photo?.w200"
									alt=""
								/>
								<span
									v-else
									class="
										inline-flex
										items-center
										justify-center
										h-6
										w-6
										rounded-full
									"
									:style="{
										background:
											staff?.staff?.profile_photo?.default
												?.color,
									}"
								>
									<span
										class="
											font-medium
											leading-none
											text-white text-xs
										"
										>{{
											staff?.staff?.profile_photo?.default
												?.initial
										}}</span
									>
								</span>
								<div class="ml-4 text-blue-600 font-semibold">
									{{ staff?.staff?.name }}
								</div>
							</div>
						</div>
						<button
							type="button"
							class="button-p btn--text w-fit mt-6 ml-8"
							@click="
								$router.push(
									`/record/student/${studentSelected.id}`
								)
							"
						>
							<span class="ml-2 whitespace-nowrap"
								>Thêm trị liệu viên</span
							>
						</button>
					</div>
				</div>
			</div>
			<div
				class="
					px-6
					py-4
					border-t border-yellow-50
					flex
					justify-end
					gap-6
				"
			>
				<div class="flex justify-end">
					<div>
						<button
							:disabled="!studentSelected || !staffs.length"
							type="button"
							class="button-p text-center w-32"
							@click="submit"
						>
							Tiếp tục
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import DaIcon from '@/components/elements/daIcon/DaIcon';
import Multiselect from '@suadelabs/vue3-multiselect';
import { $api } from '@/services';
import dayjs from 'dayjs';
import { toEnglish } from '@/helper/toEnglish';

export default defineComponent({
	name: 'UploadTimeTableStudent',

	components: { DaIcon, Multiselect },

	props: {
		student: {
			type: Object,
		},
	},

	data() {
		return {
			applyDate: Date(),
			studentSelected: null,
			students: [],
			staffs: [],
			isLoading: true,
		};
	},

	created() {
		this.fetchStudents();
	},

	computed: {
		isAdmin() {
			return this.$store.getters.isAdmin;
		},

		isAdminAndAccountant() {
			return this.$store.getters.isAdminAndAccountant;
		},
	},

	watch: {
		student: {
			immediate: true,
			deep: true,
			async handler(value) {
				this.studentSelected = value;
			},
		},
		studentSelected: {
			deep: true,
			async handler(value) {
				if (value) {
					await this.fetchAssignment(value.id);
				}
			},
		},
	},

	methods: {
		toEnglish,
		async submit() {
			await this.$router.push(
				`/timetable-student/${
					this.studentSelected?.id
				}?apply_date=${dayjs(this.applyDate).format('YYYY-MM-DD')}`
			);
		},

		close() {
			this.$emit('close');
		},

		async fetchStudents() {
			let role =
				this.$store.state.profile.staff.role === 'teacher'
					? 'staff'
					: 'org_admin';
			try {
				const {
					data: { data },
				} = await $api.student.getStudents(role, '');
				this.students = data;
			} catch (err) {
				console.log(err);
			}
		},

		async fetchAssignment(id) {
			try {
				const { data } = await $api.assignment.getAssignments(
					id,
					this.isAdmin
				);
				this.staffs = data?.data || [];
			} catch (err) {
				await this.$store.dispatch('setAlertMessage', {
					message:
						err?.response?.data?.errors?.join(<br />) ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.isLoading = false;
			}
		},
	},
});
</script>
