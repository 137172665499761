
import { Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

export default class PopupConfirm extends Vue {
	@Prop() isOff!: boolean;
	@Prop() type!: number;

	//----------------------- 🤍 Methods 🤍 -----------------------//
	submit() {
		this.$emit('submit');
	}
}
