
import { computed, defineComponent, ref, watch } from 'vue';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { _ROUTER_NAME } from '@/enums';
import { useRoute } from 'vue-router';

export default defineComponent({
	name: 'StudentDetail',
	components: {
		DaIcon,
	},
	setup() {
		const hideTab = ref(false);
		const route = useRoute();
		watch(route, () => {
			hideTab.value =
				route.name == _ROUTER_NAME.GOAL_MANAGEMENT_STUDENT_GOAL_DETAILS;
		});
		return {
			hideTab,
			routerName: computed(() => _ROUTER_NAME),
		};
	},
});
