<template>
	<div
		style="max-width: 600px"
		class="
			fixed
			inset-y-0
			right-0
			flex
			side-over
			z-50
			overflow-y-auto
			w-full
		"
	>
		<div
			class="h-full w-full flex flex-col bg-white shadow-xl"
			style="min-height: 100vh; height: fit-content"
		>
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M1.5 5.5C1.5 4.67157 2.17157 4 3 4H21C21.8284 4 22.5 4.67157 22.5 5.5C22.5 6.32843 21.8284 7 21 7H3C2.17157 7 1.5 6.32843 1.5 5.5Z"
								fill="#94A3B8"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M3.5 12C3.5 11.1716 4.17157 10.5 5 10.5H19C19.8284 10.5 20.5 11.1716 20.5 12C20.5 12.8284 19.8284 13.5 19 13.5H5C4.17157 13.5 3.5 12.8284 3.5 12Z"
								fill="#94A3B8"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M6.5 18C6.5 17.1716 7.17157 16.5 8 16.5H16C16.8284 16.5 17.5 17.1716 17.5 18C17.5 18.8284 16.8284 19.5 16 19.5H8C7.17157 19.5 6.5 18.8284 6.5 18Z"
								fill="#94A3B8"
							/>
						</svg>

						<div class="text-4xl font-bold text-neutral-700 ml-1.5">
							Bộ lọc
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							type="button"
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								class="h-6 w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div class="mt-6 relative flex-1 px-4 sm:px-6">
				<div>
					<div
						v-if="isGroupClass"
						class="search-input w-ful relative"
					>
						<div class="input-group__label">Lọc theo lớp nhóm</div>
						<div class="mt-4 bg-white select-tabs w-full">
							<Multiselect
								v-model="groupClassSelected"
								deselect-label="Đã chọn"
								track-by="id"
								:options="
									groupClasses?.map((s) => {
										return {
											nameSearch: toEnglish(s?.name),
											...s,
										};
									})
								"
								:searchable="true"
								label="nameSearch"
								:allow-empty="false"
								selectLabel=""
								placeholder="Tên lớp nhóm"
							>
								<template #noResult>
									Không tìm thấy kết quả
								</template>
								<template v-slot:option="props">
									<div class="flex flex-nowrap items-center">
										{{ props.option.name }}
									</div>
								</template>
								<template v-slot:singleLabel="{ option }">
									<div
										class="
											flex flex-nowrap
											items-center
											font-medium
										"
									>
										{{ option.name }}
									</div>
								</template>
							</Multiselect>
						</div>
					</div>
					<div v-else class="search-input w-ful relative">
						<div class="input-group__label">Lọc theo học sinh</div>
						<div class="mt-4 bg-white select-tabs w-full">
							<Multiselect
								v-model="studentSelected"
								deselect-label="Đã chọn"
								track-by="id"
								:options="
									students?.map((s) => {
										return {
											nameSearch: toEnglish(s?.name),
											...s,
										};
									})
								"
								:searchable="true"
								label="nameSearch"
								:allow-empty="false"
								selectLabel=""
								placeholder="Tên học sinh"
							>
								<template #noResult>
									Không tìm thấy kết quả
								</template>
								<template v-slot:option="props">
									<div class="flex flex-nowrap items-center">
										{{ props.option.name }}
									</div>
								</template>
								<template v-slot:singleLabel="{ option }">
									<div
										class="
											flex flex-nowrap
											items-center
											font-medium
										"
									>
										{{ option.name }}
									</div>
								</template>
							</Multiselect>
						</div>
					</div>
					<div class="search-input w-ful mt-10">
						<div class="input-group__label">Lọc theo tháng</div>
						<div class="input-group">
							<VueDatepicker
								class="input-date w-full"
								placeholder="Chọn tháng"
								uid="filter-by-date"
								v-model="month_start"
								auto-apply
								month-picker
							>
								<template #input-icon>
									<svg
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M5 0.625C5 0.279822 4.72018 0 4.375 0C4.02982 0 3.75 0.279822 3.75 0.625V1.25H2.5C1.11929 1.25 0 2.36929 0 3.75V5H20V3.75C20 2.36929 18.8807 1.25 17.5 1.25H16.25V0.625C16.25 0.279822 15.9702 0 15.625 0C15.2798 0 15 0.279822 15 0.625V1.25H5V0.625ZM20 17.5V6.25H0V17.5C0 18.8807 1.11929 20 2.5 20H17.5C18.8807 20 20 18.8807 20 17.5ZM11.875 8.75C11.5298 8.75 11.25 9.02982 11.25 9.375V10.625C11.25 10.9702 11.5298 11.25 11.875 11.25H13.125C13.4702 11.25 13.75 10.9702 13.75 10.625V9.375C13.75 9.02982 13.4702 8.75 13.125 8.75H11.875ZM15.625 8.75C15.2798 8.75 15 9.02982 15 9.375V10.625C15 10.9702 15.2798 11.25 15.625 11.25H16.875C17.2202 11.25 17.5 10.9702 17.5 10.625V9.375C17.5 9.02982 17.2202 8.75 16.875 8.75H15.625ZM2.5 13.125C2.5 12.7798 2.77982 12.5 3.125 12.5H4.375C4.72018 12.5 5 12.7798 5 13.125V14.375C5 14.7202 4.72018 15 4.375 15H3.125C2.77982 15 2.5 14.7202 2.5 14.375V13.125ZM6.875 12.5C6.52982 12.5 6.25 12.7798 6.25 13.125V14.375C6.25 14.7202 6.52982 15 6.875 15H8.125C8.47018 15 8.75 14.7202 8.75 14.375V13.125C8.75 12.7798 8.47018 12.5 8.125 12.5H6.875Z"
											fill="#94A3B8"
										/>
									</svg>
								</template>
							</VueDatepicker>
						</div>
					</div>
					<div class="search-input w-ful mt-10">
						<div class="input-group__label">
							Lọc theo trạng thái báo cáo
						</div>
						<div class="input-group">
							<select
								class="
									block
									pr-10
									py-2
									text-base
									border-gray-300
									focus:outline-none
									focus:ring-indigo-500
									focus:border-indigo-500
									sm:text-sm
									rounded-md
									input
									w-full
								"
								v-model="state"
							>
								<option value="">Tất cả</option>
								<option value="completed">Đã hoàn thành</option>
								<option value="incompleted">
									Chưa hoàn thành
								</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<div class="px-6 py-4">
				<div class="flex justify-end">
					<div>
						<button
							type="button"
							class="
								button-p
								text-center
								w-20
								bg-none
								mr-4
								shadow-none
							"
							@click="cancel"
						>
							Hủy
						</button>
					</div>
					<div>
						<button
							type="button"
							class="button-p text-center w-32"
							@click="submit"
						>
							Áp dụng bộ lọc
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, ref, watch } from 'vue';
import Multiselect from '@suadelabs/vue3-multiselect';
import { toEnglish } from '@/helper/toEnglish';
import { useRoute } from 'vue-router';
import dayjs from 'dayjs';

export default {
	name: 'SlideOverPlans',
	components: { Multiselect },
	props: {
		students: [],
		groupClasses: [],
		isOpen: {
			type: Boolean,
			default: false,
		},
		isGroupClass: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, { emit }) {
		const route = useRoute();
		const state = ref('');
		const _students = ref([]);
		const _groupClasses = ref([]);
		const studentSelected = ref(null);
		const groupClassSelected = ref(null);
		const month_start = ref(null);

		let filter = reactive({
			state: null,
		});

		watch(
			() => props.isOpen,
			(value) => {
				if (value) {
					state.value = route?.query?.state || '';
					month_start.value = route?.query?.month_start
						? {
								month: dayjs(route?.query?.month_start).month(),
								year: dayjs(route?.query?.month_start).year(),
								// eslint-disable-next-line no-mixed-spaces-and-tabs
						  }
						: null;
				}
			},
			{
				immediate: true,
				deep: true,
			}
		);

		watch(
			() => props.students,
			(value) => {
				if (value) {
					_students.value = value;
					studentSelected.value = value.find(
						(s) => +s.id === +route?.query?.student_id
					);
				}
			},
			{
				immediate: true,
				deep: true,
			}
		);

		watch(
			() => props.groupClasses,
			(value) => {
				if (value) {
					_groupClasses.value = value;
					groupClassSelected.value = value.find(
						(s) => +s.id === +route?.query?.group_class_id
					);
				}
			},
			{
				immediate: true,
				deep: true,
			}
		);

		function selected(id) {
			studentSelected.value = id;
		}

		function submit() {
			let stateData = null;
			if (state.value) {
				stateData = state.value;
			}
			filter = {
				state: stateData,
				student_id: studentSelected.value?.id,
				group_class_id: groupClassSelected.value?.id,
				month_start: this.month_start
					? dayjs(
							new Date(
								this.month_start.year,
								this.month_start.month,
								1
							)
							// eslint-disable-next-line no-mixed-spaces-and-tabs
					  ).format('YYYY-MM-DD')
					: null,
			};
			emit('submit', filter);
			emit('isFilterDate', true);
		}

		function cancel() {
			state.value = '';
			studentSelected.value = null;
			groupClassSelected.value = null;
			filter = {
				state: null,
				student_id: null,
				group_class_id: null,
				month_start: null,
			};
			emit('cancel', null);
		}

		return {
			state,
			studentSelected,
			groupClassSelected,
			month_start,
			selected,
			submit,
			cancel,
			toEnglish,
		};
	},
};
</script>

<style scoped lang="scss">
.da-dropdown {
	@apply mt-2 rounded-md bg-white w-full z-20 shadow-input py-5 pl-2;
	position: absolute;

	&__wrapper {
		@apply overflow-y-auto w-full;
		height: fit-content;
		max-height: 150px;
	}

	&-item {
		@apply pl-3 py-2 cursor-pointer;
		border-radius: 5px;

		&:hover {
			@apply bg-yellow-100 text-yellow-700;
		}
	}

	.active {
		@apply bg-yellow-100 text-yellow-700;
	}
}
</style>
