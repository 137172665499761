<template>
	<div class="fixed inset-y-0 right-0 flex side-over z-50 w-screen md:w-2/5">
		<div class="h-full w-full flex flex-col bg-white shadow-xl">
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div v-if="!data" class="flex items-center">
						<svg
							fill="none"
							height="24"
							viewBox="0 0 24 24"
							width="24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clip-rule="evenodd"
								d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM12.75 6.75C12.75 6.33579 12.4142 6 12 6C11.5858 6 11.25 6.33579 11.25 6.75V11.25H6.75C6.33579 11.25 6 11.5858 6 12C6 12.4142 6.33579 12.75 6.75 12.75H11.25V17.25C11.25 17.6642 11.5858 18 12 18C12.4142 18 12.75 17.6642 12.75 17.25V12.75H17.25C17.6642 12.75 18 12.4142 18 12C18 11.5858 17.6642 11.25 17.25 11.25H12.75V6.75Z"
								fill="#94A3B8"
								fill-rule="evenodd"
							/>
						</svg>
						<div class="text-2xl font-bold text-neutral-700 ml-2">
							Thêm buổi học
						</div>
					</div>
					<div v-else class="flex items-center">
						<svg
							fill="none"
							height="24"
							viewBox="0 0 24 24"
							width="24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clip-rule="evenodd"
								d="M13.6308 5.68726L15.8838 3.43422C16.7496 2.56844 17.1825 2.13555 17.6895 1.99477C18.0396 1.89758 18.4095 1.89758 18.7596 1.99477C19.2667 2.13555 19.6996 2.56844 20.5653 3.43423C21.4311 4.30001 21.864 4.7329 22.0048 5.23996C22.102 5.59003 22.102 5.95997 22.0048 6.31005C21.864 6.81711 21.4311 7.25 20.5653 8.11578L18.3384 10.3427C16.3859 9.22291 14.7651 7.6144 13.6308 5.68726ZM12.1764 7.14163L3.8659 15.4521C3.44084 15.8772 3.22831 16.0897 3.08857 16.3508C2.94884 16.6119 2.88989 16.9066 2.772 17.4961L1.98622 21.425C1.91969 21.7576 1.88643 21.9239 1.98104 22.0185C2.07564 22.1131 2.24194 22.0799 2.57452 22.0134L2.57455 22.0134L2.57456 22.0134L6.50349 21.2276L6.50351 21.2276C7.09295 21.1097 7.38767 21.0507 7.64876 20.911C7.90986 20.7713 8.12239 20.5587 8.54743 20.1337L8.54745 20.1337L16.8803 11.8008C14.9786 10.6167 13.3722 9.02142 12.1764 7.14163Z"
								fill="#94A3B8"
								fill-rule="evenodd"
							/>
						</svg>

						<div class="text-2xl font-bold text-neutral-700 ml-2">
							Chỉnh sửa buổi học
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							type="button"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								aria-hidden="true"
								class="h-6 w-6"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M6 18L18 6M6 6l12 12"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div
				class="
					sideover-content
					pt-6
					relative
					flex-1
					px-4
					sm:px-6
					overflow-y-auto
				"
			>
				<div>
					<div class="input-group w-full">
						<div
							class="
								input-group__label
								w-full
								font-semibold
								text-neutral-600 text-base
							"
						>
							Giờ bắt đầu
						</div>
						<VueDatepicker
							v-model="startTime"
							autoApply
							class="input-date w-full mt-2"
							minutesIncrement="30"
							placeholder="Giờ bắt đầu"
							timePicker
							uid="start_opening_hour"
						>
							<template #input-icon>
								<DaIcon
									class="h-4 w-4 text-neutral-400"
									name="das--lock"
								/>
							</template>
						</VueDatepicker>
					</div>
					<div class="input-group w-full mt-10">
						<div
							class="
								input-group__label
								w-full
								font-semibold
								text-neutral-600 text-base
							"
						>
							Giờ kết thúc
						</div>
						<VueDatepicker
							v-model="endTime"
							autoApply
							class="input-date w-full mt-2"
							minutesIncrement="30"
							placeholder="Giờ kết thúc"
							timePicker
							uid="end_opening_hour"
						>
							<template #input-icon>
								<DaIcon
									class="h-4 w-4 text-neutral-400"
									name="das--lock"
								/>
							</template>
						</VueDatepicker>
					</div>
					<div
						v-if="!data"
						class="font-semibold text-neutral-600 mt-10"
					>
						Áp dụng buổi học này cho*
					</div>
					<div v-if="!data" class="flex items-center gap-4 mt-3">
						<div
							v-for="(d, index) in daysApply"
							:key="index"
							:class="{
								'pointer-events-none font-medium':
									day === index + 1,
							}"
							class="flex items-center gap-2 text-neutral-600"
						>
							<Checkbox
								v-model:value="daysApply[index]"
								:color="
									day === index + 1 ? 'text-neutral-400' : ''
								"
							/>
							<span v-if="index !== 6">T{{ index + 2 }}</span>
							<span v-else>Chủ nhật</span>
						</div>
					</div>
					<div class="font-semibold text-neutral-600 mt-10">
						Chọn trị liệu viên*
					</div>
					<div v-for="(staff, index) in staffSelected" :key="index">
						<div class="w-full mt-2">
							<div class="w-full mt-2">
								<Multiselect
									v-model="staffSelected[index]"
									:allow-empty="false"
									:maxHeight="140"
									:options="
										staffs.filter(
											(s) => !staffSelected.includes(s)
										)
									"
									:searchable="true"
									deselect-label="Đã chọn"
									label="nameSearch"
									placeholder="Tìm theo tên TLV"
									selectLabel=""
									track-by="id"
								>
									<template #noResult>
										Không tìm thấy kết quả
									</template>
									<template v-slot:option="props">
										<div
											class="
												flex flex-nowrap
												items-center
											"
										>
											<div>
												{{ props.option.name }}
											</div>
										</div>
									</template>
									<template v-slot:singleLabel="{ option }">
										<div
											class="
												flex flex-nowrap
												items-center
												font-medium
											"
										>
											<div>{{ option.name }}</div>
										</div>
									</template>
								</Multiselect>
							</div>
						</div>
						<div
							v-if="staffSelected.length > 1"
							class="
								text-yellow-700
								font-semibold
								mt-2
								text-sm
								cursor-pointer
								text-right
								hover:text-yellow-800
							"
							@click="removeInputFieldStaff(index)"
						>
							Xoá bỏ
						</div>
					</div>
					<div
						v-if="staffSelected.length <= 2"
						class="
							text-yellow-700
							font-semibold
							mt-2
							text-sm
							cursor-pointer
							text-right
							hover:text-yellow-800
						"
						@click="addInputFieldStaff"
					>
						Thêm trị liệu viên
					</div>
				</div>
			</div>
			<div class="px-6 py-4 border-t border-yellow-50">
				<div class="flex justify-end">
					<div>
						<button
							:disabled="!isSubmit"
							class="button-p text-center"
							type="button"
							@click="submit"
						>
							<svg
								v-if="!data"
								fill="none"
								height="16"
								viewBox="0 0 16 16"
								width="16"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									clip-rule="evenodd"
									d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
									fill="currentColor"
									fill-rule="evenodd"
								/>
							</svg>

							<span class="ml-2">{{
								!!data ? 'Áp dụng' : 'Khởi tạo buổi học'
							}}</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import DaIcon from '@/components/elements/daIcon/DaIcon';
import { $api } from '@/services';
import Multiselect from '@suadelabs/vue3-multiselect';
import Checkbox from '@/components/elements/checkbox/Checkbox';
import dayjs from 'dayjs';

export default defineComponent({
	name: 'CreateTimeSlotStudent',

	components: { Checkbox, DaIcon, Multiselect },

	props: {
		open: {
			type: Boolean,
			required: true,
		},

		day: {
			type: Number,
			required: true,
		},

		data: {
			type: Object,
			required: true,
		},

		dataCreate: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			staffs: [],
			staffSelected: [null],
			startTime: null,
			endTime: null,
			daysApply: [false, false, false, false, false, false, false],
		};
	},

	created() {
		this.fetchAssignment();
	},

	computed: {
		isSubmit() {
			return (
				this.startTime &&
				this.endTime &&
				this.staffSelected.every((item) => !!item)
			);
		},

		studentId() {
			return this.$route.params.id;
		},

		applyDate() {
			return dayjs(this.dataCreate.activated_date).isBefore(
				dayjs(this.$route.query.apply_date)
			)
				? this.$route.query.apply_date
				: this.dataCreate.activated_date;
		},

		isAdmin() {
			return this.$store.getters.isAdmin;
		},

		daysOfWeek() {
			return this.daysApply
				.map((d, index) => {
					return d ? index + 1 : undefined;
				})
				.filter((d) => !!d);
		},
	},

	watch: {
		data: {
			immediate: true,
			deep: true,
			handler(value) {
				if (value) {
					this.staffSelected = this.staffs.filter((s) =>
						value.staff_ids.includes(s.id)
					);

					this.startTime = {
						hours: Number(value.display_start_slot.split(':')[0]),
						minutes: Number(value.display_start_slot.split(':')[1]),
					};

					this.endTime = {
						hours: Number(value.display_end_slot.split(':')[0]),
						minutes: Number(value.display_end_slot.split(':')[1]),
					};
				} else {
					this.startTime = this.initStartTime();
					this.endTime = {
						hours: this.startTime.hours + 1,
						minutes: this.startTime.minutes,
					};
				}
			},
		},

		startTime(value) {
			if (!value) {
				this.startTime = this.initStartTime();
			} else {
				this.endTime = {
					hours: value.hours + 1,
					minutes: value.minutes,
				};
			}
		},

		endTime(value) {
			if (!value) {
				this.endTime = {
					hours: this.startTime.hours + 1,
					minutes: this.startTime.minutes,
				};
			}
		},

		open: {
			immediate: true,
			handler() {
				this.staffSelected = [null];
				this.daysApply = [
					false,
					false,
					false,
					false,
					false,
					false,
					false,
				];
				this.daysApply[this.day - 1] = true;
			},
		},
	},

	methods: {
		initStartTime() {
			const time = new Date();
			let minutes = time.getMinutes() / 5;
			return {
				hours: time.getHours(),
				minutes: Math.ceil(minutes) * 5,
			};
		},

		addInputFieldStaff() {
			if (this.staffSelected.length <= 2) {
				this.staffSelected.push(null);
			}
		},

		removeInputFieldStaff(index) {
			if (index < this.staffSelected.length) {
				this.staffSelected.splice(index, 1);
			}
		},
		async submit() {
			try {
				const time_slot = {
					day_of_week:
						this.daysOfWeek.length && !this.data
							? undefined
							: this.day || 1,
					deactivated_date: null,
					activated_date: this.applyDate,
					end_slot: this.getTimePayload(
						this.endTime.hours || null,
						this.endTime.minutes || null
					),
					staff_ids: this.staffSelected
						? this.staffSelected.map((item) => item.id)
						: null,
					start_slot: this.getTimePayload(
						this.startTime.hours || null,
						this.startTime.minutes || null
					),
					student_timetable_id: this.dataCreate.student_timetable_id,
				};
				if (this.data) {
					await this.$api.schedule.updateTimeSlot(this.data.id, {
						day_of_weeks: this.daysOfWeek,
						time_slot,
					});
				} else {
					await this.$api.schedule.createTimeSlot(
						{
							day_of_weeks: this.daysOfWeek,
							time_slot,
						},
						this.daysOfWeek.length
					);
				}
				await this.$store.dispatch('setAlertMessage', {
					message: this.data
						? 'Cập nhật buổi học thành công'
						: 'Tạo thành công buổi học',
				});
				this.$emit('success');
			} catch (e) {
				await this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},

		async fetchAssignment() {
			try {
				const { data } = await $api.assignment.getAssignments(
					this.studentId,
					this.isAdmin
				);
				this.staffs = data?.data.map((item) => item.staff) || [];
			} catch (err) {
				await this.$store.dispatch('setAlertMessage', {
					message:
						err?.response?.data?.errors?.join(<br />) ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.isLoading = false;
			}
		},

		getTimePayload(hour = 0, minutes = 0) {
			return hour * 60 + minutes;
		},
	},
});
</script>
