
import { Options, Vue } from 'vue-class-component';
import { PropSync } from 'vue-property-decorator';

@Options({
	name: 'ConfirmModal',
})
export default class ConfirmModal extends Vue {
	//----------------------- 🤍 Props 🤍 -----------------------//
	@PropSync('success', { default: false, type: Boolean })
	successData!: boolean;
	@PropSync('data', {
		default: {
			student: '',
			week_start: '',
			report_date: '',
			staff: '',
		},
		type: Object,
	})
	reportData!: {
		student: '';
		week_start: '';
		report_date: '';
		staff: '';
	};

	//----------------------- 🤍 Methods 🤍 -----------------------//
	submit() {
		this.$emit('submit');
	}
}
