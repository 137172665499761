
import { Prop, PropSync, Watch } from 'vue-property-decorator';
import { Vue } from 'vue-class-component';

export default class TabTarget extends Vue {
	@Prop() skills!: [];
	@PropSync('goalSelected') selected!: any;

	_skills = [] as any;
	_selected = null as any;
	subIsShow = -1;

	@Watch('skills')
	onChange(value: any) {
		this._skills = value;
	}

	@Watch('selected', { immediate: true })
	onSelectedChange(value: any) {
		this._selected = value || this._skills[0]?.goals[0];
	}

	isChildSelected(s: any) {
		return s.goals.find(
			(item: any) => item.goal_id === this._selected?.goal_id
		);
	}

	show(index: number) {
		if (this.subIsShow === index) {
			this.subIsShow = -1;
		} else {
			this.subIsShow = index;
		}
	}

	countComplete(s: any) {
		let count = 0;
		s.goals.forEach((goal: any) => {
			if (goal.completed) {
				count++;
			}
		});
		return count;
	}

	submit(g: any, s: number, index: number) {
		this.$emit('selected', {
			goal: g,
			skill_index: s,
			goal_index: index,
		});
	}
}
