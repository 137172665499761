
import { computed, defineComponent, ref } from 'vue';
import { useStudent } from '@/features/student/useStudent';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail.vue';
import Loading from '@/components/common/Loading.vue';

export default defineComponent({
	name: 'ReportTarget',
	components: { Loading, TooltipGoalDetail },
	setup() {
		const { goalCompletedByStudent } = useStudent();
		const route = useRoute();
		const store = useStore();
		const goals = ref([]);
		const skill_count = ref(0);
		const goal_count = ref(0);

		const id = computed(() =>
			route.params?.id ? route.params?.id + '' : ''
		);

		const isLoading = ref(true);

		const oldPayload = ref({
			'x-cross': route.query.org_id,
		});

		const is_old_data = ref(route.query.is_old_data === 'true');

		async function fetchGoals() {
			const res = await goalCompletedByStudent(
				id.value,
				is_old_data.value ? oldPayload.value : null
			);
			if (res?.data) {
				goals.value = res?.data?.skills ?? [];
				skill_count.value = res?.data.length;
				goals.value.forEach((item: any) => {
					goal_count.value += item?.goals.length || 0;
				});
			}
			isLoading.value = false;
		}

		fetchGoals();

		return {
			id,
			goal_count,
			skill_count,
			isAdmin: computed(() => store.getters.isAdmin),
			goals,
			org: computed(() => store.state.profile.current_org),
			isLoading,
		};
	},
});
