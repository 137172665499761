
import { computed, ref, defineComponent, onBeforeUnmount, watch } from 'vue';
import { useStudent } from '@/features/student/useStudent';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { $api } from '@/services';
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail.vue';
export default defineComponent({
	name: 'GoalByStop',
	components: { TooltipGoalDetail },
	setup(_, { emit }) {
		const { goalCompletedByStudent } = useStudent();
		const route = useRoute();
		const store = useStore();
		const goals = ref([]);
		const skill_count = ref(0);
		const goal_count = ref(0);
		const alertShow = ref(true);

		const id = computed(() =>
			route.params?.id ? route.params?.id + '' : ''
		);
		const plan_id = computed(() =>
			route.params?.plan_id ? String(route.params?.plan_id) : ''
		);

		async function fetchGoals() {
			const res = await goalCompletedByStudent(id.value);
			if (res?.data) {
				goals.value = res?.data?.skills ?? [];
				skill_count.value = res?.data.length;
				goal_count.value = 0;
				goals.value.forEach((item: any) => {
					goal_count.value += item?.goals.length || 0;
				});
			}
		}

		async function doGoal(goal_id: string) {
			try {
				await $api.goal.doGoalCompleted(id.value, plan_id.value, {
					goal_id,
				});
				await store.dispatch('addGoal', goal_id);
				emit('updated');
			} catch (e) {
				await store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		}

		fetchGoals();

		const reload = computed(() => store.getters.reload);

		watch(reload, () => {
			fetchGoals();
		});

		onBeforeUnmount(() => {
			store.dispatch('clearAdded');
		});

		return {
			id,
			goal_added: computed(() => store.getters.goalAdded),
			goal_count,
			skill_count,
			isAdmin: computed(() => store.getters.isAdmin),
			goals,
			doGoal,
			org: computed(() => store.state.profile.current_org),
			alertShow,
		};
	},
});
