
import { Options, setup, Vue } from 'vue-class-component';
import { useLogin } from './services/login';
import { _var } from '@/enums/Common';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { _ROUTER_NAME } from '@/enums';
import ChooseCenterCard from '@/components/elements/cards/chooseCenterCard/ChooseCenterCard.vue';

@Options({
	name: 'Login',
	components: { ChooseCenterCard, DaIcon },
})
export default class Login extends Vue {
	//----------------------- 🤍 Data 🤍 -----------------------//
	chooseCenterStage = false;
	errorLogin = '';
	orgs = [];
	orgList = [];
	loginData = {
		username: '',
		password: '',
	};
	typePassword = 'password';
	typing: any = null;
	searchQuery = '';

	//----------------------- 🤍 Setup 🤍 -----------------------//
	setup = setup(() => {
		const { error, loading, login } = useLogin();
		return { error, loading, login };
	});

	//**------------------------------------------ Getters ------------------------------------------**\\
	get routerName() {
		return _ROUTER_NAME;
	}

	get Error() {
		let error: any = this.setup.error;
		if (this.errorLogin) {
			error = this.errorLogin;
		}
		if (error && typeof error == 'object') {
			return error.join('<br/>');
		}
		return error;
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	async onSubmit() {
		try {
			this.errorLogin = '';
			const data = await this.setup.login({
				email: this.loginData.username,
				password: this.loginData.password,
			});

			if (data && data?.data) {
				localStorage.setItem(_var.TOKEN, data?.data?.access_token);
				if (data?.data?.orgs) {
					if (data?.data?.orgs.length === 1) {
						localStorage.setItem(
							_var.SUBDOMAIN,
							data?.data?.orgs[0].subdomain
						);
						return (window.location.href = '/');
					} else if (data?.data?.orgs.length <= 0) {
						return (this.errorLogin =
							'Bạn không có trung tâm nào trong danh sách');
					} else {
						this.chooseCenterStage = true;
						this.orgs = data?.data?.orgs;
						this.orgList = data?.data?.orgs;
					}
				}
			}
		} catch (e) {
			console.log(e);
		}
	}

	selectOrg(org: any) {
		localStorage.setItem(_var.SUBDOMAIN, org.subdomain);
		return (window.location.href = '/');
	}

	searching() {
		clearInterval(this.typing);
		this.typing = setTimeout(() => {
			this.search();
		}, 0);
	}

	async search() {
		this.orgList = this.orgs.filter((s: any) => {
			return (
				this.formatTextSearch(s.address).includes(
					this.formatTextSearch(this.searchQuery)
				) ||
				this.formatTextSearch(s.name).includes(
					this.formatTextSearch(this.searchQuery)
				) ||
				this.formatTextSearch(s.subdomain).includes(
					this.formatTextSearch(this.searchQuery)
				)
			);
		});
	}

	formatTextSearch(text: string) {
		if (!text) {
			return '';
		}
		return text.toLowerCase();
	}
}
