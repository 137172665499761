import html2pdf from 'html2pdf.js';

export const generateReport = async ({
	fileName,
	orgName,
	studentName,
	email,
	code,
	date,
}) => {
	const section = document.createElement('div');
	section.setAttribute('ref', 'document');
	section.innerHTML = `<div class="text-neutral-500 p-10 bg-white rounded-2xl shadow-sm">
                            <span class="font-bold">Trung tâm ${orgName}</span> kính mời <span class="font-bold">Phụ huynh</span> bé
                            <span class="font-bold">${studentName}</span> cài đặt
                            Ứng dụng di động cho phụ huynh theo hướng dẫn sau:
                            <ul class="mt-4 pl-4 list-disc">
                            <li>
                            <span class="font-bold">Bước 1:</span> Tải app tại đường dẫn
                            (mở trên điện thoại di động)
                            <a href="http://onelink.to/lucaedu" class="font-bold text-sienna-500"
                            >http://onelink.to/lucaedu</a
                            >
                            </li>
                            <li class="mt-4">
                            <span class="font-bold">Bước 2:</span> Đăng ký tài khoản với
                            email sau
                            <span class="font-bold text-sienna-500"
                            >${email}</span
                            >
                            </li>
                            <li class="mt-4">
                            <span class="font-bold">Bước 3:</span> Chọn “Hồ sơ -> Thêm
                            hồ sơ của bé”
                            </li>
                            <li class="mt-4">
                            <span class="font-bold">Bước 4:</span> Nhập mã
                            <span class="font-bold text-sienna-500">${code}</span>
                            </li>
                            </ul>
                            <div class="uppercase font-medium mt-4 text-sm">
                            Lưu ý: Mã kích hoạt sẽ hết hạn vào
                            <span class="text-sienna-500">${date}</span> và chỉ
                            sử dụng được
                            <span class="text-sienna-500 text-sienna-500"
                            >1 lần duy nhất</span
                            >, Phụ huynh vui lòng không chia sẻ cho ai khác.
                            </div>
                            <div class="flex items-center pt-4 border-t border-yellow-50 mt-10">
                            <div>
                            <img
                            width="60px"
                            src="/img/logo/luca.svg"
                            alt=""
                            />
                            </div>
                            <div class="text-green-500 font-bold text-center w-full flex justify-center">
                            "Cha mẹ đồng hành, thầy cô kham nhẫn, trẻ thơ hạnh phúc"
                            </div>
                            </div>
                            </div>`;
	await html2pdf(section, {
		margin: 0,
		filename: fileName,
		image: { type: 'jpeg', quality: 2 },
		html2canvas: { dpi: 192, letterRendering: true },
		jsPDF: {
			unit: 'in',
			format: 'letter',
			orientation: 'landscape',
		},
	});
};
