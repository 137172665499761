<template>
	<div class="fixed inset-y-0 right-0 flex side-over z-50 w-screen md:w-2/5">
		<div class="h-full w-full flex flex-col bg-white shadow-xl">
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center" @click="$emit('close')">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM12.75 6.75C12.75 6.33579 12.4142 6 12 6C11.5858 6 11.25 6.33579 11.25 6.75V11.25H6.75C6.33579 11.25 6 11.5858 6 12C6 12.4142 6.33579 12.75 6.75 12.75H11.25V17.25C11.25 17.6642 11.5858 18 12 18C12.4142 18 12.75 17.6642 12.75 17.25V12.75H17.25C17.6642 12.75 18 12.4142 18 12C18 11.5858 17.6642 11.25 17.25 11.25H12.75V6.75Z"
								fill="#94A3B8"
							/>
						</svg>
						<div
							class="
								text-2xl
								font-bold
								text-neutral-700
								ml-2
								mt-1
							"
						>
							Tạo buổi học phát sinh
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							type="button"
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								class="h-6 w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div
				class="
					sideover-content
					pt-6
					relative
					flex-1
					px-4
					sm:px-6
					overflow-y-auto
				"
			>
				<div>
					<div>
						<div class="font-semibold text-neutral-600 mt-10">
							Chọn ngày*
						</div>
						<div class="w-full mt-2">
							<div class="input-group flex items-center">
								<VueDatepicker
									v-model="date"
									:enableTimePicker="false"
									class="input-date w-full"
									placeholder="Chọn ngày áp dụng"
									format="dd/MM/yyyy"
									autoApply
								>
									<template #input-icon>
										<DaIcon
											name="das--lock"
											class="h-4 w-4 text-neutral-400"
										/>
									</template>
								</VueDatepicker>
							</div>
						</div>
					</div>
					<div>
						<div class="font-semibold text-neutral-600 mt-10">
							Chọn lớp*
						</div>
						<div class="w-full mt-2">
							<div class="w-full mt-2">
								<Multiselect
									v-model="daycareSelected"
									deselect-label="Đã chọn"
									track-by="id"
									:options="
										daycares?.map((s) => {
											return {
												nameSearch: toEnglish(s?.name),
												...s,
											};
										})
									"
									:searchable="true"
									label="nameSearch"
									:allow-empty="false"
									selectLabel=""
									placeholder="Tìm theo tên lớp"
									:maxHeight="140"
								>
									<template #noResult>
										Không tìm thấy kết quả
									</template>
									<template v-slot:option="props">
										<div
											class="
												flex flex-nowrap
												items-center
											"
										>
											<div>
												{{ props.option.name }}
											</div>
										</div>
									</template>
									<template v-slot:singleLabel="{ option }">
										<div
											class="
												flex flex-nowrap
												items-center
												font-medium
											"
										>
											<div>{{ option.name }}</div>
										</div>
									</template>
								</Multiselect>
							</div>
						</div>
					</div>
					<div>
						<div class="font-semibold text-neutral-600 mt-10">
							Chọn học sinh*
						</div>
						<div class="w-full mt-2">
							<Multiselect
								v-model="studentSelected"
								deselect-label="Đã chọn"
								:disabled="!daycareSelected"
								track-by="id"
								:options="
									students?.map((s) => {
										return {
											nameSearch: toEnglish(s?.name),
											...s,
										};
									})
								"
								:searchable="true"
								label="nameSearch"
								:allow-empty="false"
								selectLabel=""
								placeholder="Tìm theo tên học sinh"
								:maxHeight="140"
							>
								<template #noResult>
									Không tìm thấy kết quả
								</template>
								<template v-slot:option="props">
									<div class="flex flex-nowrap items-center">
										<div>
											{{ props.option.name }}
										</div>
									</div>
								</template>
								<template v-slot:singleLabel="{ option }">
									<div
										class="
											flex flex-nowrap
											items-center
											font-medium
										"
									>
										<div>{{ option.name }}</div>
									</div>
								</template>
							</Multiselect>
						</div>
					</div>
					<div>
						<div class="font-semibold text-neutral-600 mt-10">
							Ghi chú*
						</div>
						<div class="w-full mt-2">
							<div class="input-group flex items-center">
								<textarea
									v-model="note"
									placeholder="Ghi chú về sự thay đổi này"
								>
								</textarea>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="px-6 py-4 border-t border-yellow-50">
				<div class="flex justify-end">
					<div>
						<button
							type="button"
							class="button-p text-center px-6"
							@click="submit"
							:disabled="!isValidate || inProgress"
						>
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
									fill="currentColor"
								/>
							</svg>

							<span class="ml-2">Khởi tạo buổi học</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import DaIcon from '@/components/elements/daIcon/DaIcon';
import { $api } from '@/services';
import Multiselect from '@suadelabs/vue3-multiselect';
import dayjs from 'dayjs';
import { toEnglish } from '@/helper/toEnglish';

export default defineComponent({
	name: 'CreateUnScheduleSession',

	components: { DaIcon, Multiselect },

	data() {
		return {
			students: [],
			daycares: [],
			date: new Date(),
			studentSelected: null,
			daycareSelected: null,
			note: '',
			inProgress: false,
		};
	},

	created() {
		this.fetchDaycare();
	},

	computed: {
		isValidate() {
			return !(
				!this.note.length ||
				!this.studentSelected ||
				!this.daycareSelected
			);
		},

		isAdminAndAccountant() {
			return this.$store.getters.isAdminAndAccountant;
		},
	},

	watch: {
		daycareSelected() {
			this.fetchStudentsByDaycare();
		},
	},

	methods: {
		toEnglish,
		resetForm() {
			this.studentSelected = null;
			this.daycareSelected = null;
			this.note = '';
			this.date = new Date();
		},
		async submit() {
			this.inProgress = true;
			try {
				const payload = {
					date: this.date
						? dayjs(this.date).format('YYYY-MM-DD')
						: null,
					student_id: this.studentSelected
						? this.studentSelected.id
						: null,
					note: this.note,
					day_care_class_id: this.daycareSelected?.id || null,
				};

				const { data } = await $api.schedule.createUnScheduleDaycare(
					payload
				);
				await this.$store.dispatch('setAlertMessage', {
					message: 'Tạo thành công buổi học phát sinh',
				});
				this.resetForm();
				this.$emit('success', data);
			} catch (e) {
				await this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.inProgress = false;
			}
		},

		async fetchStudentsByDaycare() {
			try {
				const {
					data: { data },
				} = await $api.student.getStudentsByDaycare(
					this.daycareSelected?.id
				);
				this.students = data;
			} catch (err) {
				console.log(err);
			}
		},

		async fetchDaycare() {
			try {
				const {
					data: { data },
				} = await $api.boarding.getDayCareClasses(
					this.isAdminAndAccountant
				);
				this.daycares = data;
			} catch (err) {
				console.log(err);
			}
		},
	},
});
</script>
