<template>
	<div class="_create-boarding _modal bg-white">
		<div
			class="
				_modal__content
				md-down:px-4
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<svg
					width="60"
					height="60"
					viewBox="0 0 60 60"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M29.8034 3.38292C15.2228 3.38292 3.36674 15.3238 3.36674 30.001C3.36674 44.6762 15.2228 56.6171 29.8034 56.6171C44.3839 56.6171 56.2452 44.6762 56.2452 30.001C56.2452 15.3238 44.3839 3.38292 29.8034 3.38292ZM29.8034 60C13.3737 60 0 46.5418 0 30.001C0 13.4582 13.3737 0 29.8034 0C46.2382 0 59.6068 13.4582 59.6068 30.001C59.6068 46.5418 46.2382 60 29.8034 60Z"
						fill="#E37056"
					/>
					<path
						d="M52.5551 30.0008C52.5551 42.6491 42.3697 52.9023 29.8011 52.9023C17.2376 52.9023 7.05225 42.6491 7.05225 30.0008C7.05225 17.3515 17.2376 7.09775 29.8011 7.09775C42.3697 7.09775 52.5551 17.3515 52.5551 30.0008Z"
						fill="#F4C870"
					/>
					<path
						d="M29.7887 36.6177C29.0367 36.6177 28.3899 36.8826 27.8685 37.408C27.3522 37.9313 27.0864 38.5748 27.0864 39.3218C27.0864 40.1721 27.3672 40.8489 27.9237 41.3324C28.4601 41.8028 29.0968 42.041 29.8187 42.041C30.5306 42.041 31.1624 41.7993 31.6938 41.3228C32.2453 40.8323 32.521 40.159 32.521 39.3218C32.521 38.5733 32.2503 37.9278 31.7188 37.4045C31.1874 36.8811 30.5357 36.6177 29.7887 36.6177Z"
						fill="white"
					/>
					<path
						d="M27.7131 31.5493V31.5609C27.8084 32.5698 27.9738 33.3102 28.2045 33.8234C28.4902 34.4387 29.0166 34.7637 29.7285 34.7637C30.4254 34.7637 30.9518 34.4336 31.2576 33.8083C31.5183 33.2748 31.6788 32.5481 31.754 31.5892L32.4107 24.025C32.4859 23.3164 32.521 22.6093 32.521 21.924C32.521 20.7021 32.3606 19.7816 32.0347 19.1083C31.784 18.5845 31.2075 17.9612 29.909 17.9612C29.0718 17.9612 28.3799 18.2463 27.8585 18.8065C27.3471 19.3602 27.0864 20.1253 27.0864 21.0806C27.0864 21.711 27.1316 22.7577 27.2268 24.1951L27.7131 31.5493Z"
						fill="white"
					/>
				</svg>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
					text-center
				"
			>
				Tải tệp mẫu không thành công
			</div>
			<div class="text-center text-neutral-500 mt-2">
				Những học sinh sau chưa có trị liệu viên dạy can thiệp cá nhân,
				bạn hãy thêm trị liệu viên và tải lại tệp mẫu sau đó
			</div>
			<div class="w-full">
				<div
					class="
						shadow-sm
						sm:rounded-2xl
						overflow-hidden
						mt-10
						w-full
					"
				>
					<div class="__record__table overflow-x-auto scroll-bar">
						<div class="overflow-hidden sm:rounded-t-2xl">
							<table class="divide-y text-neutral-200 w-full">
								<thead class="bg-neutral-100">
									<tr>
										<th
											scope="col"
											class="
												px-6
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												w-12
												whitespace-nowrap
											"
										>
											Số học sinh chưa có trị liệu viên
										</th>
										<th
											scope="col"
											class="
												py-3
												text-xs text-right
												px-6
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
												w-full
											"
										>
											{{
												list_student_have_no_assigment.length
											}}
											Học sinh
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(
											student, i
										) in list_student_have_no_assigment"
										:key="i"
										:class="
											i % 2 === 0
												? 'bg-white'
												: 'bg-neutral-50'
										"
										class="text-neutral-700"
									>
										<td
											class="
												px-6
												py-4
												whitespace-nowrap
												text-neutral-700
												flex
											"
										>
											<div>
												<div class="flex items-start">
													<div>
														<img
															class="
																shadow-sm
																inline-block
																h-6
																w-6
																rounded-full
															"
															:src="
																student
																	?.profile_photo
																	?.w200
															"
															alt=""
															v-if="
																student
																	?.profile_photo
																	?.w200
															"
														/>
														<span
															v-else
															class="
																inline-flex
																items-center
																justify-center
																h-6
																w-6
																rounded-full
															"
															:style="{
																background:
																	student
																		?.profile_photo
																		?.default
																		?.color,
															}"
														>
															<span
																class="
																	font-medium
																	leading-none
																	text-white
																	text-xs
																"
																>{{
																	student
																		?.profile_photo
																		?.default
																		?.initial
																}}</span
															>
														</span>
													</div>
													<div
														class="
															ml-4
															text-orange-600
															font-semibold
															truncate
														"
													>
														{{ student.name }}
													</div>
												</div>
											</div>
										</td>
										<td></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				type="button"
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				@click="$emit('close')"
			>
				Đóng
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ErrorUploading',

	props: {
		list_student_have_no_assigment: {
			type: Array,
			required: true,
		},
	},
};
</script>
