<template>
	<Popper :arrow="true" :placement="placement" hover>
		<div class="cursor-pointer">
			<div class="flex gap-7">
				<div
					v-for="item in data.slice(0, 4)"
					:key="item?.id"
					class="ring-2 ring-white rounded-full cursor-pointer"
				>
					<div class="p-0.5 w-fit border rounded-full relative">
						<img
							v-if="student(item.student_id)?.profile_photo?.w200"
							:src="student(item.student_id)?.profile_photo?.w200"
							alt=""
							class="
								shadow-sm
								inline-block
								rounded-full
								h-10
								w-10
							"
							style="max-width: 40px"
						/>
						<span
							v-else
							:style="{
								background: student(item.student_id)
									?.profile_photo?.default?.color,
							}"
							class="
								inline-flex
								items-center
								justify-center
								rounded-full
								h-10
								w-10
							"
						>
							<span
								class="
									font-medium
									leading-none
									text-white text-xs
								"
								>{{
									student(item.student_id)?.profile_photo
										?.default?.initial
								}}</span
							>
						</span>
						<div
							v-if="item?.complete_percent >= 0"
							:style="{
								background: TAG_COLOR(item?.complete_percent),
							}"
							class="
								absolute
								transform
								-translate-x-1/2 -translate-y-1/2
								left-1/2
								p-0.5
								px-2.5
								pt-1
								font-semibold
								text-white
								rounded-full
								text-xs
							"
						>
							{{ !item?.raw_result ? item?.result : '0%' }}
						</div>
					</div>
				</div>
				<span
					v-if="data?.length > 4"
					class="
						inline-flex
						items-center
						justify-center
						rounded-full
						bg-neutral-400
						h-10
						w-10
					"
				>
					<span class="font-medium leading-none text-white"
						>+{{ data?.length - 4 }}</span
					>
				</span>
			</div>
		</div>
		<template #content>
			<div class="flex flex-wrap gap-7" style="max-width: 360px">
				<div
					v-for="item in [...data].reverse()"
					:key="item?.id"
					class="flex flex-nowrap gap-3 items-center"
				>
					<div class="p-0.5 w-fit border-2 rounded-full relative">
						<img
							v-if="student(item.student_id)?.profile_photo?.w200"
							:src="student(item.student_id)?.profile_photo?.w200"
							alt=""
							class="
								shadow-sm
								inline-block
								rounded-full
								h-10
								w-10
							"
							style="max-width: 40px"
						/>
						<span
							v-else
							:style="{
								background: student(item.student_id)
									?.profile_photo?.default?.color,
							}"
							class="
								inline-flex
								items-center
								justify-center
								rounded-full
								h-10
								w-10
							"
						>
							<span
								class="
									font-medium
									leading-none
									text-white text-xs
								"
								>{{
									student(item.student_id)?.profile_photo
										?.default?.initial
								}}</span
							>
						</span>
						<div
							v-if="item?.complete_percent >= 0"
							:style="{
								background: TAG_COLOR(item?.complete_percent),
							}"
							class="
								absolute
								transform
								-translate-x-1/2 -translate-y-1/2
								left-1/2
								p-0.5
								px-2.5
								pt-1
								font-semibold
								text-white
								rounded-full
								text-xs
							"
						>
							{{ !item?.raw_result ? item?.result : '0%' }}
						</div>
					</div>
				</div>
			</div>
		</template>
	</Popper>
</template>
<script>
import Popper from 'vue3-popper';
import { TAG_COLOR } from '@/helper/tag';

export default {
	name: 'UserGroupReport',

	methods: {
		TAG_COLOR,

		student(id) {
			return this.students?.find((s) => s.id === id);
		},
	},

	components: { Popper },

	props: {
		students: {
			type: Array,
			required: true,
		},

		data: {
			type: Array,
			required: true,
		},

		placement: {
			type: String,
			default: 'bottom',
		},
	},
};
</script>
