<template>
	<div v-if="!isLoading">
		<div
			v-if="goals && goals.length > 0"
			class="p-6 bg-white shadow-sm rounded-2xl mb-10"
		>
			<div>
				<div class="flex items-center justify-between mb-6">
					<div class="font-semibold text-neutral-600">Thống kê</div>
					<div
						v-if="goals && goals.length > 0"
						class="flex justify-end items-center"
					>
						<button
							@click="
								$router.push(
									`/group-class-plans/${id}/create?type=library`
								)
							"
							type="button"
							class="
								button-p
								text-center
								bg-none
								shadow-none
								flex
								items-center
								justify-center
								btn--text
								w-fit
							"
						>
							<svg
								class="mr-2"
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M12.4702 6.65087L13.7102 5.41084C13.8461 5.27498 13.914 5.20705 13.9667 5.14509C14.6009 4.39841 14.6009 3.30223 13.9667 2.55555C13.914 2.49359 13.8461 2.42566 13.7102 2.2898C13.5744 2.15394 13.5064 2.08601 13.4445 2.03338C12.6978 1.39915 11.6016 1.39915 10.855 2.03338C10.793 2.086 10.7251 2.15391 10.5893 2.2897L10.5892 2.28978L10.5892 2.2898L9.33099 3.54801C10.0747 4.84221 11.1583 5.91748 12.4702 6.65087ZM7.87633 5.00267L2.86273 10.0163C2.43767 10.4413 2.22514 10.6539 2.0854 10.915C1.94567 11.1761 1.88672 11.4708 1.76883 12.0602L1.37318 14.0385C1.30666 14.3711 1.27339 14.5374 1.368 14.632C1.46261 14.7266 1.62892 14.6934 1.96153 14.6269L3.9398 14.2312L3.93982 14.2312L3.93984 14.2312C4.52927 14.1133 4.82399 14.0544 5.08508 13.9146C5.34617 13.7749 5.55869 13.5624 5.98373 13.1373L5.98376 13.1373L5.98377 13.1373L11.0118 8.10931C9.75111 7.3111 8.68202 6.24922 7.87633 5.00267Z"
									fill="currentColor"
								/>
							</svg>
							Chỉnh sửa
						</button>
					</div>
				</div>
			</div>
			<div class="grid grid-cols-1 md:grid-cols-2">
				<div
					class="
						p-6
						border
						lg:rounded-l-lg
						md:rounded-tl-lg
						border-neutral-200
						w-full
						md-down:rounded-t md-down:rounded-b-none
					"
				>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							mb-6
							flex flex-nowrap
							items-center
						"
					>
						<img
							src="@/assets/images/icons/abc.png"
							alt=""
							width="32"
							height="32"
							class="mr-2"
							style="height: 32px"
						/>
						{{ stats?.skills_count || '0' }}
					</div>
					<div class="flex flex-nowrap items-center">
						<span class="text-neutral-500 text-sm"
							>Tổng số lĩnh vực
						</span>
					</div>
				</div>
				<div
					class="
						p-6
						border
						md:border-l-0
						lg:rounded-r-lg
						md:rounded-br-lg
						border-neutral-200
						w-full
						md-down:border-t-0
						md-down:rounded-b
						md-down:rounded-t-none
					"
				>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							mb-6
							flex flex-nowrap
							items-center
						"
					>
						<img
							src="@/assets/images/icons/light.png"
							alt=""
							width="32"
							height="32"
							class="mr-2"
							style="height: 32px"
						/>
						{{ stats?.goals_count || '0' }}
					</div>
					<div class="flex flex-nowrap items-center">
						<span class="text-neutral-500 text-sm"
							>Tổng số mục tiêu</span
						>
					</div>
				</div>
			</div>
		</div>
		<div v-if="goals && goals.length > 0">
			<div>
				<div class="mb-10">
					<div class="shadow-sm sm:rounded-2xl overflow-hidden">
						<div class="__record__table overflow-x-auto scroll-bar">
							<div
								class="
									overflow-hidden
									sm:rounded-t-2xl
									w-fit
									min-w-full
								"
							>
								<table
									class="min-w-full divide-y text-neutral-200"
								>
									<thead class="bg-neutral-100 h-16">
										<tr>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													w-16
													whitespace-nowrap
												"
											>
												STT
											</th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												lĩnh vực
											</th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
												style="min-width: 160px"
											>
												Nội dung
											</th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												chỉnh sửa cuối
											</th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												KHỞI TẠO
											</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(g, i) in goalList"
											:key="g.id"
											:class="
												i % 2 === 0
													? 'bg-white'
													: 'bg-neutral-50'
											"
											class="text-neutral-700"
										>
											<td
												class="
													px-6
													py-4
													whitespace-nowrap
													text-neutral-700
												"
											>
												<div class="ml-2">
													{{ i + 1 }}
												</div>
											</td>
											<td
												class="
													px-6
													py-4
													text-neutral-700
												"
												style="width: 180px"
											>
												{{ g?.skill_name }}
											</td>
											<td
												class="
													px-6
													py-4
													text-neutral-700
												"
											>
												<div class="flex items-center">
													<router-link
														:to="`/group-class/${id}/${
															g.goal_id || g.id
														}`"
													>
														<div
															class="
																mr-2
																hover:text-yellow-700
																hover:underline
															"
														>
															{{
																g.goal_name ??
																g.name
															}}
														</div>
													</router-link>
													<TooltipGoalDetail
														:is-group="true"
														:goal-id="
															g.goal_id || g.id
														"
														:student-id="id"
													/>
												</div>
												<div
													class="flex gap-2 flex-wrap"
													style="max-width: 360px"
												>
													<div
														class="
															mt-1
															py-0.5
															px-2.5
															rounded-full
															bg-neutral-200
															text-neutral-700
															h-fit
															w-fit
															font-semibold
															shadow-input
															whitespace-nowrap
														"
														style="font-size: 10px"
														v-if="g?.raw_source"
													>
														{{ g?.display_source }}
													</div>
													<div
														class="
															mt-1
															py-0.5
															px-2.5
															rounded-full
															bg-neutral-200
															text-neutral-700
															h-fit
															w-fit
															font-semibold
															shadow-input
															whitespace-nowrap
														"
														style="font-size: 10px"
														v-if="g?.state"
													>
														{{
															$filters.sourceMap(
																g?.state,
																g.editable
															)
														}}
													</div>
													<template
														v-if="
															g?.assistance_levels &&
															org.enable_goal_assistance_level
														"
														class="flex gap-2"
													>
														<div
															v-for="ass in g?.assistance_levels"
															:key="ass"
														>
															<div
																class="
																	mt-1
																	py-0.5
																	px-2.5
																	rounded-full
																	bg-pink-100
																	whitespace-nowrap
																	text-neutral-700
																	h-fit
																	w-fit
																	font-semibold
																	shadow-input
																"
																style="
																	font-size: 10px;
																"
															>
																{{ ass }}
															</div>
														</div>
													</template>
												</div>
											</td>
											<td
												class="
													px-6
													py-4
													text-neutral-700
												"
												style="width: 110px"
											>
												<div>
													<div
														class="
															flex
															items-center
															mb-4
														"
														v-if="
															g.updated_by_staff
														"
													>
														<div>
															<img
																style="
																	width: 36px;
																	height: 36px;
																	min-width: 36px;
																"
																class="
																	shadow-sm
																	inline-block
																	rounded-full
																"
																:src="
																	g
																		.updated_by_staff
																		?.profile_photo
																		?.w200
																"
																alt=""
																v-if="
																	g
																		.updated_by_staff
																		?.profile_photo
																		?.w200
																"
															/>
															<span
																v-else
																class="
																	inline-flex
																	items-center
																	justify-center
																	h-9
																	w-9
																	rounded-full
																"
																:style="{
																	background:
																		g
																			.updated_by_staff
																			?.profile_photo
																			?.default
																			?.color,
																}"
															>
																<span
																	class="
																		font-medium
																		leading-none
																		text-white
																		text-xs
																	"
																	>{{
																		g
																			.updated_by_staff
																			?.profile_photo
																			?.default
																			?.initial
																	}}</span
																>
															</span>
														</div>
														<div
															class="
																ml-4
																text-blue-600
																font-semibold
																whitespace-pre-wrap
																w-40
															"
														>
															{{
																g
																	.updated_by_staff
																	.name
															}}
														</div>
													</div>
													<div v-if="g.updated_at">
														<span>
															{{
																$filters.formatTime(
																	g.updated_at
																)
															}}
														</span>
														<span class="ml-2">
															{{
																$filters.formatDate(
																	g.updated_at
																)
															}}
														</span>
													</div>
												</div>
											</td>
											<td
												class="
													px-6
													py-4
													text-neutral-700
												"
												style="width: 110px"
											>
												<div>
													<div
														class="
															flex
															items-center
															mb-4
														"
														v-if="
															g.created_by_staff
														"
													>
														<div>
															<img
																style="
																	width: 36px;
																	height: 36px;
																	min-width: 36px;
																"
																class="
																	shadow-sm
																	inline-block
																	rounded-full
																"
																:src="
																	g
																		.created_by_staff
																		?.profile_photo
																		?.w200
																"
																alt=""
																v-if="
																	g
																		.created_by_staff
																		?.profile_photo
																		?.w200
																"
															/>
															<span
																v-else
																class="
																	inline-flex
																	items-center
																	justify-center
																	h-9
																	w-9
																	rounded-full
																"
																:style="{
																	background:
																		g
																			.created_by_staff
																			?.profile_photo
																			?.default
																			?.color,
																}"
															>
																<span
																	class="
																		font-medium
																		leading-none
																		text-white
																		text-xs
																	"
																	>{{
																		g
																			.created_by_staff
																			?.profile_photo
																			?.default
																			?.initial
																	}}</span
																>
															</span>
														</div>
														<div
															class="
																ml-4
																text-blue-600
																font-semibold
																whitespace-pre-wrap
																w-40
															"
														>
															{{
																g
																	.created_by_staff
																	.name
															}}
														</div>
													</div>
													<div v-if="g.inserted_at">
														<span>
															{{
																$filters.formatTime(
																	g.inserted_at
																)
															}}
														</span>
														<span class="ml-2">
															{{
																$filters.formatDate(
																	g.inserted_at
																)
															}}
														</span>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else
			class="w-full h-full flex items-center justify-center flex-col"
		>
			<img
				src="@/assets/images/illustrations/not_found.png"
				alt=""
				height="250"
				width="250"
			/>
			<div
				class="text-neutral-500 text-lg text-center"
				style="max-width: 700px"
			>
				Lớp nhóm chưa có mục tiêu trong ngân hàng mục tiêu. <br />
				Đây là nơi lưu trữ/ tập hợp các mục tiêu bạn dự định sẽ dạy
				trong tương lai (mục tiêu dài hạn, cơ bản, 3 - 6 tháng). <br />
				Khi lên kế hoạch dự kiến, bạn có thể chọn mục tiêu từ Ngân hàng
				mục tiêu.
			</div>
			<div class="mt-8">
				<button
					@click="
						$router.push(
							`/group-class-plans/${id}/create?type=library`
						)
					"
					type="button"
					class="button-p btn--ghost w-fit"
				>
					<span class="ml-2 whitespace-nowrap">Thêm mục tiêu</span>
				</button>
			</div>
		</div>
	</div>
	<div v-else class="fixed top-1/2 left-1/2">
		<Loading />
	</div>
</template>

<script>
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail.vue';
import Loading from '@/components/common/Loading.vue';
import { $api } from '@/services';

export default {
	name: 'GoalBank',

	components: { Loading, TooltipGoalDetail },

	data() {
		return {
			goals: [],
			goalList: [],
			stats: null,
			isLoading: false,
		};
	},

	computed: {
		id() {
			return this.$route.params?.id;
		},

		org() {
			return this.$store.state.profile.current_org;
		},
	},

	created() {
		this.fetchGoalsInBank();
	},

	methods: {
		async fetchGoalsInBank() {
			try {
				const {
					data: { data, stats },
				} = await $api.groupClasses.goalsInBank(this.id);
				this.goals = data?.skills ?? [];
				this.goals.forEach((goal) => {
					goal?.goals.forEach((g) => {
						this.goalList.push(g);
					});
				});
				this.stats = stats || {};
			} catch (e) {
				console.log(e);
			} finally {
				this.isLoading = false;
			}
		},
	},
};
</script>
