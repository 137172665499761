<template>
	<div class="_modal bg-white">
		<div
			class="
				_modal__content
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<img
					src="@/assets/images/illustrations/warning.png"
					alt="Luca Education"
					title="Luca Education"
					height="60"
					width="60"
				/>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
					text-center
				"
			>
				Bạn có muốn xoá báo cáo bán trú này?
			</div>
			<div class="text-center text-neutral-500 mt-2">
				Báo cáo bán trú này sẽ được xoá khỏi dữ liệu của cơ sở.
			</div>
			<div class="w-full mt-10">
				<div
					class="input-group grid grid-cols-2 items-center"
					v-if="data?.student.name"
				>
					<div
						class="input-group__label text-neutral-500 font-normal"
					>
						Họ và Tên
					</div>
					<div class="font-bold text-neutral-500">
						{{ data?.student.name }}
					</div>
				</div>
				<div
					class="input-group grid grid-cols-2 items-center mt-8"
					v-if="data.date"
				>
					<div
						class="input-group__label text-neutral-500 font-normal"
					>
						Ngày học
					</div>
					<div class="font-bold text-neutral-500">
						{{ $filters.formatDate2(data.date) }}
					</div>
				</div>
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				type="button"
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				@click="$emit('close')"
			>
				Thoát
			</button>
			<button
				type="button"
				class="button-p text-center whitespace-nowrap"
				@click="submit"
			>
				Xác nhận
			</button>
		</div>
	</div>
</template>

<script>
import { $api } from '@/services';

export default {
	name: 'DeleteConfirmModal',
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
	methods: {
		async submit() {
			try {
				await $api.lessonReport.deleteDaycareReport(
					this.data.date,
					this.data?.student?.id,
					this.data?.student_class_session?.id
				);
				await this.$store.dispatch('setAlertMessage', {
					message: 'Xoá báo cáo thành công',
				});
				this.$emit('success');
			} catch (e) {
				await this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},
	},
};
</script>
