import { ref } from 'vue';
import { $api } from '@/services';

const error = ref(null);
const loading = ref(false);

export async function createBoarding(data: any) {
	error.value = null;
	try {
		const res = await $api.boarding.createDayCareClass(data);
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

export function useCreateBoarding() {
	return { createBoarding__error: error, loading, createBoarding };
}
