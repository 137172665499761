import { ref } from 'vue';
import { $api } from '@/services';

const error = ref(null);
const loading = ref(false);

export async function getAssignments(
	id: string,
	isAdmin = true,
	oldData?: any
) {
	error.value = null;
	try {
		const res = await $api.assignment.getAssignments(id, isAdmin, oldData);
		if (!res) throw new Error('Lỗi khi lấy danh sách sinh viên');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

export async function deleteStaffAssignments(
	student_id: string,
	assigment_id: string
) {
	error.value = null;
	try {
		const res = await $api.assignment.deletedStaffAssignments(
			student_id,
			assigment_id
		);
		if (!res) throw new Error('Lỗi khi lấy danh sách sinh viên');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

export async function createStaffAssignments(
	student_id: string,
	staff_id: string,
	isMain = false
) {
	error.value = null;
	try {
		const res = await $api.assignment.createStaffAssignments(
			student_id,
			staff_id,
			isMain
		);
		if (!res) throw new Error('Lỗi khi lấy danh sách sinh viên');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

export async function makeMainStaffAssignments(
	student_id: string,
	assigment_id: string
) {
	error.value = null;
	try {
		const res = await $api.assignment.makeMainStaffAssignments(
			student_id,
			assigment_id
		);
		if (!res) throw new Error('Lỗi khi lấy danh sách sinh viên');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

export function useAssignments() {
	return {
		error,
		loading,
		getAssignments,
		deleteStaffAssignments,
		createStaffAssignments,
		makeMainStaffAssignments,
	};
}
