
import { Options, Vue } from 'vue-class-component';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'Analytics',
	components: { DaIcon },
})
export default class Analytics extends Vue {
	//----------------------- 🤍 Props 🤍 -----------------------//
	@Prop() students!: any[];

	@Prop() assessments_map!: any[];

	//----------------------- 🤍 Data 🤍 -----------------------//
	get studentList() {
		return this.students;
	}

	get assessmentsMap() {
		return this.assessments_map;
	}

	assessment(studentId: string | number) {
		return this.assessmentsMap.find(
			(item) => item.student_id === studentId
		);
	}
}
