<template>
	<div v-if="!isLoading">
		<div
			v-if="plans && plans.length > 0"
			class="shadow-sm sm:rounded-2xl overflow-hidden"
		>
			<div class="__record__table overflow-x-auto scroll-bar">
				<div class="overflow-hidden sm:rounded-t-2xl w-fit min-w-full">
					<table class="min-w-full divide-y text-neutral-200">
						<thead class="bg-neutral-100 h-16">
							<tr>
								<th
									scope="col"
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
								>
									Tên kế hoạch
								</th>
								<th
									scope="col"
									class="
										px-3
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
								>
									Thời gian dạy
								</th>
								<th
									scope="col"
									class="
										px-3
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
								>
									Trạng thái
								</th>
								<th
									scope="col"
									class="
										px-3
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
								>
									Gửi PH
								</th>
								<th
									scope="col"
									class="
										px-3
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
								>
									Báo cáo KH
								</th>
								<th v-if="false"></th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(item, index) in plans"
								:key="item.id"
								:class="[
									index % 2 === 0
										? 'bg-white'
										: 'bg-neutral-50',
									item?.state === 'active'
										? 'bg-orange-50'
										: '',
								]"
								class="text-neutral-700"
							>
								<td
									class="
										px-6
										py-4
										whitespace-nowrap
										text-neutral-700
										capitalize
									"
									:class="{
										'hover:underline hover:text-yellow-700':
											!is_old_data,
									}"
								>
									<component
										:is="
											is_old_data ? 'div' : 'router-link'
										"
										:to="`/plan-in-history/${id}/${item?.id}`"
									>
										Kế hoạch
										{{ item.order }}
									</component>
								</td>
								<td
									class="
										px-3
										py-4
										whitespace-nowrap
										text-neutral-700
									"
									v-if="item.applied_at"
								>
									<span v-if="!item.done_at">Từ</span>
									{{ $filters.formatDate(item.applied_at) }}
									<span v-if="item.done_at">
										-
										{{ $filters.formatDate(item.done_at) }}
										(
										{{
											$filters.aFromB(
												item.applied_at,
												item.done_at
											)
										}}
										ngày)
									</span>
									<span v-else-if="item.done_at__future">
										-
										{{
											$filters.formatDate(
												item.done_at__future
											)
										}}
										( Dự kiến
										{{
											$filters.aFromB(
												item.apply_date,
												item.done_at__future
											)
										}}
										ngày)
									</span>
								</td>
								<td
									v-else
									class="
										px-3
										py-4
										whitespace-nowrap
										text-neutral-700
									"
								>
									<span v-if="!item.done_at">Từ</span>
									{{ $filters.formatDate2(item.apply_date) }}
									<span v-if="item.done_at">
										-
										{{ $filters.formatDate(item.done_at) }}
										(
										{{
											$filters.aFromB(
												item.apply_date,
												item.done_at
											)
										}}
										ngày)
									</span>
									<span v-else-if="item.done_at__future">
										-
										{{
											$filters.formatDate(
												item.done_at__future
											)
										}}
										( Dự kiến
										{{
											$filters.aFromB(
												item.apply_date,
												item.done_at__future
											)
										}}
										ngày)
									</span>
								</td>
								<td>
									<div
										v-if="item?.state === 'pending'"
										class="flex items-center"
									>
										<svg
											class="mr-2"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM5.83333 9.16667C5.48815 9.16667 5.20833 9.53976 5.20833 10C5.20833 10.4602 5.48815 10.8333 5.83333 10.8333H14.5833C14.9285 10.8333 15.2083 10.4602 15.2083 10C15.2083 9.53976 14.9285 9.16667 14.5833 9.16667H5.83333Z"
												fill="#CBD5E1"
											/>
										</svg>

										KH dự kiến
									</div>
									<div
										v-if="item?.state === 'done'"
										class="flex items-center"
									>
										<svg
											class="mr-2"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M11.2125 6.21209C11.5786 5.84597 12.1722 5.84597 12.5384 6.21209C12.9 6.57371 12.9044 7.15727 12.5517 7.52434L7.56141 13.7622C7.5542 13.7712 7.54651 13.7798 7.53835 13.7879C7.17224 14.154 6.57865 14.154 6.21253 13.7879L2.90447 10.4799C2.53835 10.1137 2.53835 9.52015 2.90447 9.15403C3.27059 8.78791 3.86418 8.78791 4.2303 9.15403L6.8472 11.7709L11.1877 6.24013C11.1954 6.23029 11.2037 6.22093 11.2125 6.21209Z"
												fill="#94A3B8"
											/>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M10.062 12.6374L11.2125 13.7879C11.5786 14.154 12.1722 14.154 12.5384 13.7879C12.5465 13.7798 12.5542 13.7712 12.5614 13.7622L17.5517 7.52434C17.9044 7.15727 17.9 6.57371 17.5384 6.21209C17.1722 5.84597 16.5786 5.84597 16.2125 6.21209C16.2037 6.22093 16.1954 6.23029 16.1877 6.24013L11.8472 11.7709L11.2405 11.1642L10.062 12.6374Z"
												fill="#94A3B8"
											/>
										</svg>
										Đã dạy
									</div>
									<div
										v-if="item?.state === 'active'"
										class="flex items-center"
									>
										<svg
											class="mr-2"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M11.2125 6.21209C11.5786 5.84597 12.1722 5.84597 12.5384 6.21209C12.9 6.57371 12.9044 7.15727 12.5517 7.52434L7.56141 13.7622C7.5542 13.7712 7.54651 13.7798 7.53835 13.7879C7.17224 14.154 6.57865 14.154 6.21253 13.7879L2.90447 10.4799C2.53835 10.1137 2.53835 9.52015 2.90447 9.15403C3.27059 8.78791 3.86418 8.78791 4.2303 9.15403L6.8472 11.7709L11.1877 6.24013C11.1954 6.23029 11.2037 6.22093 11.2125 6.21209Z"
												fill="#2AA797"
											/>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M10.062 12.6374L11.2125 13.7879C11.5786 14.154 12.1722 14.154 12.5384 13.7879C12.5465 13.7798 12.5542 13.7712 12.5614 13.7622L17.5517 7.52434C17.9044 7.15727 17.9 6.57371 17.5384 6.21209C17.1722 5.84597 16.5786 5.84597 16.2125 6.21209C16.2037 6.22093 16.1954 6.23029 16.1877 6.24013L11.8472 11.7709L11.2405 11.1642L10.062 12.6374Z"
												fill="#2AA797"
											/>
										</svg>

										KH hiện tại
									</div>
								</td>
								<td
									class="
										px-3
										py-4
										whitespace-nowrap
										text-neutral-700
									"
								>
									{{ item.sent_at ? 'Đã gửi' : '-' }}
								</td>
								<td
									class="
										px-3
										py-4
										whitespace-nowrap
										text-neutral-700
									"
								>
									<router-link
										v-if="
											item?.monthly_report?.month_start &&
											item?.monthly_report?.state ===
												'completed'
										"
										class="
											font-semibold
											text-yellow-600
											cursor-pointer
											hover:text-yellow-700
										"
										:to="`/report/monthly-report/${id}?month_start=${item?.monthly_report?.month_start}`"
									>
										Xem báo cáo
									</router-link>
									<router-link
										v-if="
											item?.monthly_report?.month_start &&
											item?.monthly_report?.state !==
												'completed'
										"
										class="
											font-semibold
											text-yellow-600
											cursor-pointer
											hover:text-yellow-700
										"
										:to="`/report/monthly-report/${id}?month_start=${item?.monthly_report?.month_start}&action=edit`"
									>
										Làm báo cáo
									</router-link>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div
			v-else
			class="w-full h-full flex items-center justify-center flex-col"
		>
			<img
				src="@/assets/images/illustrations/not_found.png"
				alt=""
				height="250"
				width="250"
			/>
			<div
				class="text-neutral-500 text-lg text-center"
				style="max-width: 700px"
			>
				Học sinh chưa có Kế Hoạch
			</div>
			<div class="mt-8">
				<button type="button" class="button-p btn--ghost w-fit">
					<span class="ml-2 whitespace-nowrap">Tạo kế hoạch mới</span>
				</button>
			</div>
		</div>
	</div>
	<div v-else class="fixed top-1/2 left-1/2">
		<Loading />
	</div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { $api } from '@/services';
import DaIcon from '@/components/elements/daIcon/DaIcon';
import Popper from 'vue3-popper';
import Loading from '@/components/common/Loading';

export default defineComponent({
	name: 'HistoryPlan',
	components: { Loading, DaIcon, Popper },
	setup() {
		const route = useRoute();

		const id = computed(() =>
			route.params?.id ? route.params?.id + '' : ''
		);

		const plans = ref([]);

		const isLoading = ref(true);

		const oldPayload = ref({
			'x-cross': route.query.org_id,
		});

		const is_old_data = ref(route.query.is_old_data === 'true');

		async function getHistoryPlan() {
			try {
				const { data } = await $api.monthlyReport.planHistory(
					id.value,
					is_old_data.value ? oldPayload.value : null
				);
				plans.value = data?.data?.plans || [];
				if (data?.data?.plans?.length > 1) {
					if (data?.data?.plans[0].state === 'pending') {
						plans.value[1].done_at__future =
							data?.data?.plans[0].apply_date;
					}
				}

				isLoading.value = false;
			} catch (e) {
				console.log(e);
			}
		}

		getHistoryPlan();
		return {
			plans,
			id,
			isLoading,
			is_old_data,
		};
	},
});
</script>
