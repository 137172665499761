<template>
	<div class="fixed inset-y-0 right-0 flex side-over z-50 w-screen md:w-2/5">
		<div class="h-full w-full flex flex-col bg-white shadow-xl">
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center" @click="$emit('close')">
						<svg
							fill="none"
							height="24"
							viewBox="0 0 24 24"
							width="24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clip-rule="evenodd"
								d="M16.5 3C16.5 2.17157 17.1716 1.5 18 1.5H21C21.8284 1.5 22.5 2.17157 22.5 3V21H23.25C23.6642 21 24 21.3358 24 21.75C24 22.1642 23.6642 22.5 23.25 22.5H0.75C0.335786 22.5 0 22.1642 0 21.75C0 21.3358 0.335786 21 0.75 21H1.5V16.5C1.5 15.6716 2.17157 15 3 15H6C6.82843 15 7.5 15.6716 7.5 16.5V21H9V10.5C9 9.67157 9.67157 9 10.5 9H13.5C14.3284 9 15 9.67157 15 10.5V21H16.5V3Z"
								fill="#94A3B8"
								fill-rule="evenodd"
							/>
						</svg>
						<div
							class="
								text-2xl
								font-bold
								text-neutral-700
								ml-2
								mt-1
							"
						>
							Thống kê
							<span class="text-green-500"
								>THỜI KHOÁ BIỂU {{ id }}</span
							>
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							type="button"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								aria-hidden="true"
								class="h-6 w-6"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M6 18L18 6M6 6l12 12"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div
				class="
					sideover-content
					pt-6
					relative
					flex-1
					px-4
					sm:px-6
					overflow-y-auto
				"
			>
				<div>
					<div class="text-gray-500">
						Ngày áp dụng:
						<span>{{
							$filters.formatDate2(statsData.activated_date)
						}}</span>
					</div>
					<div class="text-gray-500 mt-2 mb-6">
						Ngày kết thúc:
						<span>{{
							$filters.formatDate2(statsData.deactivated_date)
						}}</span>
					</div>
					<div :id="`list-dropdown-1`" class="mt-2">
						<div
							class="
								bg-neutral-50
								flex
								items-center
								justify-between
								p-3
								border
								rounded-t
								cursor-pointer
							"
							@click="openList(1)"
						>
							<div>Tổng h.s chưa có lịch can thiệp cá nhân</div>
							<div class="relative">
								<div class="pr-10">
									{{
										statsData.students_without_time_slots_count
									}}
									h.s
								</div>
								<div
									:id="`list-dropdown-1-icon`"
									class="
										absolute
										top-1/2
										right-0
										transform
										-translate-y-1/2
										transition-all
										duration-200
									"
								>
									<svg
										fill="none"
										height="6"
										viewBox="0 0 12 6"
										width="12"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M10.5 5.25L6 0.75L1.5 5.25"
											stroke="#48566A"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="1.5"
										/>
									</svg>
								</div>
							</div>
						</div>
						<div
							v-if="statsData"
							:id="`list-dropdown-1-content`"
							class="px-4 border border-t-0 rounded-b hidden"
						>
							<div
								v-for="(
									item, index
								) in statsData.students_without_time_slots"
								:key="index"
								class="py-4"
							>
								<div class="flex items-start">
									<div>
										<img
											v-if="item?.profile_photo?.w200"
											:src="item?.profile_photo?.w200"
											alt=""
											class="
												shadow-sm
												inline-block
												h-6
												w-6
												rounded-full
											"
										/>
										<span
											v-else
											:style="{
												background:
													item?.profile_photo?.default
														?.color,
											}"
											class="
												inline-flex
												items-center
												justify-center
												h-6
												w-6
												rounded-full
											"
										>
											<span
												class="
													font-medium
													leading-none
													text-white text-xs
												"
												>{{
													item?.profile_photo?.default
														?.initial
												}}</span
											>
										</span>
									</div>
									<div
										class="
											ml-4
											text-orange-600
											font-semibold
											whitespace-pre-wrap
											w-40
										"
									>
										{{ item.name }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div :id="`list-dropdown-2`" class="mt-2">
						<div
							class="
								bg-neutral-50
								flex
								items-center
								justify-between
								p-3
								border
								rounded-t
								cursor-pointer
							"
							@click="openList(2)"
						>
							<div>Tổng h.s đã có lịch can thiệp cá nhân</div>
							<div class="relative">
								<div class="pr-10">
									{{
										statsData.students_with_time_slots_count
									}}
									h.s
								</div>
								<div
									:id="`list-dropdown-2-icon`"
									class="
										absolute
										top-1/2
										right-0
										transform
										-translate-y-1/2
										transition-all
										duration-200
									"
								>
									<svg
										fill="none"
										height="6"
										viewBox="0 0 12 6"
										width="12"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M10.5 5.25L6 0.75L1.5 5.25"
											stroke="#48566A"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="1.5"
										/>
									</svg>
								</div>
							</div>
						</div>
						<div
							v-if="statsData"
							:id="`list-dropdown-2-content`"
							class="px-4 border border-t-0 rounded-b hidden"
						>
							<div
								v-for="(
									item, index
								) in statsData.students_with_time_slots"
								:key="index"
								class="py-4"
							>
								<div class="flex items-start">
									<div>
										<img
											v-if="item?.profile_photo?.w200"
											:src="item?.profile_photo?.w200"
											alt=""
											class="
												shadow-sm
												inline-block
												h-6
												w-6
												rounded-full
											"
										/>
										<span
											v-else
											:style="{
												background:
													item?.profile_photo?.default
														?.color,
											}"
											class="
												inline-flex
												items-center
												justify-center
												h-6
												w-6
												rounded-full
											"
										>
											<span
												class="
													font-medium
													leading-none
													text-white text-xs
												"
												>{{
													item?.profile_photo?.default
														?.initial
												}}</span
											>
										</span>
									</div>
									<div
										class="
											ml-4
											text-orange-600
											font-semibold
											whitespace-pre-wrap
											w-40
										"
									>
										{{ item.name }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="mt-2">
						<div
							class="
								bg-neutral-50
								flex
								items-center
								justify-between
								p-3
								border
								rounded-t
								cursor-pointer
							"
						>
							<div>Tổng giờ can thiệp trong tuần</div>
							<div class="relative">
								<div class="pr-10">
									{{ statsData.total_scheduled_hours }} h
								</div>
								<div
									:id="`list-dropdown-2-icon`"
									class="
										absolute
										top-1/2
										right-0
										transform
										-translate-y-1/2
										transition-all
										duration-200
									"
								>
									<svg
										fill="none"
										height="6"
										viewBox="0 0 12 6"
										width="12"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M10.5 5.25L6 0.75L1.5 5.25"
											stroke="#48566A"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="1.5"
										/>
									</svg>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="flex items-center justify-center my-10">
					<div
						class="
							text-yellow-600
							hover:text-yellow-700
							cursor-pointer
							flex
							items-center
							flex-nowrap
							underline
							gap-2
						"
						@click="downloadFile"
					>
						Tải xuống Lịch can thiệp cá nhân
						<svg
							fill="none"
							height="24"
							viewBox="0 0 25 24"
							width="25"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clip-rule="evenodd"
								d="M12.5 0C10.3604 0 8.46764 0.840683 7.10864 2.01273C5.95978 3.00355 5.12719 4.29255 4.91334 5.5875C2.39879 6.14267 0.5 8.33267 0.5 10.9773C0.5 14.049 3.06131 16.5 6.17188 16.5H11.75V8.25C11.75 7.83579 12.0858 7.5 12.5 7.5C12.9142 7.5 13.25 7.83579 13.25 8.25V16.5H19.5312C22.2535 16.5 24.5 14.3542 24.5 11.6591C24.5 9.20484 22.6371 7.20616 20.2485 6.86847C19.8849 2.99916 16.5354 0 12.5 0Z"
								fill="currentColor"
								fill-rule="evenodd"
							/>
							<path
								clip-rule="evenodd"
								d="M11.9697 23.7803C12.2626 24.0732 12.7374 24.0732 13.0303 23.7803L17.5303 19.2803C17.8232 18.9874 17.8232 18.5126 17.5303 18.2197C17.2374 17.9268 16.7626 17.9268 16.4697 18.2197L13.25 21.4393V16.5H11.75V21.4393L8.53033 18.2197C8.23744 17.9268 7.76256 17.9268 7.46967 18.2197C7.17678 18.5126 7.17678 18.9874 7.46967 19.2803L11.9697 23.7803Z"
								fill="currentColor"
								fill-rule="evenodd"
							/>
						</svg>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import { $api } from '@/services';

export default defineComponent({
	name: 'StatisticalTimeTable',

	props: {
		id: {
			type: String,
			required: true,
		},
	},

	data() {
		return {
			list: [
				{
					student: {
						name: 'A Nhược',
						profile_photo: {
							default: { color: '#F1A267', initial: 'YU' },
						},
					},
				},
				{
					student: {
						name: 'A Nhược',
						profile_photo: {
							default: { color: '#F1A267', initial: 'YU' },
						},
					},
				},
				{
					student: {
						name: 'A Nhược',
						profile_photo: {
							default: { color: '#F1A267', initial: 'YU' },
						},
					},
				},
				{
					student: {
						name: 'A Nhược',
						profile_photo: {
							default: { color: '#F1A267', initial: 'YU' },
						},
					},
				},
			],
			statsData: {},
		};
	},

	watch: {
		id: {
			immediate: true,
			handler(value) {
				if (value) {
					this.fetchTimeTableHistoryStats();
				}
			},
		},
	},

	methods: {
		submit() {
			// TODO:
		},

		openList(index) {
			const el = document.getElementById(
				`list-dropdown-${index}-content`
			);
			const icon = document.getElementById(`list-dropdown-${index}-icon`);
			if (el) {
				if (el.classList.contains('hidden')) {
					el.classList.remove('hidden');
					icon?.classList.add('rotate-180');
				} else {
					el.classList.add('hidden');
					icon?.classList.remove('rotate-180');
				}
			}
		},

		async fetchTimeTableHistoryStats() {
			try {
				const res = await $api.schedule.fetchTimetableHistoryStats(
					this.id,
					this.params
				);
				this.statsData = res?.data?.data;
			} catch (e) {
				console.log(e);
			}
		},

		async downloadFile() {
			try {
				const res = await $api.schedule.checkDownloadNewFileById(
					this.id
				);
				if (res?.data?.token) {
					window.location.href =
						process.env.VUE_APP_URL +
						'excel/export_timetable?secure_token=' +
						res?.data?.token;
				}
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},
	},
});
</script>
