<template>
	<div>
		<DaModalFrame v-model:open="modalIsOpen">
			<DeleteConfirm
				v-if="action === 'deleting-slot'"
				@close="modalIsOpen = false"
				@submit="deleteSubmit"
			/>
			<DeleteGroupClass
				v-if="action === 'deleting'"
				@close="closeModal"
				@submit="deleteGroupClass"
				:in-progress="inProgress"
			/>
			<UpdateGroupClass
				v-if="action === 'updating'"
				@close="modalIsOpen = false"
				@submit="updateGroupClass"
				:data="groupClass"
				:in-progress="inProgress"
				:error="error"
			/>
		</DaModalFrame>
		<div :class="isOpenCreated ? 'side-over--active' : ''">
			<div
				class="
					fixed
					h-screen
					w-screen
					top-0
					left-0
					bg-black
					opacity-20
					z-30
				"
				v-if="isOpenCreated"
				@click="isOpenCreated = false"
			></div>
			<CreateTimeSlotGroup
				v-if="groupClass?.staffs"
				@close="isOpenCreated = false"
				@success="success"
				:open="isOpenCreated"
				:day="day_of_week"
				:data="dataUpdate"
				:staffs="groupClass?.staffs"
			/>
		</div>
		<div v-if="!isLoading" class="__student-detail pb-30">
			<div
				class="
					__goal-management-student-detail
					p-10
					md-down:px-4
					flex
					items-center
					justify-between
					w-full
				"
			>
				<div class="flex gap-4">
					<svg
						@click="
							$router.push({ name: _ROUTER_NAME.GROUP_CLASSES })
						"
						width="28"
						height="28"
						viewBox="0 0 28 28"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						class="cursor-pointer"
					>
						<path
							d="M17.5 7L10.5 14L17.5 21"
							stroke="#94A3B8"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
					<div v-if="groupClass">
						<div class="flex gap-2">
							<div class="w-7 h-7">
								<img
									src="@/assets/images/icons/people-fill.png"
									alt=""
								/>
							</div>
							<div>
								<div
									class="text-neutral-700 font-bold text-3xl"
								>
									{{ groupClass?.name }}
								</div>
								<div class="flex gap-4 items-center mt-2">
									<UserGroup
										:data="groupClass?.students"
										placement="bottom-start"
									/>
									<div
										class="
											uppercase
											text-green-500
											font-semibold
										"
									>
										{{ groupClass?.students?.length }} học
										sinh
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Menu as="div" class="ml-3 relative">
					<div>
						<MenuButton
							@click.stop="() => {}"
							class="
								rounded-full
								flex
								text-sm
								focus:outline-none
								focus:ring-2
								focus:ring-offset-2
								focus:ring-indigo-500
								p-3
							"
						>
							<span class="sr-only">Open user menu</span>
							<svg
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M11.875 16.25C11.875 17.2855 11.0355 18.125 10 18.125C8.96447 18.125 8.125 17.2855 8.125 16.25C8.125 15.2145 8.96447 14.375 10 14.375C11.0355 14.375 11.875 15.2145 11.875 16.25ZM11.875 10C11.875 11.0355 11.0355 11.875 10 11.875C8.96447 11.875 8.125 11.0355 8.125 10C8.125 8.96447 8.96447 8.125 10 8.125C11.0355 8.125 11.875 8.96447 11.875 10ZM11.875 3.75C11.875 4.78553 11.0355 5.625 10 5.625C8.96447 5.625 8.125 4.78553 8.125 3.75C8.125 2.71447 8.96447 1.875 10 1.875C11.0355 1.875 11.875 2.71447 11.875 3.75Z"
									fill="#94A3B8"
								/>
							</svg>
						</MenuButton>
					</div>
					<transition
						enter-active-class="transition ease-out duration-200"
						enter-from-class="transform opacity-0 scale-95"
						enter-to-class="transform opacity-100 scale-100"
						leave-active-class="transition ease-in duration-75"
						leave-from-class="transform opacity-100 scale-100"
						leave-to-class="transform opacity-0 scale-95"
					>
						<MenuItems
							class="
								origin-top-right
								absolute
								right-0
								mt-2
								w-60
								rounded-md
								py-1
								bg-white
								focus:outline-none
								z-10
								shadow-sm
								border border-yellow-100
							"
						>
							<MenuItem @click.stop="openModalUpdated">
								<div
									class="
										p-2
										px-4
										bg-white
										z-10
										text-gray-600
										hover:bg-neutral-50
										flex
										items-center
										gap-4
										cursor-pointer
									"
								>
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M12.4702 6.65087L13.7102 5.41084C13.8461 5.27498 13.914 5.20705 13.9666 5.14509C14.6009 4.39841 14.6009 3.30223 13.9666 2.55555C13.914 2.49359 13.8461 2.42566 13.7102 2.2898C13.5744 2.15394 13.5064 2.08601 13.4445 2.03338C12.6978 1.39915 11.6016 1.39915 10.8549 2.03338C10.793 2.086 10.7251 2.15391 10.5893 2.2897L10.5892 2.28978L10.5892 2.2898L9.33097 3.54801C10.0746 4.84221 11.1583 5.91748 12.4702 6.65087ZM7.87631 5.00267L2.86271 10.0163C2.43765 10.4413 2.22512 10.6539 2.08538 10.915C1.94565 11.1761 1.8867 11.4708 1.76881 12.0602L1.37316 14.0385C1.30663 14.3711 1.27337 14.5374 1.36798 14.632C1.46259 14.7266 1.62889 14.6934 1.9615 14.6269L3.93978 14.2312L3.9398 14.2312L3.93982 14.2312C4.52925 14.1133 4.82397 14.0544 5.08506 13.9146C5.34615 13.7749 5.55867 13.5624 5.98371 13.1373L5.98374 13.1373L5.98374 13.1373L11.0117 8.10931C9.75108 7.3111 8.682 6.24922 7.87631 5.00267Z"
											fill="#94A3B8"
										/>
									</svg>

									<span>Chỉnh sửa lớp nhóm </span>
								</div>
							</MenuItem>
							<MenuItem
								@click.stop="
									$router.push(
										`/group-classes/${id}/add-student`
									)
								"
							>
								<div
									class="
										p-2
										px-4
										bg-white
										z-10
										text-gray-600
										hover:bg-neutral-50
										flex
										items-center
										gap-4
										cursor-pointer
									"
								>
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
											fill="#94A3B8"
										/>
									</svg>
									<span>Thêm học sinh</span>
								</div>
							</MenuItem>
							<MenuItem
								@click.stop="
									$router.push(
										`/group-classes/${id}/add-staff`
									)
								"
							>
								<div
									class="
										p-2
										px-4
										bg-white
										z-10
										text-gray-600
										hover:bg-neutral-50
										flex
										items-center
										gap-4
										cursor-pointer
									"
								>
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
											fill="#94A3B8"
										/>
									</svg>
									<span>Thêm Trị liệu viên</span>
								</div>
							</MenuItem>
							<MenuItem @click.stop="openModalDeleted">
								<div
									class="
										p-2
										px-4
										bg-white
										z-10
										text-gray-600
										hover:bg-neutral-50
										flex
										items-center
										gap-4
										cursor-pointer
									"
								>
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M2.5 1C1.94772 1 1.5 1.44772 1.5 2V3C1.5 3.55228 1.94772 4 2.5 4H3V13C3 14.1046 3.89543 15 5 15H11C12.1046 15 13 14.1046 13 13V4H13.5C14.0523 4 14.5 3.55228 14.5 3V2C14.5 1.44772 14.0523 1 13.5 1H10C10 0.447715 9.55229 0 9 0H7C6.44772 0 6 0.447715 6 1H2.5ZM5.5 5C5.77614 5 6 5.22386 6 5.5V12.5C6 12.7761 5.77614 13 5.5 13C5.22386 13 5 12.7761 5 12.5L5 5.5C5 5.22386 5.22386 5 5.5 5ZM8 5C8.27614 5 8.5 5.22386 8.5 5.5V12.5C8.5 12.7761 8.27614 13 8 13C7.72386 13 7.5 12.7761 7.5 12.5V5.5C7.5 5.22386 7.72386 5 8 5ZM11 5.5C11 5.22386 10.7761 5 10.5 5C10.2239 5 10 5.22386 10 5.5V12.5C10 12.7761 10.2239 13 10.5 13C10.7761 13 11 12.7761 11 12.5V5.5Z"
											fill="#94A3B8"
										/>
									</svg>
									<span>Xoá lớp nhóm</span>
								</div>
							</MenuItem>
						</MenuItems>
					</transition>
				</Menu>
			</div>
			<div
				class="
					__goal-management-student-detail__overview
					px-10
					pb-24
					md-down:px-4
				"
			>
				<div
					v-if="data?.length"
					class="mt-10 border border-neutral-200 rounded-lg"
					style="max-width: 600px"
				>
					<div
						v-for="index in 6"
						:key="index"
						class="flex bg-neutral-100 items-center justify-start"
						:class="{
							'border-b border-neutral-200': index !== 6,
							'rounded-t-lg': index === 1,
							'rounded-b-lg': index === 6,
						}"
					>
						<div
							class="
								p-5
								h-full
								font-semibold
								text-xs text-neutral-600 text-center
							"
							style="width: 100px; height: 100%"
						>
							{{ `Thứ ${index + 1}` }}
						</div>
						<div class="p-4 w-full bg-white">
							<div
								v-if="getTimeTableData(index).length"
								class="flex flex-col gap-2"
							>
								<div
									v-for="(dataItem, i) in getTimeTableData(
										index
									)"
									:key="i"
									class="
										flex
										items-center
										justify-between
										w-full
									"
								>
									<div
										class="
											p-2
											bg-violet-100
											border-l-2 border-violet-600
											rounded-lg
											flex
											w-full
											items-center
											justify-between
											gap-2
										"
										style="width: fit-content"
									>
										<div
											class="
												font-semibold
												text-neutral-600
												whitespace-nowrap
												text-sm
											"
										>
											{{ dataItem.display_start_slot }}
											-
											{{ dataItem.display_end_slot }}
										</div>
										<div
											class="
												flex
												items-center
												justify-end
												gap-1
											"
										>
											<div
												class="flex items-center"
												v-for="staff in dataItem.staffs"
												:key="staff.id"
											>
												<div class="h-6 w-6">
													<img
														class="
															shadow-sm
															inline-block
															h-6
															w-6
															rounded-full
														"
														:src="
															staff?.profile_photo
																?.w200
														"
														alt=""
														v-if="
															staff?.profile_photo
																?.w200
														"
													/>
													<span
														v-else
														class="
															inline-flex
															items-center
															justify-center
															h-6
															w-6
															rounded-full
														"
														:style="{
															background:
																staff
																	?.profile_photo
																	?.default
																	?.color,
														}"
													>
														<span
															class="
																font-medium
																leading-none
																text-white
																text-xs
															"
															>{{
																staff
																	?.profile_photo
																	?.default
																	?.initial
															}}</span
														>
													</span>
												</div>
											</div>
										</div>
									</div>
									<Menu as="div" class="ml-3 relative">
										<div>
											<MenuButton
												class="
													rounded-full
													flex
													text-sm
													focus:outline-none
													focus:ring-2
													focus:ring-offset-2
													focus:ring-indigo-500
												"
											>
												<span class="sr-only"
													>Open user menu</span
												>
												<svg
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M11.875 16.25C11.875 17.2855 11.0355 18.125 10 18.125C8.96447 18.125 8.125 17.2855 8.125 16.25C8.125 15.2145 8.96447 14.375 10 14.375C11.0355 14.375 11.875 15.2145 11.875 16.25ZM11.875 10C11.875 11.0355 11.0355 11.875 10 11.875C8.96447 11.875 8.125 11.0355 8.125 10C8.125 8.96447 8.96447 8.125 10 8.125C11.0355 8.125 11.875 8.96447 11.875 10ZM11.875 3.75C11.875 4.78553 11.0355 5.625 10 5.625C8.96447 5.625 8.125 4.78553 8.125 3.75C8.125 2.71447 8.96447 1.875 10 1.875C11.0355 1.875 11.875 2.71447 11.875 3.75Z"
														fill="#94A3B8"
													/>
												</svg>
											</MenuButton>
										</div>
										<transition
											enter-active-class="transition ease-out duration-200"
											enter-from-class="transform opacity-0 scale-95"
											enter-to-class="transform opacity-100 scale-100"
											leave-active-class="transition ease-in duration-75"
											leave-from-class="transform opacity-100 scale-100"
											leave-to-class="transform opacity-0 scale-95"
										>
											<MenuItems
												class="
													origin-top-right
													absolute
													right-0
													mt-2
													w-60
													rounded-md
													py-1
													bg-white
													focus:outline-none
													z-10
													shadow-sm
													border border-yellow-100
												"
											>
												<MenuItem
													v-slot="{ active }"
													@click="openCreated(index)"
												>
													<div
														class="
															p-2
															px-4
															bg-white
															z-10
															text-gray-600
															hover:bg-neutral-50
															flex
															items-center
															gap-4
															cursor-pointer
														"
														:class="
															active ? '' : ''
														"
													>
														<svg
															width="16"
															height="16"
															viewBox="0 0 16 16"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
																fill="#94A3B8"
															/>
														</svg>

														<span class="mt-0.5"
															>Thêm buổi học</span
														>
													</div>
												</MenuItem>
												<MenuItem
													v-slot="{ active }"
													@click="
														openCreated(
															index,
															dataItem
														)
													"
												>
													<div
														class="
															p-2
															px-4
															bg-white
															z-10
															text-gray-600
															hover:bg-neutral-50
															flex
															items-center
															gap-4
															cursor-pointer
														"
														:class="
															active ? '' : ''
														"
													>
														<svg
															width="16"
															height="16"
															viewBox="0 0 16 16"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M12.47 6.65175L13.71 5.41172C13.8459 5.27586 13.9138 5.20793 13.9664 5.14597C14.6006 4.39929 14.6006 3.30311 13.9664 2.55644C13.9138 2.49448 13.8459 2.42655 13.71 2.29069C13.5741 2.15482 13.5062 2.08689 13.4442 2.03426C12.6976 1.40004 11.6014 1.40004 10.8547 2.03426C10.7928 2.08688 10.7249 2.15479 10.5891 2.29059L10.589 2.29067L10.589 2.29069L9.33075 3.5489C10.0744 4.84309 11.1581 5.91837 12.47 6.65175ZM7.87608 5.00356L2.86248 10.0172C2.43742 10.4422 2.22489 10.6547 2.08516 10.9158C1.94542 11.1769 1.88648 11.4717 1.76859 12.0611L1.37293 14.0394C1.30641 14.372 1.27315 14.5383 1.36776 14.6329C1.46237 14.7275 1.62867 14.6943 1.96128 14.6277L3.93956 14.2321L3.93958 14.2321L3.9396 14.2321C4.52902 14.1142 4.82374 14.0553 5.08483 13.9155C5.34592 13.7758 5.55845 13.5633 5.98349 13.1382L5.98352 13.1382L5.98352 13.1382L11.0115 8.11019C9.75086 7.31198 8.68177 6.25011 7.87608 5.00356Z"
																fill="#94A3B8"
															/>
														</svg>

														<span class="mt-0.5"
															>Chỉnh sửa buổi
															học</span
														>
													</div>
												</MenuItem>
												<MenuItem
													v-slot="{ active }"
													@click="
														openDeleteConfirm(
															dataItem.id
														)
													"
												>
													<div
														class="
															p-2
															px-4
															bg-white
															z-10
															text-gray-600
															hover:bg-neutral-50
															flex
															items-center
															gap-4
															cursor-pointer
														"
														:class="
															active ? '' : ''
														"
													>
														<svg
															width="16"
															height="16"
															viewBox="0 0 16 16"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M2.5 1C1.94772 1 1.5 1.44772 1.5 2V3C1.5 3.55228 1.94772 4 2.5 4H3V13C3 14.1046 3.89543 15 5 15H11C12.1046 15 13 14.1046 13 13V4H13.5C14.0523 4 14.5 3.55228 14.5 3V2C14.5 1.44772 14.0523 1 13.5 1H10C10 0.447715 9.55229 0 9 0H7C6.44772 0 6 0.447715 6 1H2.5ZM5.5 5C5.77614 5 6 5.22386 6 5.5V12.5C6 12.7761 5.77614 13 5.5 13C5.22386 13 5 12.7761 5 12.5L5 5.5C5 5.22386 5.22386 5 5.5 5ZM8 5C8.27614 5 8.5 5.22386 8.5 5.5V12.5C8.5 12.7761 8.27614 13 8 13C7.72386 13 7.5 12.7761 7.5 12.5V5.5C7.5 5.22386 7.72386 5 8 5ZM11 5.5C11 5.22386 10.7761 5 10.5 5C10.2239 5 10 5.22386 10 5.5V12.5C10 12.7761 10.2239 13 10.5 13C10.7761 13 11 12.7761 11 12.5V5.5Z"
																fill="#94A3B8"
															/>
														</svg>

														<span class="mt-0.5"
															>Xoá buổi học</span
														>
													</div>
												</MenuItem>
											</MenuItems>
										</transition>
									</Menu>
								</div>
							</div>
							<div
								v-else
								class="flex items-center w-full justify-between"
							>
								<div class="text-neutral-400 text-lg">
									Lớp nhóm chưa có buổi học nào
								</div>
								<Menu as="div" class="ml-3 relative">
									<div>
										<MenuButton
											class="
												rounded-full
												flex
												text-sm
												focus:outline-none
												focus:ring-2
												focus:ring-offset-2
												focus:ring-indigo-500
											"
										>
											<span class="sr-only"
												>Open user menu</span
											>
											<svg
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M11.875 16.25C11.875 17.2855 11.0355 18.125 10 18.125C8.96447 18.125 8.125 17.2855 8.125 16.25C8.125 15.2145 8.96447 14.375 10 14.375C11.0355 14.375 11.875 15.2145 11.875 16.25ZM11.875 10C11.875 11.0355 11.0355 11.875 10 11.875C8.96447 11.875 8.125 11.0355 8.125 10C8.125 8.96447 8.96447 8.125 10 8.125C11.0355 8.125 11.875 8.96447 11.875 10ZM11.875 3.75C11.875 4.78553 11.0355 5.625 10 5.625C8.96447 5.625 8.125 4.78553 8.125 3.75C8.125 2.71447 8.96447 1.875 10 1.875C11.0355 1.875 11.875 2.71447 11.875 3.75Z"
													fill="#94A3B8"
												/>
											</svg>
										</MenuButton>
									</div>
									<transition
										enter-active-class="transition ease-out duration-200"
										enter-from-class="transform opacity-0 scale-95"
										enter-to-class="transform opacity-100 scale-100"
										leave-active-class="transition ease-in duration-75"
										leave-from-class="transform opacity-100 scale-100"
										leave-to-class="transform opacity-0 scale-95"
									>
										<MenuItems
											class="
												origin-top-right
												absolute
												right-0
												mt-2
												w-60
												rounded-md
												py-1
												bg-white
												focus:outline-none
												z-10
												shadow-sm
												border border-yellow-100
											"
										>
											<MenuItem
												v-slot="{ active }"
												@click="openCreated(index)"
											>
												<div
													class="
														p-2
														px-4
														bg-white
														z-10
														text-gray-600
														hover:bg-neutral-50
														flex
														items-center
														gap-4
														cursor-pointer
													"
													:class="active ? '' : ''"
												>
													<svg
														width="16"
														height="16"
														viewBox="0 0 16 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
															fill="#94A3B8"
														/>
													</svg>

													<span class="mt-0.5"
														>Thêm buổi học</span
													>
												</div>
											</MenuItem>
										</MenuItems>
									</transition>
								</Menu>
							</div>
						</div>
					</div>
				</div>
				<div
					v-else
					class="
						w-full
						h-full
						flex
						items-center
						justify-center
						flex-col
					"
				>
					<img
						src="@/assets/images/illustrations/not_found.png"
						alt=""
						height="250"
						width="250"
					/>
					<template
						v-if="
							groupClass?.staffs_count &&
							groupClass?.students_count
						"
					>
						<div
							class="text-neutral-400 text-lg text-center"
							style="max-width: 900px"
						>
							Lớp nhóm chưa có buổi học nào.
						</div>
						<div class="mt-8">
							<button
								type="button"
								class="
									button-p
									text-center
									shadow-none
									flex
									items-center
									justify-center
									w-fit
									px-6
									bg-none
									btn--ghost
								"
								@click="openCreated"
							>
								Tạo buổi học nhóm mới
							</button>
						</div>
					</template>
					<template v-else
						><div
							class="text-neutral-400 text-lg text-center"
							style="max-width: 900px"
						>
							Bạn cần ít nhất một Trị liệu viên và một Học sinh để
							bắt đầu tạo buổi học cho lớp nhóm.
						</div>
						<div class="mt-8 flex gap-4 items-center">
							<button
								v-if="!groupClass?.staffs_count"
								type="button"
								class="
									button-p
									text-center
									shadow-none
									flex
									items-center
									justify-center
									w-fit
									px-6
									bg-none
									btn--ghost
								"
								@click.stop="
									$router.push(
										`/group-classes/${id}/add-staff`
									)
								"
							>
								Thêm Trị liệu viên
							</button>
							<button
								v-if="!groupClass?.students_count"
								type="button"
								class="
									button-p
									text-center
									shadow-none
									flex
									items-center
									justify-center
									w-fit
									px-6
									bg-none
									btn--ghost
								"
								@click.stop="
									$router.push(
										`/group-classes/${id}/add-student`
									)
								"
							>
								Thêm học sinh
							</button>
						</div>
					</template>
				</div>
			</div>
		</div>
		<div v-else class="fixed top-1/2 left-1/2">
			<Loading />
		</div>
	</div>
</template>

<script>
import { _ROUTER_NAME } from '@/enums';
import { $api } from '@/services';
import UserGroup from '@/components/elements/UserGroup.vue';
import Loading from '@/components/common/Loading.vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import CreateTimeSlotGroup from '@/views/groupClassDetail/features/CreateTimeSlotGroup.vue';
import UpdateGroupClass from '@/views/groupClasses/features/UpdateGroupClass.vue';
import DeleteGroupClass from '@/views/groupClasses/features/DeleteGroupClass.vue';
import DeleteConfirm from '@/views/feeNotoceDetail/features/DeleteConfirm.vue';

export default {
	name: 'GroupClassDetail',

	components: {
		DeleteConfirm,
		DeleteGroupClass,
		UpdateGroupClass,
		CreateTimeSlotGroup,
		DaModalFrame,
		Loading,
		UserGroup,
		Menu,
		MenuItem,
		MenuItems,
		MenuButton,
	},

	data() {
		return {
			groupClass: null,
			isLoading: true,
			data: [],
			dataUpdate: null,
			deletedId: '',
			modalIsOpen: false,
			isOpenCreated: false,
			day_of_week: null,
			timetable_id: '',
			error: '',
			action: '',
			inProgress: false,
		};
	},

	computed: {
		_ROUTER_NAME() {
			return _ROUTER_NAME;
		},

		id() {
			return this.$route.params?.id;
		},
	},

	created() {
		this.fetchGroupClassDetail();
		this.getGroupTimeSlots();
	},

	methods: {
		async fetchGroupClassDetail() {
			try {
				const {
					data: { data },
				} = await $api.groupClasses.detail(this.id);
				this.groupClass = data;
			} catch (e) {
				console.log(e);
			}
		},

		async getGroupTimeSlots() {
			try {
				const {
					data: { data },
				} = await $api.groupClasses.groupTimeSlots(this.id);
				this.data = data || [];
			} catch (err) {
				await this.$store.dispatch('setAlertMessage', {
					message:
						err?.response?.data?.errors?.join(<br />) ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.isLoading = false;
			}
		},

		getTimeTableData(day) {
			return this.data.filter((item) => item.day_of_week === day);
		},

		openCreated(day, d) {
			this.isOpenCreated = true;
			this.day_of_week = day;

			if (d) {
				this.dataUpdate = d;
			} else {
				this.dataUpdate = null;
			}
		},

		async deleteSubmit() {
			try {
				await $api.groupClasses.deleteTimeSlot(this.id, this.deletedId);
				this.modalIsOpen = false;
				await this.getGroupTimeSlots();
				await this.$store.dispatch('setAlertMessage', {
					message: 'Đã xóa thành công buổi học',
				});
			} catch (err) {
				await this.$store.dispatch('setAlertMessage', {
					message:
						err?.response?.data?.errors?.join(<br />) ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},

		async deleteGroupClass() {
			this.inProgress = true;
			try {
				await $api.groupClasses.delete(this.id);
				this.closeModal();
				this.$store.dispatch('setAlertMessage', {
					message: 'Đã xoá lớp nhóm',
				});
				this.$router.push('/group-classes');
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.inProgress = false;
			}
		},

		async updateGroupClass(data) {
			this.inProgress = true;
			try {
				await $api.groupClasses.update(this.id, data);
				this.closeModal();
				this.$store.dispatch('setAlertMessage', {
					message: 'Chỉnh sửa lớp nhóm thành công',
				});
				await this.fetchGroupClassDetail();
			} catch (e) {
				this.error = e?.response?.data?.errors_by_field;
			} finally {
				this.inProgress = false;
			}
		},

		openModalDeleted() {
			this.action = 'deleting';
			this.modalIsOpen = true;
		},

		openModalUpdated() {
			this.action = 'updating';
			this.modalIsOpen = true;
		},

		closeModal() {
			this.action = '';
			this.modalIsOpen = false;
			this.groupDeleted = null;
			this.groupUpdated = null;
		},

		success() {
			this.getGroupTimeSlots();
			this.isOpenCreated = false;
		},

		openDeleteConfirm(id) {
			this.action = 'deleting-slot';
			this.deletedId = id;
			this.modalIsOpen = true;
		},
	},
};
</script>
