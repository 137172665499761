import { ref } from 'vue';
import { $api } from '@/services';

const error = ref(null);
const loading = ref(false);

export async function getWeeklyReport(
	id: string,
	query?: string,
	oldPayload?: any
) {
	error.value = null;
	try {
		const res = await $api.weeklyReport.weeklyReports(
			id,
			query,
			oldPayload
		);
		if (!res) throw new Error('Lỗi');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

export async function getPublicWeeklyReport(query: string) {
	error.value = null;
	try {
		const res = await $api.weeklyReport.weeklyReportPublic(query);
		if (!res) throw new Error('Lỗi');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

async function submitReport(
	id: string,
	data: { note: string; week_start: string }
) {
	error.value = null;
	try {
		const res = await $api.weeklyReport.updateWeeklyReport(id, data);
		if (!res) throw new Error('Lỗi');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

export function useWeeklyReport() {
	return {
		error,
		loading,
		getWeeklyReport,
		submitReport,
		getPublicWeeklyReport,
	};
}
