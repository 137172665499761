<template>
	<div
		class="fixed top-0 right-0 w-full"
		style="background: #faf5f1; height: 100vh"
	>
		<div class="py-12 w-full px-6">
			<div class="flex items-center justify-between">
				<div class="font-semibold text-neutral-400 whitespace-nowrap">
					Xem trước
				</div>
				<div
					class="hidden md:block cursor-pointer"
					@click="$emit('close')"
				>
					<svg
						fill="none"
						height="28"
						viewBox="0 0 28 28"
						width="28"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							clip-rule="evenodd"
							d="M8.71478 8.71453C9.05649 8.37282 9.61051 8.37282 9.95221 8.71453L14.5835 13.3458L19.2148 8.71453C19.5565 8.37282 20.1105 8.37282 20.4522 8.71453C20.7939 9.05624 20.7939 9.61026 20.4522 9.95197L15.8209 14.5833L20.4522 19.2145C20.7939 19.5562 20.7939 20.1103 20.4522 20.452C20.1105 20.7937 19.5565 20.7937 19.2148 20.452L14.5835 15.8207L9.95221 20.452C9.61051 20.7937 9.05649 20.7937 8.71478 20.452C8.37307 20.1103 8.37307 19.5562 8.71478 19.2145L13.3461 14.5833L8.71478 9.95197C8.37307 9.61026 8.37307 9.05624 8.71478 8.71453Z"
							fill="#94A3B8"
							fill-rule="evenodd"
						/>
					</svg>
				</div>
				<div class="md:hidden cursor-pointer" @click="$emit('close')">
					<button
						class="
							button-p
							btn--ghost
							text-center
							whitespace-nowrap
							w-fit
						"
						type="button"
					>
						Làm báo cáo
					</button>
				</div>
			</div>
			<div class="mt-2 font-bold text-3xl text-neutral-500">
				MT cần báo cáo
			</div>
		</div>
		<div>
			<div v-for="(s, i) in skills" :key="i" class="mb-4">
				<div
					class="
						text-neutral-500
						font-semibold
						px-6
						mb-4
						cursor-pointer
						flex
						justify-between
						items-start
					"
					@click="isSubShow = i"
				>
					<div class="mr-8">
						{{ i + 1 }}. {{ s?.name || s?.skill?.name }}
					</div>
					<div class="flex items-center justify-end gap-2">
						<svg
							v-if="
								validates?.skill_ids_completed?.includes(
									s.skill?.id
								)
							"
							fill="none"
							height="16"
							viewBox="0 0 16 16"
							width="16"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clip-rule="evenodd"
								d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM12.0303 4.96967C11.7374 4.67678 11.2625 4.67678 10.9697 4.96967C10.9626 4.97674 10.9559 4.98424 10.9498 4.9921L7.47739 9.41674L5.38387 7.32322C5.09097 7.03033 4.6161 7.03033 4.32321 7.32322C4.03031 7.61612 4.03031 8.09099 4.32321 8.38388L6.96965 11.0303C7.26255 11.3232 7.73742 11.3232 8.03031 11.0303C8.03684 11.0238 8.04299 11.0169 8.04875 11.0097L12.041 6.01947C12.3232 5.72582 12.3196 5.25897 12.0303 4.96967Z"
								fill="#2AA797"
								fill-rule="evenodd"
							/>
						</svg>
						<div
							:class="
								isChildSelected(s) || isSubShow === i
									? 'transform rotate-180'
									: ''
							"
							class="flex items-center"
						>
							<svg
								fill="none"
								height="6"
								viewBox="0 0 10 6"
								width="10"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M9 5L5 1L1 5"
									stroke="#94A3B8"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
								/>
							</svg>
						</div>
					</div>
				</div>
				<div v-if="isChildSelected(s) || isSubShow === i">
					<div
						v-for="(g, index) in s.goals"
						:key="g?.goal_id || g?.id"
						:class="
							isCompare(g, goalSelected)
								? 'border-yellow-600 text-yellow-700 bg-white'
								: 'border-cream'
						"
						class="
							py-4
							pl-10
							pr-6
							text-sm
							font-semibold
							text-neutral-500
							flex
							items-center
							cursor-pointer
							border-l-2
							hover:border-yellow-600
							hover:text-yellow-700
							hover:bg-white
						"
						@click="selectedGoal(g)"
					>
						<svg
							v-if="
								validates?.group_goal_ids_has_group_assessment?.includes(
									g?.id
								)
							"
							fill="none"
							height="16"
							viewBox="0 0 16 16"
							width="16"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clip-rule="evenodd"
								d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM12.0303 4.96967C11.7374 4.67678 11.2625 4.67678 10.9697 4.96967C10.9626 4.97674 10.9559 4.98424 10.9498 4.9921L7.47739 9.41674L5.38387 7.32322C5.09097 7.03033 4.6161 7.03033 4.32321 7.32322C4.03031 7.61612 4.03031 8.09099 4.32321 8.38388L6.96965 11.0303C7.26255 11.3232 7.73742 11.3232 8.03031 11.0303C8.03684 11.0238 8.04299 11.0169 8.04875 11.0097L12.041 6.01947C12.3232 5.72582 12.3196 5.25897 12.0303 4.96967Z"
								fill="#2AA797"
								fill-rule="evenodd"
							/>
						</svg>
						<svg
							v-else
							fill="none"
							height="16"
							viewBox="0 0 16 16"
							width="16"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clip-rule="evenodd"
								d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM12.1667 7.99677C12.1653 7.63006 11.942 7.33333 11.6667 7.33333H4.66667C4.39052 7.33333 4.16667 7.63181 4.16667 8C4.16667 8.36819 4.39052 8.66667 4.66667 8.66667H11.6667C11.942 8.66667 12.1653 8.36994 12.1667 8.00323C12.1667 8.00216 12.1667 8.00108 12.1667 8C12.1667 7.99892 12.1667 7.99784 12.1667 7.99677Z"
								fill="#E37056"
								fill-rule="evenodd"
							/>
						</svg>
						<div
							class="ml-2 truncate w-full"
							style="max-width: 350px"
						>
							{{ +index + 1 }}.
							{{ g?.goal_name || g?.name }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="p-6 w-full">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'GoalListForReport',

	props: {
		step: {
			type: Number,
			required: true,
		},

		skills: {
			type: Array,
			required: true,
		},

		goalSelected: {
			type: Object,
			required: true,
		},

		validates: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			isSubShow: -1,
		};
	},

	methods: {
		isChildSelected(s) {
			return s.goals.find((item) => {
				const id = item.goal_id || item.id;
				const valueId = this.goalSelected?.id;
				return id === valueId;
			});
		},

		selectedGoal(item) {
			this.$emit('selected', item);
		},

		isCompare(item, goal) {
			if (!item || !goal) {
				return false;
			}
			const id = item.goal_id || item.id;
			const valueId = goal.goal_id || goal.id;
			return id === valueId;
		},
	},
};
</script>
