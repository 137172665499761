<template>
	<div class="_invite-employee _modal bg-white">
		<div
			class="
				_modal__content
				md-down:px-4
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<img
					src="@/assets/images/illustrations/edit.png"
					alt="Luca Education"
					title="Luca Education"
					height="60"
					width="60"
				/>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
				"
			>
				Chỉnh sửa trạng thái thư mục
			</div>
			<div class="text-neutral-400 text-center mt-6">
				Ở trạng thái hoàn thành, các TLV đều xem được thư mục. Ở trạng
				thái bản nháp, chỉ có quản lý xem được thư mục đó
			</div>
			<div class="w-full mt-10">
				<div class="flex items-center">
					<div
						class="
							text-neutral-400
							font-normal
							w-3/5
							font-semibold
							uppercase
							text-sm
						"
					>
						tên thư mục
					</div>
					<div
						class="
							text-neutral-500
							font-normal font-semibold
							text-sm
							w-full
						"
					>
						{{ data.name }}
					</div>
				</div>
				<div class="input-group flex items-center mt-6">
					<div
						class="
							input-group__label
							text-neutral-400
							font-semibold
							w-3/5
							uppercase
							pt-3
						"
					>
						trạng thái
					</div>
					<div
						class="w-full flex items-center justify-between"
						v-if="data"
					>
						<div class="font-normal font-semibold text-sm">
							<span v-if="status" class="text-green-500">
								Hoàn thành
							</span>
							<span v-else class="text-neutral-500">
								Bản nháp
							</span>
						</div>
						<SwitchButton v-model:value="status" />
					</div>
				</div>
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				type="button"
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				@click="$emit('close')"
			>
				Đóng
			</button>
			<button
				type="button"
				class="button-p text-center whitespace-nowrap"
				:disabled="inProgress"
				@click="submit"
			>
				Xác nhận
			</button>
		</div>
	</div>
</template>

<script>
import SwitchButton from '@/components/elements/switchButton/SwitchButton.vue';
export default {
	name: 'EditGoalFolder',
	components: { SwitchButton },
	props: {
		data: {
			required: true,
			type: Object,
		},
	},

	data() {
		return {
			status: false,
			inProgress: false,
		};
	},

	watch: {
		data: {
			immediate: true,
			deep: true,
			handler(value) {
				this.status = value?.is_completed;
			},
		},
	},

	methods: {
		submit() {
			this.$emit('submit', this.status);
		},
	},
};
</script>
