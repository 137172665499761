
import { Options, Vue, setup } from 'vue-class-component';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { Prop, Watch } from 'vue-property-decorator';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import UploadAvatar from '@/components/elements/uploadAvatar/UploadAvatar.vue';
import { $api } from '@/services';
import { useStore } from 'vuex';
import { isNumber } from '@/helper/DAValidate';
import heic2any from 'heic2any';

@Options({
	name: 'InformationTable',
	components: { UploadAvatar, DaModalFrame, DaIcon },
})
export default class InformationTable extends Vue {
	//----------------------- 🤍 Props 🤍 -----------------------//
	@Prop() staff!: any;

	staffData = {
		full_name: '',
		id: 0,
		name: '',
		phone_number: '',
		emergency_phone_number: '',
		date_of_birth: '',
		role: '',
		state: '',
	};

	editMode = false;
	date = new Date();

	error = {
		name: '',
		full_name: '',
		status: false,
		phone_number: '',
		emergency_phone_number: '',
	};

	setup = setup(() => {
		const store = useStore();
		return {
			store,
		};
	});

	//----------------------- 🤍 Watch 🤍 -----------------------//
	@Watch('staff', { immediate: true, deep: true })
	onStaffChange(value: any) {
		this.staffData = value;
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	get isUpload() {
		return this.setup.store.state.profile.staff.id === this.staffData.id;
	}

	get isAdmin() {
		return this.setup.store.getters.isAdmin;
	}

	changeEditMode() {
		this.editMode = true;
	}

	save() {
		this.error = {
			name: '',
			full_name: '',
			status: false,
			phone_number: '',
			emergency_phone_number: '',
		};
		if (this.staff.name?.length <= 0) {
			this.error['name'] = 'Tên hiển thị không được bỏ trống';
			this.error.status = true;
		}
		if (this.staff.full_name?.length <= 0) {
			this.error['full_name'] = 'Tên không được bỏ trống';
			this.error.status = true;
		}
		if (this.staff.phone_number && !isNumber(this.staff.phone_number)) {
			this.error['phone_number'] = 'Số điện thoại không hợp lệ';
			this.error.status = true;
		}
		if (this.error.status) {
			return;
		}
		this.editMode = false;
		this.$emit('submit', this.staffData);
	}

	modalIsOpen = false;
	avatar: any = '';

	openModelUpload() {
		const target = document.getElementById('upload-avatar-1');
		target ? target.click() : '';
	}

	uploadChange(event: any) {
		this.avatar = event.target.files[0];

		if (
			/image\/hei(c|f)/.test(event.target.files[0].type) ||
			event.target.files[0].name.endsWith('.heic') ||
			event.target.files[0].name.endsWith('.heif')
		) {
			heic2any({
				blob: event.target.files[0],
				toType: 'image/jpeg',
				quality: 1,
			}).then(async (jpgBlob) => {
				//Change the name of the file according to the new format
				let newName = event.target.files[0].name.replace(
					/\.[^/.]+$/,
					'.jpg'
				);

				//Convert blob back to file
				this.avatar = await this.blobToFile(jpgBlob, newName);

				this.modalIsOpen = true;
			});
		} else {
			this.modalIsOpen = true;
		}
	}

	blobToFile(theBlob: any, fileName: any) {
		//A Blob() is almost a File() - it's just missing the two properties below which we will add

		theBlob.name = fileName;

		//Cast to a File() type
		return new File([theBlob], fileName, {
			lastModified: new Date().getTime(),
			type: theBlob.type,
		});
	}

	close() {
		this.avatar = '';
		const target: any = document.getElementById('upload-avatar-1');
		target ? (target.value = null) : '';
		this.modalIsOpen = false;
	}

	upload(canvas: any) {
		canvas.toBlob(async (blob: any) => {
			let file = new File([blob], this.avatar.name, {
				type: blob.type,
			});
			let data: any = new FormData();
			data.append('profile_photo', file);
			const res = await $api.staff.uploadProfilePicture(data);
			if (res) {
				await this.setup.store.dispatch('setAlertMessage', {
					message: 'Cập nhật ảnh đại diện thành công',
				});
				this.close();
				this.$emit('updated');
			}
		});
	}
}
