
import { Options, Vue } from 'vue-class-component';
import { PropSync, Watch } from 'vue-property-decorator';

@Options({
	name: 'CreateGoalLib',
})
export default class CreateGoalLib extends Vue {
	@PropSync('error', { default: '' })
	error!: any;

	@PropSync('inProgress', { default: false, type: Boolean })
	inProgress!: boolean;

	goalData = {
		name: '',
	};

	errorMes = {
		name: '',
		status: true,
	};

	@Watch('error')
	onErrorPropsChange(value: any) {
		if (value && value['name'] && this.goalData.name.length) {
			this.errorMes['name'] =
				'Tên thư viện mới không được trùng với tên thư viện Đôđã có trước đó.';
		}
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	submit() {
		this.errorMes = {
			name: '',
			status: true,
		};
		if (this.goalData['name']?.length <= 0 && !this.goalData.name?.length) {
			this.errorMes['name'] = 'Tên thư viện không được bỏ trống';
			this.errorMes.status = true;
			setTimeout(() => {
				this.errorMes = {
					name: '',
					status: true,
				};
			}, 3000);
		}
		this.$emit('submit', this.goalData);
	}
}
