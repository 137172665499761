<template>
	<div v-if="showTooltip">
		<Popper
			placement="top"
			hover
			:arrow="true"
			@open:popper="fetchGoalDetail"
		>
			<div class="cursor-pointer">
				<svg
					width="12"
					height="12"
					viewBox="0 0 16 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M8 16.0001C12.4183 16.0001 16 12.4183 16 8.00006C16 3.58178 12.4183 6.10352e-05 8 6.10352e-05C3.58172 6.10352e-05 0 3.58178 0 8.00006C0 12.4183 3.58172 16.0001 8 16.0001ZM8.9307 6.58795L6.63969 6.87506L6.55766 7.25592L7.00883 7.33795C7.3018 7.40826 7.36039 7.51373 7.29594 7.8067L6.55766 11.2755C6.3643 12.1719 6.66313 12.5938 7.36625 12.5938C7.91117 12.5938 8.54398 12.3419 8.83109 11.9962L8.91898 11.5801C8.71977 11.7559 8.4268 11.8262 8.23344 11.8262C7.95805 11.8262 7.85844 11.6329 7.92875 11.293L8.9307 6.58795ZM8 5.50006C8.55229 5.50006 9 5.05235 9 4.50006C9 3.94778 8.55229 3.50006 8 3.50006C7.44772 3.50006 7 3.94778 7 4.50006C7 5.05235 7.44772 5.50006 8 5.50006Z"
						fill="#94A3B8"
					/>
				</svg>
			</div>
			<template #content>
				<div style="max-width: 300px">
					<div v-if="org.enable_goal_reason">
						<span class="font-semibold">Cơ sở hợp lý:</span>
						<div
							v-if="goal?.reason"
							v-html="goal?.reason"
							style="white-space: pre-wrap; word-wrap: break-word"
						></div>
						<span v-else>-</span>
					</div>
					<div v-if="org.enable_goal_strategy" class="mt-1">
						<span class="font-semibold">Chiến lược: </span>
						<div
							v-if="goal?.strategy"
							v-html="goal?.strategy"
							style="white-space: pre-wrap; word-wrap: break-word"
						></div>
						<span v-else>-</span>
					</div>
					<div v-if="org.enable_goal_process" class="mt-1">
						<span class="font-semibold">Quy trình thực hiện: </span>
						<div
							v-if="goal?.process"
							v-html="goal?.process"
							style="white-space: pre-wrap; word-wrap: break-word"
						></div>
						<span v-else>-</span>
					</div>
					<div class="mt-1 text-hidden" v-if="!tooltipData">
						<span class="font-semibold">Hoạt động: </span>
						<div
							v-html="formatActivities(goal?.activities)"
							style="white-space: pre-wrap; word-wrap: break-word"
						></div>
					</div>
				</div>
			</template>
		</Popper>
	</div>
</template>

<script>
import { defineComponent, computed, ref } from 'vue';
import { useStore } from 'vuex';
import Popper from 'vue3-popper';
import { $api } from '@/services';

export default defineComponent({
	name: 'tooltipGoalDetail',

	components: { Popper },

	props: {
		tooltipData: {
			type: Object,
			default: null,
		},
		goalId: {
			type: String,
			required: true,
		},
		studentId: {
			type: String,
			required: true,
		},
		isGroup: {
			type: Boolean,
			default: false,
		},
	},

	setup(props) {
		const store = useStore();
		const goal = ref({
			reason: '',
			strategy: '',
			process: '',
		});

		function formatActivities(activities) {
			if (!activities || activities?.length <= 0) {
				return '-';
			} else if (activities?.length === 1) {
				return activities[0].name;
			} else {
				return activities
					.map(function (activity) {
						return activity.name;
					})
					.join(', ');
			}
		}

		async function fetchGoalDetail() {
			if (props.tooltipData) {
				const { reason, strategy, process } = props.tooltipData;
				goal.value.reason = reason;
				goal.value.strategy = strategy;
				goal.value.process = process;
				return;
			}
			try {
				const {
					data: { data },
				} = props.isGroup
					? await $api.groupClasses.goalDetail(
							String(props.studentId || ''),
							String(props.goalId || '')
							// eslint-disable-next-line no-mixed-spaces-and-tabs
					  )
					: await $api.goal.goalDetail(
							String(props.goalId || ''),
							String(props.studentId || '')
							// eslint-disable-next-line no-mixed-spaces-and-tabs
					  );
				goal.value = data;
			} catch (e) {
				console.log(e);
			}
		}

		const org = computed(() => store.state.profile.current_org);
		const showTooltip = computed(
			() =>
				org.value?.enable_goal_reason ||
				org.value?.enable_goal_strategy ||
				org.value?.enable_goal_process ||
				(!props.tooltipData &&
					goal?.value?.activities &&
					goal?.value?.length)
		);

		return {
			fetchGoalDetail,
			formatActivities,
			goal,
			org,
			showTooltip,
		};
	},
});
</script>

<style scoped lang="scss">
.text-hidden {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
</style>
