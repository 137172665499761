
import { Options, Vue } from 'vue-class-component';
import Checkbox from '@/components/elements/checkbox/Checkbox.vue';
import { PropSync, Watch } from 'vue-property-decorator';

@Options({
	name: 'InviteEmployee',
	components: { Checkbox },
})
export default class InviteEmployee extends Vue {
	//----------------------- 🤍 Props 🤍 -----------------------//
	@PropSync('invited', { default: false, type: Boolean })
	invitedValue!: boolean;

	@PropSync('inProgress', { default: false, type: Boolean })
	inProgress!: boolean;

	@PropSync('error-prop', { type: Object })
	errorProp!: any;

	//----------------------- 🤍 Data 🤍 -----------------------//

	staff = {
		email: '',
		full_name: '',
		name: '',
		phone_number: '',
		role: 'teacher',
		state: 'active',
		send_invitation: true,
	};

	error = {
		name: '',
		full_name: '',
		email: '',
		status: false,
	};

	@Watch('errorProp', { deep: true })
	onErrorChange(value: any) {
		this.error = value;
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	submit() {
		this.error = {
			name: '',
			full_name: '',
			email: '',
			status: false,
		};
		if (this.staff.name?.length <= 0) {
			this.error['name'] = 'Tên hiển thị không được bỏ trống';
			this.error.status = true;
		}
		if (this.staff.full_name?.length <= 0) {
			this.error['full_name'] = 'Tên không được bỏ trống';
			this.error.status = true;
		}
		if (this.staff.email?.length <= 0) {
			this.error['email'] = 'Email không được bỏ trống';
			this.error.status = true;
		}
		if (this.error.status) {
			setTimeout(() => {
				this.error = {
					name: '',
					full_name: '',
					email: '',
					status: false,
				};
			}, 3000);
			return;
		}
		this.$emit('submit', this.staff);
	}
}
