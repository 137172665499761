
import { Options, Vue, setup } from 'vue-class-component';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import InviteEmployee from '@/views/record/employee/features/inviteEmployee/InviteEmployee.vue';
import SendInvite from '@/views/record/employee/features/sendInvite/SendInvite.vue';
import { _MODAL_TYPE, _ROUTER_NAME } from '@/enums';
import { useGetEmployees } from '@/views/record/employee/services/getEmployees';
import { useCreateStaff } from '@/views/record/employee/services/createStaff';
import { staff } from '@/views/record/employeeDetail/@types/staff.interface';
import { useSendInviteStaff } from '@/views/record/employee/services/sendInviteStaff';
import { Watch } from 'vue-property-decorator';
import { useResendInviteStaff } from '@/views/record/employee/services/resendInviteStaff';
import { useStore } from 'vuex';
import Loading from '@/components/common/Loading.vue';

@Options({
	name: 'Employee',
	components: { Loading, SendInvite, InviteEmployee, DaModalFrame, DaIcon },
})
export default class Employee extends Vue {
	//----------------------- 🤍 Data 🤍 -----------------------//
	staffs: staff[] = [];

	count = {
		closed: 0,
		total: 0,
	};

	modalIsOpen = false;
	modalType = 'INVITE';
	createdData = false;
	invited = false;
	change = false;
	staffSendInvite: staff = {
		full_name: '',
		id: 0,
		name: '',
		phone_number: '',
		role: '',
		state: '',
		send_invitation: false,
	};
	error = {
		email: '',
		name: '',
	};

	errorCreateStaff = {
		email: '',
		name: '',
	};

	isLoading = true;
	inProgress = false;
	state = 'active';

	//----------------------- 🤍 Setup 🤍 -----------------------//
	setup = setup(() => {
		const store = useStore();
		const { error, loading, getEmployees } = useGetEmployees();
		const { createStaff__error, createStaff } = useCreateStaff();
		const { resendInviteStaff__error, resendInviteStaff } =
			useResendInviteStaff();
		const { sendInviteStaff__error, sendInviteStaff } =
			useSendInviteStaff();
		return {
			error,
			createStaff__error,
			sendInviteStaff__error,
			resendInviteStaff__error,
			loading,
			getEmployees,
			createStaff,
			sendInviteStaff,
			resendInviteStaff,
			store,
		};
	});

	//----------------------- 🤍 Mounted 🤍 -----------------------//
	async created() {
		const staffList = await this.setup.getEmployees('');
		this.isLoading = false;
		this.count = staffList.count;
		this.staffs = staffList.data;
	}

	//----------------------- 🤍 Getters 🤍 -----------------------//
	get ModalType() {
		return _MODAL_TYPE;
	}

	get routerName() {
		return _ROUTER_NAME;
	}

	get store() {
		return this.setup.store;
	}

	get staffList() {
		return this.staffs;
	}

	get isAdmin() {
		return this.setup.store.getters.isAdmin;
	}

	get staffListFilters() {
		if (this.state === '') {
			return this.staffList;
		} else if (this.state === 'active') {
			return this.staffList.filter((s) => s.state === 'active');
		} else {
			return this.staffList.filter((s) => s.state !== 'active');
		}
	}

	//----------------------- 🤍 Watch 🤍 -----------------------//
	@Watch('change')
	async onChange() {
		const staffList = await this.setup.getEmployees('');
		this.count = staffList.count;
		this.staffs = staffList.data;
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	openModal(type: string) {
		this.modalIsOpen = true;
		this.modalType = type;
	}

	statusLabel(status: string) {
		if (status === 'active') {
			return 'Kích hoạt';
		} else if (status === 'closed') {
			return 'Khóa';
		}
		return '';
	}

	async createStaff(value: any) {
		this.inProgress = true;
		const data = await this.setup.createStaff({
			staff: {
				email: value.email,
				full_name: value.full_name,
				name: value.name,
				phone_number: value.phone_number,
				role: value.role,
				state: value.state,
				send_invitation: value.send_invitation,
			},
		});
		this.inProgress = false;
		if (data && data?.data) {
			this.createdData = true;
			this.change = !this.change;
		}
		if (this.setup.createStaff__error) {
			let error: any = this.setup.createStaff__error;
			if (error && error['email']) {
				this.errorCreateStaff['email'] = error['email'].join('<br/>');
			}
			if (error && error['name']) {
				this.errorCreateStaff['name'] = error['name'].join('<br/>');
			}
		}

		if (data.errors?.length) {
			await this.store.dispatch('setAlertMessage', {
				message:
					data.errors?.join('<br/>') ||
					'Đã có lỗi xảy ra. Vui lòng thử lại',
				type: 'danger',
			});
		}
	}

	openModalSen(staff: staff) {
		this.invited = false;
		this.staffSendInvite = staff;
		this.openModal(this.ModalType.SEND_EMAIL);
	}

	openModalCreate() {
		this.createdData = false;
		this.staffSendInvite = {
			full_name: '',
			id: 0,
			name: '',
			phone_number: '',
			role: '',
			state: '',
			send_invitation: false,
		};
		this.openModal(this.ModalType.INVITE);
	}

	async sendInvite(value: { email: string; staff_id: string }) {
		this.inProgress = true;
		const data = await this.setup.sendInviteStaff(
			value.staff_id,
			value.email
		);
		this.inProgress = false;
		if (data && data?.data) {
			this.invited = true;
			this.change = !this.change;
		}

		if (data && data.errors_by_field) {
			if (data.errors_by_field['email']) {
				this.error['email'] =
					data.errors_by_field['email'].join('<br/>');
			}
		}

		if (data.errors?.length) {
			await this.store.dispatch('setAlertMessage', {
				message:
					data.errors?.join('<br/>') ||
					'Đã có lỗi xảy ra. Vui lòng thử lại',
				type: 'danger',
			});
		}
	}

	async resendInvite(value: { id: string; email: string }) {
		this.inProgress = true;
		const data = await this.setup.resendInviteStaff(value.id, value.email);
		this.inProgress = false;
		if (data && data?.data) {
			this.invited = true;
			this.change = !this.change;
		}

		if (data.errors?.length) {
			await this.store.dispatch('setAlertMessage', {
				message:
					data.errors?.join('<br/>') ||
					'Đã có lỗi xảy ra. Vui lòng thử lại',
				type: 'danger',
			});
		}
	}

	searchQuery = '';
	typing: any = null;

	async search() {
		const res = await this.setup.getEmployees(this.searchQuery);
		this.staffs = res?.data || [];
	}

	searching() {
		clearInterval(this.typing);
		this.typing = setTimeout(() => {
			this.search();
		}, 1000);
	}
}
