import { ref } from 'vue';
import { $api } from '@/services';

const error = ref(null);
const loading = ref(false);

export async function getWeeklyReportList(query?: any) {
	error.value = null;
	try {
		const res = await $api.weeklyReport.weeklyReportsList(query);
		if (!res) throw new Error('Lỗi');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

export async function getWeeklyReportListGroup(query?: any) {
	error.value = null;
	try {
		const res = await $api.weeklyReport.weeklyReportsListGroup(query);
		if (!res) throw new Error('Lỗi');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

export function useWeeklyReportList() {
	return { error, loading, getWeeklyReportList, getWeeklyReportListGroup };
}
