
import { defineComponent } from 'vue';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';

export default defineComponent({
	name: 'GoalDetails',
	components: { DaIcon },
	setup() {
		const people = [
			{
				id: 1,
				type: 'Mới',
				content: 'Củng cố đứng 1 chân 3-5s',
			},
			{
				id: 2,
				type: 'Mới',
				content: 'Củng cố đứng 1 chân 3-5s',
			},
			{
				id: 3,
				type: 'Mới',
				content: 'Củng cố đứng 1 chân 3-5s',
			},
		];
		return { people };
	},
});
