<template>
	<div v-if="!isLoading" class="__record">
		<div class="__record__content" v-if="!isLoading">
			<div
				id="timetable__table__box"
				class="mt-10 relative"
				v-if="timeTable.length > 0"
			>
				<div
					v-if="scrollWidth > 0 && scrollRightPosition > 0"
					@click="scrollRight"
					class="
						absolute
						bg-white
						h-full
						w-8
						-right-8
						border border-l
						flex
						items-center
						justify-center
						cursor-pointer
					"
				>
					<div class="fixed top-1/2 -translate-x-1/2">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M9 5L16 12L9 19"
								stroke="#E5A42B"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</div>
				</div>
				<div
					v-if="scrollWidth > 0 && scrollLeftPosition > 0"
					@click="scrollLeft"
					class="
						absolute
						bg-white
						h-full
						w-8
						-left-6
						border border-l
						flex
						items-center
						justify-center
						cursor-pointer
					"
				>
					<div class="fixed top-1/2 -translate-x-1/2">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M15 5L8 12L15 19"
								stroke="#E5A42B"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</div>
				</div>
				<div
					id="timetable__table"
					class="__record__table overflow-x-auto scroll-bar"
					@scroll="onTableScroll()"
				>
					<div class="overflow-hidden sm:rounded-t-2xl w-fit">
						<table class="divide-y text-neutral-200">
							<thead class="bg-neutral-100 h-16">
								<tr>
									<th
										style="width: 200px"
										scope="col"
										class="
											px-3
											py-3
											text-center text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											w-32
											whitespace-nowrap
										"
									>
										Lớp nhóm
									</th>
									<th
										style="
											width: 12.5%;
											min-width: 150px;
											max-width: 170px;
										"
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
											border-l
										"
										v-for="i in 7"
										:key="i"
									>
										{{
											i !== 7
												? `Thứ ${i + 1}`
												: 'Chủ nhật'
										}}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(item, index) in timeTable"
									:key="index"
									:class="{ 'border-t': index !== 0 }"
									class="bg-white text-neutral-700"
								>
									<td
										class="p-2 px-4"
										style="
											width: 200px;
											vertical-align: top;
										"
									>
										<div
											class="
												font-semibold
												text-neutral-700
												mb-3
											"
										>
											{{ item.name }}
										</div>
										<UserGroup
											:data="item?.students"
											:size="8"
											placement="bottom-start"
										/>
									</td>
									<td
										class="
											border-l
											p-2
											space-y-2
											items-start
										"
										style="
											width: 12.5%;
											min-width: 150px;
											max-width: 170px;
											vertical-align: top;
										"
										v-for="index in 7"
										:key="index"
									>
										<div
											v-for="(
												data, i
											) in getTimeTableData(
												item.group_time_slots,
												index
											)"
											:key="i"
										>
											<div
												class="
													p-2
													border-l-2
													rounded-lg
													flex
													w-full
													items-center
													justify-between
													gap-2
												"
												:class="
													data.overlapped
														? 'bg-sienna-100 border-sienna-600'
														: 'bg-blue-100 border-blue-600'
												"
											>
												<div
													class="
														font-semibold
														text-neutral-600
														whitespace-nowrap
														text-sm
													"
												>
													{{
														data.display_start_slot
													}}
													-
													{{ data.display_end_slot }}
												</div>
												<div
													class="
														flex
														items-center
														justify-end
														gap-1
													"
												>
													<div
														class="
															flex
															items-center
														"
														v-for="staff in data.staffs"
														:key="staff.id"
													>
														<div class="h-6 w-6">
															<img
																class="
																	shadow-sm
																	inline-block
																	h-6
																	w-6
																	rounded-full
																"
																:src="
																	staff
																		?.profile_photo
																		?.w200
																"
																alt=""
																v-if="
																	staff
																		?.profile_photo
																		?.w200
																"
															/>
															<span
																v-else
																class="
																	inline-flex
																	items-center
																	justify-center
																	h-6
																	w-6
																	rounded-full
																"
																:style="{
																	background:
																		staff
																			?.profile_photo
																			?.default
																			?.color,
																}"
															>
																<span
																	class="
																		font-medium
																		leading-none
																		text-white
																		text-xs
																	"
																	>{{
																		staff
																			?.profile_photo
																			?.default
																			?.initial
																	}}</span
																>
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div
				v-else
				class="
					w-full
					h-full
					flex
					items-center
					justify-center
					flex-col
					gap-6
				"
			>
				<img
					src="@/assets/images/illustrations/empty-tkb.svg"
					alt=""
					height="300"
					width="300"
				/>
				<div class="text-gray-500 text-center">
					Lớp chưa có Thời khoá biểu
				</div>
				<button
					type="button"
					class="
						button-p
						text-center
						shadow-none
						flex
						items-center
						justify-center
						w-fit
						px-6
						bg-none
						btn--ghost
					"
					@click="$emit('updateTimeTable')"
				>
					Tạo mới
				</button>
			</div>
		</div>
		<div v-else class="fixed top-1/2 left-1/2">
			<Loading />
		</div>
	</div>
	<div v-else class="fixed top-1/2 left-1/2">
		<Loading />
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import { $api } from '@/services';
import dayjs from 'dayjs';
import Loading from '@/components/common/Loading';
import { toEnglish } from '@/helper/toEnglish';
import UserGroup from '@/components/elements/UserGroup.vue';

export default defineComponent({
	name: 'TimeTableGroupClass',

	components: {
		UserGroup,
		Loading,
	},

	data() {
		return {
			timeTable: [],
			params: {
				date: dayjs().format('YYYY-MM-DD'),
			},
			isLoading: true,
			scrollWidth: 0,
			scrollLeftPosition: 0,
			scrollRightPosition: 0,
		};
	},

	created() {
		this.fetchTimeTable();
	},

	mounted() {
		const time = setInterval(() => {
			const table = document.getElementById('timetable__table');
			if (table) {
				this.setScrollWidth();
				this.onTableScroll();
				clearInterval(time);
			}
		}, 1000);

		window.addEventListener('resize', this.setScrollWidth);
	},

	unmounted() {
		window.removeEventListener('resize', this.setScrollWidth);
	},

	watch: {
		'$route.query.query'() {
			this.fetchTimeTable();
		},
	},

	methods: {
		toEnglish,
		onTableScroll() {
			const table = document.getElementById('timetable__table');
			const parent = document.getElementById('timetable__table__box');
			this.scrollLeftPosition = table?.scrollLeft || 0;
			this.scrollRightPosition =
				table.scrollWidth -
				this.scrollLeftPosition -
				parent.clientWidth;
		},

		setScrollWidth() {
			const table = document.getElementById('timetable__table');
			const parent = document.getElementById('timetable__table__box');
			if (table) {
				this.scrollWidth = table.scrollWidth > parent.clientWidth;
			}
		},

		scrollRight() {
			const table = document.getElementById('timetable__table');
			if (table) {
				table.scrollTo(table.scrollWidth, 0);
			}
		},

		scrollLeft() {
			const table = document.getElementById('timetable__table');
			if (table) {
				table.scrollTo(0, 0);
			}
		},

		async fetchTimeTable() {
			try {
				const res =
					await $api.schedule.fetchTimetableByWeekForGroupClass({
						...this.params,
						query: this.$route.query.query,
					});
				this.timeTable = [...res?.data?.data] || [];
			} catch (e) {
				console.log(e);
			} finally {
				this.isLoading = false;
			}
		},

		getTimeTableData(slots, day) {
			return slots?.filter((item) => item.day_of_week === day);
		},
	},
});
</script>
<style lang="scss">
#timetable__table {
	scroll-behavior: smooth;
}
</style>
