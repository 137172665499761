<template>
	<div class="da-infinity-loading w-full flex items-center justify-center">
		<div class="main" v-show="!loaded">
			<div class="one"></div>
			<div class="two"></div>
			<div class="three"></div>
		</div>
	</div>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';

export default defineComponent({
	name: 'DaInfinityLoading',
	props: {
		loaded: {
			type: Boolean,
			default: false,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, { emit }) {
		let loadedOne = ref(false);
		let loadTimeout = null;

		function infiniteLoad() {
			if (!props.isLoading) {
				emit('infinite-load');
			}
		}

		function loading() {
			const loading = document.querySelector('.da-infinity-loading');
			//@ts-ignore
			if (window.scrollY + window.innerHeight > loading?.offsetTop) {
				if (!loadedOne.value) {
					infiniteLoad();
					loadedOne.value = true;
					setTimeout(() => {
						loadedOne.value = false;
					}, 600);
				}
			}
		}

		function beforeScroll() {
			const loading = document.querySelector('.da-infinity-loading');
			let reLoading = null;
			//@ts-ignore
			if (window.scrollY + window.innerHeight > loading?.offsetTop) {
				reLoading = setTimeout(() => {
					infiniteLoad();
				}, 10);
			} else {
				clearInterval(reLoading);
			}
		}

		onMounted(() => {
			clearInterval(loadTimeout);
			loadTimeout = null;
			window.removeEventListener('scroll', loading, true);
			beforeScroll();
			loadTimeout = setInterval(() => {
				loading();
			}, 1000);
			window.addEventListener('scroll', loading, true);
		});

		watch(
			() => props.loaded,
			(value) => {
				if (value) {
					clearInterval(loadTimeout);
					loadTimeout = null;
					window.removeEventListener('scroll', loading, true);
				}
			}
		);

		onUnmounted(() => {
			clearInterval(loadTimeout);
			loadTimeout = null;
			window.removeEventListener('scroll', loading, true);
		});
	},
});
</script>
<style lang="scss" scoped>
.main {
	position: relative;
	display: flex;
}

.main div {
	margin-right: 10px;
	width: 10px;
	height: 10px;
	background-color: var(--popper-theme-background-color);
	border-radius: 50%;
	animation-duration: 0.4s;
	animation-iteration-count: infinite;
	opacity: 0.4;
}

.one {
	animation-name: load-one;
}

@keyframes load-one {
	30% {
		transform: translateY(15px);
	}
}

.two {
	animation-name: load-two;
}

@keyframes load-two {
	50% {
		transform: translateY(15px);
	}
}

.three {
	animation-name: load-three;
}

@keyframes load-three {
	70% {
		transform: translateY(10px);
	}
}
</style>
