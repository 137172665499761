<template>
	<div class="__report-lesson px-12 md-down:px-4 pb-30">
		<div
			class="
				__report-lesson__header
				pt-10
				pb-10
				flex flex-nowrap
				justify-between
				items-center
			"
		>
			<div
				class="
					__record__title
					text-neutral-500
					font-semibold
					whitespace-nowrap
					flex flex-nowrap
					items-center
					text-4xl
				"
			>
				<svg
					width="28"
					height="28"
					viewBox="0 0 28 28"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g clip-path="url(#clip0_11995_7497)">
						<path
							d="M13.7879 7.63636C15.2006 7.63636 16.3333 6.49091 16.3333 5.09091C16.3333 4.60727 16.2061 4.16182 15.9642 3.78L13.7879 0L11.6115 3.78C11.3697 4.16182 11.2424 4.60727 11.2424 5.09091C11.2424 6.49091 12.3879 7.63636 13.7879 7.63636ZM19.6424 20.3509L18.2806 18.9891L16.9061 20.3509C15.2515 22.0055 12.3497 22.0182 10.6824 20.3509L9.3206 18.9891L7.93333 20.3509C7.10606 21.1782 5.99879 21.6364 4.82788 21.6364C3.89879 21.6364 3.04606 21.3436 2.33333 20.86V26.7273C2.33333 27.4273 2.90606 28 3.60606 28H23.9697C24.6697 28 25.2424 27.4273 25.2424 26.7273V20.86C24.5297 21.3436 23.677 21.6364 22.7479 21.6364C21.577 21.6364 20.4697 21.1782 19.6424 20.3509ZM21.4242 11.4545H15.0606V8.90909H12.5152V11.4545H6.15151C4.03879 11.4545 2.33333 13.16 2.33333 15.2727V17.2327C2.33333 18.6073 3.45333 19.7273 4.82788 19.7273C5.4897 19.7273 6.12606 19.4727 6.58424 19.0018L9.30788 16.2909L12.0188 19.0018C12.9606 19.9436 14.6024 19.9436 15.5442 19.0018L18.2679 16.2909L20.9788 19.0018C21.4497 19.4727 22.0733 19.7273 22.7352 19.7273C24.1097 19.7273 25.2297 18.6073 25.2297 17.2327V15.2727C25.2424 13.16 23.537 11.4545 21.4242 11.4545Z"
							fill="#65758B"
						/>
					</g>
					<defs>
						<clipPath id="clip0_11995_7497">
							<rect width="28" height="28" fill="white" />
						</clipPath>
					</defs>
				</svg>
				<span class="ml-2 mt-2">Sinh nhật</span>
			</div>
		</div>
		<div v-if="!isLoading" class="__report-lesson__container">
			<div class="bg-white p-6 rounded-lg shadow-sm text-gray-500">
				<div class="font-semibold text-lg mb-4 text-neutral-500">
					Thống kê sinh nhật {{ title }}
				</div>
				<div class="grid grid-cols-1 lg:grid-cols-2">
					<div
						class="
							p-4
							border
							lg:rounded-l-lg
							md:rounded-tl-lg
							border-neutral-200
							w-full
							md-down:rounded-t md-down:rounded-b-none
						"
					>
						<div class="flex items-center justify-between mb-4">
							<div
								class="text-4xl font-bold text-neutral-600 mt-2"
							>
								{{ stats.student_birthdays.length }}
							</div>
							<div>Học sinh</div>
						</div>
						<div
							class="
								flex
								items-center
								justify-between
								mb-2
								cursor-pointer
							"
							v-for="(student, index) in stats.student_birthdays"
							:key="index"
							@click="
								$router.push({
									name: routerName.RECORD_STUDENT_DETAIL_INFO,
									params: {
										id: student.id,
									},
								})
							"
						>
							<div class="flex items-center">
								<div>
									<img
										class="
											shadow-sm
											inline-block
											h-6
											w-6
											rounded-full
										"
										:src="student?.profile_photo?.w200"
										alt=""
										v-if="student?.profile_photo?.w200"
									/>
									<span
										v-else
										class="
											inline-flex
											items-center
											justify-center
											h-6
											w-6
											rounded-full
										"
										:style="{
											background:
												student?.profile_photo?.default
													?.color,
										}"
									>
										<span
											class="
												font-medium
												leading-none
												text-white text-xs
											"
											>{{
												student?.profile_photo?.default
													?.initial
											}}</span
										>
									</span>
								</div>
								<div
									class="
										ml-4
										text-orange-600
										font-semibold
										truncate
									"
								>
									{{ student.name }}
								</div>
							</div>
							<div>{{ $filters.formatDate2(student.dob) }}</div>
						</div>
					</div>
					<div
						class="
							p-4
							border
							lg:rounded-r-lg
							md:rounded-br-lg
							border-neutral-200
							w-full
							md-down:rounded-b
							lg:border-l-0
							lg-down:border-t-0
						"
					>
						<div class="flex items-center justify-between mb-4">
							<div
								class="text-4xl font-bold text-neutral-600 mt-2"
							>
								{{ stats.staff_birthdays.length }}
							</div>
							<div>Trị liệu viên</div>
						</div>
						<div
							class="
								flex
								items-center
								justify-between
								mb-2
								cursor-pointer
							"
							v-for="(staff, index) in stats.staff_birthdays"
							:key="index"
							@click="
								$router.push({
									name: routerName.RECORD_EMPLOYEE_DETAIL,
									params: {
										id: staff.id,
									},
								})
							"
						>
							<div class="flex items-center">
								<div>
									<img
										class="
											shadow-sm
											inline-block
											h-6
											w-6
											rounded-full
										"
										:src="staff?.profile_photo?.w200"
										alt=""
										v-if="staff?.profile_photo?.w200"
									/>
									<span
										v-else
										class="
											inline-flex
											items-center
											justify-center
											h-6
											w-6
											rounded-full
										"
										:style="{
											background:
												staff?.profile_photo?.default
													?.color,
										}"
									>
										<span
											class="
												font-medium
												leading-none
												text-white text-xs
											"
											>{{
												staff?.profile_photo?.default
													?.initial
											}}</span
										>
									</span>
								</div>
								<div
									class="
										ml-4
										text-blue-600
										font-semibold
										truncate
									"
								>
									{{ staff.name }}
								</div>
							</div>
							<div>
								{{ $filters.formatDate(staff.date_of_birth) }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="font-semibold text-lg mb-4 text-neutral-500 mt-6">
				Danh sách sinh nhật
			</div>
			<div class="shadow-sm sm:rounded-2xl overflow-hidden mt-4">
				<div class="__record__table overflow-x-auto scroll-bar">
					<div
						class="
							overflow-hidden
							sm:rounded-t-2xl
							w-fit
							min-w-full
						"
					>
						<table class="min-w-full divide-y text-neutral-200">
							<thead class="bg-neutral-100 h-16">
								<tr>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
											text-left
										"
									>
										tháng
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										học sinh
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										TLV
									</th>
									<th class="w-24"></th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(item, index) in data"
									:key="item.id"
									:class="
										month === item.month
											? 'bg-orange-50'
											: index % 2 === 0
											? 'bg-white'
											: 'bg-neutral-50'
									"
									class="text-neutral-700"
								>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div>Tháng {{ item.month }}</div>
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div>
											{{
												item.number_of_student_birthdays
											}}
										</div>
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div>
											{{ item.number_of_staff_birthdays }}
										</div>
									</td>
									<td>
										<div
											style="width: 30px"
											@click="changeMonth(item.month)"
											class="
												cursor-pointer
												text-yellow-600
												hover:text-yellow-700
												font-semibold
												text-xs
												whitespace-nowrap
												capitalize
												pr-10
												text-right
											"
										>
											XEM CHI TIẾT
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="fixed top-1/2 left-1/2">
			<Loading />
		</div>
	</div>
</template>

<script>
import { $api } from '@/services';
import dayjs from 'dayjs';
import { _ROUTER_NAME } from '@/enums';
import Loading from '@/components/common/Loading';

export default {
	name: 'Birthday',

	components: { Loading },

	data() {
		return {
			data: [],
			params: {
				month: null,
			},
			stats: {
				student_birthdays: [],
				staff_birthdays: [],
			},
			month: dayjs().toDate(),
			year: dayjs().toDate(),
			isLoading: true,
		};
	},

	created() {
		if (
			this.monthInParam &&
			this.monthInParam > 0 &&
			this.monthInParam < 13
		) {
			this.changeMonth(this.monthInParam);
		} else {
			this.params.month = dayjs(this.date).format('MM');
			this.month = dayjs(this.date).format('MM');
		}

		this.year = dayjs(this.date).format('YYYY');

		this.fetchBirthdays();
		this.fetchBirthdaysByMonth();
	},

	computed: {
		title() {
			return 'tháng ' + this.month + '/' + this.year;
		},

		routerName() {
			return _ROUTER_NAME;
		},

		monthInParam() {
			return this.$route.query?.month;
		},
	},

	methods: {
		changeMonth(month) {
			this.params = { month };
			this.month = +month;
			this.$router.push({
				query: {
					month,
				},
			});
			window.scrollTo(0, 0);
			this.fetchBirthdaysByMonth();
		},

		async fetchBirthdays() {
			try {
				const {
					data: { data },
				} = await $api.birthday.birthdays();
				this.data = data;
			} catch (e) {
				console.log(e);
			}
		},

		async fetchBirthdaysByMonth() {
			try {
				const {
					data: { data },
				} = await $api.birthday.birthdaysByMonth(this.params);
				this.stats = data;
			} catch (e) {
				console.log(e);
			} finally {
				this.isLoading = false;
			}
		},
	},
};
</script>
