
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import Rating from '@/components/elements/rating/Rating.vue';
import { Options, Vue, setup } from 'vue-class-component';
import SideOver from '@/components/elements/sideOver/SideOver.vue';
import { useStore } from 'vuex';
import { useWeeklyReportList } from '@/features/weeklyReport/weeklyReportList';
import DaInfinityLoading from '@/components/common/daInfinityLoading/DaInfinityLoading.vue';
import SlideOverWeekFilter from '@/views/report/features/week/SlideOverWeekFilter.vue';
import Loading from '@/components/common/Loading.vue';
import { Watch } from 'vue-property-decorator';

@Options({
	name: 'ReportWeek',
	components: {
		Loading,
		SlideOverWeekFilter,
		SideOver,
		Rating,
		DaIcon,
		DaInfinityLoading,
	},
})
export default class ReportWeek extends Vue {
	weeklyReportList: any = [];
	beforeDate = '';
	stopLoad = false;
	isInfinityLoading = false;
	isLoading = true;
	isHides: any = [];
	payload: any = null;

	setup = setup(() => {
		const store = useStore();
		const { error, loading, getWeeklyReportList } = useWeeklyReportList();
		return {
			store,
			error,
			loading,
			getWeeklyReportList,
		};
	});

	get isAdmin() {
		return this.setup.store.getters.isAdmin;
	}

	get weeklyReports() {
		return this.weeklyReportList;
	}

	@Watch('$route.query', {
		deep: true,
		immediate: true,
	})
	onRouteChange(value: any) {
		this.payload = {
			...this.payload,
			state: value?.state,
			student_id: value?.student_id,
			staff_id: value?.staff_id,
			week_start: value?.week_start,
		};
		this.weeklyReportList = [];
		this.beforeDate = '';
		this.payload = {
			...this.payload,
			before: undefined,
		};
		this.getWeeklyReports();
	}

	showHide(date: any) {
		const index = this.isHides.indexOf(date);
		if (index !== -1) {
			this.isHides.splice(index, 1);
		} else {
			this.isHides.push(date);
		}
	}

	async getWeeklyReports() {
		const res = await this.setup.getWeeklyReportList(
			this.beforeDate
				? {
						...this.payload,
						before: this.beforeDate,
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  }
				: this.payload
		);

		this.isInfinityLoading = true;
		if (res?.data && res?.data.length > 0) {
			this.weeklyReportList.push({
				date:
					res?.week_start ||
					res?.data[res?.data.length - 1].week_start,
				data: res?.data,
				stats: res?.stats,
			});
			this.beforeDate =
				res?.week_start || res?.data[res?.data.length - 1].week_start;
		} else {
			this.stopLoad = true;
		}
		this.isLoading = false;
	}

	beforeDestroy() {
		this.isInfinityLoading = false;
	}
}
