<template>
	<div class="_modal bg-white">
		<div
			class="
				_modal__content
				md-down:px-4
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<img
					v-if="success"
					alt="Luca Education"
					height="60"
					src="@/assets/images/illustrations/success.png"
					title="Luca Education"
					width="60"
				/>
				<img
					v-else
					alt="Luca Education"
					height="150"
					src="@/assets/images/illustrations/fly.png"
					title="Luca Education"
					width="150"
				/>
			</div>
			<div
				v-if="success"
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
					text-center
				"
			>
				Báo cáo tuần đã hoàn thành
			</div>
			<div
				v-else
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
					text-center
				"
			>
				Hoàn thành báo cáo
			</div>
			<div v-if="success" class="text-center text-neutral-500 mt-2">
				Báo cáo này đã được lưu và bạn có thể gửi cho Phụ Huynh trong
				mục Báo cáo tuần
			</div>
			<div v-if="!success" class="w-full mt-5">
				<div class="input-group grid grid-cols-2 items-center">
					<div
						class="input-group__label text-neutral-500 font-normal"
					>
						Lớp nhóm
					</div>
					<div
						v-if="report?.group_class"
						class="font-bold text-neutral-500 text-right"
					>
						{{ report?.group_class?.name }}
					</div>
				</div>
				<div class="input-group grid grid-cols-2 items-center mt-10">
					<div
						class="input-group__label text-neutral-500 font-normal"
					>
						Tuần
					</div>
					<div
						class="
							font-bold
							text-neutral-500 text-right
							whitespace-nowrap
						"
					>
						<span v-if="report?.data?.week_start">
							{{
								`Tuần ${$filters.toWeek(
									report?.data?.week_start,
									true
								)} (${$filters.formatDate2(
									report?.data?.week_start
								)} - ${$filters.endWeek(
									report?.data?.week_start
								)})`
							}}
						</span>
					</div>
				</div>
				<div
					v-if="report?.group_plan?.applied_at"
					class="input-group grid grid-cols-2 items-center mt-10"
				>
					<div
						class="input-group__label text-neutral-500 font-normal"
					>
						Ngày nộp báo cáo
					</div>
					<div class="font-bold text-neutral-500 text-right">
						{{ dayjs(new Date()).format('DD/MM/YYYY') }}
					</div>
				</div>
				<div
					v-if="report?.next_plan"
					class="input-group grid grid-cols-2 items-center mt-10"
				>
					<div
						class="input-group__label text-neutral-500 font-normal"
					>
						Kế hoạch tiếp theo
					</div>
					<div class="font-bold text-green-500 text-right">
						<span class="text-green-500">
							KẾ HOẠCH CAN THIỆP NHÓM
							{{ report.group_plan?.order + 1 }}
						</span>
					</div>
				</div>
				<div
					v-if="report?.group_plan?.applied_at"
					class="input-group grid grid-cols-2 items-center mt-10"
				>
					<div
						class="input-group__label text-neutral-500 font-normal"
					>
						Giáo viên làm báo cáo
					</div>
					<div class="font-bold text-neutral-500 text-right">
						{{
							report?.data?.submitted_by_staff?.name ??
							profile.name
						}}
					</div>
				</div>
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				type="button"
				@click="$emit(success ? 'success' : 'close')"
			>
				{{ success ? 'Đóng' : 'Quay lại' }}
			</button>
			<button
				v-if="!success"
				class="button-p text-center whitespace-nowrap"
				type="button"
				@click="submit"
			>
				Xác nhận
			</button>
		</div>
	</div>
</template>

<script>
import dayjs from 'dayjs';

export default {
	name: 'ConfirmModal',
	computed: {
		dayjs() {
			return dayjs;
		},

		profile() {
			return this.$store.state.profile?.staff;
		},
	},

	props: {
		success: {
			type: Boolean,
			required: true,
		},

		report: {
			type: Object,
			required: true,
		},
	},

	methods: {
		submit() {
			this.$emit('submit');
		},
	},
};
</script>
