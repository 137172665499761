
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { defineComponent, computed, ref } from 'vue';
// @ts-ignore
import Popper from 'vue3-popper';

export default defineComponent({
	name: 'GoalManagement',
	components: { DaIcon, Popper },
	setup() {
		const monthValue = new Date().getMonth();
		const yearValue = new Date().getFullYear();
		const month = ref(monthValue);
		const year = ref(yearValue);
		const people = [
			{
				id: 1,
				name: 'Tên học sinh',
				phone: '0868994455',
				parent: 'Lê Kim Toàn',
				type: 1,
			},
			{
				id: 2,
				name: 'Tên học sinh',
				phone: '0868994455',
				parent: 'Lê Kim Toàn',
				type: 2,
			},
			{
				id: 3,
				name: 'Tên học sinh',
				phone: '0868994455',
				parent: 'Lê Kim Toàn',
				type: 1,
			},
			{
				id: 4,
				name: 'Tên học sinh',
				phone: '0868994455',
				parent: 'Lê Kim Toàn',
				type: 1,
			},
			{
				id: 5,
				name: 'Tên học sinh',
				phone: '0868994455',
				parent: 'Lê Kim Toàn',
				type: 2,
			},
			{
				id: 6,
				name: 'Tên học sinh',
				phone: '0868994455',
				parent: 'Lê Kim Toàn',
				type: 1,
			},
		];

		function nextMonth() {
			if (month.value < 11) {
				month.value++;
			} else {
				month.value = 0;
				year.value++;
			}
		}

		function prevMonth() {
			if (month.value > 0) {
				month.value--;
			} else {
				month.value = 11;
				year.value--;
			}
		}

		return {
			month: computed(() =>
				month.value < 9 ? '0' + (month.value + 1) : month.value + 1
			),
			year,
			people,
			nextMonth,
			prevMonth,
		};
	},
});
