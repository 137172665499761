<template>
	<div :class="isUpdateSmart ? 'side-over--active' : ''">
		<div
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			v-if="isUpdateSmart"
			@click="closeAddSmartModal"
		></div>
		<AddSmartGoal
			:goal="dataForSmart"
			:skills="skills"
			@updated="updatedSmart"
			@close="closeAddSmartModal"
		/>
	</div>
	<DaModalFrame v-model:open="modalIsOpen">
		<ViewGoalFolder
			v-if="!iEditMode && dataView"
			@close="closeModal"
			:data="dataView"
		/>
		<EditGoalFolder
			v-if="iEditMode && dataView"
			@close="closeModal"
			:data="dataView"
			@submit="
				(is_completed) => {
					updateGoalLib({
						id: dataView.id,
						data: {
							is_completed,
						},
					});
					closeModal();
				}
			"
		/>
	</DaModalFrame>
	<div v-if="folder">
		<div class="flex items-center">
			<div
				class="cursor-pointer transform"
				:class="{ 'rotate-180': isHide }"
				@click="toggleHide"
			>
				<svg
					width="28"
					height="28"
					viewBox="0 0 28 28"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M21 17.5L14 10.5L7 17.5"
						stroke="#94A3B8"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</div>
			<div class="relative my-4 w-full">
				<div
					class="absolute inset-0 flex items-center"
					aria-hidden="true"
				>
					<div class="w-full border-t border-neutral-300" />
				</div>
				<div class="relative flex justify-between">
					<div class="flex items-center bg-body px-2 gap-3">
						<Checkbox
							v-if="!isInCreateGoal"
							:value="isSelectedAll"
							:disabled="goalsSelected?.length"
							@click="folderClicked(folder)"
						/>
						<div v-if="!folder.is_completed">
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M3.83345 6.39986V3.99983C3.83345 1.79063 5.69892 0 8.00016 0C10.3014 0 12.1669 1.79077 12.1669 3.99983V6.39986C13.5476 6.39986 14.6668 7.47439 14.6668 8.7999V13.6C14.6668 14.9256 13.5476 16 12.1669 16H3.83345C2.45276 16 1.3335 14.9256 1.3335 13.6V8.7999C1.3335 7.47439 2.45276 6.39986 3.83345 6.39986ZM10.5001 6.39986V3.99983C10.5001 2.67432 9.38085 1.59979 8.00016 1.59979C6.61947 1.59979 5.50021 2.67432 5.50021 3.99983V6.39986H10.5001ZM8.00016 9.6C8.46041 9.6 8.83344 9.95812 8.83344 10.4V12C8.83344 12.4419 8.46041 12.8 8.00016 12.8C7.53992 12.8 7.16689 12.4419 7.16689 12V10.4C7.16689 9.95812 7.53992 9.6 8.00016 9.6Z"
									fill="#94A3B8"
								/>
							</svg>
						</div>
						<div
							v-if="!isEdit"
							@click="toggleHide"
							class="
								text-xl
								font-semibold
								text-gray-500
								cursor-pointer
							"
						>
							{{ folderName }}
						</div>
						<div v-else class="input-group flex items-center w-fit">
							<input
								class="font-semibold"
								type="text"
								placeholder="Tên thư mục"
								v-model="folderName"
								@blur="
									updateGoal({
										id: folder.id,
										data: {
											name: folderName,
										},
									})
								"
								@keyup.enter="
									updateGoal({
										id: folder.id,
										data: {
											name: folderName,
										},
									})
								"
								:class="errorFolderName ? 'input--error' : ''"
							/>
						</div>
					</div>
					<div
						class="bg-body pl-4 flex items-center gap-4"
						v-if="!isInCreateGoal"
					>
						<span class="font-semibold text-neutral-400 text-sm">
							Mục tiêu: {{ folder?.numbers_of_goal }}</span
						>
						<div
							v-if="!isEdit"
							class="
								flex
								items-center
								font-semibold
								cursor-pointer
								h-5
								w-5
							"
							@click="openModal(folder)"
						>
							<svg
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM13.3961 9.88184L9.95953 10.3125L9.83648 10.8838L10.5132 11.0068C10.9527 11.1123 11.0406 11.2705 10.9439 11.71L9.83648 16.9131C9.54644 18.2578 9.99469 18.8906 11.0494 18.8906C11.8668 18.8906 12.816 18.5127 13.2466 17.9941L13.3785 17.3701C13.0796 17.6338 12.6402 17.7393 12.3502 17.7393C11.9371 17.7393 11.7877 17.4492 11.8931 16.9395L13.3961 9.88184ZM12 8.25C12.8284 8.25 13.5 7.57843 13.5 6.75C13.5 5.92157 12.8284 5.25 12 5.25C11.1716 5.25 10.5 5.92157 10.5 6.75C10.5 7.57843 11.1716 8.25 12 8.25Z"
									fill="#94A3B8"
								/>
							</svg>
						</div>
						<div v-else>
							<Menu as="div" class="ml-3 relative">
								<div>
									<MenuButton
										class="
											rounded-full
											flex
											text-sm
											focus:outline-none
											focus:ring-2
											focus:ring-offset-2
											focus:ring-indigo-500
										"
									>
										<span class="sr-only"
											>Open user menu</span
										>
										<svg
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M11.875 16.25C11.875 17.2855 11.0355 18.125 10 18.125C8.96447 18.125 8.125 17.2855 8.125 16.25C8.125 15.2145 8.96447 14.375 10 14.375C11.0355 14.375 11.875 15.2145 11.875 16.25ZM11.875 10C11.875 11.0355 11.0355 11.875 10 11.875C8.96447 11.875 8.125 11.0355 8.125 10C8.125 8.96447 8.96447 8.125 10 8.125C11.0355 8.125 11.875 8.96447 11.875 10ZM11.875 3.75C11.875 4.78553 11.0355 5.625 10 5.625C8.96447 5.625 8.125 4.78553 8.125 3.75C8.125 2.71447 8.96447 1.875 10 1.875C11.0355 1.875 11.875 2.71447 11.875 3.75Z"
												fill="#94A3B8"
											/>
										</svg>
									</MenuButton>
								</div>
								<transition
									enter-active-class="transition ease-out duration-200"
									enter-from-class="transform opacity-0 scale-95"
									enter-to-class="transform opacity-100 scale-100"
									leave-active-class="transition ease-in duration-75"
									leave-from-class="transform opacity-100 scale-100"
									leave-to-class="transform opacity-0 scale-95"
								>
									<MenuItems
										class="
											origin-top-right
											absolute
											right-0
											mt-2
											w-60
											rounded-md
											py-1
											bg-white
											focus:outline-none
											z-10
											shadow-sm
											border border-yellow-100
										"
									>
										<MenuItem v-slot="{ active }">
											<div
												class="
													p-2
													px-4
													bg-white
													z-10
													text-gray-600
													hover:bg-neutral-50
													flex
													items-center
													gap-4
													cursor-pointer
												"
												:class="active ? '' : ''"
												@click="openModal(folder)"
											>
												<svg
													width="16"
													height="16"
													viewBox="0 0 16 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.9307 6.58789L6.63969 6.875L6.55766 7.25586L7.00883 7.33789C7.3018 7.4082 7.36039 7.51367 7.29594 7.80664L6.55766 11.2754C6.3643 12.1719 6.66313 12.5938 7.36625 12.5938C7.91117 12.5938 8.54398 12.3418 8.83109 11.9961L8.91898 11.5801C8.71977 11.7559 8.4268 11.8262 8.23344 11.8262C7.95805 11.8262 7.85844 11.6328 7.92875 11.293L8.9307 6.58789ZM8 5.5C8.55229 5.5 9 5.05228 9 4.5C9 3.94772 8.55229 3.5 8 3.5C7.44772 3.5 7 3.94772 7 4.5C7 5.05228 7.44772 5.5 8 5.5Z"
														fill="#94A3B8"
													/>
												</svg>

												<span>Xem thông tin</span>
											</div>
										</MenuItem>
										<MenuItem v-slot="{ active }">
											<div
												class="
													p-2
													px-4
													bg-white
													z-10
													text-gray-600
													hover:bg-neutral-50
													flex
													items-center
													gap-4
													cursor-pointer
												"
												:class="active ? '' : ''"
												@click="editFolder(folder)"
											>
												<svg
													width="16"
													height="16"
													viewBox="0 0 16 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M12.47 6.65078L13.71 5.41075C13.8459 5.27489 13.9138 5.20696 13.9664 5.14499C14.6006 4.39832 14.6006 3.30214 13.9664 2.55546C13.9138 2.4935 13.8459 2.42557 13.71 2.28971C13.5741 2.15385 13.5062 2.08592 13.4442 2.03329C12.6976 1.39906 11.6014 1.39906 10.8547 2.03329C10.7928 2.08591 10.7249 2.15382 10.5891 2.28961L10.589 2.28969L10.589 2.28971L9.33075 3.54792C10.0744 4.84212 11.1581 5.91739 12.47 6.65078ZM7.87608 5.00258L2.86248 10.0162C2.43742 10.4412 2.22489 10.6538 2.08516 10.9149C1.94542 11.176 1.88648 11.4707 1.76859 12.0601L1.37293 14.0384C1.30641 14.371 1.27315 14.5373 1.36776 14.6319C1.46237 14.7266 1.62867 14.6933 1.96128 14.6268L3.93956 14.2311L3.93958 14.2311L3.9396 14.2311C4.52902 14.1132 4.82374 14.0543 5.08483 13.9145C5.34592 13.7748 5.55845 13.5623 5.98349 13.1373L5.98352 13.1372L5.98352 13.1372L11.0115 8.10922C9.75086 7.31101 8.68177 6.24913 7.87608 5.00258Z"
														fill="#94A3B8"
													/>
												</svg>

												<span
													>Chỉnh sửa trạng thái</span
												>
											</div>
										</MenuItem>
										<MenuItem
											v-slot="{ active }"
											@click="openCreateModal(folder)"
											v-if="
												!folder.children ||
												!folder.children.length ||
												!folder.children[0].is_goal
											"
										>
											<div
												class="
													p-2
													px-4
													bg-white
													z-10
													text-gray-600
													hover:bg-neutral-50
													flex
													items-center
													gap-4
													cursor-pointer
												"
												:class="active ? '' : ''"
											>
												<svg
													width="16"
													height="16"
													viewBox="0 0 16 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
														fill="#94A3B8"
													/>
												</svg>
												<span>Thêm thư mục</span>
											</div>
										</MenuItem>
										<MenuItem
											v-slot="{ active }"
											@click="openCreateFirstMode"
											v-if="
												!folder.children ||
												!folder.children.length
											"
										>
											<div
												class="
													p-2
													px-4
													bg-white
													z-10
													text-gray-600
													hover:bg-neutral-50
													flex
													items-center
													gap-4
													cursor-pointer
												"
												:class="active ? '' : ''"
											>
												<svg
													width="16"
													height="16"
													viewBox="0 0 16 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
														fill="#94A3B8"
													/>
												</svg>
												<span>Thêm mục tiêu</span>
											</div>
										</MenuItem>
										<MenuItem v-slot="{ active }">
											<div
												class="
													p-2
													px-4
													bg-white
													z-10
													text-gray-600
													hover:bg-neutral-50
													flex
													items-center
													gap-4
													cursor-pointer
												"
												:class="active ? '' : ''"
												@click="
													deleteGoal({
														itemId: folder.id,
														isGoal: false,
													})
												"
											>
												<svg
													width="16"
													height="16"
													viewBox="0 0 16 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M2.5 1C1.94772 1 1.5 1.44772 1.5 2V3C1.5 3.55228 1.94772 4 2.5 4H3V13C3 14.1046 3.89543 15 5 15H11C12.1046 15 13 14.1046 13 13V4H13.5C14.0523 4 14.5 3.55228 14.5 3V2C14.5 1.44772 14.0523 1 13.5 1H10C10 0.447715 9.55229 0 9 0H7C6.44772 0 6 0.447715 6 1H2.5ZM5.5 5C5.77614 5 6 5.22386 6 5.5V12.5C6 12.7761 5.77614 13 5.5 13C5.22386 13 5 12.7761 5 12.5L5 5.5C5 5.22386 5.22386 5 5.5 5ZM8 5C8.27614 5 8.5 5.22386 8.5 5.5V12.5C8.5 12.7761 8.27614 13 8 13C7.72386 13 7.5 12.7761 7.5 12.5V5.5C7.5 5.22386 7.72386 5 8 5ZM11 5.5C11 5.22386 10.7761 5 10.5 5C10.2239 5 10 5.22386 10 5.5V12.5C10 12.7761 10.2239 13 10.5 13C10.7761 13 11 12.7761 11 12.5V5.5Z"
														fill="#94A3B8"
													/>
												</svg>
												<span>Xoá thư mục</span>
											</div>
										</MenuItem>
									</MenuItems>
								</transition>
							</Menu>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="errorFolderName && isEdit">
			<div class="w-full mb-2" style="margin-left: 28px">
				<div class="error text-sienna-600 text-sm w-full">
					{{ errorFolderName }}
				</div>
			</div>
		</div>
		<div v-if="!isHide">
			<div v-if="folder.children && folder.children.length">
				<div v-if="folder.children[0].is_goal">
					<div class="shadow-sm sm:rounded-2xl my-6">
						<div class="__record__table scroll-bar">
							<div class="sm:rounded-t-2xl w-fit min-w-full">
								<table
									class="
										min-w-full
										divide-y
										text-neutral-200
										rounded-2xl
									"
								>
									<thead
										class="bg-neutral-100 h-16 rounded-2xl"
									>
										<tr>
											<th
												class="px-6 py-3 w-16"
												v-if="!isInCreateGoal"
											>
												<Checkbox
													:disabled="
														foldersSelected?.length
													"
													:value="
														folder.children
															.map((f) => f.id)
															?.every((i) =>
																goalsSelected?.includes(
																	i
																)
															)
													"
													@click="
														!foldersSelected?.length &&
															checkChangedChild(
																folder,
																!folder.children
																	.map(
																		(f) =>
																			f.id
																	)
																	?.every(
																		(i) =>
																			goalsSelected?.includes(
																				i
																			)
																	)
															)
													"
												/>
											</th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													w-16
													whitespace-nowrap
												"
											>
												STT
											</th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
												@click="
													sorting(
														'goal_name_asc',
														'goal_name_desc'
													)
												"
											>
												<div
													class="
														flex
														items-center
														gap-1
														justify-between
														cur
														sor-pointer
													"
													style="min-width: 160px"
												>
													{{
														$slots.actions
															? 'Nội dung'
															: 'tên mục tiêu'
													}}
													<svg
														v-if="!$slots.actions"
														width="16"
														height="16"
														viewBox="0 0 16 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M7.99999 2.39999C8.21216 2.39999 8.41564 2.48428 8.56567 2.63431L10.9657 5.03431C11.2781 5.34673 11.2781 5.85326 10.9657 6.16568C10.6533 6.4781 10.1467 6.4781 9.8343 6.16568L7.99999 4.33136L6.16567 6.16568C5.85325 6.4781 5.34672 6.4781 5.0343 6.16568C4.72188 5.85326 4.72188 5.34673 5.0343 5.03431L7.4343 2.63431C7.58433 2.48428 7.78782 2.39999 7.99999 2.39999ZM5.0343 9.83431C5.34672 9.52189 5.85325 9.52189 6.16567 9.83431L7.99999 11.6686L9.8343 9.83431C10.1467 9.52189 10.6533 9.52189 10.9657 9.83431C11.2781 10.1467 11.2781 10.6533 10.9657 10.9657L8.56567 13.3657C8.25325 13.6781 7.74672 13.6781 7.4343 13.3657L5.0343 10.9657C4.72188 10.6533 4.72188 10.1467 5.0343 9.83431Z"
															fill="#94A3B8"
														/>
													</svg>
												</div>
											</th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
												@click="
													sorting(
														'skill_name_asc',
														'skill_name_desc'
													)
												"
											>
												<div
													class="
														flex
														items-center
														gap-1
														justify-between
														cursor-pointer
													"
												>
													lĩnh vực
													<svg
														v-if="!$slots.actions"
														width="16"
														height="16"
														viewBox="0 0 16 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M7.99999 2.39999C8.21216 2.39999 8.41564 2.48428 8.56567 2.63431L10.9657 5.03431C11.2781 5.34673 11.2781 5.85326 10.9657 6.16568C10.6533 6.4781 10.1467 6.4781 9.8343 6.16568L7.99999 4.33136L6.16567 6.16568C5.85325 6.4781 5.34672 6.4781 5.0343 6.16568C4.72188 5.85326 4.72188 5.34673 5.0343 5.03431L7.4343 2.63431C7.58433 2.48428 7.78782 2.39999 7.99999 2.39999ZM5.0343 9.83431C5.34672 9.52189 5.85325 9.52189 6.16567 9.83431L7.99999 11.6686L9.8343 9.83431C10.1467 9.52189 10.6533 9.52189 10.9657 9.83431C11.2781 10.1467 11.2781 10.6533 10.9657 10.9657L8.56567 13.3657C8.25325 13.6781 7.74672 13.6781 7.4343 13.3657L5.0343 10.9657C4.72188 10.6533 4.72188 10.1467 5.0343 9.83431Z"
															fill="#94A3B8"
														/>
													</svg>
												</div>
											</th>
											<th
												v-if="isEdit || $slots.actions"
											></th>
										</tr>
									</thead>
									<tbody>
										<tr
											class="bg-white text-neutral-700"
											v-for="(
												goal, index
											) in folder.children"
											:key="goal.id"
											:class="
												$route.query?.goal_id &&
												+goal.id ===
													+$route.query?.goal_id
													? 'bg-yellow-50'
													: index % 2 === 0
													? 'bg-white'
													: 'bg-neutral-50'
											"
										>
											<td
												v-if="!isInCreateGoal"
												class="
													px-6
													py-4
													whitespace-nowrap
													text-neutral-700
												"
											>
												<Checkbox
													:disabled="
														foldersSelected?.length
													"
													:value="
														goalsSelected.find(
															(i) =>
																+i === +goal.id
														)
													"
													@click="
														!foldersSelected?.length &&
															checkChanged([goal])
													"
												/>
											</td>
											<td
												class="
													px-6
													py-4
													whitespace-nowrap
													text-neutral-700
												"
											>
												<div class="ml-2">
													{{ index + 1 }}
												</div>
											</td>
											<td
												class="
													px-6
													py-4
													text-neutral-700
												"
											>
												<div
													class="
														w-full
														flex
														items-center
														gap-4
													"
													v-if="!isEdit"
												>
													{{ goal.name }}

													<TooltipGoalDetail
														goal-id="1"
														student-id="1"
														:tooltip-data="{
															reason: goal.reason,
															strategy:
																goal.strategy,
															process:
																goal.process,
														}"
													/>
												</div>
												<div
													v-else
													class="
														input-group
														flex
														items-center
														w-full
													"
												>
													<input
														type="text"
														placeholder="Điền tên mục tiêu mới"
														v-model="goal.name"
														@blur="
															updateGoal({
																id: goal.id,
																data: {
																	name: goal.name,
																},
															})
														"
														@keyup.enter="
															updateGoal({
																id: goal.id,
																data: {
																	name: goal.name,
																},
															})
														"
													/>
												</div>
												<div
													v-if="!isEdit && goal.used"
													class="flex gap-2 mt-1"
												>
													<svg
														width="16"
														height="16"
														viewBox="0 0 16 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M8.96966 4.96967C9.26256 4.67678 9.73743 4.67678 10.0303 4.96967C10.3196 5.25897 10.3232 5.72582 10.041 6.01947L6.04876 11.0097C6.043 11.0169 6.03685 11.0238 6.03032 11.0303C5.73743 11.3232 5.26256 11.3232 4.96966 11.0303L2.32322 8.38388C2.03032 8.09099 2.03032 7.61612 2.32322 7.32322C2.61611 7.03033 3.09098 7.03033 3.38388 7.32322L5.4774 9.41674L8.94977 4.9921C8.95595 4.98424 8.96259 4.97674 8.96966 4.96967Z"
															fill="#2AA797"
														/>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M8.04924 10.1099L8.96966 11.0303C9.26256 11.3232 9.73743 11.3232 10.0303 11.0303C10.0368 11.0238 10.043 11.0169 10.0488 11.0097L14.041 6.01947C14.3232 5.72582 14.3196 5.25897 14.0303 4.96967C13.7374 4.67678 13.2626 4.67678 12.9697 4.96967C12.9626 4.97674 12.9559 4.98424 12.9498 4.9921L9.4774 9.41674L8.99205 8.9314L8.04924 10.1099Z"
															fill="#2AA797"
														/>
													</svg>
													<span
														v-if="goal.used_at"
														class="
															font-semibold
															text-green-500
															text-xs
														"
														>{{
															$filters.formatDate(
																goal.used_at
															)
														}}</span
													>
												</div>
											</td>
											<td
												class="
													px-6
													py-4
													text-neutral-700
													w-72
												"
											>
												<div v-if="!isEdit">
													{{
														skills.find(
															(s) =>
																s.id ===
																goal.skill_id
														)?.name
													}}
												</div>
												<div v-else>
													<Multiselect
														deselect-label="Đã chọn"
														track-by="name"
														:options="
															skills?.map((s) => {
																return {
																	nameSearch:
																		toEnglish(
																			s?.name
																		),
																	...s,
																};
															})
														"
														:model-value="
															skills.find(
																(s) =>
																	s.id ===
																	goal.skill_id
															)
														"
														:searchable="true"
														label="nameSearch"
														:allow-empty="false"
														selectLabel=""
														placeholder="Chọn lĩnh vực"
														@select="
															(value) => {
																goal.skill_id =
																	value?.id;
																updateGoal({
																	id: goal.id,
																	data: {
																		name: goal.name,
																		skill_id:
																			value?.id,
																	},
																});
															}
														"
													>
														<template #noResult>
															Không tìm thấy kết
															quả
														</template>
														<template
															v-slot:option="props"
														>
															<div
																class="
																	flex
																	flex-nowrap
																	items-center
																"
															>
																<div>
																	{{
																		props
																			.option
																			.name
																	}}
																</div>
															</div>
														</template>
														<template
															v-slot:singleLabel="{
																option,
															}"
														>
															<div
																class="
																	flex
																	flex-nowrap
																	items-center
																	font-medium
																"
															>
																<div>
																	{{
																		option.name
																	}}
																</div>
															</div>
														</template>
													</Multiselect>
												</div>
											</td>
											<td
												class="w-12"
												v-if="isInCreateGoal"
											>
												<div
													@click="
														$emit(
															'action-clicked',
															goal
														)
													"
													class="
														px-4
														cursor-pointer
														text-yellow-600
														hover:text-yellow-700
														font-semibold
														text-sm
														flex
														items-center
														md-down:items-right
														whitespace-nowrap
													"
												>
													Thêm MT
												</div>
												<slot name="actions"></slot>
											</td>
											<td
												:class="
													isShowSmart
														? 'w-24'
														: 'w-12'
												"
												v-else-if="isEdit"
											>
												<div
													class="
														flex
														items-center
														gap-6
													"
												>
													<div
														@click="
															deleteGoal({
																itemId: goal.id,
																isGoal: true,
															})
														"
														class="
															text-yellow-600
															cursor-pointer
															hover:text-yellow-700
														"
													>
														<svg
															width="20"
															height="20"
															viewBox="0 0 20 20"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M3.125 1.25024C2.43464 1.25024 1.875 1.80989 1.875 2.50024V3.75024C1.875 4.4406 2.43464 5.00024 3.125 5.00024H3.75V16.2502C3.75 17.631 4.86929 18.7502 6.25 18.7502H13.75C15.1307 18.7502 16.25 17.631 16.25 16.2502V5.00024H16.875C17.5654 5.00024 18.125 4.4406 18.125 3.75024V2.50024C18.125 1.80989 17.5654 1.25024 16.875 1.25024H12.5C12.5 0.559888 11.9404 0.000244141 11.25 0.000244141H8.75C8.05964 0.000244141 7.5 0.559888 7.5 1.25024H3.125ZM6.875 6.25024C7.22018 6.25024 7.5 6.53007 7.5 6.87524V15.6252C7.5 15.9704 7.22018 16.2502 6.875 16.2502C6.52982 16.2502 6.25 15.9704 6.25 15.6252L6.25 6.87524C6.25 6.53007 6.52982 6.25024 6.875 6.25024ZM10 6.25024C10.3452 6.25024 10.625 6.53007 10.625 6.87524V15.6252C10.625 15.9704 10.3452 16.2502 10 16.2502C9.65482 16.2502 9.375 15.9704 9.375 15.6252V6.87524C9.375 6.53007 9.65482 6.25024 10 6.25024ZM13.75 6.87524C13.75 6.53007 13.4702 6.25024 13.125 6.25024C12.7798 6.25024 12.5 6.53007 12.5 6.87524V15.6252C12.5 15.9704 12.7798 16.2502 13.125 16.2502C13.4702 16.2502 13.75 15.9704 13.75 15.6252V6.87524Z"
																fill="currentColor"
															/>
														</svg>
													</div>
													<div
														v-if="isShowSmart"
														@click="
															openAddSmartModal(
																goal
															)
														"
														class="
															text-yellow-600
															cursor-pointer
															hover:text-yellow-700
														"
													>
														<svg
															width="20"
															height="20"
															viewBox="0 0 20 20"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M15.4041 8.49733L17.1377 6.76375L17.1377 6.76374L17.1377 6.76372C17.6368 6.26463 17.8863 6.01508 18.0269 5.74991C18.3376 5.164 18.3376 4.46221 18.0269 3.8763C17.8863 3.61112 17.6368 3.36156 17.1377 2.86246C16.6386 2.36335 16.389 2.11379 16.1238 1.97321C15.5379 1.66258 14.8361 1.66258 14.2502 1.97321C13.985 2.11379 13.7355 2.36335 13.2364 2.86246L11.4807 4.61818C12.4198 6.22874 13.772 7.57058 15.4041 8.49733ZM10.0262 6.07265L3.36419 12.7346C2.93913 13.1597 2.7266 13.3722 2.58687 13.6333C2.44713 13.8944 2.38819 14.1891 2.27029 14.7786L1.67957 17.7322C1.61305 18.0648 1.57979 18.2311 1.6744 18.3257C1.76901 18.4203 1.93531 18.3871 2.26792 18.3206L5.22152 17.7298C5.81098 17.6119 6.1057 17.553 6.3668 17.4133C6.6279 17.2735 6.84043 17.061 7.26549 16.6359L13.9459 9.95556C12.3647 8.96432 11.027 7.63576 10.0262 6.07265Z"
																fill="currentColor"
															/>
														</svg>
													</div>
												</div>
											</td>
										</tr>
										<tr
											class="bg-white text-neutral-700"
											v-if="isEdit"
										>
											<td></td>
											<td
												class="
													px-6
													py-4
													whitespace-nowrap
													text-neutral-700
												"
											>
												<div class="ml-2">
													{{
														folder.children.length +
														1
													}}
												</div>
											</td>
											<td
												class="
													px-6
													py-4
													text-neutral-700
												"
											>
												<div
													class="
														input-group
														flex
														items-center
														w-full
													"
												>
													<input
														type="text"
														placeholder="Điền tên mục tiêu mới"
														v-model="name"
														:class="
															error
																? 'input--error'
																: ''
														"
														@blur="
															createGoal({
																name,
																skill: skillSelected,
																parent_id:
																	folder.id,
															})
														"
														@keyup.enter="
															createGoal({
																name,
																skill: skillSelected,
																parent_id:
																	folder.id,
															})
														"
													/>
												</div>
											</td>
											<td
												class="
													px-6
													py-4
													text-neutral-700
													w-72
												"
											>
												<Multiselect
													deselect-label="Đã chọn"
													track-by="name"
													:options="
														skills?.map((s) => {
															return {
																nameSearch:
																	toEnglish(
																		s?.name
																	),
																...s,
															};
														})
													"
													v-model="skillSelected"
													:searchable="true"
													label="nameSearch"
													:allow-empty="false"
													selectLabel=""
													placeholder="Chọn lĩnh vực"
													@select="selected"
												>
													<template #noResult>
														Không tìm thấy kết quả
													</template>
													<template
														v-slot:option="props"
													>
														<div
															class="
																flex flex-nowrap
																items-center
															"
														>
															<div>
																{{
																	props.option
																		.name
																}}
															</div>
														</div>
													</template>
													<template
														v-slot:singleLabel="{
															option,
														}"
													>
														<div
															class="
																flex flex-nowrap
																items-center
																font-medium
															"
														>
															<div>
																{{
																	option.name
																}}
															</div>
														</div>
													</template>
												</Multiselect>
											</td>
											<td></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="pl-10">
					<div v-for="child in folder.children" :key="child.id">
						<GoalFolderItem
							:is-edit="isEdit"
							:folder="child"
							:skills="skills"
							@updated="$emit('updated')"
							@create-goal="createGoal"
							@update-goal="updateGoal"
							@delete-goal="deleteGoal"
							@open-create-modal="openCreateModal"
							@action-clicked="
								(data) => $emit('action-clicked', data)
							"
							:isInCreateGoal="isInCreateGoal"
							:is-search="isSearch"
							:only-folder="onlyFolder"
							@fetch-new="(data) => $emit('fetchNew', data)"
							><template v-if="isInCreateGoal" v-slot:actions
								><div></div></template
						></GoalFolderItem>
					</div>
				</div>
			</div>
			<div v-else class="text-gray-500">
				<div v-if="isEdit && isCreatFirst">
					<div class="shadow-sm sm:rounded-2xl my-6">
						<div class="__record__table scroll-bar">
							<div class="sm:rounded-t-2xl w-fit min-w-full">
								<table
									class="
										min-w-full
										divide-y
										text-neutral-200
										rounded-2xl
									"
								>
									<thead
										class="bg-neutral-100 h-16 rounded-2xl"
									>
										<tr>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													w-16
													whitespace-nowrap
												"
											>
												STT
											</th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												<div
													class="
														flex
														items-center
														gap-1
														justify-between
														cursor-pointer
													"
												>
													tên mục tiêu
												</div>
											</th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												<div
													class="
														flex
														items-center
														gap-1
														justify-between
														cursor-pointer
													"
												>
													Lĩnh vực
												</div>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr class="bg-white text-neutral-700">
											<td
												class="
													px-6
													py-4
													whitespace-nowrap
													text-neutral-700
												"
											>
												<div class="ml-2">1</div>
											</td>
											<td
												class="
													px-6
													py-4
													text-neutral-700
												"
											>
												<div
													class="
														input-group
														flex
														items-center
														w-full
													"
												>
													<input
														type="text"
														placeholder="Điền tên mục tiêu mới"
														v-model="name"
														:class="
															error
																? 'input--error'
																: ''
														"
														@blur="
															createGoal({
																name,
																skill: skillSelected,
																parent_id:
																	folder.id,
															})
														"
														@keyup.enter="
															createGoal({
																name,
																skill: skillSelected,
																parent_id:
																	folder.id,
															})
														"
													/>
												</div>
											</td>
											<td
												class="
													px-6
													py-4
													text-neutral-700
													w-72
												"
											>
												<Multiselect
													deselect-label="Đã chọn"
													track-by="name"
													:options="
														skills?.map((s) => {
															return {
																nameSearch:
																	toEnglish(
																		s?.name
																	),
																...s,
															};
														})
													"
													v-model="skillSelected"
													:searchable="true"
													label="nameSearch"
													:allow-empty="false"
													selectLabel=""
													placeholder="Chọn lĩnh vực"
													@select="selected"
												>
													<template #noResult>
														Không tìm thấy kết quả
													</template>
													<template
														v-slot:option="props"
													>
														<div
															class="
																flex flex-nowrap
																items-center
															"
														>
															<div>
																{{
																	props.option
																		.name
																}}
															</div>
														</div>
													</template>
													<template
														v-slot:singleLabel="{
															option,
														}"
													>
														<div
															class="
																flex flex-nowrap
																items-center
																font-medium
															"
														>
															<div>
																{{
																	option.name
																}}
															</div>
														</div>
													</template>
												</Multiselect>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div v-else>Thư mục không có mục tiêu hay thư mục nào</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import Multiselect from '@suadelabs/vue3-multiselect';
import ViewGoalFolder from '@/views/goalFolders/features/ViewGoalFolder';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame';
import { $api } from '@/services';
import EditGoalFolder from '@/views/goalFolders/features/EditGoalFolder';
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail';
import { toEnglish } from '@/helper/toEnglish';
import AddSmartGoal from '@/views/goalFolders/features/AddSmartGoal';
import Checkbox from '@/components/elements/checkbox/Checkbox';

export default {
	name: 'GoalFolderItem',

	components: {
		AddSmartGoal,
		TooltipGoalDetail,
		EditGoalFolder,
		DaModalFrame,
		ViewGoalFolder,
		Menu,
		MenuItem,
		MenuItems,
		MenuButton,
		Multiselect,
		Checkbox,
	},

	props: {
		folder: {
			type: Object,
			required: true,
		},
		isEdit: {
			type: Boolean,
			required: true,
		},
		skills: {
			type: Array,
			required: true,
		},
		isInCreateGoal: {
			type: Boolean,
			default: false,
		},
		isSearch: {
			type: Boolean,
			default: false,
		},
		isCheckedAll: {
			type: Boolean,
			default: false,
		},
		onlyFolder: {
			type: Boolean,
			default: false,
		},
	},

	watch: {
		folder: {
			immediate: true,
			handler(value) {
				this.errorFolderName = '';
				this.folderName = value?.name;
				if (value) {
					this.onlyFolder
						? this.getAllChildFolderIsFolder(value)
						: this.getAllChildFolder(value);
				}
			},
		},

		isEdit: {
			handler(value) {
				if (!value) {
					this.closeCreateFirstMode();
				}
			},
		},
	},

	computed: {
		id() {
			return this.$route?.params?.id || '';
		},

		isAdmin() {
			return this.$store.getters.isAdmin;
		},

		org() {
			return this.$store.state.profile.current_org;
		},

		isShowSmart() {
			return (
				this.org?.enable_goal_reason ||
				this.org?.enable_goal_strategy ||
				this.org?.enable_goal_process
			);
		},

		isSelectedAll() {
			if (this.onlyFolder) {
				return [
					...this.foldersSelected,
					...this.childFoldersSelected,
				].includes(this.folder?.id);
			}

			return (
				this.allChild.length &&
				this.allChild.every((i) =>
					this.goalsSelected.find((c) => c === i.id)
				)
			);
		},

		goalsSelected() {
			const s = this.$store.getters.goalsSelected;
			return (s || []).map((i) => i.id);
		},

		foldersSelected() {
			const s = this.$store.getters.foldersSelected;
			return (s || []).map((i) => i.id);
		},

		childFoldersSelected() {
			const s = this.$store.getters.childFoldersSelected;
			return (s || []).map((i) => i.id);
		},

		isHide() {
			return this.$store.getters.goalHides.includes(this.folder.id);
		},
	},

	data() {
		return {
			skillSelected: null,
			name: '',
			error: '',
			folderName: '',
			modalIsOpen: false,
			dataView: null,
			isCreatFirst: false,
			errorFolderName: '',
			sortBy: '',
			iEditMode: false,
			isUpdateSmart: false,
			dataForSmart: null,
			allChildSelected: [],
			allChild: [],
			allChildIsFolder: [],
		};
	},

	created() {
		this.sortBy = this.$route.query;
	},

	methods: {
		sorting(key1, key2) {
			if (this.sortBy === key1) {
				this.sortBy = key2;
			} else {
				this.sortBy = key1;
			}
			this.$router.push({
				query: {
					...this.$route.query,
					sort_by: this.sortBy,
				},
			});
			this.$emit('fetchNew', this.sortBy);
		},
		getAllChild(parent) {
			const listChild = parent.children;
			if (listChild.length) {
				if (listChild[0].is_goal) {
					this.allChildSelected = [
						...this.allChildSelected,
						...listChild,
					];
				} else {
					for (let child of listChild) {
						this.getAllChild(child);
					}
				}
			}
		},
		getAllChildFolder(parent) {
			const listChild = parent.children;
			if (listChild.length) {
				if (listChild[0].is_goal) {
					this.allChild = [...this.allChild, ...listChild];
				} else {
					for (let child of listChild) {
						this.getAllChildFolder(child);
					}
				}
			}
		},
		getAllChildFolderIsFolder(parent) {
			const listChild = parent.children;
			if (!listChild.length || !listChild[0].is_goal) {
				this.allChildIsFolder = [
					...this.allChildIsFolder,
					...listChild,
				];
			}
			if (listChild.length && !listChild[0].is_goal) {
				for (let child of listChild) {
					this.getAllChildFolderIsFolder(child);
				}
			}
		},
		toEnglish,

		toggleHide() {
			this.$store.dispatch('hide', {
				goal: { id: this.folder.id },
			});
		},

		selected() {
			if (!this.name) {
				this.error = 'Tên mục tiêu không được trống';
				setTimeout(() => {
					this.error = '';
				}, 3000);
				return;
			}
			this.$emit('create-goal', {
				name: this.name,
				skill: this.skillSelected,
				parent_id: this.folder.id,
			});
			this.name = '';
		},

		openCreateFirstMode() {
			this.isCreatFirst = true;
		},

		closeCreateFirstMode() {
			this.isCreatFirst = false;
		},

		openCreateModal(data) {
			this.$emit('open-create-modal', data);
		},

		createGoal(data) {
			this.$emit('create-goal', data);
			this.name = '';
		},

		updateGoal(data) {
			this.updateGoalLib(data);
		},

		deleteGoal(data) {
			this.$emit('delete-goal', {
				...data,
				root: false,
			});
		},

		closeModal() {
			this.dataView = null;
			this.modalIsOpen = false;
			this.iEditMode = false;
		},

		openModal(data) {
			this.dataView = data;
			this.modalIsOpen = true;
			this.iEditMode = false;
		},

		editFolder(data) {
			this.dataView = data;
			this.modalIsOpen = true;
			this.iEditMode = true;
		},

		openAddSmartModal(data) {
			this.dataForSmart = data;
			this.isUpdateSmart = true;
		},

		closeAddSmartModal() {
			this.dataForSmart = null;
			this.isUpdateSmart = false;
		},

		selectedFolder(folder) {
			this.$store.dispatch('folderSelected', { folder });
		},

		async checkChangedChild(goals, isAll) {
			this.allChildSelected = [];
			await this.getAllChild(goals);
			this.checkChanged(this.allChildSelected, isAll);
		},

		checkChanged(goals, isAll) {
			this.$store.dispatch('selected', { goals, isAll });
		},

		updatedSmart() {
			this.closeAddSmartModal();
			this.$store.dispatch('setAlertMessage', {
				message: 'Cập nhật mục tiêu SMART thành công',
			});
			this.$emit('updated');
		},

		async updateGoalLib({ id, data }) {
			this.errorFolderName = '';
			try {
				await $api.goal.updateGoalByParent(this.id, id, data);
				this.$store.dispatch('setAlertMessage', {
					message: 'Cập nhật mục tiêu thành công',
				});
				this.$emit('updated');
			} catch (e) {
				if (
					e?.response?.data?.errors_by_field &&
					e?.response?.data?.errors_by_field['library_items']
				) {
					this.errorFolderName =
						'Tên thư mục mới không được trùng với tên thư mục đã có trước đó.';
				} else {
					await this.$store.dispatch('setAlertMessage', {
						message:
							e?.response?.data?.errors?.join('<br/>') ||
							'Đã có lỗi xảy ra. Vui lòng thử lại',
						type: 'danger',
					});
				}
			}
		},

		folderClicked(folder) {
			if (!this.onlyFolder) {
				this.checkChangedChild(folder, !this.isSelectedAll);
			} else if (!this.goalsSelected?.length) {
				if (!this.childFoldersSelected.includes(folder.id)) {
					if (this.foldersSelected.includes(folder.id)) {
						this.$store.dispatch('clearChildrenSelected');
					} else {
						this.$store.dispatch('childFolderSelected', {
							folders: this.allChildIsFolder,
						});
					}
					this.selectedFolder(folder);
				}
			}
		},
	},
};
</script>
