<template>
	<div></div>
</template>

<script>
export default {
	name: 'Home',

	created() {
		switch (this.role) {
			case 'accountant':
				return this.$router.push('/fee-notices');
			case 'teacher':
				return this.$router.push('/record/student');
			case 'admin':
				return this.$router.push('/record/student');
			default:
				return this.$router.push('/dashboard');
		}
	},

	computed: {
		role() {
			return this.$store.state.profile.staff.role;
		},
	},
};
</script>
