
import { Vue, Options, setup } from 'vue-class-component';
import { isEmail } from '@/helper/DAValidate';
import { useForgotPassword } from './services/forgotPassword';

@Options({
	name: 'ForgotPassword',
})
export default class ForgotPassword extends Vue {
	//----------------------- 🤍 Data 🤍 -----------------------//
	forgotPasswordData = {
		email: '',
	};
	errorData = {
		email: '',
		status: false,
	};
	successfully = false;

	//----------------------- 🤍 Setup 🤍 -----------------------//
	setup = setup(() => {
		const { error, loading, forgotPassword } = useForgotPassword();
		return { error, loading, forgotPassword };
	});

	//----------------------- 🤍 Getters 🤍 -----------------------//
	get Error() {
		const error: any = this.setup.error;
		if (error) {
			return error.join('<br/>');
		}
		return error;
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	async onSubmit() {
		this.errorData = {
			email: '',
			status: false,
		};
		if (!isEmail(this.forgotPasswordData.email)) {
			this.errorData.email = 'Địa chỉ email không đúng định dạng';
			return (this.errorData.status = true);
		}
		try {
			const { data } = await this.setup.forgotPassword(
				this.forgotPasswordData
			);
			if (data) {
				this.successfully = true;
			}
		} catch (e) {
			this.errorData.email = e;
		}
	}
}
