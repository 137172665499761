
import { computed, defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { _MODAL_TYPE, _ROUTER_NAME } from '@/enums';
import router from '@/router';
import { $api } from '@/services';
import { useStore } from 'vuex';
import DownloadCSV from '@/mixins/downloadCSV.vue';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import ConfirmSendReportTarget from '@/views/plansDetail/features/reportTarget/confirmSendReportTarget/ConfirmSendReportTarget.vue';
import { ACTIONS } from '@/enums/Common';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import Loading from '@/components/common/Loading.vue';
import GroupDetail from '@/components/modules/groups/GroupDetail.vue';
// import GoalList from '@/components/elements/GoalList.vue';

export default defineComponent({
	name: 'ReportTarget',
	components: {
		// GoalList,
		GroupDetail,
		Loading,
		ConfirmSendReportTarget,
		DaModalFrame,
		Menu,
		MenuItem,
		MenuItems,
		MenuButton,
	},
	setup() {
		const route = useRoute();
		const store = useStore();
		const goals = ref([]);
		const skill_count = ref(0);
		const goal_count = ref(0);
		const data = ref(null as any);

		const isLoading = ref(true);
		const modalIsOpen = ref(false);
		const modalType = ref('');
		const order_by = ref('');
		//
		const goals_count = ref(0);
		const skills_count = ref(0);
		const continued_goals_count = ref(0);
		const goals_from_lib_count = ref(0);
		const goals_from_stopped_count = ref(0);
		const new_goals_count = ref(0);

		const id = computed(() =>
			route.params?.id ? route.params?.id + '' : ''
		);

		const oldPayload = ref({
			'x-cross': route.query.org_id,
		});

		const is_old_data = ref(route.query.is_old_data === 'true');

		async function planActive() {
			try {
				const {
					data: { data: data_report },
				} = await $api.monthlyReport.planActive(
					id.value,
					is_old_data.value ? oldPayload.value : null
				);
				data.value = data_report;
			} catch (e) {
				console.log(e);
			}
		}

		planActive();

		function redirectCreatePage() {
			router.push({
				name: _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_CREATE_CURRENT__CREATE,
				params: {
					id: id.value,
					plan_id: data.value.id,
				},
			});
		}

		// eslint-disable-next-line vue/return-in-computed-property
		const isMonthShow = computed(() => {
			const dateCompare = new Date(2022, 2, 1, 0, 0);
			return new Date(data.value?.applied_at) >= dateCompare;
		});

		function cancel() {
			modalIsOpen.value = false;
			modalType.value = '';
		}

		const type = computed(() => ACTIONS);
		const reportName = ref('');

		function confirmSendOpen() {
			modalIsOpen.value = true;
			reportName.value = data.value?.order || '';
			modalType.value = type.value.CREATE;
		}

		async function sendNotification() {
			try {
				await $api.monthlyReport.sendNotification(
					id.value,
					data.value?.id
				);
				cancel();
				await listGroup();
				await store.dispatch('setAlertMessage', {
					message: 'Gửi kế hoạch đến phụ huynh thành công',
				});
			} catch (err) {
				await store.dispatch('setAlertMessage', {
					message: 'Phụ huynh chưa kích hoạt tài khoản',
					type: 'danger',
				});
			}
		}

		const groups = ref([] as any);
		const sortList = (value: any) => {
			order_by.value = value;
			listGroup();
		};
		async function listGroup() {
			try {
				const {
					data: { data, stats },
				} = await $api.goal.getListGroupWithDetail(id.value, {
					order_by: order_by.value,
				});
				groups.value = data;
				console.log('data', data);
				if (stats) {
					goals_count.value = stats.goals_count;
					skills_count.value = stats.skills_count;
					continued_goals_count.value = stats.continued_goals_count;
					goals_from_lib_count.value = stats.goals_from_lib_count;
					goals_from_stopped_count.value =
						stats.goals_from_stopped_count;
					new_goals_count.value = stats.new_goals_count;
				}
			} catch (err) {
				await store.dispatch('setAlertMessage', {
					message: 'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				isLoading.value = false;
			}
		}

		listGroup();

		return {
			id,
			reportName,
			modalIsOpen,
			modalType,
			isMonthShow,
			data,
			goals_count,
			skills_count,
			continued_goals_count,
			goals_from_lib_count,
			goals_from_stopped_count,
			new_goals_count,
			goals,
			goal_count,
			skill_count,
			modalTypes: computed(() => _MODAL_TYPE),
			stop,
			redirectCreatePage,
			cancel,
			confirmSendOpen,
			sendNotification,
			listGroup,
			type,
			org: computed(() => store.state.profile.current_org),
			isLoading,
			is_old_data,
			groups,
			sortList,
		};
	},
	mixins: [DownloadCSV],
});
