<template>
	<div>
		<div class="flex-nowrap flex items-center">
			<svg
				v-if="state === 'active'"
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
			>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM15.0379 6.21209C14.6718 5.84597 14.0782 5.84597 13.7121 6.21209C13.7032 6.22093 13.6949 6.23029 13.6872 6.24013L9.34673 11.7709L6.72983 9.15403C6.36372 8.78791 5.77012 8.78791 5.40401 9.15403C5.03789 9.52015 5.03789 10.1137 5.40401 10.4799L8.71207 13.7879C9.07818 14.154 9.67178 14.154 10.0379 13.7879C10.046 13.7798 10.0537 13.7712 10.0609 13.7622L15.0512 7.52434C15.404 7.15727 15.3995 6.57371 15.0379 6.21209Z"
					fill="#41BEAD"
				/>
			</svg>
			<svg
				v-else-if="state === 'pending'"
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
			>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM15.0379 6.21209C14.6718 5.84597 14.0782 5.84597 13.7121 6.21209C13.7032 6.22093 13.6949 6.23029 13.6872 6.24013L9.34673 11.7709L6.72983 9.15403C6.36372 8.78791 5.77012 8.78791 5.40401 9.15403C5.03789 9.52015 5.03789 10.1137 5.40401 10.4799L8.71207 13.7879C9.07818 14.154 9.67178 14.154 10.0379 13.7879C10.046 13.7798 10.0537 13.7712 10.0609 13.7622L15.0512 7.52434C15.404 7.15727 15.3995 6.57371 15.0379 6.21209Z"
					fill="#F0BB4F"
				/>
			</svg>
			<svg
				v-else
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM5.83333 9.16667C5.48816 9.16667 5.20833 9.53976 5.20833 10C5.20833 10.4602 5.48816 10.8333 5.83333 10.8333H14.5833C14.9285 10.8333 15.2083 10.4602 15.2083 10C15.2083 9.53976 14.9285 9.16667 14.5833 9.16667H5.83333Z"
					fill="#E68D7B"
				/>
			</svg>
			<div class="text-neutral-500 ml-4">{{ studentState(state) }}</div>
		</div>
	</div>
</template>

<script>
import { studentState } from '@/helper/studentState';

export default {
	methods: { studentState },
	props: {
		state: {
			type: String,
			required: true,
		},
	},
};
</script>
