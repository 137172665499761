<script setup>
import { computed, defineEmits, defineProps, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { $api } from '@/services';
import DocumentItem from '@/views/documents/components/DocumentItem.vue';
import { _ROUTER_NAME } from '@/enums';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import CreateGoalFolder from '@/views/goalFolders/features/CreateGoalFolder.vue';
import { useStore } from 'vuex';

const modalIsOpen = ref(false);
const inProgress = ref(false);
const listDocument = ref([]);
const order_by = ref('');
const error = ref('');
const route = useRoute();
const store = useStore();
const emit = defineEmits(['created']);

const getListDocument = async () => {
	try {
		const res = await $api.org.listDocumentsFolder({
			order_by: order_by.value,
			query: route.query?.query,
		});
		if (res) listDocument.value = res.data.data;
	} catch (e) {
		console.log(e);
	}
};
const sortList = (value) => {
	order_by.value = value;
	getListDocument();
};
const onSuccess = () => {
	modalIsOpen.value = false;
	getListDocument();
};
onMounted(() => {
	getListDocument();
});

watch(
	() => route.query?.query,
	() => {
		getListDocument();
	}
);

const props = defineProps(['onCreateFlag']);

watch(
	() => props.onCreateFlag,
	(value) => {
		if (value) {
			modalIsOpen.value = true;
			setTimeout(() => {
				emit('created', 0);
			}, 500);
		}
	}
);

const createFolder = async (data) => {
	inProgress.value = true;
	try {
		await $api.org.createDocumentsFolder({
			name: data.name,
		});
		emit('created', 0);
		modalIsOpen.value = false;
		await getListDocument();
		store.dispatch('setAlertMessage', {
			message: 'Tạo thư mục thành công',
		});
	} catch (e) {
		if (e?.response?.data?.errors) {
			store.dispatch('setAlertMessage', {
				message:
					e?.response?.data?.errors?.join('<br/>') ||
					'Đã có lỗi xảy ra. Vui lòng thử lại',
				type: 'danger',
			});
		}
		error.value = e?.response?.data?.errors_by_field['library_items'];
	} finally {
		inProgress.value = false;
	}
};

const canCreateDocument = computed(() => route.name === _ROUTER_NAME.DOCUMENTS);
</script>
<template>
	<DaModalFrame v-model:open="modalIsOpen">
		<CreateGoalFolder
			:error="error"
			:in-progress="inProgress"
			@close="modalIsOpen = false"
			@submit="createFolder"
		/>
	</DaModalFrame>
	<div class="__report-lesson">
		<div v-if="listDocument.length">
			<div v-for="document in listDocument" :key="document.id">
				<document-item
					:document="document"
					@sortList="sortList"
					@success="onSuccess"
				></document-item>
			</div>
		</div>
		<div
			v-else
			class="w-full h-full flex items-center justify-center flex-col"
		>
			<img
				alt=""
				height="250"
				src="@/assets/images/illustrations/not_found.png"
				width="250"
			/>
			<div
				class="text-neutral-500 text-lg text-center"
				style="max-width: 700px"
			>
				Trung tâm chưa có tài liệu tham khảo nào.
			</div>
			<button
				v-if="canCreateDocument"
				class="button-p btn--ghost w-fit px-4 mt-4"
				type="button"
				@click="modalIsOpen = true"
			>
				Tạo mới
			</button>
		</div>
	</div>
</template>
