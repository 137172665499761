<template>
	<div>
		<div class="w-full mb-10">
			<div class="overflow-x-auto pb-2">
				<div class="border-b border-neutral-200 mt-6">
					<nav aria-label="Tabs" class="-mb-px flex space-x-8 tabs">
						<div
							v-for="student in studentOptions"
							:key="student.id"
							:class="
								studentSelected?.id === student.id
									? 'text-yellow-700 border-yellow-500 font-semibold'
									: 'text-neutral-500 hover:text-yellow-700 hover:border-yellow-500 font-medium'
							"
							class="
								border-transparent
								whitespace-nowrap
								py-3
								px-1
								border-b-2
								cursor-pointer
							"
							@click="selectedStudent(student)"
						>
							<div class="flex items-center gap-2">
								<div>{{ student.name }}</div>
							</div>
						</div>
					</nav>
				</div>
			</div>
			<div
				v-if="studentSelected?.id !== 'all'"
				class="flex items-center mt-8"
			>
				<div class="relative">
					<img
						v-if="studentSelected?.profile_photo?.w200"
						:alt="studentSelected?.name"
						:src="studentSelected?.profile_photo?.w200"
						class="shadow-sm inline-block h-14 w-14 rounded-full"
						style="min-width: 56px"
					/>
					<span
						v-else
						:style="{
							background:
								studentSelected?.profile_photo?.default?.color,
						}"
						class="
							inline-flex
							items-center
							justify-center
							h-14
							w-14
							rounded-full
						"
						style="min-width: 56px"
					>
						<span
							class="font-medium leading-none text-white text-4xl"
							>{{
								studentSelected?.profile_photo?.default?.initial
							}}</span
						>
					</span>
					<div
						v-if="
							studentSelected.state !== 'active' &&
							studentSelected?.profile_photo?.state !== 'active'
						"
						class="
							absolute
							transform
							-translate-x-1/2 -translate-y-1/2
							top-1/2
							left-1/2
							h-14
							w-14
							rounded-full
							flex
							items-center
							justify-center
						"
						style="
							min-width: 56px;
							background-color: rgba(241, 162, 103, 0.5);
						"
					>
						<svg
							fill="none"
							height="32"
							viewBox="0 0 40 40"
							width="32"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clip-rule="evenodd"
								d="M9.84364 16.0997V10.2496C9.84364 4.86466 14.3907 0.5 20 0.5C25.6093 0.5 30.1564 4.865 30.1564 10.2496V16.0997C33.5218 16.0997 36.25 18.7188 36.25 21.9497V33.6499C36.25 36.8812 33.5218 39.5 30.1564 39.5H9.84364C6.47821 39.5 3.75 36.8812 3.75 33.6499V21.9497C3.75 18.7188 6.47821 16.0997 9.84364 16.0997V16.0997ZM26.0936 16.0997V10.2496C26.0936 7.01866 23.3654 4.3995 20 4.3995C16.6346 4.3995 13.9064 7.01866 13.9064 10.2496V16.0997H26.0936ZM20 23.9C21.1218 23.9 22.0311 24.7729 22.0311 25.8499V29.7501C22.0311 30.8271 21.1218 31.7 20 31.7C18.8782 31.7 17.9689 30.8271 17.9689 29.7501V25.8499C17.9689 24.7729 18.8782 23.9 20 23.9Z"
								fill="#FAF5F1"
								fill-rule="evenodd"
							/>
						</svg>
					</div>
				</div>
				<div class="ml-4">
					<div
						class="
							font-bold
							text-neutral-700 text-4xl
							md-down:text-2xl
						"
					>
						{{ studentSelected?.name }}
					</div>
					<div class="flex items-center">
						<div
							v-if="studentSelected?.age"
							class="
								text-green-500
								font-semibold
								text-xs
								uppercase
								letter-spacing-8
							"
						>
							<span
								>{{ studentSelected?.age }}
								{{
									studentSelected?.learning_time &&
									![
										'graduated',
										'closed',
										'transfered',
										'stopped',
										'pending',
									].includes(studentSelected?.state)
										? `| ${studentSelected?.learning_time}`
										: ''
								}}</span
							>
						</div>
						<div
							v-if="
								[
									'graduated',
									'closed',
									'transfered',
									'stopped',
									'pending',
								].includes(studentSelected?.state)
							"
							class="
								text-green-500
								font-semibold
								text-xs
								uppercase
								letter-spacing-8
							"
						>
							<span v-if="studentSelected?.age" class="ml-1"
								>|</span
							>
							<span class="ml-1">{{
								studentState(studentSelected?.state)
							}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="mt-12 bg-white shadow-sm rounded-2xl overflow-hidden">
				<div class="text-neutral-500 p-6">
					<div class="font-semibold">
						{{
							studentSelected?.id === 'all'
								? 'Nhận xét chung/ lưu ý cho cấp quản lý'
								: 'Nhận xét chung/ lưu ý cho phụ huynh'
						}}
					</div>
				</div>
				<div
					class="
						flex
						items-stretch
						gap-5
						border-t border-orange-50
						p-6
					"
				>
					<div class="w-full h-full">
						<textarea
							v-if="studentSelected?.id === 'all'"
							v-model="reportNote"
							:class="noteError ? 'text-error' : ''"
							:disabled="isLoading"
							placeholder="Nhận xét chung/ lưu ý cho cấp quản lý"
							style="
								height: 100%;
								resize: none;
								min-height: 160px;
							"
							@blur="onSaveNote(notes[studentSelected?.id])"
						></textarea>
						<textarea
							v-else
							v-model="notes[studentSelected?.id].note"
							:class="noteError ? 'text-error' : ''"
							:disabled="isLoading"
							placeholder="Nhận xét chung/ lưu ý cho phụ huynh"
							style="
								height: 100%;
								resize: none;
								min-height: 160px;
							"
							@blur="onSaveNote(notes[studentSelected?.id])"
						></textarea>
						<div v-if="noteError" class="text-sienna-600">
							Vui lòng nhập ghi chú trước khi chọn học sinh khác
						</div>
					</div>
				</div>
			</div>
			<div class="relative mt-12">
				<div
					aria-hidden="true"
					class="absolute inset-0 flex items-center"
				>
					<div class="w-full border-t border-neutral-300" />
				</div>
				<div class="relative flex items-center justify-center">
					<div
						class="
							px-2.5
							py-1
							bg-body
							text-neutral-600
							font-semibold
						"
					>
						Nội dung báo cáo
					</div>
				</div>
			</div>
			<div class="mt-10">
				<div class="p-6 bg-white shadow-sm rounded-2xl">
					<div class="font-semibold text-neutral-600 mb-6">
						Thống kê của tháng
						{{ $filters.toMMYYY(report?.data?.monthly_start) }}
					</div>
					<div class="flex flex-nowrap w-full md-down:flex-col">
						<div
							class="
								p-6
								border
								rounded-l-lg
								border-neutral-200
								w-full
								md-down:rounded-t md-down:rounded-b-none
							"
						>
							<div
								class="
									text-33
									font-bold
									text-neutral-600
									mb-6
									flex flex-nowrap
									items-center
								"
							>
								<img
									alt=""
									class="mr-2"
									height="32"
									src="@/assets/images/icons/abc.png"
									style="height: 32px"
									width="32"
								/>
								{{
									Number(
										report?.stats?.learn_skill_count || 0
									) +
									'/' +
									Number(report?.stats?.skill_count || 0)
								}}
							</div>
							<div class="flex flex-nowrap items-center">
								<span class="text-neutral-500 text-sm"
									>Lĩnh vực đã học
								</span>
							</div>
						</div>
						<div
							class="
								p-6
								border-t border-b border-neutral-200
								w-full
								md-down:border-l
								md-down:border-r
								md-down:border-b-0
							"
						>
							<div
								class="
									text-33
									font-bold
									text-neutral-600
									mb-6
									flex flex-nowrap
									items-center
								"
							>
								<img
									alt=""
									class="mr-2"
									height="32"
									src="@/assets/images/icons/light.png"
									style="height: 32px"
									width="32"
								/>
								{{
									Number(
										report?.stats?.learn_goal_count || 0
									) +
									'/' +
									Number(report?.stats?.goal_count || 0)
								}}
							</div>
							<div class="flex flex-nowrap items-center">
								<span class="text-neutral-500 text-sm"
									>Mục tiêu đã học
								</span>
							</div>
						</div>
						<div
							class="
								p-6
								border-l border-t border-b border-neutral-200
								w-full
								md-down:border-r md-down:border-b-0
							"
						>
							<div
								class="
									text-33
									font-bold
									text-neutral-600
									mb-6
									flex flex-nowrap
									items-center
								"
							>
								<img
									alt=""
									class="mr-2"
									height="32"
									src="@/assets/images/icons/light.png"
									style="height: 32px"
									width="32"
								/>
								{{ report?.stats?.completed_count }}
							</div>
							<div class="flex flex-nowrap items-center">
								<span class="text-neutral-500 text-sm"
									>Mục tiêu đã hoàn thành
								</span>
							</div>
						</div>
						<div
							class="
								p-6
								border-l border-t border-b border-neutral-200
								w-full
								md-down:border-r md-down:border-b-0
							"
						>
							<div
								class="
									text-33
									font-bold
									text-neutral-600
									mb-6
									flex flex-nowrap
									items-center
								"
							>
								<img
									alt=""
									class="mr-2"
									height="32"
									src="@/assets/images/icons/light.png"
									style="height: 32px"
									width="32"
								/>
								{{ report?.stats?.continued_count }}
							</div>
							<div class="flex flex-nowrap items-center">
								<span class="text-neutral-500 text-sm"
									>Mục tiêu sẽ tiếp tục
								</span>
							</div>
						</div>
						<div
							class="
								p-6
								border
								rounded-r-lg
								border-neutral-200
								w-full
								md-down:rounded-b md-down:rounded-t-none
							"
						>
							<div
								class="
									text-33
									font-bold
									text-neutral-600
									mb-6
									flex flex-nowrap
									items-center
								"
							>
								<img
									alt=""
									class="mr-2"
									height="32"
									src="@/assets/images/icons/light.png"
									style="height: 32px"
									width="32"
								/>
								{{ report?.stats?.stoped_count }}
							</div>
							<div class="flex flex-nowrap items-center">
								<span class="text-neutral-500 text-sm"
									>Mục tiêu sẽ tạm dừng</span
								>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="mt-10">
				<div class="grid grid-cols-2 gap-8 xl-down:grid-cols-1">
					<div class="bg-white shadow-sm rounded-2xl overflow-hidden">
						<div class="font-semibold text-neutral-500 p-6">
							Biểu đồ cảm xúc
						</div>
						<div
							class="
								border-t border-orange-50
								flex
								justify-center
								items-center
								p-6
								lg-pl-0
								lg-down:flex-col
							"
						>
							<div
								class="
									relative
									w-1/2
									md-down:w-full
									flex
									items-center
									justify-center
								"
							>
								<apexchart
									:options="chartOptions"
									:series="emotions"
									height="220"
									type="donut"
								></apexchart>
								<div
									class="
										text-center
										absolute
										top-1/2
										left-1/2
										transform
									"
									style="transform: translate(-50%, -50%)"
								>
									<div class="text-neutral-500">Cảm xúc</div>
								</div>
							</div>
							<div class="w-1/3 lg-down:w-full lg-down:mt-4">
								<div
									v-if="emotions[0] > 0"
									class="
										flex flex-nowrap
										items-center
										justify-between
										w-full
									"
								>
									<div
										class="flex flex-nowrap items-center"
										style="min-width: 130px"
									>
										<img
											alt=""
											height="24"
											src="@/assets/images/emoji/happy.png"
											width="24"
										/>
										<div
											class="
												h-2.5
												w-2.5
												rounded-full
												ml-2
											"
											style="background: #9edf93"
										></div>
										<div
											class="
												ml-2
												text-base text-neutral-500
												whitespace-nowrap
											"
										>
											Vui vẻ
										</div>
									</div>
									<div
										class="
											text-xs text-neutral-600
											font-bold
											whitespace-nowrap
										"
									>
										{{ emotions[0] }}%
									</div>
								</div>
								<div
									v-if="emotions[1] > 0"
									class="
										flex flex-nowrap
										items-center
										justify-between
										w-full
										mt-2.5
									"
								>
									<div
										class="flex flex-nowrap items-center"
										style="min-width: 130px"
									>
										<img
											alt=""
											height="24"
											src="@/assets/images/emoji/5565d66a50b44df9ae37a955bdfb3757.png"
											width="24"
										/>
										<div
											class="
												h-2.5
												w-2.5
												rounded-full
												ml-2
											"
											style="background: #d0db54"
										></div>
										<div
											class="
												ml-2
												text-base text-neutral-500
												whitespace-nowrap
											"
										>
											Bình thường
										</div>
									</div>
									<div
										class="
											text-xs text-neutral-600
											font-bold
											whitespace-nowrap
										"
									>
										{{ emotions[1] }}%
									</div>
								</div>
								<div
									v-if="emotions[2] > 0"
									class="
										flex flex-nowrap
										items-center
										justify-between
										w-full
										mt-2.5
									"
								>
									<div
										class="flex flex-nowrap items-center"
										style="min-width: 130px"
									>
										<img
											alt=""
											height="24"
											src="@/assets/images/emoji/cf1f643bc1244f5e8e37767bc946d607.png"
											width="24"
										/>
										<div
											class="
												h-2.5
												w-2.5
												rounded-full
												ml-2
											"
											style="background: #ffe68c"
										></div>
										<div
											class="
												ml-2
												text-base text-neutral-500
												whitespace-nowrap
											"
										>
											Khó chịu
										</div>
									</div>
									<div
										class="
											text-xs text-neutral-600
											font-bold
											whitespace-nowrap
										"
									>
										{{ emotions[2] }}%
									</div>
								</div>
								<div
									v-if="emotions[3] > 0"
									class="
										flex flex-nowrap
										items-center
										justify-between
										w-full
										mt-2.5
									"
								>
									<div
										class="flex flex-nowrap items-center"
										style="min-width: 130px"
									>
										<img
											alt=""
											height="24"
											src="@/assets/images/emoji/5ede936757cd4fbdb6247bad22bf454d.png"
											width="24"
										/>
										<div
											class="
												h-2.5
												w-2.5
												rounded-full
												ml-2
											"
											style="background: #ff9d6d"
										></div>
										<div
											class="
												ml-2
												text-base text-neutral-500
												whitespace-nowrap
											"
										>
											Tức giận
										</div>
									</div>
									<div
										class="
											text-xs text-neutral-600
											font-bold
											whitespace-nowrap
										"
									>
										{{ emotions[3] }}%
									</div>
								</div>
								<div
									v-if="emotions[4] > 0"
									class="
										flex flex-nowrap
										items-center
										justify-between
										w-full
										mt-2.5
									"
								>
									<div
										class="flex flex-nowrap items-center"
										style="min-width: 130px"
									>
										<img
											alt=""
											height="24"
											src="@/assets/images/emoji/0b356bb507d64b9489e2fbced131f795.png"
											width="24"
										/>
										<div
											class="
												h-2.5
												w-2.5
												rounded-full
												ml-2
											"
											style="background: #ff6967"
										></div>
										<div
											class="
												ml-2
												text-base text-neutral-500
												whitespace-nowrap
											"
										>
											Bùng nổ
										</div>
									</div>
									<div
										class="
											text-xs text-neutral-600
											font-bold
											whitespace-nowrap
										"
									>
										{{ emotions[4] }}%
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="bg-white shadow-sm rounded-2xl overflow-hidden">
						<div class="font-semibold text-neutral-500 p-6">
							Chuyên cần
						</div>
						<div
							class="
								border-t border-orange-50
								p-6
								flex
								items-center
								justify-center
								flex-col
							"
						>
							<div
								class="text-neutral-600 font-semibold mb-5"
								style="font-size: 72px"
							>
								{{ report?.group_sessions_count }}
							</div>
							<div class="text-neutral-500 mb-5 text-center">
								Số buổi học tháng này <br />
								<span
									v-if="studentSelected?.id !== 'all'"
									style="font-style: italic"
									>(PH không xem được thông tin này)</span
								>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="radarData.length && radarData[0]?.data.length >= 3"
				class="mt-10"
			>
				<div
					class="
						bg-white
						shadow-sm
						overflow-hidden
						rounded-2xl
						mb-10
						pb-5
					"
				>
					<div class="font-semibold text-neutral-500 p-6">
						Biểu đồ mối tương quan giữa kết quả các lĩnh vực trong
						tháng
					</div>
					<div
						class="
							p-6
							border-t border-orange-50
							md-down:px-0
							overflow-x-auto
							w-full
						"
					>
						<apexchart
							:options="radarChartOptions"
							:series="radarData"
							height="700"
							type="radar"
						></apexchart>
						<div
							class="
								py-4
								flex flex-wrap
								gap-6
								justify-center
								items-center
							"
						>
							<div
								v-for="(c, index) in categories"
								:key="c"
								class="flex items-center flex-nowrap gap-2"
							>
								<div class="font-bold text-lf text-neutral-700">
									{{ index + 1 }}: {{ c }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="relative mt-12">
				<div
					aria-hidden="true"
					class="absolute inset-0 flex items-center"
				>
					<div class="w-full border-t border-neutral-300" />
				</div>
				<div class="relative flex items-center justify-center">
					<div
						class="
							px-2.5
							py-1
							bg-body
							text-neutral-600
							font-semibold
						"
					>
						Kết quả mục tiêu đã học
					</div>
				</div>
			</div>
			<div v-if="studentSelected?.id === 'all'">
				<GoalsGroupClass
					:group_class="report?.group_class"
					:skills="report.skills"
					:students="students"
				/>
			</div>
			<div v-else>
				<GoalsGroupClassForStudent
					:skills="report.skills"
					:student-id="studentSelected?.id"
				/>
			</div>
		</div>
	</div>
</template>
<script>
import GoalsGroupClass from '@/views/planGroupEdit/GoalsGroupClass.vue';
import GoalsGroupClassForStudent from '@/views/planGroupEdit/GoalsGroupClassForStudent.vue';
import { studentState } from '@/helper/studentState';
import { $api } from '@/services';

export default {
	name: 'ReportDetail',

	components: { GoalsGroupClassForStudent, GoalsGroupClass },

	props: {
		students: {
			type: Array,
			required: true,
		},

		studentSelected: {
			type: Object,
			required: true,
		},

		report: {
			type: Object,
			required: true,
		},

		report_note: {
			type: String,
			required: true,
		},
	},

	data() {
		return {
			chartOptions: {
				colors: ['#9EDF93', '#D0DB54', '#FFE68C', '#FF9D6D', '#FF6967'],
				chart: {
					width: 380,
					type: 'polarArea',
				},
				labels: [
					'Vui vẻ',
					'Bình thường',
					'Khó chịu',
					'Tức giận',
					'Bùng nổ',
				],
				fill: {
					opacity: 1,
					colors: [
						'#9EDF93',
						'#D0DB54',
						'#FFE68C',
						'#FF9D6D',
						'#FF6967',
					],
				},
				stroke: {
					lineCap: 'round',
				},
				yaxis: {
					show: false,
				},
				tooltip: {
					fillSeriesColor: true,
					y: {
						formatter: function (value) {
							if (!value || value == 'null') {
								return 'Không thực hiện';
							}
							return value + '%';
						},
					},
				},
				legend: {
					show: false,
				},
				dataLabels: {
					enabled: false,
				},
				plotOptions: {
					polarArea: {
						rings: {
							strokeWidth: 0,
						},
						spokes: {
							strokeWidth: 0,
						},
					},
				},
			},
			radarChartOptions: {
				chart: {
					id: 'radarChart',
					type: 'radar',
				},
				title: {
					show: false,
				},
				stroke: {
					width: 0,
				},
				fill: {
					opacity: 0.2,
					colors: ['#65A30D'],
				},
				markers: {
					size: 5,
					hover: {
						size: 10,
					},
					colors: ['#65A30D'],
				},
				dataLabels: {
					enabled: true,
					formatter: function (val) {
						return val + '%';
					},
					style: {
						fontFamily: 'Gilrory',
						fontSize: '20px',
						fontWeight: 'bold',
						colors: ['#344256'],
					},
					background: {
						enabled: false,
					},
				},
				yaxis: {
					show: false,
					logBase: 100,
					tickAmount: 10,
					min: 0,
					max: 100,
					labels: {
						show: false,
					},
				},
				xaxis: {
					categories: [],
					labels: {
						show: true,
						style: {
							fontFamily: 'Gilrory',
							fontSize: '14px',
							fontWeight: 600,
							colors: Array(1000).fill('#344256'),
						},
						formatter: function (val, index) {
							return index?.dataPointIndex + 1;
						},
					},
				},
				tooltip: {
					x: {
						show: true,
						formatter: function (val, object) {
							return `${object?.dataPointIndex + 1}: ${val}`;
						},
						title: {
							formatter: (seriesName) => seriesName,
						},
					},
					y: {
						show: true,
						formatter: function (val) {
							return val + '%';
						},
						title: {
							title: {
								formatter: (seriesName) => seriesName + ':',
							},
						},
					},
				},
			},
			notes: {},
			noteError: false,
			isLoading: false,
			reportNote: '',
		};
	},

	watch: {
		report_note: {
			immediate: true,
			handler(value) {
				this.reportNote = value || '';
			},
		},

		students: {
			immediate: true,
			deep: true,
			handler(value) {
				if (value && !this.notes?.length) {
					this.notes = {};
					this.studentOptions.forEach((s) => {
						this.notes[s.id] = {
							group_monthly_report_id: '',
							note: '',
							student_id: s.id,
						};
					});
				}
			},
		},

		'studentSelected.id': {
			deep: true,
			handler(value) {
				if (value !== 'all' && !this.notes[value]?.id) {
					this.fetchNoteForStudentGroup();
				}
			},
		},
	},

	computed: {
		studentOptions() {
			return [
				{
					id: 'all',
					name: 'Báo cáo nhóm nội bộ',
				},
				...this.students,
			];
		},

		emotionTotal() {
			return this.report?.emotion_scale_stats?.reduce(
				(a, b) => +a + +b.count,
				0
			);
		},

		emotions() {
			return this.report?.emotion_scale_stats?.map((item) => {
				const r = (item.count * 100) / this.emotionTotal;
				return Math.round(r * 10) / 10 || 0;
			});
		},

		categories() {
			return this.report?.skills.map((skill) => {
				return skill?.skill?.name;
			});
		},

		radarData() {
			return [
				{
					name: 'Kết quả',
					data: this.report?.skills_stats.map((s) => {
						return +s?.average_result || 0;
					}),
				},
			];
		},
	},

	methods: {
		studentState,

		onSaveNote(oldNote) {
			if (oldNote?.student_id === 'all') {
				this.updateReport();
			} else if (!oldNote?.id) {
				this.createNoteForStudentGroup(oldNote);
			} else {
				this.updateNoteForStudentGroup(oldNote);
			}
		},

		async fetchNoteForStudentGroup() {
			this.isLoading = true;
			try {
				const {
					data: { data },
				} = await $api.monthlyReport.fetchNoteForStudentGroup(
					this.report?.group_class?.id,
					this.report?.data?.id,
					this.studentSelected?.id
				);
				if (data) {
					this.notes[this.studentSelected?.id] = data;
				}
			} catch (e) {
				console.log(e);
			} finally {
				this.isLoading = false;
			}
		},

		async createNoteForStudentGroup(note) {
			try {
				const {
					data: { data, all_student_group_monthly_report_completed },
				} = await $api.monthlyReport.createNoteForStudentGroup(
					this.report?.group_class?.id,
					this.report?.data?.id,
					{
						note: note?.note,
						student_id: note?.student_id,
					}
				);
				note = data;
				this.$emit('reportUpdated', {
					all_student_group_monthly_report_completed,
				});
			} catch (err) {
				const error =
					err?.response?.data?.errors?.join(<br />) ||
					err?.message ||
					'Đã có lỗi xảy ra. Vui lòng thử lại';
				await this.$store.dispatch('setAlertMessage', {
					message: error,
					type: 'danger',
				});
			}
		},

		async updateNoteForStudentGroup(note) {
			if (!note?.id) {
				return;
			}

			try {
				const {
					data: { data, all_student_group_monthly_report_completed },
				} = await $api.monthlyReport.updateNoteForStudentGroup(
					this.report?.group_class?.id,
					this.report?.data?.id,
					note.id,
					{
						note: note?.note,
						student_id: note?.student_id,
					}
				);
				note = data;
				this.$emit('reportUpdated', {
					all_student_group_monthly_report_completed,
				});
			} catch (err) {
				const error =
					err?.response?.data?.errors?.join(<br />) ||
					err?.message ||
					'Đã có lỗi xảy ra. Vui lòng thử lại';
				await this.$store.dispatch('setAlertMessage', {
					message: error,
					type: 'danger',
				});
			}
		},

		async updateReport() {
			try {
				const {
					data: { all_student_group_monthly_report_completed },
				} = await $api.monthlyReport.editReportGroupClass(
					this.report?.group_class?.id,
					this.report?.data?.id,
					{
						group_monthly_report: {
							note: this.reportNote,
						},
					}
				);

				this.$emit('reportUpdated', {
					all_student_group_monthly_report_completed,
				});
			} catch (err) {
				const error =
					err?.response?.data?.errors?.join(<br />) ||
					err?.message ||
					'Đã có lỗi xảy ra. Vui lòng thử lại';
				await this.$store.dispatch('setAlertMessage', {
					message: error,
					type: 'danger',
				});
			}
		},

		selectedStudent(student) {
			this.$emit('changeStudent', student);
		},
	},
};
</script>
