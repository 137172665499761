
import { computed, defineComponent, ref } from 'vue';
import { useStudent } from '@/features/student/useStudent';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { $api } from '@/services';
import { _MODAL_TYPE, _ROUTER_NAME } from '@/enums';
import Multiselect from '@suadelabs/vue3-multiselect';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import ConfirmModal from '@/views/record/studentDetail/features/reportTarget/confirmModal/ConfirmModal.vue';
import EditGoal from '@/views/record/studentDetail/features/reportTarget/editGoal/EditGoal.vue';
import { toEnglish } from '@/helper/toEnglish';

export default defineComponent({
	name: 'CreateGoals',
	components: {
		EditGoal,
		ConfirmModal,
		DaIcon,
		DaModalFrame,
		Multiselect,
	},
	setup() {
		const { goalByStudent, getStudent } = useStudent();
		const route = useRoute();
		const router = useRouter();
		const store = useStore();
		const goals = ref([]);
		const skills = ref([]);
		const modalIsOpen = ref(false);
		const isEdit = ref(false);
		const isStop = ref(false);
		const isShow = ref(true);
		let modalType = ref('');
		let error = ref('');
		let errorEdit = ref('');
		let assistance_levels = ref([
			{
				name: 'Bằng lời nói trực tiếp',
				value: 5,
			},
			{
				name: 'Bằng lời nói gián tiếp',
				value: 6,
			},
			{
				name: 'Cử chỉ',
				value: 4,
			},
			{
				name: 'Chủ động / sau khi được yêu cầu',
				value: 9,
			},
			{
				name: 'Hỗ trợ thể chất toàn phần',
				value: 1,
			},
			{
				name: 'Hỗ trợ thể chất một phần',
				value: 2,
			},
			{
				name: 'Làm mẫu',
				value: 3,
			},
			{
				name: 'Nhắc hình ảnh',
				value: 7,
			},
			{
				name: 'Nhắc bằng vị trí',
				value: 8,
			},
			{
				name: 'Nhắc kết hợp',
				value: 10,
			},
		]);
		const student: any = ref(null);

		const id = computed(() =>
			route.params?.id ? route.params?.id + '' : ''
		);

		function closeModal() {
			stopID.value = '';
			modalType.value = '';
			modalIsOpen.value = false;
		}

		async function fetchStudent() {
			const res = await getStudent(id.value);
			if (res?.data) {
				student.value = res?.data;
			}
		}

		let deleteOne = ref(false);

		function openDeleteModal(id?: string) {
			if (stopGoalList.value.length <= 0 && !id) {
				return;
			}
			if (id) {
				stopID.value = id;
				deleteOne.value = true;
			} else {
				deleteOne.value = false;
				stopID.value = '';
			}
			modalType.value = _MODAL_TYPE.DELETE;
			modalIsOpen.value = true;
		}

		let editData = ref(null as any);

		function editGoalModal(data: any, skill_id: string) {
			editData.value = {
				id: data?.id,
				skill_id: skill_id,
				name: data?.name,
				assistance_level: data?.assistance_level,
			};
			modalType.value = _MODAL_TYPE.EDIT;
			modalIsOpen.value = true;
		}

		async function editSubmit(data: any) {
			try {
				const res = await $api.goal.updateGoal(id.value, data.id, {
					goal: data,
				});
				if (res?.data) {
					closeModal();
					await fetchGoals();
					store.dispatch('setAlertMessage', {
						message: 'Chỉnh sửa tiêu thành công',
					});
					clearInput();
				}
			} catch (e) {
				errorEdit.value =
					'Nội dung báo cáo ' +
						e?.response?.data?.errors_by_field['name']?.join('') ||
					'Có lỗi xảy ra';
			}
		}

		function stop() {
			isShow.value = true;
			isStop.value = true;
			stopGoalList.value = [];
			stopSkillList.value = [];
		}

		function exitStop() {
			stopID.value = '';
			isStop.value = false;
		}

		let goalContentInput = ref('');
		const skillSelected = ref(null);
		const assistanceSelected = ref(null);

		async function createSubmit() {
			try {
				const res = await $api.goal.createGoal(id.value, {
					goal: {
						// @ts-ignore
						assistance_level: assistanceSelected.value?.value,
						name: goalContentInput.value,
						// @ts-ignore
						skill_id: skillSelected.value?.id || '',
					},
				});
				if (res?.data) {
					await fetchGoals();
					store.dispatch('setAlertMessage', {
						message: 'Tạo mục tiêu thành công',
					});
					clearInput();
				}
			} catch (e) {
				if (e?.response?.data?.errors_by_field?.name) {
					error.value = `${
						goalContentInput.value || 'Nội dung mục tiêu'
					} ${e?.response?.data?.errors_by_field?.name.join('')}`;
					setTimeout(() => {
						error.value = '';
					}, 5000);
				}
			}
		}

		function save() {
			isEdit.value = false;
			isStop.value = false;
		}

		function finished() {
			router.push({
				name: _ROUTER_NAME.RECORD_STUDENT_DETAIL_TARGET,
				params: {
					id: id.value,
				},
			});
		}

		async function fetchGoals() {
			const res = await goalByStudent(id.value);
			if (res?.data) {
				goals.value = res?.data?.skills ?? [];
			}
		}

		function clearInput() {
			goalContentInput.value = '';
			// skillSelected.value = skills.value[0];
			assistanceSelected.value = null;
		}

		async function fetchSkill() {
			try {
				const res = await $api.goal.allSkills();
				if (res?.data) {
					skills.value = res?.data?.data;
					skillSelected.value = res?.data?.data[0];
				}
			} catch (e) {
				console.log(e);
			}
		}

		fetchStudent();
		fetchGoals();
		fetchSkill();

		const stopGoalList = ref([]);
		const stopSkillList = ref([]);

		function stopGoalChecked(value: any, skill_id: any) {
			// @ts-ignore
			const index = stopGoalList.value.indexOf(value);
			// @ts-ignore
			const skill_index = stopSkillList.value.indexOf(skill_id);
			// @ts-ignore
			if (index !== -1) {
				stopGoalList.value.splice(index, 1);
			} else {
				// @ts-ignore
				stopGoalList.value.push(value);
			}
			if (skill_index === -1) {
				// @ts-ignore
				stopSkillList.value.push(skill_id);
			}
		}

		async function stopSubmit() {
			if (stopGoalList.value.length <= 0) {
				isStop.value = false;
				isEdit.value = false;
				return;
			}
			try {
				const res = await $api.goal.stopGoals(id.value, {
					ids: stopGoalList.value,
				});
				if (res?.data) {
					await fetchGoals();
					closeModal();
					exitStop();
				}
			} catch (e) {
				console.log(e);
			}
		}

		let stopID = ref('');

		async function stopOneSubmit() {
			try {
				const res = await $api.goal.stopGoal(id.value, stopID.value);
				if (res?.data) {
					await fetchGoals();
					closeModal();
					exitStop();
				}
			} catch (e) {
				console.log(e);
			} finally {
				stopID.value = '';
			}
		}

		function redirectCreatePage() {
			router.push({
				name: _ROUTER_NAME.RECORD_STUDENT_DETAIL_TARGET_CREATE,
				params: {
					id: id.value,
				},
			});
		}

		return {
			id,
			goals,
			modalIsOpen,
			skills,
			isEdit,
			isStop,
			isShow,
			stopGoalList,
			deleteOne,
			modalType,
			stopSkillList,
			modalTypes: computed(() => _MODAL_TYPE),
			error,
			goalContentInput,
			skillSelected,
			assistance_levels,
			assistanceSelected,
			student,
			editData,
			errorEdit,
			dataDelete: computed(() => ({
				goals: stopGoalList.value,
				skills: stopSkillList.value,
			})),
			routerName: computed(() => _ROUTER_NAME),
			openDeleteModal,
			stop,
			exitStop,
			save,
			stopGoalChecked,
			stopSubmit,
			stopOneSubmit,
			createSubmit,
			closeModal,
			redirectCreatePage,
			editGoalModal,
			editSubmit,
			finished,
			org: computed(() => store.state.profile.current_org),
			toEnglish: () => toEnglish,
		};
	},
});
