
import { computed, defineComponent, ref } from 'vue';
import { useStudent } from '@/features/student/useStudent';
import { useRoute } from 'vue-router';
import { _MODAL_TYPE, _ROUTER_NAME } from '@/enums';
import router from '@/router';
import { useStore } from 'vuex';

export default defineComponent({
	name: 'ReportTarget',
	setup() {
		const { goalByStudent } = useStudent();
		const route = useRoute();
		const store = useStore();
		const goals = ref([]);
		const skill_count = ref(0);
		const goal_count = ref(0);

		const id = computed(() =>
			route.params?.id ? route.params?.id + '' : ''
		);

		async function fetchGoals() {
			const res = await goalByStudent(id.value);
			if (res?.data) {
				goals.value = res?.data?.skills ?? [];
				skill_count.value = res?.data.length;
				goals.value.forEach((item: any) => {
					goal_count.value += item?.goals.length || 0;
				});
			}
		}

		fetchGoals();

		function redirectCreatePage() {
			router.push({
				name: _ROUTER_NAME.RECORD_STUDENT_DETAIL_TARGET_CREATE,
				params: {
					id: id.value,
				},
			});
		}

		return {
			id,
			goals,
			goal_count,
			skill_count,
			modalTypes: computed(() => _MODAL_TYPE),
			stop,
			redirectCreatePage,
			org: computed(() => store.state.profile.current_org),
		};
	},
});
