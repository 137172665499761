
export default {
	name: 'ViewGoalFolder',

	props: {
		data: {
			required: true,
			type: Object,
		},
	},
};
