
import { Vue } from 'vue-class-component';
import { Prop, PropSync } from 'vue-property-decorator';

export default class CreateBoarding extends Vue {
	//----------------------- 🤍 Props 🤍 -----------------------//
	@PropSync('success', { default: false, type: Boolean })
	createSuccessful!: boolean;
	@PropSync('title') titleData!: null;
	@PropSync('label') labelData!: null;
	@PropSync('error') errorData!: null;
	@Prop() textData!: null;
	@Prop() valueData!: null;

	//----------------------- 🤍 Mounted 🤍 -----------------------//
	mounted() {
		this.createSuccessful = false;
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	submit() {
		this.$emit('submit');
	}
}
