<template>
	<div class="fixed inset-y-0 right-0 flex side-over z-50 w-screen md:w-2/5">
		<div
			class="h-full w-full flex flex-col bg-white shadow-xl overflow-auto"
		>
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div v-if="!data" class="flex items-center">
						<svg
							fill="none"
							height="24"
							viewBox="0 0 24 24"
							width="24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clip-rule="evenodd"
								d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM12.75 6.75C12.75 6.33579 12.4142 6 12 6C11.5858 6 11.25 6.33579 11.25 6.75V11.25H6.75C6.33579 11.25 6 11.5858 6 12C6 12.4142 6.33579 12.75 6.75 12.75H11.25V17.25C11.25 17.6642 11.5858 18 12 18C12.4142 18 12.75 17.6642 12.75 17.25V12.75H17.25C17.6642 12.75 18 12.4142 18 12C18 11.5858 17.6642 11.25 17.25 11.25H12.75V6.75Z"
								fill="#94A3B8"
								fill-rule="evenodd"
							/>
						</svg>
						<div class="text-2xl font-bold text-neutral-700 ml-2">
							Thêm buổi học
						</div>
					</div>
					<div v-else class="flex items-center">
						<svg
							fill="none"
							height="24"
							viewBox="0 0 24 24"
							width="24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clip-rule="evenodd"
								d="M13.6308 5.68726L15.8838 3.43422C16.7496 2.56844 17.1825 2.13555 17.6895 1.99477C18.0396 1.89758 18.4095 1.89758 18.7596 1.99477C19.2667 2.13555 19.6996 2.56844 20.5653 3.43423C21.4311 4.30001 21.864 4.7329 22.0048 5.23996C22.102 5.59003 22.102 5.95997 22.0048 6.31005C21.864 6.81711 21.4311 7.25 20.5653 8.11578L18.3384 10.3427C16.3859 9.22291 14.7651 7.6144 13.6308 5.68726ZM12.1764 7.14163L3.8659 15.4521C3.44084 15.8772 3.22831 16.0897 3.08857 16.3508C2.94884 16.6119 2.88989 16.9066 2.772 17.4961L1.98622 21.425C1.91969 21.7576 1.88643 21.9239 1.98104 22.0185C2.07564 22.1131 2.24194 22.0799 2.57452 22.0134L2.57455 22.0134L2.57456 22.0134L6.50349 21.2276L6.50351 21.2276C7.09295 21.1097 7.38767 21.0507 7.64876 20.911C7.90986 20.7713 8.12239 20.5587 8.54743 20.1337L8.54745 20.1337L16.8803 11.8008C14.9786 10.6167 13.3722 9.02142 12.1764 7.14163Z"
								fill="#94A3B8"
								fill-rule="evenodd"
							/>
						</svg>

						<div class="text-2xl font-bold text-neutral-700 ml-2">
							Chỉnh sửa buổi học
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							type="button"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								aria-hidden="true"
								class="h-6 w-6"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M6 18L18 6M6 6l12 12"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div
				class="
					sideover-content
					pt-6
					relative
					flex-1
					px-4
					sm:px-6
					overflow-y-auto
					flex flex-col
					justify-between
				"
			>
				<div>
					<div class="md-down:flex-col flex items-center gap-6">
						<div class="input-group w-full">
							<div
								class="
									input-group__label
									w-full
									font-semibold
									text-neutral-600 text-base
								"
							>
								Giờ bắt đầu
							</div>
							<VueDatepicker
								v-model="startTime"
								autoApply
								class="input-date w-full mt-2"
								minutesIncrement="30"
								placeholder="Giờ bắt đầu"
								timePicker
								uid="start_opening_hour"
							>
								<template #input-icon>
									<DaIcon
										class="h-4 w-4 text-neutral-400"
										name="das--lock"
									/>
								</template>
							</VueDatepicker>
						</div>
						<div class="input-group w-full">
							<div
								class="
									input-group__label
									w-full
									font-semibold
									text-neutral-600 text-base
								"
							>
								Giờ kết thúc
							</div>
							<VueDatepicker
								v-model="endTime"
								autoApply
								class="input-date w-full mt-2"
								minutesIncrement="30"
								placeholder="Giờ kết thúc"
								timePicker
								uid="end_opening_hour"
							>
								<template #input-icon>
									<DaIcon
										class="h-4 w-4 text-neutral-400"
										name="das--lock"
									/>
								</template>
							</VueDatepicker>
						</div>
					</div>
					<div
						v-if="!data"
						class="font-semibold text-neutral-600 mt-10"
					>
						Áp dụng buổi học này cho*
					</div>
					<div v-if="!data" class="flex items-center gap-4 mt-3">
						<div
							v-for="(d, index) in daysApply"
							:key="index"
							:class="{
								'pointer-events-none font-medium':
									day === index + 1,
							}"
							class="flex items-center gap-2 text-neutral-600"
						>
							<Checkbox
								v-model:value="daysApply[index]"
								:color="
									day === index + 1 ? 'text-neutral-400' : ''
								"
							/>
							<span v-if="index !== 6">T{{ index + 2 }}</span>
							<span v-else>Chủ nhật</span>
						</div>
					</div>
					<div class="font-semibold text-neutral-600 mt-10">
						Chọn trị liệu viên*
					</div>
					<div v-for="(_, index) in staffSelected" :key="index">
						<template
							v-if="!!staffSelected[index] || moreData?.length"
						>
							<div class="w-full mt-2">
								<div class="w-full mt-2">
									<Multiselect
										v-model="staffSelected[index]"
										:allow-empty="false"
										:maxHeight="140"
										:options="
											moreData?.map((s) => {
												return {
													nameSearch: toEnglish(
														s?.name
													),
													...s,
												};
											})
										"
										:searchable="true"
										deselect-label="Đã chọn"
										label="nameSearch"
										placeholder="Tìm theo tên TLV"
										selectLabel=""
										track-by="id"
									>
										<template #noResult>
											Không tìm thấy kết quả
										</template>
										<template v-slot:option="props">
											<div
												class="
													flex flex-nowrap
													items-center
												"
											>
												<div>
													{{ props.option.name }}
												</div>
											</div>
										</template>
										<template
											v-slot:singleLabel="{ option }"
										>
											<div
												class="
													flex flex-nowrap
													items-center
													font-medium
												"
											>
												<div>{{ option.name }}</div>
											</div>
										</template>
									</Multiselect>
								</div>
							</div>
							<div
								v-if="staffSelected.length > 1"
								class="
									text-yellow-700
									font-semibold
									mt-2
									text-sm
									cursor-pointer
									text-right
									hover:text-yellow-800
								"
								@click="removeInputFieldStaff(index)"
							>
								Xoá bỏ
							</div>
						</template>
					</div>
					<div
						v-if="moreData?.length"
						class="
							text-yellow-700
							font-semibold
							text-sm
							cursor-pointer
							text-right
							hover:text-yellow-800
							mt-3
						"
						@click="addInputFieldStaff"
					>
						Thêm trị liệu viên
					</div>
				</div>
				<template v-if="staffsOverload && staffsOverload.length">
					<div class="mb-12">
						<div class="flex text-neutral-500 mt-10">
							Danh sách buổi học bị trùng
						</div>
						<div class="text-sm text-red-400 mt-2">
							Trị liệu viên được chọn có thể báo cáo cho cả
							{{ staffsOverload.length }} buổi học này
						</div>
						<div class="shadow-sm sm:rounded-2xl mt-4">
							<div
								class="
									__record__table
									overflow-x-auto
									scroll-bar
								"
							>
								<div
									class="
										sm:rounded-t-2xl
										overflow-hidden
										w-fit
										min-w-full
									"
								>
									<table
										class="
											min-w-full
											divide-y
											text-neutral-200
										"
									>
										<thead class="bg-neutral-100 h-16">
											<tr>
												<th
													class="
														px-3
														py-3
														text-left text-xs
														font-semibold
														text-neutral-600
														uppercase
														tracking-wider
														whitespace-nowrap
													"
													scope="col"
												>
													Giờ học
												</th>
												<th
													class="
														px-3
														py-3
														text-left text-xs
														font-semibold
														text-neutral-600
														uppercase
														tracking-wider
														whitespace-nowrap
													"
													scope="col"
												>
													Trị liệu viên
												</th>
												<th
													class="
														px-3
														py-3
														text-left text-xs
														font-semibold
														text-neutral-600
														uppercase
														tracking-wider
														whitespace-nowrap
													"
													scope="col"
												>
													tên lớp nhóm
												</th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="(
													item, index
												) in staffsOverload"
												:key="item.id"
												:class="
													item.state === 'cancelled'
														? 'bg-sienna-50'
														: index % 2 === 0
														? 'bg-white'
														: 'bg-neutral-50'
												"
												class="text-neutral-700"
											>
												<td>
													<div
														class="
															px-3
															py-4
															text-neutral-700
														"
													>
														<span
															v-if="
																item.display_start_slot
															"
															>{{
																item.display_start_slot
															}}</span
														><span
															v-if="
																item.display_end_slot
															"
														>
															-
															{{
																item.display_end_slot
															}}</span
														>
													</div>
												</td>
												<td
													class="
														px-3
														py-4
														whitespace-nowrap
														text-neutral-700
													"
												>
													<div
														v-if="item.staffs"
														class="
															flex flex-col
															gap-2
														"
													>
														<div
															v-for="staff in item.staffs"
															:key="staff?.id"
															class="
																flex
																items-center
															"
															@click.stop="
																$router.push(
																	`/record/student/${staff?.id}`
																)
															"
														>
															<div>
																<img
																	v-if="
																		staff
																			?.profile_photo
																			?.w200
																	"
																	:src="
																		staff
																			?.profile_photo
																			?.w200
																	"
																	alt=""
																	class="
																		shadow-sm
																		inline-block
																		h-9
																		w-9
																		rounded-full
																	"
																/>
																<span
																	v-else
																	:style="{
																		background:
																			staff
																				?.profile_photo
																				?.default
																				?.color,
																	}"
																	class="
																		inline-flex
																		items-center
																		justify-center
																		h-9
																		w-9
																		rounded-full
																	"
																>
																	<span
																		class="
																			font-medium
																			leading-none
																			text-white
																			text-xs
																		"
																		>{{
																			staff
																				?.profile_photo
																				?.default
																				?.initial
																		}}</span
																	>
																</span>
															</div>
															<div
																class="
																	ml-4
																	text-blue-600
																	hover:text-blue-700
																	hover:underline
																	cursor-pointer
																	font-semibold
																	whitespace-pre-wrap
																"
															>
																{{
																	staff?.name
																}}
															</div>
														</div>
													</div>
												</td>
												<td
													class="
														px-6
														py-4
														whitespace-nowrap
														text-neutral-700
													"
												>
													<div
														v-if="
															item?.group_class
																?.id
														"
														class="
															flex
															items-center
															gap-4
														"
														@click.stop="
															$router.push(
																`/group-classes/${item?.group_class?.id}`
															)
														"
													>
														<svg
															fill="none"
															height="24"
															viewBox="0 0 24 24"
															width="24"
															xmlns="http://www.w3.org/2000/svg"
														>
															<g
																clip-path="url(#clip0_21795_4077)"
															>
																<path
																	clip-rule="evenodd"
																	d="M7.34111 8.43768C7.34111 9.90561 6.15158 11.1204 4.65835 11.1204C3.16511 11.1204 1.97559 9.8803 1.97559 8.41238C1.97559 6.94445 3.16511 5.72961 4.65835 5.72961C6.12627 5.72961 7.34111 6.96976 7.34111 8.43768ZM6.60714 11.3989C7.46765 11.7279 8.1763 12.3606 8.60656 13.1958C7.8979 13.7526 7.3158 14.4866 7.01209 15.3724C6.86023 15.8026 6.73369 16.2835 6.63245 16.7391H0.634203C0.279876 16.7391 -0.0238327 16.4354 0.00147638 16.0557C0.0520945 15.2458 0.229258 14.436 0.507658 13.6767C0.887294 12.6137 1.69718 11.8038 2.70955 11.3989C3.26635 11.7785 3.94969 11.981 4.65834 11.981C5.367 11.981 6.05034 11.7785 6.60714 11.3989ZM23.5136 13.6767C23.134 12.6137 22.3241 11.8038 21.3117 11.3989C20.7296 11.7785 20.0716 11.981 19.3376 11.981C18.6037 11.981 17.9456 11.7785 17.3888 11.3989C16.5283 11.7279 15.8197 12.3606 15.3894 13.1958C16.0981 13.7526 16.6802 14.4866 16.9839 15.3724C17.1357 15.828 17.2623 16.2835 17.3635 16.7391H23.3618C23.7414 16.7391 24.0451 16.4354 23.9945 16.0557C23.9692 15.2458 23.792 14.436 23.5136 13.6767ZM22.0204 8.43768C22.0204 9.90561 20.8309 11.1204 19.3377 11.1204C17.8697 11.1204 16.6549 9.8803 16.6549 8.41238C16.6549 6.94445 17.8444 5.72961 19.3377 5.72961C20.8309 5.72961 22.0204 6.96976 22.0204 8.43768ZM13.9468 13.373C13.39 13.7526 12.7066 13.9551 11.998 13.9551C11.2893 13.9551 10.606 13.7526 10.0492 13.373C9.03682 13.7779 8.20162 14.5878 7.82199 15.6508C7.54359 16.4101 7.39173 17.22 7.31581 18.0299C7.2905 18.4095 7.59421 18.7132 7.97384 18.7132H16.0221C16.4018 18.7132 16.7055 18.4095 16.6802 18.0299C16.6295 17.22 16.4524 16.4101 16.174 15.6508C15.7943 14.5878 14.9591 13.7779 13.9468 13.373ZM14.6808 10.4118C14.6808 11.8798 13.4913 13.0946 11.9981 13.0946C10.5048 13.0946 9.31531 11.9051 9.31531 10.4118C9.31531 8.9439 10.5301 7.72906 11.9981 7.72906C13.466 7.72906 14.6808 8.91859 14.6808 10.4118Z"
																	fill="#94A3B8"
																	fill-rule="evenodd"
																/>
															</g>
															<defs>
																<clipPath
																	id="clip0_21795_4077"
																>
																	<rect
																		fill="white"
																		height="24"
																		width="24"
																	/>
																</clipPath>
															</defs>
														</svg>
														<div class="text-sm">
															{{
																item
																	?.group_class
																	?.name
															}}
														</div>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>

			<div class="px-6 py-4 border-t border-yellow-50">
				<div class="flex justify-end">
					<div>
						<button
							:disabled="!isSubmit"
							class="button-p text-center"
							type="button"
							@click="submit"
						>
							<svg
								v-if="!data"
								fill="none"
								height="16"
								viewBox="0 0 16 16"
								width="16"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									clip-rule="evenodd"
									d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
									fill="currentColor"
									fill-rule="evenodd"
								/>
							</svg>

							<span class="ml-2">{{
								!!data ? 'Áp dụng' : 'Khởi tạo buổi học'
							}}</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import DaIcon from '@/components/elements/daIcon/DaIcon';
import Multiselect from '@suadelabs/vue3-multiselect';
import Checkbox from '@/components/elements/checkbox/Checkbox';
import { $api } from '@/services';
import { toEnglish } from '@/helper/toEnglish';

export default defineComponent({
	name: 'CreateTimeSlotGroup',

	components: { Checkbox, DaIcon, Multiselect },

	props: {
		open: {
			type: Boolean,
			required: true,
		},

		day: {
			type: Number,
			required: true,
		},

		data: {
			type: Object,
			required: true,
		},

		staffs: {
			type: Array,
			required: true,
		},
	},

	data() {
		return {
			staffSelected: [],
			startTime: null,
			endTime: null,
			daysApply: [false, false, false, false, false, false, false],
			staffsOverload: [],
			isAllStaff: false,
		};
	},

	computed: {
		id() {
			return this.$route.params.id;
		},

		isSubmit() {
			return (
				this.startTime &&
				this.endTime &&
				this.staffSelected.some((item) => !!item) &&
				this.daysOfWeek?.length
			);
		},

		isAdmin() {
			return this.$store.getters.isAdmin;
		},

		daysOfWeek() {
			return this.daysApply
				.map((d, index) => {
					return d ? index + 1 : undefined;
				})
				.filter((d) => !!d);
		},

		staffSelectedData() {
			return this.staffSelected.filter((s) => !!s?.id);
		},

		moreData() {
			return this.staffs.filter(
				(s) => !this.staffSelectedData.find((st) => st?.id === s?.id)
			);
		},
	},

	watch: {
		data: {
			immediate: true,
			deep: true,
			handler(value) {
				if (value) {
					this.staffSelected = this.staffs.filter((s) =>
						value.staff_ids.includes(s.id)
					);

					this.startTime = {
						hours: Number(value.display_start_slot.split(':')[0]),
						minutes: Number(value.display_start_slot.split(':')[1]),
					};

					this.endTime = {
						hours: Number(value.display_end_slot.split(':')[0]),
						minutes: Number(value.display_end_slot.split(':')[1]),
					};
				} else {
					this.startTime = this.initStartTime();
					this.endTime = {
						hours: this.startTime.hours + 1,
						minutes: this.startTime.minutes,
					};
				}
			},
		},

		staffSelected: {
			immediate: true,
			deep: true,
			handler(value) {
				if (value && value?.[0]) {
					this.fetchStaffsOverload();
				}
			},
		},

		startTime(value) {
			if (!value) {
				this.startTime = this.initStartTime();
			} else {
				this.endTime = {
					hours: value.hours + 1,
					minutes: value.minutes,
				};
			}
		},

		endTime(value) {
			if (!value) {
				this.endTime = {
					hours: this.startTime.hours + 1,
					minutes: this.startTime.minutes,
				};
			} else {
				if (this.isAllStaff && this.staffSelected?.[0]) {
					this.fetchStaffsOverload();
				}
			}
		},

		open: {
			immediate: true,
			handler() {
				this.staffSelected = [null];
				this.daysApply = [
					false,
					false,
					false,
					false,
					false,
					false,
					false,
				];
				this.daysApply[this.day - 1] = true;
			},
		},
	},

	methods: {
		toEnglish,
		initStartTime() {
			const time = new Date();
			let minutes = time.getMinutes() / 5;
			return {
				hours: time.getHours(),
				minutes: Math.ceil(minutes) * 5,
			};
		},

		addInputFieldStaff() {
			this.staffSelected.push(null);
		},

		removeInputFieldStaff(index) {
			if (index < this.staffSelected.length) {
				this.staffSelected.splice(index, 1);
			}
		},

		async submit() {
			try {
				const payload = {
					day_of_weeks: this.daysOfWeek,
					group_time_slot: {
						end_slot: this.getTimePayload(
							this.endTime?.hours || null,
							this.endTime?.minutes || null
						),
						start_slot: this.getTimePayload(
							this.startTime?.hours || null,
							this.startTime?.minutes || null
						),
						staff_ids: this.staffSelectedData
							? this.staffSelectedData.map((item) => item?.id)
							: null,
					},
				};
				if (this.data) {
					await this.$api.groupClasses.updateTimeSlot(
						this.id,
						this.data.id,
						payload
					);
				} else {
					await this.$api.groupClasses.createTimeSlot(
						this.id,
						payload
					);
				}
				await this.$store.dispatch('setAlertMessage', {
					message: this.data
						? 'Cập nhật buổi học thành công'
						: 'Tạo thành công buổi học',
				});
				this.$emit('success');
			} catch (e) {
				await this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},

		getTimePayload(hour = 0, minutes = 0) {
			return hour * 60 + minutes;
		},

		async fetchStaffsOverload() {
			try {
				const {
					data: { data },
				} = await $api.groupClasses.getStaffWhenCreateUnScheduleOverload(
					this.id,
					{
						day_of_weeks: this.daysOfWeek,
						group_time_slot: {
							end_slot: this.getTimePayload(
								this.endTime?.hours || null,
								this.endTime?.minutes || null
							),
							start_slot: this.getTimePayload(
								this.startTime?.hours || null,
								this.startTime?.minutes || null
							),
							staff_ids: this.staffSelectedData
								? this.staffSelectedData.map((item) => item?.id)
								: null,
						},
					}
				);
				this.staffsOverload = data;
			} catch (err) {
				console.log(err);
			}
		},
	},
});
</script>
