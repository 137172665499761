export function studentState(state: string) {
	switch (state) {
		case 'active':
			return 'Kích hoạt';
		case 'pending':
			return 'Chưa nhập học';
		case 'closed':
			return 'Tạm dừng';
		case 'stopped':
			return 'Xin nghỉ';
		case 'graduated':
			return 'Tốt nghiệp';
		default:
			return 'Xin nghỉ';
	}
}

export function studentStateShort(state: string) {
	switch (state) {
		case 'active':
			return 'đang kích hoạt';
		case 'pending':
			return 'chưa nhập học';
		case 'closed':
			return 'đang tạm dừng';
		case 'stopped':
			return 'đang khoá';
		case 'graduated':
			return 'đã tốt nghiệp';
		case 'transfered':
			return 'đã chuyển trường';
		default:
			return 'đang hoá';
	}
}
