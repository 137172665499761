import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13a73626"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "upload-avatar pb-6 p-4 bg-white _modal relative" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "mt-6" }
const _hoisted_4 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Cropper = _resolveComponent("Cropper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Cropper, {
      ref: "cropper",
      class: "cropper",
      src: _ctx.url,
      "stencil-props": {
				aspectRatio: 1,
			},
      "stencil-component": "circle-stencil"
    }, null, 8, ["src"]),
    (_ctx._error)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "mt-6 text-center text-sienna-500",
          innerHTML: _ctx._error
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          type: "button",
          class: "button-p text-center mr-3 bg-none shadow-none text-neutral-500 hover:text-neutral-700",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, " Đóng "),
        _createElementVNode("button", {
          type: "button",
          class: "button-p text-center whitespace-nowrap",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.upload && _ctx.upload(...args)))
        }, " Tải lên ")
      ])
    ])
  ]))
}