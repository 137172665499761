
import { Options, Vue } from 'vue-class-component';
import { PropSync } from 'vue-property-decorator';

@Options({
	name: 'MakeMainStaffModal',
})
export default class MakeMainStaffModal extends Vue {
	//----------------------- 🤍 Props 🤍 -----------------------//

	@PropSync('data')
	popupData!: {
		staffNew: '';
		staffOle: '';
		studentName: '';
		assigmentId: '';
	};

	//----------------------- 🤍 Methods 🤍 -----------------------//
	submit() {
		this.$emit('submit', { assigmentId: this.popupData.assigmentId });
	}
}
