
import { Options, Vue } from 'vue-class-component';
import { PropSync } from 'vue-property-decorator';

@Options({
	name: 'CopyModal',
})
export default class CopyModal extends Vue {
	@PropSync('path') _path!: string;
	status = false;

	close() {
		this.$emit('close');
	}

	copyPath() {
		navigator.clipboard.writeText(this._path).then(() => {
			this.status = true;
			setTimeout(() => {
				this.status = false;
			}, 4000);
		});
	}

	openPath() {
		window.open(this._path, '_blank');
	}
}
