<template>
	<div class="da-infinity-loading w-full flex items-center justify-center">
		<div class="main">
			<div class="one"></div>
			<div class="two"></div>
			<div class="three"></div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Loading',
});
</script>
<style lang="scss" scoped>
.main {
	position: relative;
	display: flex;
}

.main div {
	margin-right: 10px;
	width: 10px;
	height: 10px;
	background-color: var(--popper-theme-background-color);
	border-radius: 50%;
	animation-duration: 0.4s;
	animation-iteration-count: infinite;
	opacity: 0.4;
}

.one {
	animation-name: load-one;
}

@keyframes load-one {
	30% {
		transform: translateY(15px);
	}
}

.two {
	animation-name: load-two;
}

@keyframes load-two {
	50% {
		transform: translateY(15px);
	}
}

.three {
	animation-name: load-three;
}

@keyframes load-three {
	70% {
		transform: translateY(10px);
	}
}
</style>
