
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { staff } from '@/views/profile/@types/staff.interface';

@Options({
	name: 'ChangeStatus',
})
export default class ChangeStatus extends Vue {
	@Prop({ default: false }) activeMode?: boolean;
	@Prop() staff!: staff;

	//----------------------- 🤍 Methods 🤍 -----------------------//
	close() {
		this.$emit('close');
	}

	submit() {
		if (this.activeMode) {
			this.$emit('active');
		} else {
			this.$emit('block');
		}
	}
}
