<template>
	<DaModalFrame v-model:open="modalIsOpen">
		<ConfirmModal
			v-if="modalType === modalTypes.CONFIRM"
			v-model:report="report"
			v-model:success="successData"
			@close="cancelConfirm"
			@doNextPlan="doNextPlan"
			@submit="submitReport"
			@close-complete="cancelConfirmCompleted"
		/>
	</DaModalFrame>
	<div class="flex min-h-screen overflow-x-hidden">
		<div
			:class="[
				[1].includes(step) && isOpen
					? 'content-report-is-show'
					: 'content-report-is-hide',
				[1].includes(step) ? 'transition' : '',
			]"
			class="w-full body-content"
		>
			<div class="w-full pb-30">
				<div class="w-full p-10 md-down:px-4 gap-4">
					<div
						class="
							__breadcrumbs
							text-sm text-neutral-500
							font-semibold
							flex-nowrap flex
							items-center
							mb-4
						"
					>
						<router-link
							:to="{ name: ROUTER_NAME.REPORT_PLANS_GROUP }"
						>
							Báo cáo tháng
						</router-link>
					</div>
					<div
						class="
							flex
							sm:items-center
							justify-between
							flex-col
							sm:flex-row
						"
					>
						<div v-if="groupClass" class="flex gap-2">
							<div class="w-7 h-7">
								<img
									alt=""
									src="@/assets/images/icons/people-fill.png"
								/>
							</div>
							<div>
								<div
									class="text-neutral-700 font-bold text-3xl"
								>
									{{ groupClass?.name }}
								</div>
								<div class="flex gap-4 items-center mt-2">
									<UserGroup
										:data="groupClass?.students"
										placement="bottom-start"
									/>
									<div
										class="
											uppercase
											text-green-500
											font-semibold
										"
									>
										{{ groupClass?.students?.length }} học
										sinh
									</div>
								</div>
							</div>
						</div>
						<div class="md-down:mt-8 flex flex-wrap gap-4">
							<div v-if="step > 1" class="flex space-x-4">
								<button
									class="
										button-p
										text-center
										w-fit
										btn--ghost
									"
									type="button"
									@click="step -= 1"
								>
									<svg
										class="mr-2"
										fill="none"
										height="16"
										viewBox="0 0 16 16"
										width="16"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M10 4L6 8L10 12"
											stroke="#D89210"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
									</svg>
									Trở về bước {{ step - 1 }}
								</button>
							</div>
							<button
								v-if="[1].includes(step)"
								class="button-p text-center w-fit btn--ghost"
								type="button"
								@click="isOpen = true"
							>
								Xem trước MT cần báo cáo
							</button>
						</div>
					</div>
					<div>
						<div class="relative mt-12">
							<div
								aria-hidden="true"
								class="
									hidden
									xs:flex
									absolute
									inset-0
									items-center
								"
							>
								<div
									class="w-full border-t border-neutral-300"
								/>
							</div>
							<div
								class="
									relative
									flex
									xs:items-center
									justify-between
									flex-col
									xs:flex-row
								"
							>
								<span
									class="
										pr-3
										bg-body
										font-semibold
										text-sm text-neutral-600
									"
								>
									<span v-if="step === 1">
										Bước 1: Quyết định mục tiêu học tiếp hay
										tạm dừng
									</span>
									<span v-if="step === 2">
										Bước 2: Thống kê
									</span>
									<span v-if="step === 3"
										>Bước 3: Kế hoạch tiếp theo</span
									>
								</span>
								<button
									:disabled="disableNextButton"
									class="
										button-p
										text-center
										whitespace-nowrap
										w-fit
									"
									type="button"
									@click="onNext"
								>
									<svg
										class="mr-2"
										fill="none"
										height="10"
										viewBox="0 0 6 10"
										width="6"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M1 9L5 5L1 1"
											stroke="currentColor"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
									</svg>
									<span v-if="step === 1"
										>Bước 2: Thống kê</span
									>
									<span v-if="step === 2"
										>Bước 3: KH tiếp theo</span
									>
									<span v-if="step === 3"
										>Hoàn thành báo cáo</span
									>
								</button>
							</div>
						</div>
						<div v-if="alertShow && step !== 3" class="mt-12">
							<div
								class="
									bg-yellow-50
									border-l-4 border-yellow-600
									p-4
									rounded-md
								"
							>
								<div class="relative">
									<div class="flex items-center gap-4">
										<div>
											<img
												alt="luca-logo"
												height="24"
												src="@/assets/images/logo/logo.svg"
												width="35"
											/>
										</div>
										<div v-if="step === 2">
											Bạn hãy hoàn thành Nhận xét chung/
											lưu ý cho cấp quản lý và phụ huynh
											để nộp báo cáo
										</div>
										<div
											v-else
											class="
												pt-1
												text-neutral-600 text-sm
											"
										>
											Các bước để hoàn thành báo cáo
											tháng: <br /><br />
											1. Nhận xét & ghi chú từng mục tiêu
											cho lớp nhóm và từng học sinh<br />
											2. Nhận xét chung/ lưu ý cho cấp
											quản lý và phụ huynh<br /><br />

											Bạn hãy hoàn tất bước 1 và ấn nút
											“Thống kê” để sang bước 2 nhé
										</div>
									</div>
									<div
										class="
											top-1
											right-2
											absolute
											cursor-pointer
										"
										@click="alertShow = false"
									>
										<svg
											fill="none"
											height="12"
											viewBox="0 0 12 12"
											width="12"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												clip-rule="evenodd"
												d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
												fill="#E5A42B"
												fill-rule="evenodd"
											/>
										</svg>
									</div>
								</div>
							</div>
						</div>
						<div v-if="step === 1">
							<ReportDetailStep2
								:assessment_group_monthly_report="
									assessment_group_monthly_report
								"
								:assessments="assessments"
								:goal="goal"
								:groupClass="groupClass"
								:list_student_ids_learn_group_goal="
									list_student_ids_learn_group_goal
								"
								:report-id="reportId || report?.data?.id"
								:result="result"
								:student_assessment_group_monthly_reports="
									student_assessment_group_monthly_reports
								"
								@submit="onNextGoal"
							/>
							<div
								v-if="activities?.length"
								class="
									mt-10
									shadow-sm
									sm:rounded-2xl
									lg-down:overflow-x-auto
								"
							>
								<div
									class="
										shadow-sm
										sm:rounded-2xl
										overflow-hidden
									"
								>
									<div class="__record__table scroll-bar">
										<div
											class="
												sm:rounded-t-2xl
												w-fit
												min-w-full
											"
										>
											<table
												class="
													min-w-full
													divide-y
													text-neutral-200
													rounded-2xl
												"
											>
												<thead
													class="bg-neutral-100 h-16"
												>
													<tr>
														<th
															class="
																px-3
																py-3
																text-xs
																text-left
																font-semibold
																text-neutral-600
																uppercase
																tracking-wider
																w-2/5
															"
															scope="col"
														>
															tuần
														</th>
														<th
															class="
																px-3
																py-3
																text-left
																text-xs
																font-semibold
																text-neutral-600
																uppercase
																tracking-wider
															"
															scope="col"
														>
															Hoạt ĐỘng
														</th>
													</tr>
												</thead>
												<tbody>
													<tr
														v-for="(
															activity, index
														) in activities"
														:key="activity.id"
														:class="[
															index % 2 === 0
																? 'bg-white'
																: 'bg-neutral-50',
														]"
														class="text-neutral-700"
													>
														<td>
															<div
																class="
																	px-3
																	py-4
																	text-neutral-700
																"
															>
																<div
																	v-if="
																		activity
																			.group_weekly_report
																			?.week_start
																	"
																>
																	{{
																		`Tuần ${$filters.toWeek(
																			activity
																				.group_weekly_report
																				?.week_start,
																			true
																		)} (${$filters.formatDate2(
																			activity
																				.group_weekly_report
																				?.week_start
																		)} - ${$filters.endWeek(
																			activity
																				.group_weekly_report
																				?.week_start
																		)})`
																	}}
																</div>
															</div>
														</td>
														<td>
															<div
																class="
																	px-3
																	py-4
																	text-left
																	text-neutral-700
																"
															>
																<div
																	v-for="a in activity.group_activities"
																	:key="a.id"
																>
																	{{ a.name }}
																</div>
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="step === 2">
							<ReportDetail
								v-if="reportData"
								:report="reportData"
								:report_note="reportData?.data?.note"
								:student-selected="studentSelected"
								:students="groupClass.students"
								@report-updated="updateNoteForReport"
								@change-student="onChangeStudent"
							/>
						</div>
						<div v-if="step === 3">
							<ReportDetailStep4
								:apply="apply"
								:report="report"
								@submit="updateApplyDate"
							/>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="[1].includes(step)"
				:class="isOpen ? 'is-MT-show' : 'is-MT-hide'"
				class="bg-cream overflow-y-auto fixed right-0 top-0 pb-30"
				style="height: 100vh"
			>
				<div
					class="h-screen fixed top-0 right-0 overflow-y-auto"
					style="width: inherit; background: #faf5f1"
				>
					<GoalListForReport
						:goal-selected="goalSelected"
						:skills="skills"
						:step="step"
						:validates="validates"
						@close="isOpen = false"
						@selected="(goalS) => (goalSelected = goalS)"
					>
						<button
							:disabled="disableNextButton"
							class="
								button-p
								text-center
								whitespace-nowrap
								w-full
							"
							type="button"
							@click="onNext"
						>
							<svg
								class="mr-2"
								fill="none"
								height="10"
								viewBox="0 0 6 10"
								width="6"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1 9L5 5L1 1"
									stroke="currentColor"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
								/>
							</svg>
							<span v-if="step === 1">Bước 2: Thống kê</span>
							<span v-if="step === 2">Bước 3: KH tiếp theo</span>
							<span v-if="step === 3">Hoàn thành báo cáo</span>
						</button>
					</GoalListForReport>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import UserGroup from '@/components/elements/UserGroup.vue';
import { _MODAL_TYPE, _MODAL_TYPE as modalTypes, _ROUTER_NAME } from '@/enums';
import { $api } from '@/services';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import ReportDetail from '@/views/planGroupEdit/ReportDetail.vue';
import ReportDetailStep4 from '@/views/planGroupEdit/ReportDetailStep4.vue';
import GoalListForReport from '@/views/planGroupEdit/GoalListForReport.vue';
import ReportDetailStep2 from '@/views/planGroupEdit/ReportDetailStep2.vue';
import ConfirmModal from '@/views/planGroupEdit/ConfirmModal.vue';
import dayjs from 'dayjs';

export default {
	name: 'PlanGroupDetail',

	components: {
		ConfirmModal,
		DaModalFrame,
		ReportDetailStep2,
		GoalListForReport,
		ReportDetailStep4,
		ReportDetail,
		UserGroup,
	},

	data() {
		return {
			ROUTER_NAME: _ROUTER_NAME,
			alertShow: true,
			modalIsOpen: false,
			modalType: null,
			successData: false,
			step: 1,
			studentSelected: {
				id: 'all',
			},
			groupClass: null,
			report: null,
			goal: null,
			assessment_group_monthly_report: null,
			student_assessment_group_monthly_reports: null,
			goalSelected: null,
			skills: [],
			list_student_ids_learn_group_goal: [],
			studentReport: {},
			apply: {
				apply_date: new Date(),
				apply_now: false,
			},
			isOpen: true,
			activities: [],
			result: {
				data: [],
				number_of_student: 0,
			},
			assessments: [],
			validates: {
				all_group_goal_has_assessment: false,
				all_student_group_monthly_report_completed: false,
				group_goal_ids_has_group_assessment: [],
				skill_ids_completed: [],
			},
		};
	},

	watch: {
		goalSelected: {
			handler() {
				this.fetchGoalDetail();
			},
		},

		step: {
			handler(value, oldValue) {
				if (value === 2) {
					this.fetchReport();

					if (oldValue === 1) {
						this.fillReport();
					}
				}
			},
		},

		'studentSelected.id': {
			deep: true,
			handler(value) {
				if (
					this.step === 2 &&
					value !== 'all' &&
					!this.studentReport[value]
				) {
					this.fetchStatsForStudentGroup();
				}
			},
		},
	},

	computed: {
		modalTypes() {
			return modalTypes;
		},

		editMode() {
			return !!this.$route.query?.report_id;
		},

		reportId() {
			return this.$route.query?.report_id;
		},

		id() {
			return this.$route.params?.id;
		},

		disableNextButton() {
			if (this.step === 1) {
				return !this.validates?.all_group_goal_has_assessment;
			} else if (this.step === 2) {
				return !this.validates
					?.all_student_group_monthly_report_completed;
			} else if (this.step === 3) {
				return (
					!this.apply.apply_now &&
					dayjs(new Date())
						.endOf('day')
						.isAfter(this.apply.apply_date)
				);
			}
			return false;
		},

		reportData() {
			return this.studentSelected?.id !== 'all' &&
				this.studentReport[+this.studentSelected?.id]
				? this.studentReport[+this.studentSelected?.id]
				: this.report;
		},
	},

	created() {
		this.createReport();
		if (this.editMode) {
			this.fetchReport();
		}
		this.validateSteps();
	},

	methods: {
		async createReport() {
			try {
				if (!this.editMode) {
					const { data } =
						await $api.monthlyReport.createMonthlyReportGroupClass(
							this.id,
							{
								month_start: this.$route.query?.month_start,
							}
						);
					this.report = data;
				}
				await this.fetchGoalsList();
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},

		async validateSteps() {
			this.studentSelected = {
				id: 'all',
			};
			try {
				const { data } = await $api.monthlyReport.validateSteps(
					this.id,
					this.reportId || this.report?.data?.id
				);
				this.validates = data;
			} catch (e) {
				console.log(e);
			}
		},

		async fetchReport() {
			try {
				const { data } =
					await $api.monthlyReport.detailMonthlyReportGroupClass(
						this.id,
						this.reportId || this.report?.data?.id
					);
				this.report = data;
				if (
					dayjs(data?.group_plan?.applied_at)
						.endOf('day')
						.isBefore(new Date())
				) {
					this.report.group_plan.applied_at = dayjs().toDate();
					this.apply.apply_date = dayjs().toDate();
					this.apply.apply_now = true;
				}
			} catch (e) {
				console.log(e);
			}
		},

		onChangeStudent(student) {
			this.studentSelected = student;
		},

		async fetchResult() {
			try {
				const {
					data: { data, number_of_student },
				} = await $api.monthlyReport.fetchGroupResult(
					this.id,
					this.reportId || this.report?.data?.id,
					{
						group_goal_id: this.goalSelected?.id,
						group_monthly_report_id:
							this.reportId || this.report?.data?.id,
					}
				);
				this.result = { data, number_of_student };
			} catch (err) {
				console.log(err?.response?.data?.errors || err.message);
			}
		},

		async fetchGoalActivities() {
			try {
				const {
					data: { data },
				} = await $api.monthlyReport.fetchGoalActivities(
					this.id,
					this.reportId || this.report?.data?.id,
					{
						group_goal_id: this.goalSelected?.id,
					}
				);
				this.activities = data ?? [];
			} catch (err) {
				console.log(err?.response?.data?.errors || err.message);
			}
		},

		async fetchGoalDetail(
			{ isFetchResult, isFetchActivities } = {
				isFetchResult: true,
				isFetchActivities: true,
			}
		) {
			try {
				const {
					data: {
						goal,
						data,
						list_student_ids_learn_group_goal,
						assessment_group_monthly_report,
					},
				} = await $api.monthlyReport.fetchGroupGoalDetail(
					this.id,
					this.reportId || this.report?.data?.id,
					this.goalSelected?.id
				);
				this.goal = goal;
				if (isFetchResult) {
					await this.fetchResult();
				}
				if (isFetchActivities) {
					await this.fetchGoalActivities();
				}
				const dataMapped = {};
				for (let item of data) {
					dataMapped[item?.student_id] = item;
				}
				this.student_assessment_group_monthly_reports =
					this.groupClass?.students.map((s) => {
						return (
							this.result?.data?.find(
								(r) => r.student_id === s.id
							) || {}
						);
					});
				this.assessment_group_monthly_report = {
					...assessment_group_monthly_report,
					student_id: this.studentSelected?.id,
				};
				this.list_student_ids_learn_group_goal =
					list_student_ids_learn_group_goal || [];
				this.assessments = data;
			} catch (err) {
				console.log(err?.response?.data?.errors || err.message);
			}
		},

		async fetchGoalsList(reload = false) {
			try {
				const {
					data: { skills, group_class },
				} = await $api.monthlyReport.fetchGroupGoalsList(
					this.id,
					this.reportId || this.report?.data?.id
				);

				this.skills = skills;

				if (!reload) {
					this.groupClass = group_class;
					this.goalSelected = skills?.[0]?.goals?.[0];
				}
			} catch (err) {
				console.log(err?.response?.data?.errors || err.message);
			}
		},

		onNextGoal({ completed = false, next = true }) {
			if (next) {
				let skillIndex = -1;
				let goalIndex = -1;
				for (const sIndex in this.skills) {
					const skill = this.skills[sIndex];
					const index = skill.goals?.findIndex(
						(g) => g.id === this.goalSelected?.id
					);

					if (index !== -1) {
						skillIndex = +sIndex;
						goalIndex = +index;
						break;
					}
				}

				const skill = this.skills[skillIndex];

				if (goalIndex === skill.goals?.length - 1) {
					this.goalSelected =
						skillIndex === this.skills?.length - 1
							? this.skills[0]?.goals?.[0]
							: this.skills[skillIndex + 1]?.goals?.[0];
				} else {
					this.goalSelected = skill.goals[goalIndex + 1];
				}
			}

			if (completed) {
				this.fetchGoalsList(true);
				this.fetchGoalDetail({
					isFetchActivities: false,
					isFetchResult: false,
				});
				this.validateSteps();
			}
		},

		onNext() {
			if (this.step === 2) {
				this.studentSelected = {
					id: 'all',
				};
			} else if (this.step === 3) {
				this.openConfirm();
				return;
			} else if ([1, 2].includes(this.step)) {
				this.goalSelected = this.skills[0]?.goals[0];
			}
			this.step++;
			this.validateSteps();
		},

		openConfirm() {
			this.modalIsOpen = true;
			this.modalType = _MODAL_TYPE.CONFIRM;
		},

		updateApplyDate(params) {
			const { applyDate, applyNow } = params || {};
			this.apply = {
				apply_now: applyNow,
				apply_date: applyDate,
			};
			if (this.report.group_plan) {
				this.report.group_plan.applied_at = applyDate;
			}
		},

		async submitReport() {
			try {
				await $api.monthlyReport.submitReportGroupClass(
					this.groupClass?.id,
					this.reportId || this.report?.data?.id,
					{
						group_monthly_report: {
							note: this.report?.data?.note || '',
						},
						group_plan: {
							...this.apply,
						},
					}
				);
				this.successData = true;
			} catch (err) {
				if (err?.response?.data?.errors_by_field?.state) {
					const error =
						err?.response?.data?.errors_by_field?.state?.join(
							<br />
						) || 'Đã có lỗi xảy ra. Vui lòng thử lại';
					await this.$store.dispatch('setAlertMessage', {
						message: error,
						type: 'danger',
					});
				} else {
					const error =
						err?.response?.data?.errors?.join(<br />) ||
						err?.message ||
						'Đã có lỗi xảy ra. Vui lòng thử lại';
					await this.$store.dispatch('setAlertMessage', {
						message: error,
						type: 'danger',
					});
				}
			}
		},

		cancelConfirm() {
			this.modalIsOpen = false;
			this.modalType = null;
		},

		cancelConfirmCompleted() {
			this.$router.push(
				`/report/monthly-report/group-class/${this.report?.group_class?.id}/detail?report_id=${this.report?.data?.id}`
			);
		},

		updateNoteForReport({ all_student_group_monthly_report_completed }) {
			this.report.all_student_group_monthly_report_completed =
				all_student_group_monthly_report_completed ||
				this.report.all_student_group_monthly_report_completed;
		},

		doNextPlan() {
			this.$router.push(
				`/group-class-plans/${this.report?.data?.id}/future`
			);
		},

		async fillReport() {
			this.isLoading = true;
			try {
				await $api.monthlyReport.fillReport(
					this.report?.group_class?.id,
					this.report?.data?.id
				);
			} catch (e) {
				console.log(e);
			} finally {
				this.isLoading = false;
			}
		},

		async fetchStatsForStudentGroup() {
			this.isLoading = true;
			try {
				const {
					data: {
						data: {
							emotion_scale_stats,
							group_sessions_count,
							skills_stats,
							skills,
						},
					},
				} = await $api.monthlyReport.fetchStatsForStudentGroup(
					this.report?.group_class?.id,
					this.report?.data?.id,
					this.studentSelected?.id
				);
				this.studentReport[this.studentSelected?.id] = {
					...this.report,
					emotion_scale_stats,
					group_sessions_count,
					skills_stats,
					skills,
				};
			} catch (e) {
				console.log(e);
			} finally {
				this.isLoading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/responsive';

.is-MT-show {
	width: 100vw;
	transform: translateX(0);
	transition: all 0.4s ease;

	@include media-breakpoint-up(lg) {
		width: 360px;
	}
}

.is-MT-hide {
	transform: translateX(400px);
	transition: all 0.4s ease;
}

.transition {
	transition: all 0.4s ease;
}

.content-report-is-show {
	@include media-breakpoint-up(lg) {
		padding-right: 360px;
	}
}

.content-report-is-hide {
	@include media-breakpoint-up(lg) {
		padding-right: 0;
	}
}
</style>
