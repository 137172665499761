<template>
	<div class="_modal bg-white">
		<div
			class="
				_modal__content
				py-8
				md-down:px-4
				px-12
				md:px-12
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<svg
					width="60"
					height="61"
					viewBox="0 0 60 61"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M29.8034 3.88289C15.2228 3.88289 3.36674 15.8238 3.36674 30.501C3.36674 45.1762 15.2228 57.117 29.8034 57.117C44.3839 57.117 56.2452 45.1762 56.2452 30.501C56.2452 15.8238 44.3839 3.88289 29.8034 3.88289ZM29.8034 60.5C13.3737 60.5 0 47.0418 0 30.501C0 13.9581 13.3737 0.499969 29.8034 0.499969C46.2382 0.499969 59.6068 13.9581 59.6068 30.501C59.6068 47.0418 46.2382 60.5 29.8034 60.5Z"
						fill="#F4C870"
					/>
					<path
						d="M52.5551 30.5009C52.5551 43.1492 42.3697 53.4025 29.8011 53.4025C17.2376 53.4025 7.05225 43.1492 7.05225 30.5009C7.05225 17.8516 17.2376 7.59787 29.8011 7.59787C42.3697 7.59787 52.5551 17.8516 52.5551 30.5009Z"
						fill="#F0BB4F"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M23.4437 18.5C23.4437 18.0582 23.0879 17.7 22.649 17.7C22.2101 17.7 21.8543 18.0582 21.8543 18.5V19.3H20.2649C18.5093 19.3 17.0861 20.7327 17.0861 22.5V24.1H42.5165V22.5C42.5165 20.7327 41.0933 19.3 39.3377 19.3H37.7483V18.5C37.7483 18.0582 37.3925 17.7 36.9536 17.7C36.5147 17.7 36.1589 18.0582 36.1589 18.5V19.3H23.4437V18.5ZM42.5165 40.1V25.7H17.0861V40.1C17.0861 41.8673 18.5093 43.3 20.2649 43.3H39.3377C41.0933 43.3 42.5165 41.8673 42.5165 40.1ZM27.9791 30.7343C27.6688 30.4219 27.1656 30.4219 26.8552 30.7343C26.5449 31.0467 26.5449 31.5533 26.8552 31.8657L28.6774 33.7L26.8552 35.5343C26.5449 35.8467 26.5449 36.3533 26.8552 36.6657C27.1656 36.9781 27.6688 36.9781 27.9791 36.6657L29.8013 34.8314L31.6235 36.6657C31.9338 36.9781 32.437 36.9781 32.7473 36.6657C33.0577 36.3533 33.0577 35.8467 32.7473 35.5343L30.9252 33.7L32.7473 31.8657C33.0577 31.5533 33.0577 31.0467 32.7473 30.7343C32.437 30.4219 31.9338 30.4219 31.6235 30.7343L29.8013 32.5686L27.9791 30.7343Z"
						fill="white"
					/>
				</svg>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
				"
			>
				{{ data ? 'Chỉnh sửa ngày nghỉ' : 'Tạo ngày nghỉ' }}
			</div>
			<div class="text-center text-neutral-500 mt-2">
				Bạn hãy điền tên ngày nghỉ và ấn “Xác nhận” để tạo ngày nghỉ.
			</div>
			<div class="w-full mt-5">
				<div class="input-group flex items-center gap-4">
					<div
						class="
							input-group__label
							text-neutral-400
							font-semibold
							text-xs
							uppercase
							w-2/5
						"
					>
						Tên ngày nghỉ
					</div>
					<input
						type="text"
						placeholder="Gõ tên ngày nghỉ"
						v-model="name"
					/>
				</div>
			</div>
			<div class="w-full mt-5">
				<div class="input-group flex items-center gap-4">
					<div
						class="
							input-group__label
							text-neutral-400
							font-semibold
							text-xs
							uppercase
							w-2/5
						"
					>
						ngày bắt đầu
					</div>
					<VueDatepicker
						v-model="start_date"
						class="input-date w-full"
						placeholder="Chọn ngày bắt đầu"
						autoApply
						:min-date="new Date()"
						:max-date="end_date"
						:enableTimePicker="false"
						format="dd/MM/yyyy"
						uid="holiday_start_date"
					>
						<template #input-icon>
							<DaIcon
								name="das--lock"
								class="h-4 w-4 text-neutral-400"
							/>
						</template>
					</VueDatepicker>
				</div>
			</div>
			<div class="w-full mt-5">
				<div class="input-group flex items-center gap-4">
					<div
						class="
							input-group__label
							text-neutral-400
							font-semibold
							text-xs
							uppercase
							w-2/5
						"
					>
						ngày kết thúc
					</div>
					<VueDatepicker
						v-model="end_date"
						class="input-date w-full"
						placeholder="Chọn ngày kết thúc"
						:enableTimePicker="false"
						autoApply
						:min-date="start_date"
						format="dd/MM/yyyy"
						uid="holiday_end_date"
					>
						<template #input-icon>
							<DaIcon
								name="das--lock"
								class="h-4 w-4 text-neutral-400"
							/>
						</template>
					</VueDatepicker>
				</div>
			</div>
			<div class="w-full mt-5">
				<div class="input-group flex items-center gap-4">
					<div
						class="
							input-group__label
							text-neutral-400
							font-semibold
							text-xs
							uppercase
							w-2/5
						"
					>
						TỔNG SỐ NGÀY
					</div>
					<div class="w-full">
						<span v-if="end_date && start_date">
							{{ $filters.aFromB(start_date, end_date) + 1 }}
						</span>
						<span v-else>-</span>
					</div>
				</div>
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
				md-down:flex-col-reverse
			"
		>
			<button
				type="button"
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				@click="$emit('close')"
			>
				Thoát
			</button>
			<button
				:disabled="!name || !start_date || !end_date"
				type="button"
				class="button-p text-center whitespace-nowrap"
				@click="submit"
			>
				{{ data ? 'Lưu và thoát' : 'Xác nhận' }}
			</button>
		</div>
	</div>
</template>

<script>
import DaIcon from '@/components/elements/daIcon/DaIcon';
import { $api } from '@/services';
import { aFromB } from '@/helper/formatDate';
import dayjs from 'dayjs';
export default {
	name: 'CreateHoliday',
	components: { DaIcon },
	props: {
		data: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			id: '',
			name: '',
			start_date: null,
			end_date: null,
		};
	},

	watch: {
		data: {
			deep: true,
			immediate: true,
			handler(value) {
				if (value) {
					this.id = value.id;
					this.name = value.title;
					this.start_date = value.start_date;
					this.end_date = value.end_date;
				}
			},
		},
	},

	methods: {
		getDateYYYYMMDDSubmit(date) {
			if (date) {
				const newDate = new Date(date).valueOf();
				const data = dayjs(newDate).format('YYYY-MM-DD');
				if (data.toLowerCase() === 'invalid date') {
					return null;
				}
				return data;
			}
			return '';
		},

		async submit() {
			try {
				if (this.data) {
					await $api.schedule.updateHoliday(this.id, {
						holiday: {
							number_of_days:
								aFromB(this.start_date, this.end_date) + 1,
							start_date: this.getDateYYYYMMDDSubmit(
								this.start_date
							),
							title: this.name,
						},
					});
					await this.$store.dispatch('setAlertMessage', {
						message: 'Cập nhật ngày nghỉ thành công',
					});
				} else {
					await $api.schedule.createHoliday({
						is_public_holiday: true,
						number_of_days:
							aFromB(this.start_date, this.end_date) + 1,
						start_date: this.getDateYYYYMMDDSubmit(this.start_date),
						title: this.name,
					});
					await this.$store.dispatch('setAlertMessage', {
						message: 'Thêm ngày nghỉ thành công',
					});
				}
				this.$emit('success');
				this.$emit('close');
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},
	},
};
</script>
