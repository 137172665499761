
import { computed, ref, defineComponent, onBeforeUnmount, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { $api } from '@/services';
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail.vue';
export default defineComponent({
	name: 'GoalByStop',

	components: { TooltipGoalDetail },

	setup(_, { emit }) {
		const route = useRoute();
		const store = useStore();
		const goals = ref([]);
		const skill_count = ref(0);
		const goal_count = ref(0);
		const alertShow = ref(true);

		const id = computed(() =>
			route.params?.id ? route.params?.id + '' : ''
		);
		const plan_id = computed(() =>
			route.query?.plan_id ? String(route.query?.plan_id) : ''
		);

		async function fetchGoals() {
			const {
				data: { data, stats },
			} = await $api.groupClasses.goalsCompletedByGroupClass(id.value);
			if (data) {
				goals.value = data?.skills ?? [];
				if (stats) {
					skill_count.value = stats?.skills_count || 0;
					goal_count.value = stats?.goals_count || 0;
				}
			}
		}

		async function doGoal(group_goal_id: string) {
			try {
				await $api.groupClasses.doGoalByLib(id.value, plan_id.value, {
					group_goal_id,
				});
				await store.dispatch('addGoal', group_goal_id);
				emit('updated');
			} catch (e) {
				await store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		}

		fetchGoals();

		const reload = computed(() => store.getters.reload);

		watch(reload, () => {
			fetchGoals();
		});

		onBeforeUnmount(() => {
			store.dispatch('clearAdded');
		});

		return {
			id,
			goal_count,
			skill_count,
			isAdmin: computed(() => store.getters.isAdmin),
			goals,
			doGoal,
			org: computed(() => store.state.profile.current_org),
			goal_added: computed(() => store.getters.goalAdded),
			alertShow,
		};
	},
});
