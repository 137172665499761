<script setup>
import { defineEmits, defineProps, ref, watch } from 'vue';
import CreateDocument from '@/views/documents/CreateDocument.vue';
import ViewPhotoV2 from '@/components/common/ViewPhotoV2.vue';
import { useStore } from 'vuex';
import { $api } from '@/services';

const props = defineProps(['document']);
const emit = defineEmits(['success', 'sortList']);
const store = useStore();

const isHide = ref(true);
const idFolder = ref(null);
const modalIsOpen = ref(false);
const deleteFolderModal = ref(false);
const isSlideOpen = ref(false);
const moveSlide = ref(false);
const deleteData = ref(null);
const data = ref(null);
const photos = ref([]);
const index = ref(0);
const changeNameModal = ref(false);
const title = ref('');
const viewOnWeb = ref(false);
const params = ref({
	order_by: '',
});
watch(
	params,
	() => {
		emit('sortList', params.value.order_by);
	},
	{ deep: true }
);
const close = () => {
	changeNameModal.value = false;
	moveSlide.value = false;
	isSlideOpen.value = false;
	modalIsOpen.value = false;
	deleteFolderModal.value = false;
	data.value = null;
	deleteData.value = null;
};
const onSuccess = () => {
	isSlideOpen.value = false;
	modalIsOpen.value = false;
	deleteFolderModal.value = false;
	changeNameModal.value = false;
	moveSlide.value = false;
	emit('success');
};
const viewDocumentsOnWebsite = (item, categoryId) => {
	console.log('categoryId', categoryId);
	if (item?.asset_type === 'pdf') {
		window.open(item?.asset_url, '_blank').focus();
	} else {
		fetchDocumentDetail(item?.id, categoryId);
		viewOnWeb.value = true;
	}
};

const fetchDocumentDetail = async (id, categoryId) => {
	photos.value = [];
	try {
		const {
			data: { data },
		} = await $api.org.lucaDocumentDetail(id, categoryId);
		photos.value = data?.reference_images;
		title.value = data.title;
	} catch (e) {
		await store.dispatch('setAlertMessage', {
			message:
				e?.response?.data?.errors?.join('<br/>') ||
				'Đã có lỗi xảy ra. Vui lòng thử lại',
			type: 'danger',
		});
	}
};
</script>
<template>
	<ViewPhotoV2
		v-if="photos?.length"
		:images="photos"
		:index="index"
		:title="title"
		:viewOnWeb="viewOnWeb"
		@close="photos = []"
	/>
	<div v-if="isSlideOpen" :class="isSlideOpen ? 'side-over--active' : ''">
		<div
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			@click="isSlideOpen = false"
		></div>
		<CreateDocument
			:id="data?.id"
			:idFolder="idFolder"
			:show="isSlideOpen"
			@close="close"
			@success="onSuccess"
		/>
	</div>
	<div>
		<div class="flex items-center">
			<div class="relative my-4 w-full">
				<div
					aria-hidden="true"
					class="absolute inset-0 flex items-center"
				>
					<div class="w-full border-t border-neutral-300" />
				</div>
				<div class="relative flex justify-between">
					<div class="flex items-center bg-body px-2 gap-3">
						<div
							:class="{ 'rotate-180': !isHide }"
							class="cursor-pointer transform"
							@click="isHide = !isHide"
						>
							<svg
								fill="none"
								height="28"
								viewBox="0 0 28 28"
								width="28"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M21 17.5L14 10.5L7 17.5"
									stroke="#94A3B8"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
								/>
							</svg>
						</div>
						<div
							class="
								text-xl
								font-semibold
								text-gray-500
								cursor-pointer
							"
						>
							{{
								props.document.name ||
								props.document?.reference_category?.name
							}}
						</div>
					</div>
					<div class="bg-body pl-4 flex items-center gap-4">
						<span
							class="
								font-semibold
								text-neutral-400 text-sm
								whitespace-nowrap
							"
						>
							TÀI LIỆU:
							{{ props.document.references?.length || 0 }}</span
						>
					</div>
				</div>
			</div>
		</div>
		<div v-if="props.document.references?.length">
			<div
				v-if="isHide"
				class="__report-lesson__container overflow-x-auto"
			>
				<div class="shadow-sm sm:rounded-2xl mb-12 mt-1">
					<div class="__record__table scroll-bar">
						<div class="sm:rounded-t-2xl w-fit min-w-full">
							<table class="min-w-full divide-y text-neutral-200">
								<thead class="bg-neutral-100 h-16">
									<tr>
										<th
											class="
												px-3
												py-3
												text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
												text-center
											"
											scope="col"
										>
											STT
										</th>
										<th
											class="
												px-3
												py-3
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
											"
											scope="col"
										>
											Ảnh thumbnail
										</th>
										<th
											class="
												px-6
												py-3
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
											"
											scope="col"
											@click="
												params.order_by =
													params.order_by !==
													'title_asc'
														? 'title_asc'
														: 'title_desc'
											"
										>
											<div
												class="
													flex
													items-center
													gap-1
													justify-between
													cursor-pointer
												"
											>
												Tiêu đề
												<svg
													fill="none"
													height="16"
													viewBox="0 0 16 16"
													width="16"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														clip-rule="evenodd"
														d="M7.99999 2.39999C8.21216 2.39999 8.41564 2.48428 8.56567 2.63431L10.9657 5.03431C11.2781 5.34673 11.2781 5.85326 10.9657 6.16568C10.6533 6.4781 10.1467 6.4781 9.8343 6.16568L7.99999 4.33136L6.16567 6.16568C5.85325 6.4781 5.34672 6.4781 5.0343 6.16568C4.72188 5.85326 4.72188 5.34673 5.0343 5.03431L7.4343 2.63431C7.58433 2.48428 7.78782 2.39999 7.99999 2.39999ZM5.0343 9.83431C5.34672 9.52189 5.85325 9.52189 6.16567 9.83431L7.99999 11.6686L9.8343 9.83431C10.1467 9.52189 10.6533 9.52189 10.9657 9.83431C11.2781 10.1467 11.2781 10.6533 10.9657 10.9657L8.56567 13.3657C8.25325 13.6781 7.74672 13.6781 7.4343 13.3657L5.0343 10.9657C4.72188 10.6533 4.72188 10.1467 5.0343 9.83431Z"
														fill="#94A3B8"
														fill-rule="evenodd"
													/>
												</svg>
											</div>
										</th>
										<th
											class="
												px-3
												py-3
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
											"
											scope="col"
											@click="
												params.order_by =
													params.order_by !==
													'source_asc'
														? 'source_asc'
														: 'source_desc'
											"
										>
											<div
												class="
													flex
													items-center
													gap-1
													justify-between
													cursor-pointer
												"
											>
												nguồn
												<svg
													fill="none"
													height="16"
													viewBox="0 0 16 16"
													width="16"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														clip-rule="evenodd"
														d="M7.99999 2.39999C8.21216 2.39999 8.41564 2.48428 8.56567 2.63431L10.9657 5.03431C11.2781 5.34673 11.2781 5.85326 10.9657 6.16568C10.6533 6.4781 10.1467 6.4781 9.8343 6.16568L7.99999 4.33136L6.16567 6.16568C5.85325 6.4781 5.34672 6.4781 5.0343 6.16568C4.72188 5.85326 4.72188 5.34673 5.0343 5.03431L7.4343 2.63431C7.58433 2.48428 7.78782 2.39999 7.99999 2.39999ZM5.0343 9.83431C5.34672 9.52189 5.85325 9.52189 6.16567 9.83431L7.99999 11.6686L9.8343 9.83431C10.1467 9.52189 10.6533 9.52189 10.9657 9.83431C11.2781 10.1467 11.2781 10.6533 10.9657 10.9657L8.56567 13.3657C8.25325 13.6781 7.74672 13.6781 7.4343 13.3657L5.0343 10.9657C4.72188 10.6533 4.72188 10.1467 5.0343 9.83431Z"
														fill="#94A3B8"
														fill-rule="evenodd"
													/>
												</svg>
											</div>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(item, index) in props.document
											.references"
										:key="item.id"
										:class="
											index % 2 === 0
												? 'bg-white'
												: 'bg-neutral-50'
										"
										class="text-neutral-700"
									>
										<td
											class="
												px-3
												py-4
												whitespace-nowrap
												text-neutral-700 text-center
											"
											style="width: 60px"
										>
											<div>
												{{ index + 1 }}
											</div>
										</td>
										<td
											class="
												px-3
												py-4
												whitespace-nowrap
												text-neutral-700
											"
											style="width: 160px"
										>
											<div
												class="
													bg-neutral-100
													rounded-lg
													overflow-hidden
													object-fill
												"
												style="
													width: 120px;
													max-height: 80px;
												"
											>
												<img
													:alt="item.title"
													:src="
														item.thumbnail_url ||
														'/img/16.png'
													"
													class="object-fill"
												/>
											</div>
										</td>
										<td
											class="
												px-3
												py-4
												whitespace-nowrap
												text-neutral-700
											"
										>
											<div
												class="
													hover:underline
													hover:text-yellow-700
													cursor-pointer
												"
												@click="
													viewDocumentsOnWebsite(
														item,
														props.document?.id ||
															props.document
																?.reference_category
																?.id
													)
												"
											>
												{{ item?.title }}
											</div>
										</td>
										<td
											class="px-3 py-4 text-neutral-700"
											style="width: 320px"
										>
											{{ item.source }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
.disabled {
	background: var(--Gray-200, #e1e7ef) !important;
	color: var(--Gray-400, #94a3b8) !important;
	cursor: not-allowed;
}
</style>
