<script setup>
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { $api } from '@/services';
import LucaDocumentItem from '@/views/documents/components/LucaDocumentItem.vue';

const modalIsOpen = ref(false);
const listDocument = ref([]);
const order_by = ref('');
const route = useRoute();

const getListDocument = async () => {
	try {
		const res = await $api.org.listLucaDocumentsFolder({
			order_by: order_by.value,
			search: route.query?.query,
		});
		if (res)
			listDocument.value = res.data.data?.filter(
				(item) => item?.reference_category?.name !== 'newest'
			);
	} catch (e) {
		console.log(e);
	}
};

const sortList = (value) => {
	order_by.value = value;
	getListDocument();
};

const onSuccess = () => {
	modalIsOpen.value = false;
	getListDocument();
};

onMounted(() => {
	getListDocument();
});

watch(
	() => route.query?.query,
	() => {
		getListDocument();
	}
);
</script>

<template>
	<div class="__report-lesson">
		<div v-if="listDocument.length">
			<div v-for="document in listDocument" :key="document.id">
				<luca-document-item
					:document="document"
					@sortList="sortList"
					@success="onSuccess"
				></luca-document-item>
			</div>
		</div>
		<div
			v-else
			class="w-full h-full flex items-center justify-center flex-col"
		>
			<img
				alt=""
				height="250"
				src="@/assets/images/illustrations/not_found.png"
				width="250"
			/>
			<div
				class="text-neutral-500 text-lg text-center"
				style="max-width: 700px"
			>
				Trung tâm chưa có tài liệu tham khảo nào.
			</div>
			<button
				class="button-p btn--ghost w-fit px-4 mt-4"
				type="button"
				@click="modalIsOpen = true"
			>
				Tạo mới
			</button>
		</div>
	</div>
</template>
