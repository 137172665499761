
import { Vue, Options, setup } from 'vue-class-component';
import { useUpdatePassword } from './services/updatePassword';
import { useCheckToken } from '@/views/register/services/checkToken';
import { _ROUTER_NAME } from '@/enums';
import { useStore } from 'vuex';

@Options({
	name: 'UpdatePassword',
})
export default class UpdatePassword extends Vue {
	//----------------------- 🤍 Data 🤍 -----------------------//
	updatePasswordData = {
		password: '',
		password_confirmation: '',
	};
	typePasswordConfirm = 'password';
	typePassword = 'password';
	token: string | null = '';

	errorData = {
		password: '',
		password_confirmation: '',
		status: false,
	};

	successfully = false;

	//----------------------- 🤍 Setup 🤍 ----------------//
	setup = setup(() => {
		const store = useStore();
		const { updatePassword__error, loading, updatePassword } =
			useUpdatePassword();
		const { checkToken__error, checkToken } = useCheckToken();
		localStorage.clear();
		return {
			store,
			updatePassword__error,
			loading,
			updatePassword,
			checkToken,
			checkToken__error,
		};
	});

	//----------------------- 🤍 Created 🤍 -----------------------//
	async created() {
		localStorage.clear();
		this.token = this.$route?.query?.token
			? this.$route?.query?.token + ''
			: null;
		if (!this.token) {
			return this.$router.push('/');
		}
		await this.setup.checkToken({
			token: this.$route?.query?.token
				? this.$route?.query?.token + ''
				: null,
		});
		if (this.setup.checkToken__error) {
			await this.$router.push('/invalid-token');
		}
	}

	//----------------------- 🤍 Getters 🤍 -----------------------//
	get Error() {
		const error: any = this.setup.updatePassword__error;
		if (error) {
			return error.join('<br/>');
		}
		return error;
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	async onSubmit() {
		this.errorData = {
			password: '',
			password_confirmation: '',
			status: false,
		};
		if (this.updatePasswordData.password.length < 8) {
			this.errorData.password =
				'Phải có ít nhất 8 ký tự (bao gồm chữ và số)';
			this.errorData.status = true;
		} else if (
			!/[0-9]/.test(this.updatePasswordData.password) ||
			!/[a-zA-Z]/.test(this.updatePasswordData.password)
		) {
			this.errorData.password =
				'Phải có ít nhất 8 ký tự (bao gồm chữ và số)';
			this.errorData.status = true;
		}
		if (
			this.updatePasswordData.password_confirmation !=
			this.updatePasswordData.password
		) {
			this.errorData.password_confirmation =
				'Xác nhận mật khẩu không khớp';
			this.errorData.status = true;
		}
		if (this.errorData.status) {
			return;
		}
		try {
			const { data } = await this.setup.updatePassword({
				account: {
					password: this.updatePasswordData.password,
					password_confirmation:
						this.updatePasswordData.password_confirmation,
				},
				token: this.token,
			});

			if (data && data?.access_token) {
				return (this.successfully = true);
			}
		} catch (e) {
			if (
				this.Error &&
				this.Error.includes('Token is invalid or expired')
			) {
				await this.$router.push({
					name: _ROUTER_NAME.INVALID_TOKEN,
				});
			} else {
				await this.setup.store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		}
	}

	goLogin() {
		this.$router.push('/signin');
	}
}
