
import { Vue, Options, setup } from 'vue-class-component';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { useGetBoardings } from '@/views/setupCenter/features/boarding/services/getBoardings';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import CreateBoarding from '@/views/setupCenter/features/boarding/features/createBoarding/CreateBoarding.vue';
import { useStore } from 'vuex';
import { useUpdateBoarding } from '@/views/setupCenter/features/boarding/services/updateBoarding';
import DeleteConfirm from '@/views/setupCenter/features/deleteConfirm/DeleteConfirm.vue';
import Timesheet from '@/views/setupCenter/features/timesheet/Timesheet.vue';
import { Prop, PropSync } from 'vue-property-decorator';

@Options({
	name: 'Boarding',
	components: {
		Timesheet,
		DeleteConfirm,
		CreateBoarding,
		DaModalFrame,
		DaIcon,
	},
})
export default class SetupBoarding extends Vue {
	@PropSync('updated') updateSuccessful!: boolean;
	@Prop() org!: any;
	//----------------------- 🤍 Data 🤍 -----------------------//
	totalBoardings = 0;
	isEdit = false;

	//----------------------- 🤍 Setup 🤍 -----------------------//
	setup = setup(() => {
		const store = useStore();
		const { updateDayCareClass, updateDayCareClass__error } =
			useUpdateBoarding();
		const { getBoardings } = useGetBoardings();
		return {
			updateDayCareClass__error,
			updateDayCareClass,
			store,
			getBoardings,
		};
	});

	created() {
		this.fetchBoardings();
	}

	//----------------------- 🤍 Getters 🤍 -----------------------//
	get total() {
		return this.totalBoardings < 10
			? '0' + this.totalBoardings
			: this.totalBoardings;
	}

	get store() {
		return this.setup.store;
	}

	updateOrg(data: any) {
		this.$emit('updateOrg', data);
	}

	async fetchBoardings() {
		const res = await this.setup.getBoardings();
		if (res && res?.data && res?.count) {
			this.totalBoardings = res?.count;
		}
	}
}
