<template>
	<div>
		<div class="p-6 bg-white shadow-sm rounded-2xl my-10">
			<div>
				<div class="flex items-center justify-between mb-8">
					<div class="font-semibold text-neutral-600">
						Thống kê học bán trú
					</div>
					<div class="flex items-center gap-2">
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M4.79733 16C4.97121 15.9999 5.14328 15.9647 5.30323 15.8965C5.46318 15.8283 5.60771 15.7285 5.72818 15.6031C5.84864 15.4777 5.94256 15.3293 6.00429 15.1668C6.06602 15.0042 6.09431 14.8309 6.08745 14.6571L5.87126 7.90599L6.81336 7.33827C7.05368 7.19261 7.25086 6.98557 7.38462 6.73844C7.51838 6.4913 7.58389 6.213 7.57442 5.93214L7.38635 0.493958C7.3818 0.362774 7.32646 0.238489 7.23202 0.147326C7.13757 0.0561632 7.01141 0.00525057 6.88015 0.00532972C6.74951 0.00662705 6.62442 0.058386 6.53106 0.149781C6.4377 0.241177 6.38329 0.36513 6.37922 0.495716L6.24739 3.88447C6.24625 3.98548 6.2089 4.08272 6.14213 4.15852C6.07536 4.23432 5.98361 4.28364 5.88356 4.29752C5.82798 4.30361 5.77174 4.29814 5.71836 4.28147C5.66499 4.2648 5.61563 4.23729 5.57339 4.20066C5.53115 4.16403 5.49693 4.11906 5.47287 4.06859C5.44882 4.01811 5.43545 3.96321 5.4336 3.90732L5.30177 0.51505C5.29868 0.398701 5.25717 0.286646 5.18373 0.196354C5.11029 0.106061 5.00904 0.0426041 4.89576 0.0158756C4.82827 0.00450191 4.75936 0.00450191 4.69187 0.0158756C4.57859 0.0426041 4.47734 0.106061 4.40389 0.196354C4.33045 0.286646 4.28895 0.398701 4.28585 0.51505L4.15403 3.90732C4.15218 3.96321 4.13881 4.01811 4.11475 4.06859C4.0907 4.11906 4.05648 4.16403 4.01423 4.20066C3.97199 4.23729 3.92264 4.2648 3.86926 4.28147C3.81589 4.29814 3.75965 4.30361 3.70407 4.29752C3.60401 4.28364 3.51226 4.23432 3.4455 4.15852C3.37873 4.08272 3.34138 3.98548 3.34023 3.88447L3.20314 0.495716C3.199 0.362688 3.14264 0.236641 3.04625 0.144862C2.94987 0.0530826 2.82121 0.00295475 2.68814 0.00532972C2.55688 0.00525057 2.43072 0.0561632 2.33627 0.147326C2.24183 0.238489 2.1865 0.362774 2.18194 0.493958L2.0009 5.9339C1.99143 6.21475 2.05694 6.49306 2.1907 6.74019C2.32447 6.98733 2.52165 7.19437 2.76196 7.34002L3.72164 7.91654L3.50545 14.6571C3.49859 14.831 3.52692 15.0045 3.58876 15.1672C3.65061 15.3299 3.74468 15.4784 3.86533 15.6038C3.98598 15.7292 4.13073 15.8289 4.29088 15.897C4.45104 15.9651 4.6233 16.0001 4.79733 16Z"
								fill="#3B82F6"
							/>
							<path
								d="M14.25 6.59655C14.5002 6.13664 14.6296 5.6208 14.6262 5.09727C14.6138 3.37652 14.3027 0.0176447 11.8297 6.81432e-05C9.3567 -0.0175084 9.0456 3.36949 9.03329 5.09727C9.02932 5.62064 9.15813 6.13648 9.40767 6.59655C9.67212 7.08462 10.0623 7.49304 10.5378 7.77945L10.7699 7.92006L10.5554 14.6572C10.5491 14.8284 10.5773 14.9991 10.6384 15.1591C10.6995 15.3192 10.7923 15.4653 10.9112 15.5886C11.0301 15.712 11.1726 15.8102 11.3303 15.8772C11.488 15.9442 11.6575 15.9788 11.8288 15.9788C12.0002 15.9788 12.1697 15.9442 12.3274 15.8772C12.4851 15.8102 12.6276 15.712 12.7465 15.5886C12.8654 15.4653 12.9581 15.3192 13.0193 15.1591C13.0804 14.9991 13.1086 14.8284 13.1023 14.6572L12.8826 7.91655L13.1146 7.77593C13.5916 7.49123 13.9836 7.084 14.25 6.59655Z"
								fill="#3B82F6"
							/>
						</svg>
						<div>{{ day_care_class.name }}</div>
					</div>
				</div>
			</div>
			<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
				<div
					class="
						p-6
						border
						lg:rounded-l-lg
						md:rounded-tl-lg
						border-neutral-200
						w-full
						md-down:rounded-t md-down:rounded-b-none
					"
				>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							mb-6
							flex flex-nowrap
							items-center
						"
					>
						<img
							src="@/assets/images/icons/schedule.png"
							alt=""
							width="32"
							height="32"
							class="mr-2"
							style="height: 32px"
						/>
						{{ stats.finished_count }}
					</div>
					<div class="flex flex-nowrap items-center">
						<span class="text-neutral-500 text-sm"
							>Ngày đã học
						</span>
					</div>
				</div>
				<div
					class="
						p-6
						border border-neutral-200
						w-full
						lg:border-l-0
						lg-down:border-t-0
						md:rounded-bl-lg
						lg:rounded-bl-none
					"
				>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							mb-6
							flex flex-nowrap
							items-center
						"
					>
						<img
							src="@/assets/images/icons/schedule.png"
							alt=""
							width="32"
							height="32"
							class="mr-2"
							style="height: 32px"
						/>
						{{ stats.scheduled_count }}
					</div>
					<div class="flex flex-nowrap items-center">
						<span class="text-neutral-500 text-sm"
							>Ngày học cố định</span
						>
					</div>
				</div>
				<div
					class="
						p-6
						border border-neutral-200
						w-full
						md:border-l-0
						lg-down:border-t-0
					"
				>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							mb-6
							flex flex-nowrap
							items-center
						"
					>
						<img
							src="@/assets/images/icons/schedule.png"
							alt=""
							width="32"
							height="32"
							class="mr-2"
							style="height: 32px"
						/>
						{{ stats.unscheduled_count }}
					</div>
					<div class="flex flex-nowrap items-center">
						<span class="text-neutral-500 text-sm"
							>Ngày học phát sinh</span
						>
					</div>
				</div>
				<div
					class="
						p-6
						border
						md:border-l-0
						lg:rounded-r-lg
						md:rounded-br-lg
						border-neutral-200
						w-full
						lg-down:border-t-0
						md-down:rounded-b md-down:rounded-t-none
					"
				>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							mb-6
							flex flex-nowrap
							items-center
						"
					>
						<img
							src="@/assets/images/icons/schedule.png"
							alt=""
							width="32"
							height="32"
							class="mr-2"
							style="height: 32px"
						/>
						{{ stats.cancelled_count }}
					</div>
					<div class="flex flex-nowrap items-center">
						<span class="text-neutral-500 text-sm"
							>Ngày xin nghỉ</span
						>
					</div>
				</div>
			</div>
		</div>
		<div
			class="
				grid
				gap-8
				mb-10
				md-down:gap-4
				grid-cols-2
				md-down:grid-cols-1
			"
		>
			<div
				class="
					bg-white
					p-4
					rounded-xl
					border-sm
					shadow-sm
					flex
					items-center
					justify-between
				"
			>
				<div class="flex items-center flex-nowrap">
					<svg
						width="21"
						height="20"
						viewBox="0 0 21 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clip-path="url(#clip0_1704:5240)">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M1.66797 1.66667C1.66797 0.746192 2.40767 0 3.32014 0H16.5375C17.45 0 18.1897 0.746192 18.1897 1.66667V8.81793C17.4244 8.50542 16.5878 8.33333 15.7114 8.33333C12.0616 8.33333 9.10275 11.25 9.10275 14.9319C9.10275 16.9231 9.96809 18.7784 11.3401 20H3.32014C2.40767 20 1.66797 19.2538 1.66797 18.3333V1.66667ZM2.9071 7.5C2.9071 7.26988 3.09203 7.08333 3.32014 7.08333H11.168C11.3961 7.08333 11.581 7.26988 11.581 7.5C11.581 7.73012 11.3961 7.91667 11.168 7.91667H3.32014C3.09203 7.91667 2.9071 7.73012 2.9071 7.5ZM2.9071 10C2.9071 9.76988 3.09203 9.58333 3.32014 9.58333H9.51579C9.74391 9.58333 9.92884 9.76988 9.92884 10C9.92884 10.2301 9.74391 10.4167 9.51579 10.4167H3.32014C3.09203 10.4167 2.9071 10.2301 2.9071 10ZM2.9071 12.5C2.9071 12.2699 3.09203 12.0833 3.32014 12.0833H7.86362C8.09174 12.0833 8.27666 12.2699 8.27666 12.5C8.27666 12.7301 8.09174 12.9167 7.86362 12.9167H3.32014C3.09203 12.9167 2.9071 12.7301 2.9071 12.5ZM2.9071 15C2.9071 14.7699 3.09203 14.5833 3.32014 14.5833H6.21145C6.43956 14.5833 6.62449 14.7699 6.62449 15C6.62449 15.2301 6.43956 15.4167 6.21145 15.4167H3.32014C3.09203 15.4167 2.9071 15.2301 2.9071 15ZM2.9071 3.75C2.9071 3.28976 3.27695 2.91667 3.73319 2.91667H14.8854C15.3416 2.91667 15.7114 3.28976 15.7114 3.75C15.7114 4.21024 15.3416 4.58333 14.8854 4.58333H3.73319C3.27695 4.58333 2.9071 4.21024 2.9071 3.75Z"
								fill="#2AA797"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M15.7114 20C18.4489 20 20.668 17.7614 20.668 15C20.668 12.2386 18.4489 10 15.7114 10C12.974 10 10.7549 12.2386 10.7549 15C10.7549 17.7614 12.974 20 15.7114 20ZM17.5514 13.106C17.7328 12.923 18.027 12.923 18.2085 13.106C18.3877 13.2869 18.3899 13.5786 18.2151 13.7622L15.7417 16.8811C15.7381 16.8856 15.7343 16.8899 15.7302 16.894C15.5488 17.077 15.2546 17.077 15.0731 16.894L13.4334 15.2399C13.252 15.0569 13.252 14.7601 13.4334 14.577C13.6149 14.394 13.9091 14.394 14.0906 14.577L15.3877 15.8855L17.539 13.1201C17.5429 13.1151 17.547 13.1105 17.5514 13.106Z"
								fill="#2AA797"
							/>
						</g>
						<defs>
							<clipPath id="clip0_1704:5240">
								<rect
									width="20"
									height="20"
									fill="white"
									transform="translate(0.667969)"
								/>
							</clipPath>
						</defs>
					</svg>
					<div class="text-neutral-500 text-sm ml-2">
						Báo cáo hoàn thành
					</div>
				</div>
				<div class="flex items-center flex-nowrap justify-between">
					<div class="text-neutral-600 font-bold text-4xl">
						{{ stats.completed_day_care_report_count }}
					</div>
				</div>
			</div>
			<div
				class="
					bg-white
					p-4
					rounded-xl
					border-sm
					shadow-sm
					flex
					items-center
					justify-between
				"
			>
				<div class="flex items-center flex-nowrap">
					<svg
						width="21"
						height="20"
						viewBox="0 0 21 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clip-path="url(#clip0_1704:5253)">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M2.83073 0C1.91025 0 1.16406 0.746192 1.16406 1.66667V18.3333C1.16406 19.2538 1.91026 20 2.83073 20H10.921C9.53699 18.7784 8.66406 16.9231 8.66406 14.9319C8.66406 11.25 11.6488 8.33333 15.3307 8.33333C16.2148 8.33333 17.0587 8.50542 17.8307 8.81793V1.66667C17.8307 0.746192 17.0845 0 16.1641 0H2.83073ZM2.83073 7.08333C2.60061 7.08333 2.41406 7.26988 2.41406 7.5C2.41406 7.73012 2.60061 7.91667 2.83073 7.91667H10.7474C10.9775 7.91667 11.1641 7.73012 11.1641 7.5C11.1641 7.26988 10.9775 7.08333 10.7474 7.08333H2.83073ZM2.83073 9.58333C2.60061 9.58333 2.41406 9.76988 2.41406 10C2.41406 10.2301 2.60061 10.4167 2.83073 10.4167H9.08073C9.31085 10.4167 9.4974 10.2301 9.4974 10C9.4974 9.76988 9.31085 9.58333 9.08073 9.58333H2.83073ZM2.83073 12.0833C2.60061 12.0833 2.41406 12.2699 2.41406 12.5C2.41406 12.7301 2.60061 12.9167 2.83073 12.9167H7.41406C7.64418 12.9167 7.83073 12.7301 7.83073 12.5C7.83073 12.2699 7.64418 12.0833 7.41406 12.0833H2.83073ZM2.83073 14.5833C2.60061 14.5833 2.41406 14.7699 2.41406 15C2.41406 15.2301 2.60061 15.4167 2.83073 15.4167H5.7474C5.97751 15.4167 6.16406 15.2301 6.16406 15C6.16406 14.7699 5.97751 14.5833 5.7474 14.5833H2.83073ZM3.2474 2.91667C2.78716 2.91667 2.41406 3.28976 2.41406 3.75C2.41406 4.21024 2.78716 4.58333 3.2474 4.58333H14.4974C14.9576 4.58333 15.3307 4.21024 15.3307 3.75C15.3307 3.28976 14.9576 2.91667 14.4974 2.91667H3.2474Z"
								fill="#94A3B8"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M20.3307 15C20.3307 17.7614 18.0922 20 15.3307 20C12.5693 20 10.3307 17.7614 10.3307 15C10.3307 12.2386 12.5693 10 15.3307 10C18.0922 10 20.3307 12.2386 20.3307 15ZM13.6767 12.904C13.5547 12.782 13.3568 12.782 13.2348 12.904C13.1127 13.0261 13.1127 13.2239 13.2348 13.346L14.8888 15L13.2348 16.654C13.1127 16.7761 13.1127 16.9739 13.2348 17.096C13.3568 17.218 13.5547 17.218 13.6767 17.096L15.3307 15.4419L16.9848 17.096C17.1068 17.218 17.3047 17.218 17.4267 17.096C17.5487 16.9739 17.5487 16.7761 17.4267 16.654L15.7727 15L17.4267 13.346C17.5487 13.2239 17.5487 13.0261 17.4267 12.904C17.3047 12.782 17.1068 12.782 16.9848 12.904L15.3307 14.5581L13.6767 12.904Z"
								fill="#94A3B8"
							/>
						</g>
						<defs>
							<clipPath id="clip0_1704:5253">
								<rect
									width="20"
									height="20"
									fill="white"
									transform="translate(0.332031)"
								/>
							</clipPath>
						</defs>
					</svg>
					<div class="text-neutral-500 text-sm ml-2">
						Báo cáo chưa hoàn thành
					</div>
				</div>
				<div class="flex items-center flex-nowrap justify-between">
					<div class="text-neutral-600 font-bold text-4xl">
						{{ stats.incompleted_day_care_report_count }}
					</div>
				</div>
			</div>
		</div>
		<div v-if="data && data.length">
			<div class="shadow-sm sm:rounded-2xl overflow-hidden mt-10">
				<div class="__record__table overflow-x-auto scroll-bar">
					<div
						class="
							overflow-hidden
							sm:rounded-t-2xl
							w-fit
							min-w-full
						"
					>
						<table class="min-w-full divide-y text-neutral-200">
							<thead class="bg-neutral-100 h-16">
								<tr>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-center text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											w-16
											whitespace-nowrap
										"
									>
										STT
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Ngày
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Lớp bán trú
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Báo cáo
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Tên học sinh
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Trạng thái
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Phân loại
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Ghi chú
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(item, index) in data"
									:key="item.id"
									:class="
										item.state === 'cancelled'
											? 'bg-sienna-50'
											: index % 2 === 0
											? 'bg-white'
											: 'bg-neutral-50'
									"
									class="text-neutral-700"
								>
									<td
										class="
											px-6
											py-4
											whitespace-nowrap
											text-sm text-neutral-700
										"
									>
										{{ index + 1 }}
									</td>
									<td>
										<div class="px-3 py-4 text-neutral-700">
											<span v-if="item.date">{{
												$filters.formatDate2(item.date)
											}}</span>
										</div>
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
											hover:text-blue-600 hover:underline
											cursor-pointer
											w-40
										"
									>
										{{
											item.day_care_class
												? item.day_care_class.name
												: ''
										}}
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<svg
											v-if="item.state === 'completed'"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M1 1.66667C1 0.746192 1.7397 0 2.65217 0H15.8696C16.782 0 17.5217 0.746192 17.5217 1.66667V8.81793C16.7564 8.50542 15.9199 8.33333 15.0435 8.33333C11.3936 8.33333 8.43478 11.25 8.43478 14.9319C8.43478 16.9231 9.30012 18.7784 10.6721 20H2.65217C1.7397 20 1 19.2538 1 18.3333V1.66667ZM2.23913 7.5C2.23913 7.26988 2.42406 7.08333 2.65217 7.08333H10.5C10.7281 7.08333 10.913 7.26988 10.913 7.5C10.913 7.73012 10.7281 7.91667 10.5 7.91667H2.65217C2.42406 7.91667 2.23913 7.73012 2.23913 7.5ZM2.23913 10C2.23913 9.76988 2.42406 9.58333 2.65217 9.58333H8.84783C9.07594 9.58333 9.26087 9.76988 9.26087 10C9.26087 10.2301 9.07594 10.4167 8.84783 10.4167H2.65217C2.42406 10.4167 2.23913 10.2301 2.23913 10ZM2.23913 12.5C2.23913 12.2699 2.42406 12.0833 2.65217 12.0833H7.19565C7.42377 12.0833 7.6087 12.2699 7.6087 12.5C7.6087 12.7301 7.42377 12.9167 7.19565 12.9167H2.65217C2.42406 12.9167 2.23913 12.7301 2.23913 12.5ZM2.23913 15C2.23913 14.7699 2.42406 14.5833 2.65217 14.5833H5.54348C5.7716 14.5833 5.95652 14.7699 5.95652 15C5.95652 15.2301 5.7716 15.4167 5.54348 15.4167H2.65217C2.42406 15.4167 2.23913 15.2301 2.23913 15ZM2.23913 3.75C2.23913 3.28976 2.60898 2.91667 3.06522 2.91667H14.2174C14.6736 2.91667 15.0435 3.28976 15.0435 3.75C15.0435 4.21024 14.6736 4.58333 14.2174 4.58333H3.06522C2.60898 4.58333 2.23913 4.21024 2.23913 3.75Z"
												fill="#2AA797"
											/>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M15.0435 20C17.7809 20 20 17.7614 20 15C20 12.2386 17.7809 10 15.0435 10C12.3061 10 10.087 12.2386 10.087 15C10.087 17.7614 12.3061 20 15.0435 20ZM16.8834 13.106C17.0649 12.923 17.3591 12.923 17.5405 13.106C17.7198 13.2869 17.722 13.5786 17.5471 13.7622L15.0737 16.8811C15.0701 16.8856 15.0663 16.8899 15.0623 16.894C14.8808 17.077 14.5866 17.077 14.4051 16.894L12.7655 15.2399C12.584 15.0569 12.584 14.7601 12.7655 14.577C12.9469 14.394 13.2412 14.394 13.4226 14.577L14.7197 15.8855L16.8711 13.1201C16.8749 13.1151 16.879 13.1105 16.8834 13.106Z"
												fill="#2AA797"
											/>
										</svg>
										<svg
											v-else
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M1 1.66667C1 0.746192 1.7397 0 2.65217 0H15.8696C16.782 0 17.5217 0.746192 17.5217 1.66667V8.81793C16.7564 8.50542 15.9199 8.33333 15.0435 8.33333C11.3936 8.33333 8.43478 11.25 8.43478 14.9319C8.43478 16.9231 9.30012 18.7784 10.6721 20H2.65217C1.7397 20 1 19.2538 1 18.3333V1.66667ZM2.23913 7.5C2.23913 7.26988 2.42406 7.08333 2.65217 7.08333H10.5C10.7281 7.08333 10.913 7.26988 10.913 7.5C10.913 7.73012 10.7281 7.91667 10.5 7.91667H2.65217C2.42406 7.91667 2.23913 7.73012 2.23913 7.5ZM2.23913 10C2.23913 9.76988 2.42406 9.58333 2.65217 9.58333H8.84783C9.07594 9.58333 9.26087 9.76988 9.26087 10C9.26087 10.2301 9.07594 10.4167 8.84783 10.4167H2.65217C2.42406 10.4167 2.23913 10.2301 2.23913 10ZM2.23913 12.5C2.23913 12.2699 2.42406 12.0833 2.65217 12.0833H7.19565C7.42377 12.0833 7.6087 12.2699 7.6087 12.5C7.6087 12.7301 7.42377 12.9167 7.19565 12.9167H2.65217C2.42406 12.9167 2.23913 12.7301 2.23913 12.5ZM2.23913 15C2.23913 14.7699 2.42406 14.5833 2.65217 14.5833H5.54348C5.7716 14.5833 5.95652 14.7699 5.95652 15C5.95652 15.2301 5.7716 15.4167 5.54348 15.4167H2.65217C2.42406 15.4167 2.23913 15.2301 2.23913 15ZM2.23913 3.75C2.23913 3.28976 2.60898 2.91667 3.06522 2.91667H14.2174C14.6736 2.91667 15.0435 3.28976 15.0435 3.75C15.0435 4.21024 14.6736 4.58333 14.2174 4.58333H3.06522C2.60898 4.58333 2.23913 4.21024 2.23913 3.75Z"
												fill="#CBD5E1"
											/>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M15.0435 20C17.7809 20 20 17.7614 20 15C20 12.2386 17.7809 10 15.0435 10C12.3061 10 10.087 12.2386 10.087 15C10.087 17.7614 12.3061 20 15.0435 20ZM16.8834 13.106C17.0649 12.923 17.3591 12.923 17.5405 13.106C17.7198 13.2869 17.722 13.5786 17.5471 13.7622L15.0737 16.8811C15.0701 16.8856 15.0663 16.8899 15.0623 16.894C14.8808 17.077 14.5866 17.077 14.4051 16.894L12.7655 15.2399C12.584 15.0569 12.584 14.7601 12.7655 14.577C12.9469 14.394 13.2412 14.394 13.4226 14.577L14.7197 15.8855L16.8711 13.1201C16.8749 13.1151 16.879 13.1105 16.8834 13.106Z"
												fill="#CBD5E1"
											/>
										</svg>
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
											hover:text-yellow-700
											hover:underline
											cursor-pointer
										"
									>
										<div
											@click.stop="
												$router.push(
													`/record/student/${item.student.id}`
												)
											"
											class="flex items-center"
											v-if="item.student"
										>
											<div>
												<img
													class="
														shadow-sm
														inline-block
														h-9
														w-9
														rounded-full
													"
													:src="
														item.student
															?.profile_photo
															?.w200
													"
													alt=""
													v-if="
														item.student
															?.profile_photo
															?.w200
													"
												/>
												<span
													v-else
													class="
														inline-flex
														items-center
														justify-center
														h-9
														w-9
														rounded-full
													"
													:style="{
														background:
															item.student
																?.profile_photo
																?.default
																?.color,
													}"
												>
													<span
														class="
															font-medium
															leading-none
															text-white text-xs
														"
														>{{
															item.student
																?.profile_photo
																?.default
																?.initial
														}}</span
													>
												</span>
											</div>
											<div
												class="
													ml-4
													text-yellow-600
													font-semibold
													whitespace-pre-wrap
													w-40
												"
											>
												{{ item.student.name }}
											</div>
										</div>
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div>
											<div
												v-if="item.state === 'ongoing'"
												class="
													w-fit
													font-semibold
													bg-blue-100
													text-xs text-blue-600
													p-2
													px-4
													pb-1.5
													rounded-full
													flex
													items-center
													justify-center
													gap-2
												"
											>
												<div>
													<svg
														width="15"
														height="14"
														viewBox="0 0 15 14"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M7.5 14C11.366 14 14.5 10.866 14.5 7C14.5 3.13401 11.366 0 7.5 0C3.63401 0 0.5 3.13401 0.5 7C0.5 10.866 3.63401 14 7.5 14ZM11.0491 6.63407C10.9689 6.50148 10.846 6.41667 10.7083 6.41667H4.58333C4.44564 6.41667 4.3228 6.50148 4.2426 6.63407C4.14461 6.72535 4.08333 6.85552 4.08333 7C4.08333 7.14448 4.14461 7.27465 4.2426 7.36593C4.3228 7.49852 4.44564 7.58333 4.58333 7.58333H10.7083C10.846 7.58333 10.9689 7.49852 11.0491 7.36593C11.1471 7.27465 11.2083 7.14448 11.2083 7C11.2083 6.85552 11.1471 6.72535 11.0491 6.63407Z"
															fill="#CBD5E1"
														/>
													</svg>
												</div>
												<div>Đang học</div>
											</div>
											<div
												v-if="
													item.state !== 'created' &&
													item.state !==
														'cancelled' &&
													item.state !== 'ongoing'
												"
												class="
													text-xs
													w-fit
													font-semibold
													bg-neutral-100
													text-gray-500
													p-2
													px-4
													pb-1.5
													rounded-full
													flex
													items-center
													justify-center
													gap-2
												"
											>
												<div>
													<svg
														width="15"
														height="14"
														viewBox="0 0 15 14"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M7.5 14C11.366 14 14.5 10.866 14.5 7C14.5 3.13401 11.366 0 7.5 0C3.63401 0 0.5 3.13401 0.5 7C0.5 10.866 3.63401 14 7.5 14ZM11.0491 6.63407C10.9689 6.50148 10.846 6.41667 10.7083 6.41667H4.58333C4.44564 6.41667 4.3228 6.50148 4.2426 6.63407C4.14461 6.72535 4.08333 6.85552 4.08333 7C4.08333 7.14448 4.14461 7.27465 4.2426 7.36593C4.3228 7.49852 4.44564 7.58333 4.58333 7.58333H10.7083C10.846 7.58333 10.9689 7.49852 11.0491 7.36593C11.1471 7.27465 11.2083 7.14448 11.2083 7C11.2083 6.85552 11.1471 6.72535 11.0491 6.63407Z"
															fill="#CBD5E1"
														/>
													</svg>
												</div>
												<div>Chưa học</div>
											</div>
											<div
												v-if="
													item.state === 'cancelled'
												"
												class="
													text-xs
													w-fit
													font-semibold
													bg-sienna-100
													text-sienna-500
													p-2
													px-4
													pb-1.5
													rounded-full
													flex
													items-center
													justify-center
													gap-2
												"
											>
												<div>
													<svg
														width="15"
														height="14"
														viewBox="0 0 15 14"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M5.19063 4.35739C5.36148 4.18653 5.63849 4.18653 5.80934 4.35739L8.12498 6.67303L10.4406 4.35739C10.6115 4.18653 10.8885 4.18653 11.0593 4.35739C11.2302 4.52824 11.2302 4.80525 11.0593 4.97611L8.7437 7.29175L11.0593 9.60739C11.2302 9.77824 11.2302 10.0553 11.0593 10.2261C10.8885 10.397 10.6115 10.397 10.4406 10.2261L8.12498 7.91047L5.80934 10.2261C5.63849 10.397 5.36148 10.397 5.19063 10.2261C5.01977 10.0553 5.01977 9.77824 5.19063 9.60739L7.50627 7.29175L5.19063 4.97611C5.01977 4.80525 5.01977 4.52824 5.19063 4.35739Z"
															fill="#DB5A41"
														/>
													</svg>
												</div>
												<div>Nghỉ học</div>
											</div>
											<div
												v-if="item.state === 'created'"
												class="
													text-xs
													w-fit
													font-semibold
													bg-green-50
													text-green-500
													p-2
													px-4
													pb-1.5
													rounded-full
													flex
													items-center
													justify-center
													gap-2
												"
											>
												<div>
													<svg
														width="15"
														height="14"
														viewBox="0 0 15 14"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M8.68176 4.34846C8.93804 4.09218 9.35355 4.09218 9.60983 4.34846C9.86297 4.6016 9.86608 5.01009 9.61915 5.26704L6.12597 9.63351C6.12093 9.63981 6.11554 9.64583 6.10983 9.65154C5.85355 9.90782 5.43804 9.90782 5.18176 9.65154L2.86612 7.3359C2.60983 7.07962 2.60983 6.6641 2.86612 6.40782C3.1224 6.15154 3.53791 6.15154 3.79419 6.40782L5.62602 8.23965L8.66435 4.36809C8.66975 4.36121 8.67557 4.35465 8.68176 4.34846Z"
															fill="#339588"
														/>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M7.87639 8.84617L8.68176 9.65154C8.93804 9.90782 9.35355 9.90782 9.60984 9.65154C9.61554 9.64583 9.62093 9.63981 9.62597 9.63351L13.1191 5.26704C13.3661 5.01009 13.363 4.6016 13.1098 4.34846C12.8536 4.09218 12.438 4.09218 12.1818 4.34846C12.1756 4.35465 12.1698 4.36121 12.1644 4.36809L9.12602 8.23965L8.70135 7.81497L7.87639 8.84617Z"
															fill="#339588"
														/>
													</svg>
												</div>
												<div>Đã học</div>
											</div>
										</div>
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div>
											<div
												v-if="!item.scheduled"
												class="
													text-xs
													w-fit
													font-semibold
													text-sienna-600
													p-2
													px-4
													pb-1.5
													rounded-full
													border border-sienna-200
													flex
													items-center
													justify-center
												"
											>
												Phát sinh
											</div>
											<div
												v-else
												class="
													text-xs
													w-fit
													font-semibold
													text-neutral-600
													p-2
													px-4
													pb-1.5
													rounded-full
													border border-neutral-300
													flex
													items-center
													justify-center
												"
											>
												Cố định
											</div>
										</div>
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div class="text-neutral-600">
											<div v-if="item.unscheduled_note">
												<span class="font-semibold"
													>Phát sinh: </span
												>{{ item.unscheduled_note }}
											</div>
											<div v-if="item.leave_note">
												<span class="font-semibold"
													>Báo nghỉ: </span
												>{{ item.leave_note }}
											</div>
											<div v-if="item.staff_change_note">
												<span class="font-semibold"
													>Đổi TLV: </span
												>{{ item.staff_change_note }}
											</div>
											<div v-if="item.slot_change_note">
												<span class="font-semibold"
													>Đổi giờ:
												</span>
												{{ item.slot_change_note }}
											</div>
											<div v-if="item.note">
												{{ item.note }}
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else
			class="
				w-full
				h-full
				flex
				items-center
				justify-center
				flex-col
				mt-10
			"
		>
			<img
				src="@/assets/images/illustrations/not_found.png"
				alt=""
				height="250"
				width="250"
			/>
			<div
				class="text-neutral-500 text-lg text-center"
				style="max-width: 700px"
			>
				Không có lịch học bán trú hôm nay
			</div>
		</div>
	</div>
</template>

<script>
import { $api } from '@/services';

export default {
	name: 'ScheduleStatsDetailClass',

	props: {
		timeData: {
			type: Object,
			required: true,
		},
		student: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			staffs_map: {},
			students_map: {},
			day_care_class: {},
			stats: {
				cancelled_count: 0,
				completed_day_care_report_count: 0,
				finished_count: 0,
				incompleted_day_care_report_count: 0,
				scheduled_count: 0,
				unscheduled_count: 0,
			},
			data: [],
		};
	},
	computed: {
		studentId() {
			return this.$route.query.student_id;
		},
	},

	created() {
		this.getScheduleStatsStudent();
	},

	methods: {
		getStaff(staff_id) {
			return this.staffs_map[staff_id]?.[0];
		},

		getStudent(student_id) {
			return this.students_map[student_id]?.[0];
		},

		async getScheduleStatsStudent() {
			try {
				const {
					data: {
						data,
						staffs_map,
						students_map,
						stats,
						day_care_class,
					},
				} = await $api.schedule.scheduleStatsByMonthDayCare({
					month: this.timeData.month,
					year: this.timeData.year,
					student_id: this.studentId,
				});
				this.staffs_map = staffs_map || {};
				this.day_care_class = day_care_class || {};
				this.students_map = students_map || {};
				this.data = data;
				this.stats = stats;
			} catch (e) {
				console.log(e);
			}
		},
	},
};
</script>
