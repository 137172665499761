import { ref } from 'vue';
import { $api } from '@/services';

const error = ref(null);
const loading = ref(false);

export async function forgotPassword(data: { email: string }) {
	error.value = null;
	try {
		const res = await $api.auth.forgotPassword(data);
		if (!res) throw new Error('Lỗi khi quên mật khẩu');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

export function useForgotPassword() {
	return { error, loading, forgotPassword };
}
