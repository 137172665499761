<template>
	<div class="_modal bg-white">
		<div
			class="
				_modal__content
				md-down:px-4
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<img
					src="@/assets/images/illustrations/email_parent.svg"
					alt="Luca Education"
					title="Luca Education"
					height="150"
					width="150"
				/>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
					text-center
				"
			>
				Gửi thông báo học phí
			</div>
			<div class="w-full mt-5">
				<div class="input-group grid grid-cols-2 items-center">
					<div
						class="
							input-group__label
							text-neutral-500
							uppercase
							font-medium
						"
					>
						ĐỐI TƯỢNG
					</div>
					<div class="font-bold text-neutral-500 truncate">
						{{ notification.student.name }}
					</div>
				</div>
				<div class="input-group grid grid-cols-2 items-center mt-10">
					<div
						class="
							input-group__label
							text-neutral-500
							uppercase
							font-medium
						"
					>
						THÁNG
					</div>
					<div class="font-bold text-neutral-500 truncate">
						<span v-if="notification.month_start">
							{{
								`Tháng ${notification.month_start.month + 1}/${
									notification.month_start.year
								}`
							}}
						</span>
						<span v-else-if="notification.month">
							{{
								`Tháng ${$filters.toMMYYY(
									notification.month + 1
								)}`
							}}
						</span>
						<span v-else>-</span>
					</div>
				</div>
				<div class="input-group grid grid-cols-2 items-center mt-10">
					<div
						class="
							input-group__label
							text-neutral-500
							uppercase
							font-medium
						"
					>
						Tiêu đề
					</div>
					<div class="font-bold text-neutral-500 truncate">
						{{ notification.title }}
					</div>
				</div>
				<div class="input-group grid grid-cols-2 items-center mt-10">
					<div
						class="
							input-group__label
							text-neutral-500
							font-medium
							uppercase
						"
					>
						nội dung
					</div>
					<div class="font-bold text-neutral-500 truncate">
						{{ notification?.content }}
					</div>
				</div>
				<div class="input-group grid grid-cols-2 items-center mt-10">
					<div
						class="
							input-group__label
							text-neutral-500
							font-medium
							uppercase
						"
					>
						Tệp đính kèm
					</div>
					<div
						v-if="
							notification.images.length ||
							notification.files.length
						"
					>
						<div v-if="notification.images.length">
							<div
								class="relative"
								@click="$emit('openViewPhoto')"
							>
								<div
									v-if="notification.images.length > 1"
									class="
										absolute
										top-0
										left-0
										h-full
										w-full
										rounded-lg
										flex
										items-center
										justify-center
										text-white
										font-semibold
										text-4xl
									"
									style="background: rgba(0, 0, 0, 0.4)"
								>
									+ {{ notification.images.length - 1 }}
								</div>
								<img
									:src="getUrl(notification.images[0])"
									alt=""
									class="rounded-lg object-fill h-full w-full"
									style="aspect-ratio: 4/3"
								/>
							</div>
						</div>
						<div
							v-if="notification.files.length"
							class="mt-4 flex flex-col gap-2"
						>
							<div
								v-for="(file, index) in notification.files"
								:key="index"
								class="text-neutral-400 flex items-center gap-2"
							>
								<svg
									width="32"
									height="32"
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M4 4C4 1.79086 5.79086 0 8 0H18.5858C19.1162 0 19.6249 0.210714 20 0.585786L27.4142 8C27.7893 8.37507 28 8.88378 28 9.41421V28C28 30.2091 26.2091 32 24 32H8C5.79086 32 4 30.2091 4 28V4ZM19 7V3L25 9H21C19.8954 9 19 8.10457 19 7ZM21.7071 15.7071C22.0976 15.3166 22.0976 14.6834 21.7071 14.2929C21.3166 13.9024 20.6834 13.9024 20.2929 14.2929L15 19.5858L12.7071 17.2929C12.3166 16.9024 11.6834 16.9024 11.2929 17.2929C10.9024 17.6834 10.9024 18.3166 11.2929 18.7071L14.2929 21.7071C14.4804 21.8946 14.7348 22 15 22C15.2652 22 15.5196 21.8946 15.7071 21.7071L21.7071 15.7071Z"
										fill="#E5A42B"
									/>
								</svg>

								<span style="max-width: 200px" class="truncate">
									{{ file.name }}</span
								>
							</div>
						</div>
					</div>
					<div v-else class="font-bold text-neutral-500">-</div>
				</div>
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				type="button"
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				@click="$emit('close')"
			>
				Quay lại
			</button>
			<button
				type="button"
				class="button-p text-center whitespace-nowrap"
				@click="$emit('submit')"
				:disabled="inProgress"
			>
				Xác nhận
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ConfirmSendFeeNotice',

	props: {
		notification: {
			type: Object,
			required: true,
		},
		inProgress: {
			type: Boolean,
			required: true,
		},
		isNetwork: {
			type: Boolean,
			default: false,
		},
	},

	methods: {
		getUrl(file) {
			if (!file || file?.asset_url) {
				return file?.asset_url ?? '';
			}

			return this.isNetwork ? file?.asset_url : URL.createObjectURL(file);
		},
	},
};
</script>
