import { ref } from 'vue';
import { $api } from '@/services';

const error = ref(null);
const loading = ref(false);

export async function listDaycareReportOldData(
	id: string,
	oldData: any,
	date?: string | null
) {
	error.value = null;
	try {
		const res = await $api.lessonReport.listDaycareReportOldData(
			id,
			oldData,
			{
				date,
				state: 'completed',
			}
		);
		if (!res) throw new Error('Lỗi');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

export function useListDaycareReportOldData() {
	return { error, loading, listDaycareReportOldData };
}
