
import { Vue, Options } from 'vue-class-component';
import SwitchButton from '@/components/elements/switchButton/SwitchButton.vue';
import { Prop, Watch } from 'vue-property-decorator';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import PopupConfirm from '@/views/setupCenter/features/others/PopupConfirm.vue';

@Options({
	name: 'Others',
	components: {
		PopupConfirm,
		DaModalFrame,
		SwitchButton,
	},
})
export default class Others extends Vue {
	//----------------------- 🤍 Props 🤍 -----------------------//
	@Prop() org!: any;

	//----------------------- 🤍 Data 🤍 -----------------------//
	has_day_care_chat_group = false;
	has_group_class_chat_group = false;
	enable_chat_group_users_management = false;
	onChange = false;
	onWatchOrg = true;
	modalIsOpen = false;
	type = 0;

	//----------------------- 🤍 Watch 🤍 -----------------------//
	@Watch('org', { deep: true, immediate: true })
	onOrgChange(org: any) {
		if (org && this.onWatchOrg) {
			this.has_day_care_chat_group = org?.has_day_care_chat_group;
			this.has_group_class_chat_group = org?.has_group_class_chat_group;
			this.enable_chat_group_users_management =
				org?.enable_chat_group_users_management;
			setTimeout(() => {
				this.onChange = true;
			}, 1000);
			this.onWatchOrg = false;
		}
	}

	@Watch('enable_chat_group_users_management')
	onRequestGoalNote(value: any) {
		if (this.onChange) {
			this.$emit('updateOrg', {
				enable_chat_group_users_management: value,
			});
		}
	}

	onOpenModal(type = 0) {
		this.modalIsOpen = true;
		this.type = type;
	}

	cancel() {
		this.modalIsOpen = false;
		this.type = 0;
	}

	onSubmit() {
		this.$emit('updateOrg', {
			has_day_care_chat_group:
				this.type == 0 ? !this.has_day_care_chat_group : undefined,
			has_group_class_chat_group:
				this.type == 1 ? !this.has_group_class_chat_group : undefined,
		});
		this.modalIsOpen = false;
		if (this.type === 0) {
			this.has_day_care_chat_group = !this.has_day_care_chat_group;
		} else {
			this.has_group_class_chat_group = !this.has_group_class_chat_group;
		}
		this.type = 0;
	}
}
