
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import Rating from '@/components/elements/rating/Rating.vue';
import { Options, Vue, setup } from 'vue-class-component';
import SideOver from '@/components/elements/sideOver/SideOver.vue';
import { useStore } from 'vuex';
import { useWeeklyReportList } from '@/features/weeklyReport/weeklyReportList';
import DaInfinityLoading from '@/components/common/daInfinityLoading/DaInfinityLoading.vue';
import { $api } from '@/services';
import SideOverFilter from '@/components/modules/notifications/SideOverFilter.vue';
import Loading from '@/components/common/Loading.vue';

@Options({
	name: 'Notifications',
	components: {
		Loading,
		SideOverFilter,
		SideOver,
		Rating,
		DaIcon,
		DaInfinityLoading,
	},
})
export default class ReportWeek extends Vue {
	notificationList: any = [];
	notificationListForStaff: any = [];
	stopLoad = false;
	isInfinityLoading = false;
	sideOverShow = false;
	filter = {
		className: '',
		day_care_class_id: '',
		state: '',
	};
	tab = 0;
	isLoading = true;

	//----------------------- 🤍 Setup 🤍 -----------------------//
	setup = setup(() => {
		const store = useStore();
		const { error, loading, getWeeklyReportList } = useWeeklyReportList();
		return {
			store,
			error,
			loading,
			getWeeklyReportList,
		};
	});

	//----------------------- 🤍 Getters 🤍 -----------------------//
	get isAdmin() {
		return this.setup.store.getters.isAdmin;
	}

	//----------------------- 🤍 Created 🤍 -----------------------//
	created() {
		this.getNotificationList();
		this.getNotificationListForStaff();
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	async getNotificationList(query?: any) {
		try {
			const {
				data: { data },
			} = await $api.notification.getNotificationsByYear(query);
			this.notificationList = data;
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoading = false;
		}
	}

	async getNotificationListForStaff(query?: any) {
		try {
			const {
				data: { data },
			} = await $api.notification.getNotificationsByYearForStaffs(query);
			this.notificationListForStaff = data;
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoading = false;
		}
	}

	clean(obj: any) {
		for (let propName in obj) {
			if (
				obj[propName] === null ||
				obj[propName] === undefined ||
				obj[propName].length <= 0
			) {
				delete obj[propName];
			}
		}
		return obj;
	}

	filterSubmit(queryPa: any) {
		if (queryPa) {
			this.clean(queryPa);
			this.getNotificationList({
				...queryPa,
				day_care_class_id:
					queryPa.day_care_class_id !== 'all_parents_in_org'
						? queryPa.day_care_class_id
						: null,
			});
			this.getNotificationListForStaff({
				...queryPa,
				day_care_class_id:
					queryPa.day_care_class_id !== 'all_parents_in_org'
						? queryPa.day_care_class_id
						: null,
			});
			this.filter = {
				...queryPa,
				day_care_class_id:
					queryPa.day_care_class_id !== 'all_parents_in_org'
						? queryPa.day_care_class_id
						: null,
			};
		} else {
			this.getNotificationList();
			this.getNotificationListForStaff();
			this.filter = {
				className: '',
				state: '',
				day_care_class_id: '',
			};
		}
	}

	beforeDestroy() {
		this.isInfinityLoading = false;
	}
}
