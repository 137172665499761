export const CITY = [
	{
		"_id": "60eaaa6f1173335842c35663",
		"name": "An Giang",
		"slug": "an-giang",
		"type": "tinh",
		"name_with_type": "Tỉnh An Giang",
		"code": "89",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c3565b",
		"name": "Bà Rịa - Vũng Tàu",
		"slug": "ba-ria---vung-tau",
		"type": "tinh",
		"name_with_type": "Tỉnh Bà Rịa - Vũng Tàu",
		"code": "77",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35668",
		"name": "Bạc Liêu",
		"slug": "bac-lieu",
		"type": "tinh",
		"name_with_type": "Tỉnh Bạc Liêu",
		"code": "95",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35639",
		"name": "Bắc Giang",
		"slug": "bac-giang",
		"type": "tinh",
		"name_with_type": "Tỉnh Bắc Giang",
		"code": "24",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c3562e",
		"name": "Bắc Kạn",
		"slug": "bac-kan",
		"type": "tinh",
		"name_with_type": "Tỉnh Bắc Kạn",
		"code": "06",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c3563c",
		"name": "Bắc Ninh",
		"slug": "bac-ninh",
		"type": "tinh",
		"name_with_type": "Tỉnh Bắc Ninh",
		"code": "27",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c3565f",
		"name": "Bến Tre",
		"slug": "ben-tre",
		"type": "tinh",
		"name_with_type": "Tỉnh Bến Tre",
		"code": "83",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35659",
		"name": "Bình Dương",
		"slug": "binh-duong",
		"type": "tinh",
		"name_with_type": "Tỉnh Bình Dương",
		"code": "74",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c3564d",
		"name": "Bình Định",
		"slug": "binh-dinh",
		"type": "tinh",
		"name_with_type": "Tỉnh Bình Định",
		"code": "52",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35657",
		"name": "Bình Phước",
		"slug": "binh-phuoc",
		"type": "tinh",
		"name_with_type": "Tỉnh Bình Phước",
		"code": "70",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35651",
		"name": "Bình Thuận",
		"slug": "binh-thuan",
		"type": "tinh",
		"name_with_type": "Tỉnh Bình Thuận",
		"code": "60",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35669",
		"name": "Cà Mau",
		"slug": "ca-mau",
		"type": "tinh",
		"name_with_type": "Tỉnh Cà Mau",
		"code": "96",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c3562d",
		"name": "Cao Bằng",
		"slug": "cao-bang",
		"type": "tinh",
		"name_with_type": "Tỉnh Cao Bằng",
		"code": "04",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35665",
		"name": "Cần Thơ",
		"slug": "can-tho",
		"type": "thanh-pho",
		"name_with_type": "Thành phố Cần Thơ",
		"code": "92",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c3564a",
		"name": "Đà Nẵng",
		"slug": "da-nang",
		"type": "thanh-pho",
		"name_with_type": "Thành phố Đà Nẵng",
		"code": "48",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35654",
		"name": "Đắk Lắk",
		"slug": "dak-lak",
		"type": "tinh",
		"name_with_type": "Tỉnh Đắk Lắk",
		"code": "66",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35655",
		"name": "Đắk Nông",
		"slug": "dak-nong",
		"type": "tinh",
		"name_with_type": "Tỉnh Đắk Nông",
		"code": "67",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35631",
		"name": "Điện Biên",
		"slug": "dien-bien",
		"type": "tinh",
		"name_with_type": "Tỉnh Điện Biên",
		"code": "11",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c3565a",
		"name": "Đồng Nai",
		"slug": "dong-nai",
		"type": "tinh",
		"name_with_type": "Tỉnh Đồng Nai",
		"code": "75",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35662",
		"name": "Đồng Tháp",
		"slug": "dong-thap",
		"type": "tinh",
		"name_with_type": "Tỉnh Đồng Tháp",
		"code": "87",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35653",
		"name": "Gia Lai",
		"slug": "gia-lai",
		"type": "tinh",
		"name_with_type": "Tỉnh Gia Lai",
		"code": "64",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c3562c",
		"name": "Hà Giang",
		"slug": "ha-giang",
		"type": "tinh",
		"name_with_type": "Tỉnh Hà Giang",
		"code": "02",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35641",
		"name": "Hà Nam",
		"slug": "ha-nam",
		"type": "tinh",
		"name_with_type": "Tỉnh Hà Nam",
		"code": "35",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c3562b",
		"name": "Hà Nội",
		"slug": "ha-noi",
		"type": "thanh-pho",
		"name_with_type": "Thành phố Hà Nội",
		"code": "01",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35646",
		"name": "Hà Tĩnh",
		"slug": "ha-tinh",
		"type": "tinh",
		"name_with_type": "Tỉnh Hà Tĩnh",
		"code": "42",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c3563d",
		"name": "Hải Dương",
		"slug": "hai-duong",
		"type": "tinh",
		"name_with_type": "Tỉnh Hải Dương",
		"code": "30",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c3563e",
		"name": "Hải Phòng",
		"slug": "hai-phong",
		"type": "thanh-pho",
		"name_with_type": "Thành phố Hải Phòng",
		"code": "31",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35666",
		"name": "Hậu Giang",
		"slug": "hau-giang",
		"type": "tinh",
		"name_with_type": "Tỉnh Hậu Giang",
		"code": "93",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35635",
		"name": "Hoà Bình",
		"slug": "hoa-binh",
		"type": "tinh",
		"name_with_type": "Tỉnh Hoà Bình",
		"code": "17",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c3565c",
		"name": "Hồ Chí Minh",
		"slug": "ho-chi-minh",
		"type": "thanh-pho",
		"name_with_type": "Thành phố Hồ Chí Minh",
		"code": "79",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c3563f",
		"name": "Hưng Yên",
		"slug": "hung-yen",
		"type": "tinh",
		"name_with_type": "Tỉnh Hưng Yên",
		"code": "33",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c3564f",
		"name": "Khánh Hòa",
		"slug": "khanh-hoa",
		"type": "tinh",
		"name_with_type": "Tỉnh Khánh Hòa",
		"code": "56",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35664",
		"name": "Kiên Giang",
		"slug": "kien-giang",
		"type": "tinh",
		"name_with_type": "Tỉnh Kiên Giang",
		"code": "91",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35652",
		"name": "Kon Tum",
		"slug": "kon-tum",
		"type": "tinh",
		"name_with_type": "Tỉnh Kon Tum",
		"code": "62",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35632",
		"name": "Lai Châu",
		"slug": "lai-chau",
		"type": "tinh",
		"name_with_type": "Tỉnh Lai Châu",
		"code": "12",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35637",
		"name": "Lạng Sơn",
		"slug": "lang-son",
		"type": "tinh",
		"name_with_type": "Tỉnh Lạng Sơn",
		"code": "20",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35630",
		"name": "Lào Cai",
		"slug": "lao-cai",
		"type": "tinh",
		"name_with_type": "Tỉnh Lào Cai",
		"code": "10",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35656",
		"name": "Lâm Đồng",
		"slug": "lam-dong",
		"type": "tinh",
		"name_with_type": "Tỉnh Lâm Đồng",
		"code": "68",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c3565d",
		"name": "Long An",
		"slug": "long-an",
		"type": "tinh",
		"name_with_type": "Tỉnh Long An",
		"code": "80",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35642",
		"name": "Nam Định",
		"slug": "nam-dinh",
		"type": "tinh",
		"name_with_type": "Tỉnh Nam Định",
		"code": "36",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35645",
		"name": "Nghệ An",
		"slug": "nghe-an",
		"type": "tinh",
		"name_with_type": "Tỉnh Nghệ An",
		"code": "40",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35643",
		"name": "Ninh Bình",
		"slug": "ninh-binh",
		"type": "tinh",
		"name_with_type": "Tỉnh Ninh Bình",
		"code": "37",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35650",
		"name": "Ninh Thuận",
		"slug": "ninh-thuan",
		"type": "tinh",
		"name_with_type": "Tỉnh Ninh Thuận",
		"code": "58",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c3563a",
		"name": "Phú Thọ",
		"slug": "phu-tho",
		"type": "tinh",
		"name_with_type": "Tỉnh Phú Thọ",
		"code": "25",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c3564e",
		"name": "Phú Yên",
		"slug": "phu-yen",
		"type": "tinh",
		"name_with_type": "Tỉnh Phú Yên",
		"code": "54",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35647",
		"name": "Quảng Bình",
		"slug": "quang-binh",
		"type": "tinh",
		"name_with_type": "Tỉnh Quảng Bình",
		"code": "44",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c3564b",
		"name": "Quảng Nam",
		"slug": "quang-nam",
		"type": "tinh",
		"name_with_type": "Tỉnh Quảng Nam",
		"code": "49",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c3564c",
		"name": "Quảng Ngãi",
		"slug": "quang-ngai",
		"type": "tinh",
		"name_with_type": "Tỉnh Quảng Ngãi",
		"code": "51",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35638",
		"name": "Quảng Ninh",
		"slug": "quang-ninh",
		"type": "tinh",
		"name_with_type": "Tỉnh Quảng Ninh",
		"code": "22",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35648",
		"name": "Quảng Trị",
		"slug": "quang-tri",
		"type": "tinh",
		"name_with_type": "Tỉnh Quảng Trị",
		"code": "45",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35667",
		"name": "Sóc Trăng",
		"slug": "soc-trang",
		"type": "tinh",
		"name_with_type": "Tỉnh Sóc Trăng",
		"code": "94",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35633",
		"name": "Sơn La",
		"slug": "son-la",
		"type": "tinh",
		"name_with_type": "Tỉnh Sơn La",
		"code": "14",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35658",
		"name": "Tây Ninh",
		"slug": "tay-ninh",
		"type": "tinh",
		"name_with_type": "Tỉnh Tây Ninh",
		"code": "72",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35640",
		"name": "Thái Bình",
		"slug": "thai-binh",
		"type": "tinh",
		"name_with_type": "Tỉnh Thái Bình",
		"code": "34",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35636",
		"name": "Thái Nguyên",
		"slug": "thai-nguyen",
		"type": "tinh",
		"name_with_type": "Tỉnh Thái Nguyên",
		"code": "19",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35644",
		"name": "Thanh Hóa",
		"slug": "thanh-hoa",
		"type": "tinh",
		"name_with_type": "Tỉnh Thanh Hóa",
		"code": "38",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35649",
		"name": "Thừa Thiên Huế",
		"slug": "thua-thien-hue",
		"type": "tinh",
		"name_with_type": "Tỉnh Thừa Thiên Huế",
		"code": "46",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c3565e",
		"name": "Tiền Giang",
		"slug": "tien-giang",
		"type": "tinh",
		"name_with_type": "Tỉnh Tiền Giang",
		"code": "82",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35660",
		"name": "Trà Vinh",
		"slug": "tra-vinh",
		"type": "tinh",
		"name_with_type": "Tỉnh Trà Vinh",
		"code": "84",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c3562f",
		"name": "Tuyên Quang",
		"slug": "tuyen-quang",
		"type": "tinh",
		"name_with_type": "Tỉnh Tuyên Quang",
		"code": "08",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35661",
		"name": "Vĩnh Long",
		"slug": "vinh-long",
		"type": "tinh",
		"name_with_type": "Tỉnh Vĩnh Long",
		"code": "86",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c3563b",
		"name": "Vĩnh Phúc",
		"slug": "vinh-phuc",
		"type": "tinh",
		"name_with_type": "Tỉnh Vĩnh Phúc",
		"code": "26",
		"isDeleted": false
	},
	{
		"_id": "60eaaa6f1173335842c35634",
		"name": "Yên Bái",
		"slug": "yen-bai",
		"type": "tinh",
		"name_with_type": "Tỉnh Yên Bái",
		"code": "15",
		"isDeleted": false
	}
];
