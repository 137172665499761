/* eslint-disable */
import { ref } from 'vue';
import { $api } from '@/services';
import store from '@/store';

const error: any = ref(null);
const loading = ref(false);

export async function resendInviteStaff(id: string, email: string) {
	error.value = null;
	try {
		const res = await $api.staff.resendInviteStaff(id, email);
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
		await store.dispatch('setAlertMessage', {
			type: 'danger',
			message: error.value[0],
		});
	} finally {
		loading.value = false;
	}
}

export function useResendInviteStaff() {
	return { resendInviteStaff__error: error, loading, resendInviteStaff };
}
