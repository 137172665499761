
import Rating from '@/components/elements/rating/Rating.vue';
import { Options, Vue, setup } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import SideOver from '@/components/elements/sideOver/SideOver.vue';
import { useStore } from 'vuex';
import dayjs from 'dayjs';
import DaInfinityLoading from '@/components/common/daInfinityLoading/DaInfinityLoading.vue';
import { useGetEmployees } from '@/views/record/employee/services/getEmployees';
import { useStudent } from '@/features/student/useStudent';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { useListDayCareReport } from '@/features/lessonReport/listDayCareReport';
import Loading from '@/components/common/Loading.vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import DeleteConfirmModal from '@/views/report/features/dayCareClasses/DeleteConfirm.vue';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import { $api } from '@/services';

@Options({
	name: 'DayCareClasses',
	components: {
		DaModalFrame,
		DeleteConfirmModal,
		Loading,
		DaInfinityLoading,
		SideOver,
		Rating,
		DaIcon,
		Menu,
		MenuButton,
		MenuItem,
		MenuItems,
	},
})
export default class DayCareClasses extends Vue {
	sideOverShow = false;
	filter: any = null;

	lessonReports = [] as any;
	dateNow = new Date();
	dateFilter = '';
	before = null;
	isFilterDate = false;
	stopLoad = false;
	staffs: any[] = [];
	students: any[] = [];
	isInfinityLoading = true;
	isLoading = true;
	alertShow = true;
	modalIsOpen = false;
	dataDelete = null;
	isHides: any = [];

	//----------------------- 🤍 Setup 🤍 -----------------------//
	setup = setup(() => {
		const store = useStore();
		const { getStudents } = useStudent();
		const { getEmployees } = useGetEmployees();
		const { listDayCareReport } = useListDayCareReport();
		return {
			getEmployees,
			getStudents,
			listDayCareReport,
			store,
		};
	});

	get isAdmin() {
		return this.setup.store.getters.isAdmin;
	}

	get store() {
		return this.setup.store;
	}

	get org() {
		return this.setup.store.state.profile.current_org;
	}

	get has_day_care() {
		return this.setup.store.state.profile.current_org?.has_day_care;
	}

	//----------------------- 🤍 Created 🤍 -----------------------//
	created() {
		if (!this.has_day_care) {
			this.$router.push('/');
		}
		const q: any = this.$route.query;
		this.dateFilter = q.date ? dayjs(q.date).format('DD/MM/YYYY') : '';
		this.listDayCareReport();
		this.search();
		this.fetchStudents();
	}

	//----------------------- 🤍 Watch 🤍 -----------------------//
	@Watch('$route')
	onRouterChange(value: any) {
		if (value?.name === 'report_day-care') {
			this.lessonReports = [];
			this.listDayCareReport();
		}
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	async listDayCareReport() {
		const q = this.$route.query;
		const res = await this.setup.listDayCareReport(
			q,
			this.before,
			!this.isAdmin
		);
		if (res?.data?.length > 0) {
			let day_care_classes = res?.day_care_classes || [];
			// @ts-ignore
			this.lessonReports.push({
				date: res?.date,
				data: this.getClassDayCare(res?.data, day_care_classes),
				stats: res?.stats,
			});
			this.before = res?.date;
		} else {
			this.stopLoad = true;
		}

		this.isLoading = false;
	}

	async search() {
		const res = await this.setup.getEmployees('');
		this.staffs = res?.data || [];
	}

	async fetchStudents() {
		let role =
			this.store.state.profile.staff.role === 'teacher'
				? 'staff'
				: 'org_admin';
		const studentList = await this.setup.getStudents(role, {});
		this.students = studentList.data;
	}

	getClassDayCare(array: any, day_care_classes: { id: string }[]) {
		return array.map((item: any) => {
			let dayCare: any = day_care_classes.find(
				(element) => element.id === item.day_care_class_id
			);
			return {
				...item,
				dayCareClass: dayCare?.name,
			};
		});
	}

	studentById(id: string) {
		return this.students.find((item: { id: string }) => item.id === id);
	}

	staffById(id: string) {
		return this.staffs.find((item: { id: string }) => item.id === id);
	}

	clean(obj: any) {
		for (let propName in obj) {
			if (
				obj[propName] === null ||
				obj[propName] === undefined ||
				obj[propName].length <= 0
			) {
				delete obj[propName];
			}
		}
		return obj;
	}

	filterSubmit(queryPa: any) {
		this.before = null;
		if (!queryPa) {
			this.dateFilter = dayjs(this.dateNow).format('DD/MM/YYYY');
			this.filter = null;
			this.$router.push('');
		} else {
			this.filter = {
				...this.filter,
				...queryPa,
			};
			this.clean(this.filter);
			const query = Object.keys(this.filter)
				.map((key) => `${key}=${this.filter[key]}`)
				.join('&');
			this.$router.push(`?${query}`);
		}
		this.sideOverShow = false;
	}

	showHide(date: any) {
		const index = this.isHides.indexOf(date);
		if (index !== -1) {
			this.isHides.splice(index, 1);
		} else {
			this.isHides.push(date);
		}
	}

	openDeleteConfirm(data: any) {
		this.dataDelete = data;
		this.modalIsOpen = true;
	}

	closeModal() {
		this.dataDelete = null;
		this.modalIsOpen = false;
	}

	async success() {
		this.closeModal();
		this.before = null;
		this.lessonReports = [];
		await this.listDayCareReport();
	}

	async sendReport(item: any) {
		try {
			await $api.lessonReport.sendDaycareReport(item?.id);
			this.closeModal();
			await this.store.dispatch('setAlertMessage', {
				message: 'Báo cáo đã được gửi thành công đến phụ huynh.',
			});
			item.sent_at = new Date();
		} catch (err) {
			await this.store.dispatch('setAlertMessage', {
				message:
					err?.response?.data?.errors?.join('<br/>') ||
					'Đã có lỗi xảy ra. Vui lòng thử lại',
				type: 'danger',
			});
		}
	}

	async recoverReport(id: any) {
		try {
			const res = await $api.lessonReport.recoverReport(id);
			if (res.data) {
				await this.store.dispatch('setAlertMessage', {
					message: 'Khôi phục báo cáo thành công',
				});
			}
		} catch (err) {
			await this.store.dispatch('setAlertMessage', {
				message:
					err?.response?.data?.errors?.join('<br/>') ||
					'Đã có lỗi xảy ra. Vui lòng thử lại',
				type: 'danger',
			});
		}
	}

	beforeDestroy() {
		this.isInfinityLoading = false;
	}
}
