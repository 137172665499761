<template>
	<div :class="sideOverShow && type === 'updated' ? 'side-over--active' : ''">
		<div
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			v-if="sideOverShow && type === 'updated'"
			@click="close"
		></div>
		<UpdateSkillUsing
			@close="close"
			:selected="skillSelected"
			@refetch="reloadData"
			:is-open="sideOverShow && type === 'updated'"
		/>
	</div>
	<div
		:class="
			sideOverShow && type === 'updatedSkill' ? 'side-over--active' : ''
		"
	>
		<div
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			v-if="sideOverShow && type === 'updatedSkill'"
			@click="close"
		></div>
		<UpdateSkill
			@refetch="reloadData"
			@close="close"
			:is-open="sideOverShow && type === 'updatedSkill'"
		/>
	</div>
	<DaModalFrame v-model:open="modalIsOpen">
		<AddSkill @close="closeAddSkill" @created="reloadData" />
	</DaModalFrame>
	<div class="h-full w-full">
		<div
			class="
				__setup-target-smart
				__section
				shadow-sm
				bg-white
				overflow-hidden
			"
		>
			<div
				class="
					__section__header
					p-6
					border-b
					flex
					items-center
					justify-between
				"
			>
				<div class="font-semibold text-neutral-600">
					Lựa chọn lĩnh vực
					<div
						class="
							font-normal
							leading-5
							text-sm
							mt-2
							text-neutral-500
						"
					>
						Chọn các lĩnh vực mà cơ sở muốn sử dụng trong việc lập
						kế hoạch hoặc báo cáo.
					</div>
				</div>
				<div>
					<Menu as="div" class="ml-3 relative">
						<div>
							<MenuButton
								class="
									rounded-full
									flex
									text-sm
									focus:outline-none
									focus:ring-2
									focus:ring-offset-2
									focus:ring-indigo-500
								"
							>
								<span class="sr-only">Open user menu</span>
								<svg
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M11.875 16.25C11.875 17.2855 11.0355 18.125 10 18.125C8.96447 18.125 8.125 17.2855 8.125 16.25C8.125 15.2145 8.96447 14.375 10 14.375C11.0355 14.375 11.875 15.2145 11.875 16.25ZM11.875 10C11.875 11.0355 11.0355 11.875 10 11.875C8.96447 11.875 8.125 11.0355 8.125 10C8.125 8.96447 8.96447 8.125 10 8.125C11.0355 8.125 11.875 8.96447 11.875 10ZM11.875 3.75C11.875 4.78553 11.0355 5.625 10 5.625C8.96447 5.625 8.125 4.78553 8.125 3.75C8.125 2.71447 8.96447 1.875 10 1.875C11.0355 1.875 11.875 2.71447 11.875 3.75Z"
										fill="#94A3B8"
									/>
								</svg>
							</MenuButton>
						</div>
						<transition
							enter-active-class="transition ease-out duration-200"
							enter-from-class="transform opacity-0 scale-95"
							enter-to-class="transform opacity-100 scale-100"
							leave-active-class="transition ease-in duration-75"
							leave-from-class="transform opacity-100 scale-100"
							leave-to-class="transform opacity-0 scale-95"
						>
							<MenuItems
								class="
									origin-top-right
									absolute
									right-0
									mt-2
									w-60
									rounded-md
									py-1
									bg-white
									focus:outline-none
									z-10
									shadow-sm
									border border-yellow-100
								"
							>
								<MenuItem v-slot="{ active }">
									<div
										class="
											p-2
											px-4
											bg-white
											z-10
											text-gray-600
											hover:bg-neutral-50
											flex
											items-center
											gap-4
											cursor-pointer
										"
										:class="active ? '' : ''"
										@click="openAddSkill"
									>
										<svg
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
												fill="#94A3B8"
											/>
										</svg>
										<span>Thêm lĩnh vực mới</span>
									</div>
								</MenuItem>
								<MenuItem v-slot="{ active }">
									<div
										class="
											p-2
											px-4
											bg-white
											z-10
											text-gray-600
											hover:bg-neutral-50
											flex
											items-center
											gap-4
											cursor-pointer
										"
										:class="active ? '' : ''"
										@click="open('updatedSkill')"
									>
										<svg
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M12.4702 6.65087L13.7102 5.41084C13.8461 5.27498 13.914 5.20705 13.9667 5.14509C14.6009 4.39841 14.6009 3.30223 13.9667 2.55555C13.914 2.49359 13.8461 2.42566 13.7102 2.2898C13.5744 2.15394 13.5064 2.08601 13.4445 2.03338C12.6978 1.39915 11.6016 1.39915 10.855 2.03338C10.793 2.086 10.7251 2.15391 10.5893 2.2897L10.5892 2.28978L10.5892 2.2898L9.33099 3.54801C10.0747 4.84221 11.1583 5.91748 12.4702 6.65087ZM7.87633 5.00267L2.86273 10.0163C2.43767 10.4413 2.22514 10.6539 2.0854 10.915C1.94567 11.1761 1.88672 11.4708 1.76883 12.0602L1.37318 14.0385C1.30666 14.3711 1.27339 14.5374 1.368 14.632C1.46261 14.7266 1.62892 14.6934 1.96153 14.6269L3.9398 14.2312L3.93982 14.2312L3.93984 14.2312C4.52927 14.1133 4.82399 14.0544 5.08508 13.9146C5.34617 13.7749 5.55869 13.5624 5.98373 13.1373L5.98376 13.1373L5.98377 13.1373L11.0118 8.10931C9.75111 7.3111 8.68202 6.24922 7.87633 5.00267Z"
												fill="#94A3B8"
											/>
										</svg>
										<span>Chỉnh sửa lĩnh vực</span>
									</div>
								</MenuItem>
							</MenuItems>
						</transition>
					</Menu>
				</div>
			</div>
			<div class="__section__content p-6">
				<div class="grid grid-cols-1 md:grid-cols-3 gap-8">
					<div
						class="flex items-center justify-start gap-4"
						v-for="(skill, index) in skills"
						:key="index"
					>
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M16.6666 5L7.49992 14.1667L3.33325 10"
								stroke="#2AA797"
								stroke-width="1.66667"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
						<span class="text-neutral-700">{{ skill.name }}</span>
					</div>
				</div>
			</div>
			<div class="__section__footer p-6 flex justify-end">
				<button
					type="button"
					class="button-p text-center whitespace-nowrap"
					@click="open('updated')"
				>
					Lựa chọn lĩnh vực
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { $api } from '@/services';
import UpdateSkillUsing from '@/components/modules/allSkills/UpdateSkillUsing';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import AddSkill from '@/views/setupGoals/features/AddSkill';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame';
import UpdateSkill from '@/views/setupGoals/features/UpdateSkill';

export default {
	name: 'SetupGoals',

	components: {
		UpdateSkill,
		DaModalFrame,
		AddSkill,
		UpdateSkillUsing,
		MenuItems,
		MenuItem,
		Menu,
		MenuButton,
	},

	data() {
		return {
			skills: [],
			skillSelected: [],
			sideOverShow: false,
			type: 'updated',
			modalIsOpen: false,
		};
	},

	created() {
		this.fetchSkills();
	},

	computed: {
		isAdmin() {
			return this.$store.getters.isAdmin;
		},
	},

	methods: {
		async fetchSkills() {
			try {
				const {
					data: { data },
				} = await $api.goal.allSkills();
				this.skills = data || [];
			} catch (err) {
				console.log(err);
			}
		},

		open(type) {
			this.type = type;
			this.skillSelected = [...this.skills];
			this.sideOverShow = true;
		},

		close() {
			this.sideOverShow = false;
			this.type = '';
			this.skillSelected = [];
		},

		openAddSkill() {
			this.modalIsOpen = true;
		},

		closeAddSkill() {
			this.modalIsOpen = false;
		},

		reloadData() {
			this.fetchSkills();
			this.closeAddSkill();
			this.close();
		},
	},
};
</script>
