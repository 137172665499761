
import { computed, defineComponent, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { $api } from '@/services';
import { useStore } from 'vuex';
import { useStudent } from '@/features/student/useStudent';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import DeleteConfirm from '@/components/modules/reportTargetFuture/DeleteConfirm.vue';
import EditGoalSideOver from '@/components/elements/editGoalSideOver/EditGoalSideOver.vue';
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail.vue';
import Popper from 'vue3-popper';

export default defineComponent({
	name: 'PlanFutureSlideOver',
	components: {
		TooltipGoalDetail,
		EditGoalSideOver,
		DeleteConfirm,
		DaModalFrame,
		Popper,
	},
	props: {
		isCompleted: {
			type: Boolean,
			default: false,
		},
		isUpdated: {
			type: Boolean,
			default: false,
		},
	},

	setup(props: any, { emit }: any) {
		const route = useRoute();
		const store = useStore();

		const id = computed(() =>
			route.params?.id ? route.params?.id + '' : ''
		);

		//
		const data = ref(null as any);
		const goals = ref(null as any);
		const goals_count = ref(0);
		const skills_count = ref(0);
		const continued_goals_count = ref(0);
		const goals_from_lib_count = ref(0);
		const goals_from_stopped_count = ref(0);
		const new_goals_count = ref(0);

		async function planSchedule() {
			try {
				const {
					data: { data: data_report, stats, skills },
				} = await $api.monthlyReport.planActive(id.value);
				data.value = data_report;
				goals.value = skills.continued;
				goals_count.value = stats.goals_count;
				skills_count.value = stats.skills_count;
				continued_goals_count.value = stats.continued_goals_count;
				goals_from_lib_count.value = stats.goals_from_lib_count;
				goals_from_stopped_count.value = stats.goals_from_stopped_count;
				new_goals_count.value = stats.new_goals_count;
			} catch (e) {
				console.log(e);
			}
		}

		const plan_id = computed(() =>
			route.params?.plan_id ? String(route.params?.plan_id) : ''
		);

		const student_id = computed(() =>
			route.params?.id ? route.params?.id + '' : ''
		);

		async function deleteGoal(id: string) {
			try {
				await $api.goal.deleteGoal(id, student_id.value, plan_id.value);
				await planSchedule();
				await store.dispatch('setAlertMessage', {
					message: 'Đã xoá mục tiêu thành công',
				});
				isSuccess.value = true;
				await store.dispatch('removeGoal', id);
				closeModal();
			} catch (e) {
				await store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		}

		watch(
			() => props.isUpdated,
			(value: boolean) => {
				if (value) {
					planSchedule();
					emit('updated');
				}
			}
		);

		//
		const { goalCompletedByStudent } = useStudent();

		const goals__completed = ref([]);
		const skills_count__completed = ref(0);
		const goals_count__completed = ref(0);

		//
		async function fetchGoals() {
			const res = await goalCompletedByStudent(id.value);
			if (res?.data) {
				goals__completed.value = res?.data.skills ?? [];
				skills_count__completed.value = res?.data.length;
				goals__completed.value.forEach((item: any) => {
					goals_count__completed.value += item?.goals.length || 0;
				});
			}
		}

		const modalIsOpen = ref(false);
		const idSelected = ref('');
		const isSuccess = ref(false);
		const isNoRepeat = ref(false);
		const isReteach = ref(false);
		const isNoRepeatReTeach = ref(false);
		const isEditModal = ref(false);

		function deleting(id: string, reteach = false) {
			if (reteach && isNoRepeatReTeach.value) {
				deleteGoal(id);
				closeModal();
				return;
			}
			if (!reteach && isNoRepeat.value) {
				deleteGoal(id);
				closeModal();
				return;
			}
			idSelected.value = id;
			modalIsOpen.value = true;
			isSuccess.value = false;
			isEditModal.value = false;
			isReteach.value = reteach;
		}

		let editData = ref(null as any);
		const tabActive = ref(null as any);

		async function editGoalModal(data: any) {
			editData.value = {
				id: data?.id || data?.goal_id,
				name: data?.name || data?.goal_name,
			};
			isEditModal.value = true;
			if (!data?.editable) {
				tabActive.value = 1;
			} else {
				tabActive.value = null;
			}
		}

		function closeModal() {
			modalIsOpen.value = false;
			idSelected.value = '';
			isSuccess.value = false;
			isEditModal.value = false;
		}

		function closeModelParent() {
			if (isReteach.value) {
				isNoRepeatReTeach.value = false;
			} else {
				isNoRepeat.value = false;
			}
			closeModal();
		}

		const errorEdit = ref('');

		async function editSubmit(data: any) {
			try {
				const res = await $api.goal.updateGoal(id.value, data.id, {
					goal: data,
				});
				if (res?.data) {
					closeModal();
					await planSchedule();
					await store.dispatch('setAlertMessage', {
						message: 'Chỉnh sửa mục tiêu thành công',
					});
				}
			} catch (e) {
				errorEdit.value = e?.response?.data?.errors_by_field || '';
			}
		}

		const skills = ref([]);

		async function fetchSkill() {
			try {
				const res = await $api.goal.allSkills();
				if (res?.data) {
					skills.value = res?.data?.data;
				}
			} catch (e) {
				console.log(e);
			}
		}

		const isShow = ref(true);

		fetchGoals();
		planSchedule();
		fetchSkill();

		return {
			id,
			isShow,
			isReteach,
			isNoRepeat,
			isNoRepeatReTeach,
			modalIsOpen,
			idSelected,
			isSuccess,
			errorEdit,
			editData,
			isEditModal,
			skills,
			deleting,
			closeModal,
			closeModelParent,
			editSubmit,
			editGoalModal,
			data,
			goals,
			goals_count,
			skills_count,
			continued_goals_count,
			goals_from_lib_count,
			goals_from_stopped_count,
			new_goals_count,
			goals__completed,
			skills_count__completed,
			goals_count__completed,
			stop,
			deleteGoal,
			tabActive,
			org: computed(() => store.state.profile.current_org),
		};
	},
});
