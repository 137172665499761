<template>
	<div
		class="
			fixed
			inset-y-0
			right-0
			pl-10
			flex
			side-over
			z-50
			overflow-y-auto
		"
		style="width: 720px"
	>
		<div
			class="h-full w-full flex flex-col bg-white shadow-xl"
			style="min-height: 100vh; height: fit-content"
		>
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M17.3005 10.5H23.1992C23.5172 10.5 23.6909 10.8708 23.4873 11.1151L20.538 14.6543C20.388 14.8342 20.1117 14.8342 19.9618 14.6543L17.0124 11.1151C16.8089 10.8708 16.9826 10.5 17.3005 10.5Z"
								fill="#94A3B8"
							/>
							<path
								d="M0.800522 13.5H6.69924C7.01718 13.5 7.19086 13.1292 6.98732 12.8849L4.03796 9.3457C3.88804 9.16579 3.61172 9.16579 3.4618 9.3457L0.512438 12.8849C0.308899 13.1292 0.482583 13.5 0.800522 13.5Z"
								fill="#94A3B8"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M11.9999 4.5C9.67114 4.5 7.59073 5.56045 6.21391 7.22758C5.95015 7.54696 5.47742 7.59205 5.15804 7.32829C4.83866 7.06452 4.79358 6.59179 5.05734 6.27242C6.70689 4.27504 9.20481 3 11.9999 3C16.4124 3 20.0819 6.17476 20.8515 10.3645C20.8598 10.4096 20.8677 10.4547 20.8753 10.5H19.35C18.6555 7.07667 15.6278 4.5 11.9999 4.5ZM4.64976 13.5C5.34428 16.9233 8.37194 19.5 11.9999 19.5C14.3286 19.5 16.409 18.4396 17.7859 16.7724C18.0496 16.453 18.5223 16.408 18.8417 16.6717C19.1611 16.9355 19.2062 17.4082 18.9424 17.7276C17.2929 19.725 14.795 21 11.9999 21C7.58733 21 3.91785 17.8252 3.14826 13.6355C3.13998 13.5904 3.13204 13.5453 3.12444 13.5H4.64976Z"
								fill="#94A3B8"
							/>
						</svg>
						<div class="text-2xl font-bold text-neutral-700 ml-2">
							Chỉnh sửa lĩnh vực
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							type="button"
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								class="h-6 w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div class="mt-6 relative flex-1 px-4 sm:px-6">
				<div>
					<div class="font-semibold text-neutral-600 mb-4">
						Chọn lĩnh vực*
					</div>
					<div class="select-tabs mb-6">
						<Multiselect
							v-if="skills.length > 0"
							v-model="skillSelected"
							deselect-label="Đã chọn"
							track-by="name"
							:options="
								skills?.map((s) => {
									return {
										nameSearch: toEnglish(s?.name),
										...s,
									};
								})
							"
							:searchable="true"
							label="nameSearch"
							:allow-empty="false"
							selectLabel=""
							placeholder="Chọn lĩnh vực (*)"
						>
							<template #noResult>
								Không tìm thấy kết quả
							</template>
							<template v-slot:option="props">
								<div class="flex flex-nowrap items-center">
									<div>
										{{ props.option.name }}
									</div>
								</div>
							</template>
							<template v-slot:singleLabel="{ option }">
								<div
									class="
										flex flex-nowrap
										items-center
										font-medium
									"
								>
									<div>{{ option.name }}</div>
								</div>
							</template>
						</Multiselect>
					</div>
				</div>
				<div
					class="my-4 flex justify-end font-semibold text-neutral-400"
				>
					<div>Đã chọn {{ selected.length }} mục tiêu</div>
				</div>
				<div class="mb-10">
					<div class="shadow-sm sm:rounded-2xl overflow-hidden">
						<div class="__record__table scroll-bar">
							<div class="sm:rounded-t-2xl w-fit min-w-full">
								<table
									class="
										min-w-full
										divide-y
										text-neutral-200
										rounded-2xl
									"
								>
									<thead
										class="bg-neutral-100 h-16 rounded-2xl"
									>
										<tr>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													w-16
													whitespace-nowrap
												"
											>
												STT
											</th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												<div
													class="
														flex
														items-center
														gap-1
														justify-between
														cursor-pointer
													"
												>
													Tên mục tiêu
												</div>
											</th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												<div
													class="
														flex
														items-center
														gap-1
														justify-between
														cursor-pointer
													"
												>
													lĩnh vực
													<svg
														width="16"
														height="16"
														viewBox="0 0 16 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M7.99999 2.39999C8.21216 2.39999 8.41564 2.48428 8.56567 2.63431L10.9657 5.03431C11.2781 5.34673 11.2781 5.85326 10.9657 6.16568C10.6533 6.4781 10.1467 6.4781 9.8343 6.16568L7.99999 4.33136L6.16567 6.16568C5.85325 6.4781 5.34672 6.4781 5.0343 6.16568C4.72188 5.85326 4.72188 5.34673 5.0343 5.03431L7.4343 2.63431C7.58433 2.48428 7.78782 2.39999 7.99999 2.39999ZM5.0343 9.83431C5.34672 9.52189 5.85325 9.52189 6.16567 9.83431L7.99999 11.6686L9.8343 9.83431C10.1467 9.52189 10.6533 9.52189 10.9657 9.83431C11.2781 10.1467 11.2781 10.6533 10.9657 10.9657L8.56567 13.3657C8.25325 13.6781 7.74672 13.6781 7.4343 13.3657L5.0343 10.9657C4.72188 10.6533 4.72188 10.1467 5.0343 9.83431Z"
															fill="#94A3B8"
														/>
													</svg>
												</div>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(goal, i) in selected"
											:key="goal.id"
											:class="
												i % 2 === 0
													? 'bg-white'
													: 'bg-neutral-50'
											"
											class="text-neutral-700"
										>
											<td
												class="
													px-6
													py-4
													whitespace-nowrap
													text-neutral-700
												"
											>
												<div class="ml-2">
													{{ i + 1 }}
												</div>
											</td>
											<td
												class="
													px-6
													py-4
													text-neutral-700
												"
											>
												<div
													class="
														flex
														items-center
														gap-2
													"
												>
													{{ goal?.name }}
												</div>
											</td>
											<td
												class="
													px-6
													py-4
													text-neutral-700
												"
												style="width: 300px"
											>
												<div
													class="
														flex
														items-center
														gap-2
													"
												>
													{{
														skills.find(
															(s) =>
																s.id ===
																goal.skill_id
														)?.name || '-'
													}}
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="px-6 py-4 border-t border-yellow-100">
				<div class="flex justify-end">
					<div>
						<button
							:disabled="!skillSelected"
							type="button"
							class="button-p text-center w-32"
							@click="submit"
						>
							Xác nhận
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Multiselect from '@suadelabs/vue3-multiselect';
import { toEnglish } from '@/helper/toEnglish';
import { $api } from '@/services';

export default {
	name: 'UpdateGoalSkills',

	components: { Multiselect },

	props: {
		skills: {
			type: Array,
			required: true,
		},

		selected: {
			type: Array,
			required: true,
		},

		isOpen: {
			type: Boolean,
			required: true,
		},
	},

	computed: {
		id() {
			return this.$route?.params?.id || '';
		},
	},

	watch: {
		isOpen: {
			immediate: true,
			handler() {
				this.skillSelected = null;
			},
		},
	},

	data() {
		return {
			skillSelected: null,
		};
	},

	methods: {
		toEnglish,
		async submit() {
			try {
				await $api.goal.updateSkillInGoals(this.id, {
					library_item_ids: this.selected.map((i) => i.id),
					skill_id: this.skillSelected.id,
				});
				this.$emit('updated');
				this.$store.dispatch('setAlertMessage', {
					message: 'Chỉnh sửa lĩnh vực thành công',
				});
			} catch (err) {
				this.$store.dispatch('setAlertMessage', {
					message: err?.response?.data?.errors.join('<br/>'),
					type: 'danger',
				});
			}
		},
	},
};
</script>
