
import Rating from '@/components/elements/rating/Rating.vue';
import { Options, Vue, setup } from 'vue-class-component';
import { useListLessonReportOldData } from '@/features/lessonReport/listLessonReportOldData';
import { useStore } from 'vuex';
import DaInfinityLoading from '@/components/common/daInfinityLoading/DaInfinityLoading.vue';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import Loading from '@/components/common/Loading.vue';
import { _GET_DATE_MM_YYYY } from '@/helper/formatDate';

@Options({
	name: 'ReportLesson',
	components: { Loading, DaInfinityLoading, Rating, DaIcon },
})
export default class ReportLesson extends Vue {
	LessonReports: any = [];
	date = null;
	stopLoad = false;
	staffs = [];
	students = [];
	isInfinityLoading = false;
	isLoading = true;

	//----------------------- 🤍 Setup 🤍 -----------------------//
	setup = setup(() => {
		const store = useStore();
		const { listLessonReportOldData } = useListLessonReportOldData();
		return {
			listLessonReportOldData,
			store,
		};
	});

	get id() {
		return this.$route.params.id;
	}

	get oldPayload() {
		return {
			'x-cross': this.$route.query.org_id,
		};
	}

	get isAdmin() {
		return this.setup.store.getters.isAdmin;
	}

	get org() {
		return this.setup.store.state.profile.current_org;
	}

	get store() {
		return this.setup.store;
	}

	get LessonReportsList() {
		return this.LessonReports;
	}

	//----------------------- 🤍 Created 🤍 -----------------------//
	created() {
		this.getLessonList();
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	async getLessonList() {
		try {
			const res = await this.setup.listLessonReportOldData(
				String(this.id || ''),
				this.oldPayload,
				this.date
			);
			if (res?.data.length > 0) {
				// @ts-ignore
				this.LessonReports.push({
					title: res.latest_date
						? `Tháng ${_GET_DATE_MM_YYYY(res.latest_date)}`
						: '',
					data: [...res?.data],
				});
				this.date = res?.previous_month;
			} else {
				this.stopLoad = true;
			}
			this.isLoading = false;
		} catch (e) {
			console.log(e);
		} finally {
			this.isInfinityLoading = true;
		}
	}

	clean(obj: any) {
		for (let propName in obj) {
			if (
				obj[propName] === null ||
				obj[propName] === undefined ||
				obj[propName].length <= 0
			) {
				delete obj[propName];
			}
		}
		return obj;
	}

	beforeDestroy() {
		this.isInfinityLoading = false;
	}
}
