
import { Options, Vue } from 'vue-class-component';
import { _MODAL_TYPE } from '@/enums';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';

@Options({
	name: 'LeaveReport',
	components: { DaIcon },
})
export default class LeaveReport extends Vue {
	//----------------------- 🤍 Data 🤍 -----------------------//
	successfully = true;
	leaveType: string | null = null;
	time = {
		hours: 9,
		minutes: 0,
	};

	date = new Date();

	//----------------------- 🤍 Getter 🤍 -----------------------//
	get ModalType() {
		return _MODAL_TYPE;
	}

	get title() {
		if (this.successfully) {
			return 'Báo nghỉ thành công';
		} else if (
			this.leaveType &&
			this.leaveType === this.ModalType.LEAVE_REPORT_LONG
		) {
			return 'Bạn muốn nghỉ phép dài ngày?';
		} else if (
			this.leaveType &&
			this.leaveType === this.ModalType.LEAVE_REPORT_SHORT
		) {
			return 'Bạn muốn nghỉ phép trong ngày?';
		} else {
			return 'Bạn muốn báo nghỉ?';
		}
	}

	get label() {
		if (this.successfully) {
			return (
				'Luca đã lưu lịch nghỉ của bạn & báo cho quản lý của cơ sở này. \n' +
				'Chúc bạn một kỳ nghỉ tốt lành!'
			);
		} else if (
			this.leaveType &&
			this.leaveType === this.ModalType.LEAVE_REPORT_LONG
		) {
			return 'Hãy chọn ngày bạn muốn báo nghỉ tại đây';
		} else if (
			this.leaveType &&
			this.leaveType === this.ModalType.LEAVE_REPORT_SHORT
		) {
			return 'Hãy chọn ngày và giờ bạn muốn báo nghỉ tại đây';
		} else {
			return 'Bạn hãy lựa chọn hình thức báo nghỉ tại đây';
		}
	}

	get exitLabelButton() {
		if (this.successfully) {
			return 'Đóng';
		} else if (this.leaveType) {
			return 'Quay lại';
		} else {
			return 'Nghỉ trong ngày';
		}
	}

	//----------------------- 🤍 Button 🤍 -----------------------//

	backOrClose() {
		if (this.leaveType && this.successfully) {
			return this.$emit('close');
		} else if (this.leaveType) {
			return (this.leaveType = null);
		} else {
			return (this.leaveType = this.ModalType.LEAVE_REPORT_SHORT);
		}
	}

	confirm() {
		if (this.leaveType) {
			return this.$emit('submit');
		} else {
			return (this.leaveType = this.ModalType.LEAVE_REPORT_LONG);
		}
	}
}
