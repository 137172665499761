
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { _ROUTER_NAME } from '@/enums';
import { useStudent } from '@/features/student/useStudent';
import Multiselect from '@suadelabs/vue3-multiselect';
import { _GET_DATE_2 } from '@/helper/formatDate';
import { _MONTH_DIFF } from '@/helper/monthDiff';

export default defineComponent({
	name: 'StudentDetail',
	components: { DaIcon, Multiselect },
	setup() {
		const id = ref('');
		const router = useRouter();
		const student: any = ref(null);
		const tab = ref({
			label: '',
			value: '',
		} as any);
		const tabs = ref([
			{
				label: 'Kế hoạch hiện tại',
				value: 'plans_report_detail__current',
			},
			{
				label: 'Kế hoạch dự kiến',
				value: 'plans_report_detail__future',
			},
			{
				label: 'Lịch sử kế hoạch',
				value: 'plans_report_detail__history_plan',
			},
			{
				label: 'Ngân hàng mục tiêu',
				value: 'plans_report_detail__goal_bank',
			},
			{
				label: 'Mục tiêu đã hoàn thành',
				value: 'plans_report_detail__goal_completed',
			},
			{
				label: 'Mục tiêu tạm dừng',
				value: 'plans_report_detail__goal_stop',
			},
			{
				label: 'Tất cả MT',
				value: 'plans_report_detail__all',
			},
		]);
		const route = useRoute();
		const queryData = route.query;
		id.value = route.params.id ? route.params.id + '' : '';
		const { getStudent } = useStudent();

		watch(route, () => {
			const name = route?.name;
			tab.value = tabs.value.find((item) => item.value == name);
		});

		watch(tab, (value: { value: string }) => {
			const query = route.query;
			if (value) {
				router.push({ name: value.value, query: { ...query } });
			}
		});

		onMounted(() => {
			const name = route?.name;
			tab.value = tabs.value.find((item) => item.value == name);
		});

		const oldPayload = ref({
			'x-cross': route.query.org_id,
		});

		const is_old_data = ref(route.query.is_old_data === 'true');

		async function fetchStudent() {
			const res = await getStudent(
				id.value,
				is_old_data.value ? oldPayload.value : null
			);
			if (res?.data) {
				student.value = res?.data;
			}
		}

		const dateData = computed(() => {
			const join_date = route.query.join_date;
			const left_date = route.query.left_date;
			if (!join_date) {
				return '';
			}
			return `${_GET_DATE_2(join_date)} - ${
				left_date ? _GET_DATE_2(left_date) : 'Hiện tại'
			} | Đã học ${_MONTH_DIFF(join_date, left_date)} tháng`;
		});

		async function viewReport() {
			const query = route.query;
			await router.push({
				path: `/student/${id.value}/old-data`,
				query: {
					...query,
				},
			});
		}

		function exit() {
			const new_id = route.query.new_id;
			const r = router.resolve({
				path: `/record/student/${new_id}/staff-list`,
			});
			window.location.assign(r.href);
		}

		function toDetail() {
			const query = route.query;
			const params = route.params;
			return router.push({
				path: `/record/student/${params?.id}/staff-list`,
				query: {
					...query,
				},
			});
		}

		fetchStudent();

		return {
			tab,
			tabs,
			student,
			routerName: computed(() => _ROUTER_NAME),
			queryData,
			dateData,
			is_old_data,
			viewReport,
			exit,
			toDetail,
		};
	},
});
