<template>
	<div class="_modal bg-white">
		<div
			class="
				_modal__content
				md-down:px-4
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<img
					src="@/assets/images/illustrations/link_send.png"
					alt="Luca Education"
					title="Luca Education"
					height="150"
					width="150"
				/>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
					text-center
				"
			>
				Chọn giáo viên dạy thay cho giáo viên bạn vừa xoá khỏi danh sách
				dạy học sinh?
			</div>
			<div class="text-center text-neutral-500 mt-2">
				Bạn hãy chọn giáo viên từ danh sách giáo viên của cơ sở
			</div>
		</div>
		<div class="w-full p-6">
			<div class="bg-white select-tabs">
				<Multiselect
					v-model="staffSelected"
					deselect-label="Đã chọn"
					track-by="id"
					:options="
						staffs?.map((s) => {
							return {
								nameSearch: toEnglish(s?.name),
								...s,
							};
						})
					"
					:searchable="true"
					label="nameSearch"
					:allow-empty="false"
					selectLabel=""
					placeholder="Chọn trị liệu viên"
				>
					<template #noResult> Không tìm thấy kết quả </template>
					<template v-slot:option="props">
						<div class="flex flex-nowrap items-center">
							<div>
								{{ props.option.name }}
							</div>
						</div>
					</template>
					<template v-slot:singleLabel="{ option }">
						<div class="flex flex-nowrap items-center font-medium">
							<div>{{ option.name }}</div>
						</div>
					</template>
				</Multiselect>
				<div class="mt-4 flex items-center gap-2">
					<Checkbox
						:value="checkBoxSelected"
						@click.stop="checkBoxSelected = !checkBoxSelected"
					/>
					<div
						class="cursor-pointer"
						@click.stop="checkBoxSelected = !checkBoxSelected"
					>
						Áp dụng vai trò Giáo viên phụ trách
					</div>
				</div>
			</div>
			<div
				v-if="timeSlots?.length"
				class="rounded-md bg-orange-100 py-3 px-4 mt-4"
			>
				<div class="flex gap-3">
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clip-path="url(#clip0_26617_7270)">
							<path
								d="M9.99984 18.3333C14.6022 18.3333 18.3332 14.6024 18.3332 10C18.3332 5.39763 14.6022 1.66667 9.99984 1.66667C5.39746 1.66667 1.6665 5.39763 1.6665 10C1.6665 14.6024 5.39746 18.3333 9.99984 18.3333Z"
								stroke="#F59F0A"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M10 13.3333V10"
								stroke="#F59F0A"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M10 6.66667H10.0083"
								stroke="#F59F0A"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</g>
						<defs>
							<clipPath id="clip0_26617_7270">
								<rect width="20" height="20" fill="white" />
							</clipPath>
						</defs>
					</svg>
					<div class="font-semibold text-orange-600">
						Có {{ timeSlots?.length }} buổi học bị trùng.
					</div>
				</div>
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				type="button"
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				@click="$emit('close')"
			>
				Quay lại
			</button>
			<button
				type="button"
				class="button-p text-center whitespace-nowrap"
				@click="submit"
				:disabled="!staffSelected"
			>
				Xác nhận
			</button>
		</div>
	</div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import { PropSync, Watch } from 'vue-property-decorator';
import Multiselect from '@suadelabs/vue3-multiselect';
import { toEnglish } from '@/helper/toEnglish';
import Checkbox from '@/components/elements/checkbox/Checkbox.vue';
import { $api } from '@/services';

@Options({
	name: 'ChangedStaffModal',
	components: { Checkbox, Multiselect },
})
export default class CreateStaffModal extends Vue {
	@PropSync('data')
	staffs;

	@PropSync('deletedData')
	deletedData;

	staffSelected = null;
	checkBoxSelected = false;
	timeSlots = [];

	@Watch('staffSelected', {
		deep: true,
	})
	onStaffSelectedChanged(value) {
		if (value) {
			this.fetchDaycareClassByStudent();
		}
	}

	async fetchDaycareClassByStudent() {
		try {
			const {
				data: { data },
			} = await $api.staff.overloadTimeSlot({
				staff_id: this.deletedData?.staffId,
				substitute_staff_id: this.staffSelected?.id,
				student_id: Number(this.$route.params?.id),
			});

			this.timeSlots = data;
		} catch (e) {
			console.log(e);
		}
	}

	submit() {
		// @ts-ignore
		let id = this.staffSelected ? this.staffSelected.id + '' : '';
		this.$emit('submit', { staff_id: id, main: this.checkBoxSelected });
	}

	toEnglish(string = '') {
		return toEnglish(string);
	}
}
</script>
