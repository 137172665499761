<template>
	<div>
		<div v-for="(ass, index) in skills" :key="index" class="mt-10">
			<div class="font-semibold text-lg text-neutral-600 mb-2">
				{{ +index + 1 }}. {{ ass?.skill?.name }}
			</div>
			<div class="shadow-sm sm:rounded-2xl overflow-hidden">
				<div class="__record__table overflow-x-auto scroll-bar">
					<div
						class="
							overflow-hidden
							sm:rounded-t-2xl
							w-fit
							min-w-full
						"
					>
						<table class="min-w-full divide-y text-neutral-200">
							<thead class="bg-neutral-100 h-16">
								<tr>
									<th
										class="
											px-4
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
											w-16
										"
										scope="col"
									>
										STT
									</th>
									<th
										class="
											px-6
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											w-8/12
											whitespace-nowrap
										"
										scope="col"
										style="width: 350px"
									>
										Mục Tiêu
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(assessment, index) in ass.goals"
									:key="assessment?.goal_id || assessment?.id"
									:class="
										index % 2 === 0
											? 'bg-white'
											: 'bg-neutral-50'
									"
									class="
										cursor-pointer
										text-neutral-700
										hover:bg-orange-50
									"
									@click="
										$router.push(
											`/group-class/${group_class?.id}/${
												assessment?.goal_id ||
												assessment?.id
											}`
										)
									"
								>
									<td
										class="
											p-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div class="ml-4">
											{{ index + 1 }}
										</div>
									</td>
									<td
										class="
											px-6
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div>
											<div class="whitespace-normal">
												{{
													assessment?.goal_name ||
													assessment?.name
												}}
											</div>
											<div
												v-if="
													assessment?.student_assessment_group_weekly_reports
												"
												class="py-2"
											>
												<UserGroupReport
													:data="
														assessment?.student_assessment_group_weekly_reports
													"
													:students="students"
												></UserGroupReport>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import UserGroupReport from '@/components/elements/UserGroupReport.vue';

export default {
	name: 'GoalsGroupClass',
	components: { UserGroupReport },

	props: {
		skills: {
			type: Array,
			required: true,
		},

		students: {
			type: Array,
			required: true,
		},

		group_class: {
			type: Array,
			required: true,
		},
	},

	computed: {
		org() {
			return this.$store.state.profile.current_org;
		},
	},

	methods: {
		student(id) {
			return this.students?.find((s) => s.id === id);
		},
	},
};
</script>
